import React from "react";
import "./style.scss";
import { BiLoaderAlt } from "react-icons/bi";

type Props = {
	children: string | JSX.Element | any;
	className?: string;
	onClick?: any;
	style?: any;
	loading?: boolean;
	disabled?: boolean;
	type?: "button" | "submit" | "reset" | undefined;
	variant?: "pry" | "outline";
};

export default function Button({
	children,
	className,
	onClick,
	style,
	loading = false,
	disabled = false,
	type = "button",
	variant = "pry",
}: Props) {
	return (
		<button
			data-test-id="button"
			className={`Button Button--${variant} ${className} ${
				disabled ? "disabled" : ""
			}`}
			onClick={(e) => {
				if (disabled) e.preventDefault();
				else if (!disabled && !loading) onClick && onClick();
			}}
			style={style}
			type={type}
			disabled={disabled}
		>
			{loading ? <BiLoaderAlt className="btn-loader" /> : children}
		</button>
	);
}
