import {
	combinableSearch,
	fetchFlightData,
	fetchMulticityFlightData,
} from "../../../requests";
import { useTripStore } from "../../../zustand/store";
import CustomLoader from "../../CustomLoader";
import { useNavigate, useSearchParams } from "react-router-dom";
import { searchFlight } from "../../../helpers/searchFlightFlow";
import { useEffect } from "react";

const EditFauxPage = () => {
	const navigate = useNavigate();
	const [searchParams, setSearchParms] = useSearchParams();

	// zustand
	const bigFlightData = useTripStore((state: any) => state.bigFlightData);
	const updateBigFlightData = useTripStore(
		(state: any) => state.updateBigFlightData
	);
	const editFlightData = useTripStore((state: any) => state.editFlightData);
	const updateEditFlightData = useTripStore(
		(state: any) => state.updateEditFlightData
	);

	const localAirports = useTripStore((state: any) => state.localAirports);
	const globalAirports = useTripStore((state: any) => state.globalAirports);
	const searchLoading = useTripStore((state: any) => state.searchLoading);
	const setSearchLoading = useTripStore((state: any) => state.setSearchLoading);
	const arrivalLoading = useTripStore((state: any) => state.arrivalLoading);
	const setArrivalLoading = useTripStore(
		(state: any) => state.setArrivalLoading
	);

	//search flight flow

	const searchFlights = async () => {
		try {
			await searchFlight(
				false,
				searchLoading,
				setSearchLoading,
				arrivalLoading,
				setArrivalLoading,
				editFlightData,
				updateBigFlightData,
				navigate,
				JSON.parse(searchParams.get("direct") as string)
			);
		} catch (err) {
			console.error(err);
		}
	};

	useEffect(() => {
		updateBigFlightData({
			...editFlightData,
			selectedDeparture: undefined,
			selectedArrival: undefined,
		});
		searchFlights();
	}, []);

	if (searchLoading || arrivalLoading)
		return (
			<CustomLoader
				info={
					bigFlightData.showTripType === "Multicity"
						? bigFlightData.multiCity?.map((infoItem: any) => ({
								locations: `${
									globalAirports?.filter(
										(item: any) => item.iata_code === infoItem.departure
									)[0]?.city
								} (${infoItem.departure}) - ${
									globalAirports?.filter(
										(item: any) => item.iata_code === infoItem.destination
									)[0]?.city
								} (${infoItem?.destination})`,
								dates: `${new Date(infoItem.departure_date).toDateString()}`,
								other: `${bigFlightData.selectedTraveller?.length} adults, ${bigFlightData.cabin}`,
						  }))
						: {
								locations: `${bigFlightData?.departureCity[0]?.city} (${bigFlightData.departure}) - ${bigFlightData?.arrivalCity[0]?.city} (${bigFlightData?.destination})`,
								dates: `${new Date(
									bigFlightData.departure_date
								).toDateString()} ${
									bigFlightData.return_date
										? " - " + new Date(bigFlightData.return_date).toDateString()
										: ""
								}`,
								other: `${bigFlightData.selectedTraveller?.length} adults, ${bigFlightData.cabin}`,
						  }
				}
			/>
		);

	return <main></main>;
};

export default EditFauxPage;
