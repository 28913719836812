const ConfirmationLayout = ({ children }: { children: JSX.Element[] }) => {
	return (
		<div className="ConfirmationLayout">
			<div className="ConfirmationLayout__left">{children[0]}</div>
			<div className="ConfirmationLayout__right">{children[1]}</div>
		</div>
	);
};

export default ConfirmationLayout;
