import "./style.scss";

const EmptyTab = () => (
	<div className="EmptyTab">
		<p className="title">Coming Soon.</p>
		<p className="desc">
			{/* This feature is not available yet. You can continue exploring. */}
			We're cooking up something special. Check back soon!
		</p>
	</div>
);

export default EmptyTab;
