const SelectOption = ({
	city,
	airport,
	iata,
}: {
	city: string;
	airport: string;
	iata?: string;
}) => {
	return (
		<div className="parent-select" style={{ width: "100%" }}>
			<div
				style={{
					fontWeight: 450,
					fontSize: "12.17px",
					lineHeight: "15px",
					// color: "#9c9393"
				}}
				className="one"
			>
				{city}
			</div>
			<div
				className="two"
				style={{
					fontWeight: 450,
					fontSize: "12.17px",
					lineHeight: "15px",
					color: "#9c9393",
				}}
			>
				{airport}
			</div>
		</div>
	);
};
export const CheckBox = ({
	title,
	value,
	tripType,
}: {
	title: string;
	value?: string;
	tripType: any;
}) => {
	let { showTripType, setShowTripType } = tripType;
	return (
		<>
			<div
				className="trip-item"
				onClick={() => {
					setShowTripType(value ? value : title);
				}}
			>
				<div
					className={`radio hover ${
						showTripType === (value ? value : title) ? "active" : ""
					}`}
				>
					<div className="active-circle"></div>
				</div>

				<p>{title}</p>
			</div>
			<div
				className="trip-item mob"
				onClick={() => {
					setShowTripType(value ? value : title);
				}}
			>
				<p
					className={`${
						showTripType === (value ? value : title) ? "mob-active" : ""
					}`}
				>
					{title}
				</p>
			</div>
		</>
	);
};
export let localLocations = [
	{
		label: <SelectOption city="Abuja" airport="Abuja Airport" iata="ABV" />,
		value: "Abuja ",
		iata: "ABJ",
	},
	{
		label: <SelectOption city="Kano" airport="Kano Airport" iata="ABV" />,
		value: "Kano ",
	},
	{
		label: <SelectOption city="Lagos" airport="Lagos Airport" iata="ABV" />,
		value: "Lagos",
	},
];
