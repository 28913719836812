import React, { useState } from "react";
import { Outlet, Link, useLocation } from "react-router-dom";
import Header from "../components/Header";
import { MenuCancel } from "../components/pieces/svgs";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import MobileNavDrawer from "../components/Header/MobileNavDrawer";
import { toggleSideDrawer } from "../helpers/helperFuncs";

import "../App.scss";

const MainAppLayout = (): JSX.Element => {
	const [sideOpen, setSideOpen] = useState(false);

	let userPicture = "https://xsgames.co/randomusers/avatar.php?g=male";
	const { pathname } = useLocation();

	const navList = [
		{ label: "Dashboard", route: "/dashboard" },
		{ label: "Trips", route: "/trips" },
		{ label: "Trip History", route: "/trip-history" },
		{ label: "Invoicing", route: "/invoicing" },
		{ label: "Reporting", route: "/reporting" },
		{ label: "Events", route: "/events" },
	];

	const mobileNavList = [...navList, { label: "Settings", route: "/settings" }];

	return (
		<div>
			<Header sideOpen={sideOpen} setSideOpen={setSideOpen} />
			<div className="main">
				<Outlet />
				<SwipeableDrawer
					anchor="left"
					open={sideOpen}
					onClose={toggleSideDrawer(false, setSideOpen)}
					onOpen={toggleSideDrawer(true, setSideOpen)}
				>
					<MobileNavDrawer
						mobileNavList={mobileNavList}
						sideOpen={sideOpen}
						setSideOpen={setSideOpen}
					/>
				</SwipeableDrawer>
			</div>
		</div>
	);
};

export default MainAppLayout;
