import { SetStateAction, Dispatch } from "react";
import CustomFilter from "../Filter";
import { useTripStore } from "../../zustand/store";
import { IoClose } from "react-icons/io5";
import Button from "../Button";

interface MobileFiltersProps {
	triggerFilter: boolean;
	setTriggerFilter: Dispatch<SetStateAction<boolean>>;
	stopFilter: number | null;
	setStopFilter: Dispatch<SetStateAction<any>>;
	airlineArr: unknown[];
	airlineFilters: any;
	timeFilters: {
		departure: Array<"early" | "morning" | "afternoon" | "night">;
		arrival: Array<"early" | "morning" | "afternoon" | "night">;
	};
	setTimeFilters: Dispatch<
		SetStateAction<{
			departure: Array<"early" | "morning" | "afternoon" | "night">;
			arrival: Array<"early" | "morning" | "afternoon" | "night">;
		}>
	>;
	setAirlineFilters: Dispatch<SetStateAction<any>>;
	setOpenFilters: Dispatch<SetStateAction<any>>;
	jump: any;
	resultCount: number;
}

const MobileFilters = ({
	triggerFilter,
	setTriggerFilter,
	stopFilter,
	setStopFilter,
	airlineArr,
	airlineFilters,
	setAirlineFilters,
	timeFilters,
	setTimeFilters,
	setOpenFilters,
	jump,
	resultCount,
}: MobileFiltersProps) => {
	const bigFlightData = useTripStore((state: any) => state.bigFlightData);
	const updateBigFlightData = useTripStore(
		(state: any) => state.updateBigFlightData
	);

	console.log("airlineFilters", airlineFilters);

	return (
		<div className="MobileFilters">
			<div className="MobileFilters__Nav">
				<p>Flight details</p>
				<IoClose
					onClick={() => {
						setOpenFilters(false);
					}}
				/>
			</div>
			<div className="MobileFilters__rest">
				<p className="Showing">Showing 2 of 8 flights</p>

				<CustomFilter
					placeholder="Time"
					title="Flight Time"
					optionsList={[]}
					type="time"
					timeFilters={timeFilters}
					setTimeFilters={setTimeFilters}
				/>
				<CustomFilter
					placeholder="Stops"
					title="Stops"
					optionsList={[
						{ label: "Non-stop", value: 0 },
						{
							label: "1 stop or fewer",
							value: 1,
						},
						{
							label: "2 stop or fewer",
							value: 2,
						},
					]}
					type="radio"
					onSelect={(val: any) => {
						setStopFilter(val);
					}}
					selectedRadio={stopFilter}
				/>
				<CustomFilter
					placeholder="Airline"
					title="Airline"
					optionsList={airlineArr?.map((item: any) => ({
						label: item,
						value: item,
					}))}
					type="checkbox"
					onSelect={(val: any) => {
						jump(1);
						setTriggerFilter(!triggerFilter);
						if (airlineFilters.includes(val))
							setAirlineFilters([
								...airlineFilters?.filter((item: any) => item !== val),
							]);
						else setAirlineFilters([...airlineFilters, val]);
					}}
					selectedList={airlineFilters}
				/>
				<CustomFilter
					placeholder="Price"
					title="Price"
					optionsList={[
						{ label: "Refundable", value: "Refundable" },
						{
							label: "Non-Refundable",
							value: "Non-Refundable",
						},
						{
							label: "Mixed",
							value: "Mixed",
						},
					]}
					type="radio"
				/>
			</div>
			<div className="MobileFilters__Footer">
				<p>{resultCount} results found</p>
				<Button className="pry" onClick={() => setOpenFilters(false)}>
					See results
				</Button>
			</div>
		</div>
	);
};

export default MobileFilters;
