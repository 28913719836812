import "../style.scss";
import Button from "../../../components/Button";
import { useState } from "react";
import { changePassword } from "../../../requests";
import { toast } from "react-toastify";

const Security = () => {
	const [formValues, setFormValues] = useState({
		old_password: "",
		new_password1: "",
		new_password2: "",
	});
	const [loading, setLoading] = useState(false);

	const changeFunc = (e: React.ChangeEvent<HTMLInputElement>) => {
		setFormValues({ ...formValues, [e.target.name]: e.target.value });
		console.log(formValues);
	};

	return (
		<div className="SettingsCrux">
			<form
				className="SettingsCrux__main"
				autoComplete="off"
				onSubmit={async (e) => {
					e.preventDefault();
					setLoading(true);
					try {
						const res = await changePassword(formValues);
						if (res.status === 200) {
							toast.success("Password changed");
							setFormValues({
								old_password: "",
								new_password1: "",
								new_password2: "",
							});
						}
					} catch (e) {
						console.log(e);
					} finally {
						setLoading(false);
					}
				}}
			>
				<h3 className="protect-title">
					Protect your account with a unique password
				</h3>
				<div className="SettingsCrux__form">
					<label htmlFor="">
						Current password
						<input
							type="password"
							name="old_password"
							id=""
							value={formValues.old_password}
							onChange={changeFunc}
						/>
					</label>
					<label htmlFor="">
						New password
						<input
							type="password"
							name="new_password1"
							id=""
							autoComplete="nope"
							value={formValues.new_password1}
							onChange={changeFunc}
						/>
					</label>
					<label htmlFor="">
						Confirm new password
						<input
							type="password"
							name="new_password2"
							id=""
							autoComplete="nope"
							value={formValues.new_password2}
							onChange={changeFunc}
						/>
					</label>
				</div>
				<div className="submit-div">
					<Button className="pry" type="submit" loading={loading}>
						Save
					</Button>
				</div>
			</form>
			<div className="SettingsCrux__aside"></div>
		</div>
	);
};

export default Security;
