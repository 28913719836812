import "../style.scss";
import UploadPhoto from "../../../components/UploadPhoto";
import ToggleSwitch from "../../../components/ToggleSwitch";
import { MenuItem, Box, FormControl, Select } from "@mui/material";
import { useState, useRef } from "react";
import Button from "../../../components/Button";
import { industriesList } from "../../../helpers/constants";
import CustomDropdown from "../../../components/CustomDropdown";
import { createCompany, updateCompany } from "../../../requests";
import { useTripStore } from "../../../zustand/store";
import { toast } from "react-toastify";

const CompanyProfile = () => {
	const companyData = useTripStore((state: any) => state.companyData);
	const countryList = useTripStore((state: any) => state.countryList);

	const formRef = useRef<HTMLFormElement>(null);

	const [formValues, setFormValues] = useState(
		companyData
		// {
		// 	// business_name: userData?.business_name,
		// 	// business_bio: userData?.business_bio,
		// 	// business_industry: userData?.username,
		// 	// business_address: userData?.email,
		// 	// logo: userData?.job_role,
		// }
	);
	const [loading, setLoading] = useState(false);

	console.log("companyData", companyData);

	const changeFunc = (
		e:
			| React.ChangeEvent<HTMLInputElement>
			| React.ChangeEvent<HTMLTextAreaElement>
	) => {
		setFormValues({ ...formValues, [e.target.name]: e.target.value });
		console.log(formValues);
	};

	return (
		<div className="SettingsCrux">
			<form
				className="SettingsCrux__main"
				onSubmit={async (e) => {
					e.preventDefault();
					// setLoading(true);
					console.log(e.target);
					const myFormData = new FormData(formRef.current!);
					myFormData.append("business_industry", formValues?.business_industry);
					myFormData.append("business_country", formValues?.business_country);
					for (var pair of myFormData.entries()) {
						console.log(pair[0] + ", " + pair[1]);
					}
					try {
						// const { id, ...formValuesRest } = formValues;
						const res = await updateCompany(myFormData);
						console.log("updatePersonalProfile", res);
						if (res?.status === 200) toast.success("Details updated");
					} catch (e) {
						console.log(e);
						toast.error("Something went wrong");
					} finally {
						setLoading(false);
					}
				}}
				ref={formRef}
			>
				<div className="SettingsCrux__form">
					<label htmlFor="">
						Business Name
						<input
							type="text"
							name="business_name"
							id=""
							value={formValues?.business_name}
							onChange={changeFunc}
						/>
					</label>
					<label htmlFor="">
						Company bio
						<textarea
							name="business_bio"
							id=""
							rows={3}
							value={formValues?.business_bio}
							onChange={changeFunc}
						></textarea>
					</label>
					<label htmlFor="">
						Business industry
						<div style={{ marginTop: "0.75rem" }}>
							<CustomDropdown
								onSelect={(val: any) => {
									setFormValues({
										...formValues,
										business_industry: val,
									});
								}}
								defaultValue={formValues.business_industry}
								optionsList={industriesList.map((item) => ({
									label: item,
									value: item,
								}))}
								ind={0}
							/>
						</div>
					</label>
					<label htmlFor="">
						Business Address
						<input
							type="text"
							name="business_address"
							id=""
							value={formValues?.business_address}
							onChange={changeFunc}
						/>
					</label>
					<label htmlFor="">
						City
						<input
							type="text"
							name="business_city"
							id=""
							value={formValues?.business_city}
							onChange={changeFunc}
						/>
					</label>
					<label htmlFor="">
						State
						<input
							type="text"
							name="business_state"
							id=""
							value={formValues?.business_state}
							onChange={changeFunc}
						/>
					</label>
					<label htmlFor="">
						Country
						<div style={{ marginTop: "0.75rem" }}>
							<CustomDropdown
								onSelect={(val: any) => {
									setFormValues({
										...formValues,
										business_country: val,
									});
								}}
								defaultValue={formValues?.business_country}
								optionsList={countryList.map((item: any) => ({
									label: item?.name?.common,
									value: item?.cca2,
								}))}
								ind={1}
							/>
						</div>
					</label>
				</div>

				<div className="submit-div">
					<Button className="pry" type="submit" loading={loading}>
						Save
					</Button>
				</div>
			</form>
			<div className="SettingsCrux__aside">
				<UploadPhoto title="Company's photo" />
			</div>
		</div>
	);
};

export default CompanyProfile;
