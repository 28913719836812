import { useState, useEffect, useRef } from "react";
import "./style.scss";
import PlainTable from "../../../components/PlainTable";
import { RiErrorWarningFill } from "react-icons/ri";
import { HiPrinter } from "react-icons/hi";
import { MdOutlineContactSupport } from "react-icons/md";
import UserAvatar from "../../../components/UserAvatar";
import { useTripStore } from "../../../zustand/store";
import { toast } from "react-toastify";
import { DateTime } from "ts-luxon";
import { minutesToDuration } from "../../../helpers/helperFuncs";
import {
	FlightDetailsBit,
	ShowDetailsDrawer,
	PassengerBlock,
	FootNoteCard,
	PriceBreakdownBlock,
} from "../../Flights/confirmationComponents";
import ConfirmationLayout from "../../../layouts/ConfirmationLayout";
import { ConfirmedStamp } from "../../../components/pieces/svgs";
import confirmed from "../../../assets/images/confirmed.svg";
import { CarDetailsCard } from "./components";
import PriceBreakdownBlockCar from "../../Flights/confirmationComponents/PriceBreakdownBlockCar";

const CarSuccessfulPage = (props: any) => {
	// zustand states
	const bigCarData = useTripStore((state: any) => state.bigCarData);
	const updateBigCarData = useTripStore((state: any) => state.updateBigCarData);

	const [imgUrl, setImgUrl] = useState("/images/fun-img.png");
	const [FormattedAddr, setFormattedAddr] = useState("/images/fun-img.png");
	const tripRef = useRef<HTMLDivElement>(null);

	return (
		<>
			<div className="CarSuccessfulPage">
				<ConfirmationLayout>
					<div>
						<img
							src={confirmed}
							alt=""
							style={{
								width: "84px",
								height: "84px",
							}}
						/>
						<h3>
							Your <span>{bigCarData?.carType?.car_type}</span> car is on its
							way!
						</h3>
						<p className="msg">
							Thanks for choosing Kardinal. Your request is being processed, and
							will be sent to your email and to the emails of all other
							passengers on this trip.
						</p>
						<PassengerBlock disabled={true} variant="car" />
						<div className="left-tables">
							<PlainTable
								tableHead="Booking Information"
								tableBody={<PriceBreakdownBody props={props} />}
							/>
							<div className="dets">
								<p className="dets__title">Booking details</p>
								<CarDetailsCard />
							</div>
							<FootNoteCard
								title="2474U"
								textCol="#FCA17D"
								bgCol="#FFEAE2"
								icon={<MdOutlineContactSupport size={16} />}
							>
								<div>
									<p style={{ marginBottom: "0.8rem" }}>
										Thank you for your booking.
									</p>
									<p>
										We are available to help you 24/7. Please contact us on any
										of the number below:{" "}
										<a href="tel:+2348026993460">+234 802 6993 460</a>,{" "}
										<a href="tel:+2348121463765">+234 802 5198 619</a> or email
										us on{" "}
										<a href="mailto:bookings@gokardinal.com">
											bookings@gokardinal.com
										</a>
									</p>
								</div>
							</FootNoteCard>
						</div>
					</div>
					<div>
						<div style={{ marginTop: "6.2rem" }}>
							<PriceBreakdownBlockCar
								base_fare={bigCarData?.searchResults[0]?.base_fare}
								booking_fee={bigCarData?.searchResults[0]?.booking_fee}
								parking_fee={bigCarData?.searchResults[0]?.parking_fee}
							/>
						</div>
					</div>
				</ConfirmationLayout>
			</div>
			<div className="CarSuccessfulPage-mobile">
				<div>
					<img src={confirmed} alt="" />

					<h3>
						Your <span>{bigCarData?.carType?.car_type}</span> car is on its way!
					</h3>
					<p className="msg">
						Thanks for choosing Kardinal. Your request is being processed, and
						will be sent to your email and to the emails of all other passengers
						on this trip.
					</p>
				</div>
				<div className="passengers">
					<PassengerBlock disabled={true} variant="car" />
				</div>

				{/* <FlightDetailsBit /> */}
				<div className="booking-details">
					<p className="booking-details__title">Booking details</p>
					<CarDetailsCard />
				</div>

				<div className="price-breakdown">
					<PriceBreakdownBlockCar
						base_fare={bigCarData?.searchResults[0]?.base_fare}
						booking_fee={bigCarData?.searchResults[0]?.booking_fee}
						parking_fee={bigCarData?.searchResults[0]?.parking_fee}
					/>
				</div>

				<FootNoteCard
					title="2474U"
					textCol="#FCA17D"
					bgCol="#FFEAE2"
					icon={<MdOutlineContactSupport size={16} />}
				>
					<div>
						<p style={{ marginBottom: "0.8rem" }}>
							Thank you for your booking.
						</p>
						<p>
							We are available to help you 24/7. Please contact us on any of the
							number below: <a href="tel:+2348026993460">+234 802 6993 460</a>,{" "}
							<a href="tel:+2348121463765">+234 802 5198 619</a> or email us on{" "}
							<a href="mailto:bookings@gokardinal.com">
								bookings@gokardinal.com
							</a>
						</p>
					</div>
				</FootNoteCard>
			</div>
			<div ref={tripRef}></div>
		</>
	);
};

export default CarSuccessfulPage;

// tableBody Components
const PriceBreakdownBody = (props: any) => {
	const bigCarData = useTripStore((state: any) => state.bigCarData);
	const updateBigCarData = useTripStore((state: any) => state.updateBigCarData);

	return (
		<div className="tableBody priceBreakdown">
			<div className="double-col">
				<p>Booking Reference</p>
				<p style={{ color: "#252627" }}>{bigCarData?.kardinal_reference}</p>
			</div>
			<div className="double-col">
				<p>Booking Date</p>
				<p style={{ color: "#252627" }}>
					{DateTime.fromISO(new Date().toISOString()).toLocaleString({
						weekday: "short",
						day: "2-digit",
						month: "short",
					})}
				</p>
			</div>
			<div className="double-col">
				<p>Payment Status</p>
				<p style={{ color: "#21D047" }}>Confirmed!</p>
			</div>
			{/* <div className="double-col">
				<p></p>
				<p className="printer">
					<HiPrinter />
					<span>Print ticket</span>
				</p>
			</div> */}
		</div>
	);
};
