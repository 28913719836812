import {
	TripIcon,
	HotelIcon,
	MailIcon,
	ViewConfirmationIcon,
	ChangeTicketIcon,
	SendTicketIcon,
	OpenTicketIcon,
	CancelBookingIcon,
	UpArrowIcon,
	DownArrowIcon,
	FlightIcon,
} from "../../components/pieces/svgs";
import {
	InputEmailModal,
	ChangeTicketModal,
	CancelBookingModal,
	OpenTicketModal,
	TripHistoryDrawer,
} from "./ModalComponents";
import { BiSearch, BiDotsVerticalRounded } from "react-icons/bi";
import { BsDownload } from "react-icons/bs";
import { FiArrowUpRight } from "react-icons/fi";
import { TbArrowsLeftRight } from "react-icons/tb";
import MUIModal from "../../components/MUIModal";
import "./style.scss";
import ComingSoon from "../ComingSoon";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { fetchTripHistoryDetail } from "../../requests/trip-history";
import { DateTime } from "ts-luxon";
import {
	ellipsisTruncation,
	parseItinerary,
	toggleSideDrawer,
} from "../../helpers/helperFuncs";
import SpinLoader from "../../components/SpinLoader";
import { useTripStore } from "../../zustand/store";
import { useNavigate } from "react-router-dom";
import { FullItenaryMobileDrawer, FullItenaryModal } from "../../modals";
import { Drawer } from "@mui/material";
import { ReactComponent as OneWayArrowIcon } from "../../assets/svgs/one-way-arrow.svg";
import { ReactComponent as RoundTripArrowIcon } from "../../assets/svgs/round-trip-arrow.svg";

interface TripInfoDrawerProps {
	data: any;
	refetch: boolean;
	setRefetch: Dispatch<SetStateAction<boolean>>;
	ind: number;
	openIndex: number | null;
	setOpenIndex: Dispatch<SetStateAction<number | null>>;
}

const TripInfoDrawer = ({
	data,
	refetch,
	setRefetch,
	openIndex,
	ind,
	setOpenIndex,
}: TripInfoDrawerProps) => {
	const navigate = useNavigate();

	const [openModal, setOpenModal] = useState(false);
	const [openDrawer, setOpenDrawer] = useState(false);
	const [openTicketModal, setOpenTicketModal] = useState(false);
	const [openDetailsModal, setOpenDetailsModal] = useState(false);
	const [openMobileDetailsModal, setOpenMobileDetailsModal] = useState(false);
	const [openChangeModal, setOpenChangeModal] = useState(false);
	const [openCancelModal, setOpenCancelModal] = useState(false);
	const [openSendEmailModal, setOpenSendEmailModal] = useState(false);
	const [modalComponent, setModalComponent] = useState(<></>);
	const [loading, setLoading] = useState(false);
	const [tripData, setTripData] = useState<any>({});

	//zustand states
	const bigFlightData = useTripStore((state: any) => state.bigFlightData);
	const updateBigFlightData = useTripStore(
		(state: any) => state.updateBigFlightData
	);
	const updateBigCarData = useTripStore((state: any) => state.updateBigCarData);
	const myTravellers = useTripStore((state: any) => state.myTravellers);
	const globalAirports = useTripStore((state: any) => state.globalAirports);

	useEffect(() => {
		const fetchTripHistoryList = async () => {
			setLoading(true);
			try {
				const res = await fetchTripHistoryDetail(
					data?.kardinal_reference,
					data?.service
				);
				console.log("fetchTripHistoryDetail", res.data?.data);
				if (res.data?.message === "success") {
					setTripData(res.data?.data);
				}
			} catch (error) {
				console.log(error);
			} finally {
				setLoading(false);
			}
		};

		if (ind !== openIndex) fetchTripHistoryList();
	}, [ind === openIndex]);

	console.log("bigFlightData", bigFlightData);
	console.log("myTravellers", myTravellers);

	const populateBigFlightData = () => {
		if (tripData?.booking_category === "domestic")
			updateBigFlightData({
				selectedTraveller: tripData?.passengers?.map(
					(item: any) =>
						myTravellers?.filter(
							(travellerItem: any) =>
								travellerItem?.first_name === item?.first_name &&
								travellerItem?.middle_name === item?.middle_name &&
								travellerItem?.last_name === item?.last_name
						)[0]
				),
				showTripType:
					data?.trip_type === "oneway"
						? "One way"
						: data?.trip_type === "return"
						? "Roundtrip"
						: data?.trip_type === "multicity"
						? "Multicity"
						: "Roundtrip",
				route: tripData?.booking_category,
				departureCity: globalAirports?.filter(
					(item: any) => item.iata_code === data?.origin
				),
				arrivalCity: globalAirports?.filter(
					(item: any) => item.iata_code === data?.destination
				),
				bookingConfirmed: true,
				cabin: "Economy",
				departure: data?.origin,
				departure_date: data?.departure_date,
				destination: data?.destination,
				flightSelected: true,
				multiCity:
					data?.trip_type === "multicity"
						? parseItinerary(tripData?.itenary).trips
						: [],
				paymentConfirmed: true,
				return_date: data?.return_date,
				flightReference: data?.kardinal_reference,
				// ss: tripData?.flight[0],
				selectedDeparture: {
					id: "placeholder, not null",
					fare_basis: null,
					amount: tripData?.price,
					travelers_price: [
						{
							adult: tripData?.price,
						},
					],
					price_summary: [
						{
							passenger_type: "adult",
							total_price: tripData?.price,
							quantity: 1,
						},
					],
					currency: "NGN",
					total_duration: 75,
					outbound: [
						{
							equipment_type: null,
							airport_to: tripData?.flight[0]?.destination,
							marketing_airline: tripData?.flight[0]?.airline_logo
								.split("/")
								.pop()
								.split(".")[0],
							baggage: tripData?.flight[0]?.baggage,
							arrival_time: tripData?.flight[0]?.return_date,
							marriage_group: null,
							overnight: true,
							departure_time: tripData?.flight[0]?.travel_date,
							airport_from: tripData?.flight[0]?.origin,
							booking_class: null,
							duration: 75,
							operating_airline: tripData?.flight[0]?.airline_logo
								.split("/")
								.pop()
								.split(".")[0],
							flight_number: tripData?.flight[0]?.airline_number,
							cabin_type: tripData?.flight[0]?.cabin_type,
							layover: null,
							airline_details: {
								logo: tripData?.flight[0]?.airline_logo,
								name: tripData?.flight[0]?.airline_name,
								code: tripData?.flight[0]?.airline_logo
									.split("/")
									.pop()
									.split(".")[0],
							},
						},
					],
					inbound: [],
					total_outbound_duration: 75,
					total_inbound_duration: null,
					outbound_stops: 0,
					inbound_stops: 0,
					pricing: {
						payable: tripData?.price,
						markup: {
							markup_value: 3000,
							markup_type: "fixed",
						},
					},
				},
				selectedArrival:
					data?.trip_type === "return"
						? {
								id: "placeholder, not null",
								fare_basis: null,
								amount: 0,
								travelers_price: [
									{
										adult: tripData?.price,
									},
								],
								price_summary: [
									{
										passenger_type: "adult",
										total_price: tripData?.price,
										quantity: 1,
									},
								],
								currency: "NGN",
								total_duration: 75,
								outbound: [
									{
										equipment_type: null,
										airport_to: tripData?.flight[1]?.destination,
										marketing_airline: tripData?.flight[0]?.airline_logo
											.split("/")
											.pop()
											.split(".")[0],
										baggage: tripData?.flight[1]?.baggage,
										arrival_time: tripData?.flight[1]?.return_date,
										marriage_group: null,
										overnight: true,
										departure_time: tripData?.flight[1]?.travel_date,
										airport_from: tripData?.flight[1]?.origin,
										booking_class: null,
										duration: 75,
										operating_airline: tripData?.flight[0]?.airline_logo
											.split("/")
											.pop()
											.split(".")[0],
										flight_number: tripData?.flight[1]?.airline_number,
										cabin_type: tripData?.flight[1]?.cabin_type,
										layover: null,
										airline_details: {
											logo: tripData?.flight[1]?.airline_logo,
											name: tripData?.flight[1]?.airline_name,
											code: tripData?.flight[0]?.airline_logo
												.split("/")
												.pop()
												.split(".")[0],
										},
									},
								],
								inbound: [],
								total_outbound_duration: 75,
								total_inbound_duration: null,
								outbound_stops: 0,
								inbound_stops: 0,
								pricing: {
									payable: tripData?.price,
									markup: {
										markup_value: 3000,
										markup_type: "fixed",
									},
								},
						  }
						: undefined,
				selectedMultiCityFlights: tripData?.flight?.map(
					(item: any, ind: number) => ({
						id: "placeholder, not null",
						fare_basis: null,
						amount: ind === 0 ? tripData?.price : 0,
						travelers_price: [
							{
								adult: tripData?.price,
							},
						],
						price_summary: [
							{
								passenger_type: "adult",
								total_price: tripData?.price,
								quantity: 1,
							},
						],
						currency: "NGN",
						total_duration: 75,
						outbound: [
							{
								equipment_type: null,
								airport_to: item?.destination,
								marketing_airline: tripData?.flight[0]?.airline_logo
									.split("/")
									.pop()
									.split(".")[0],
								baggage: item?.baggage,
								arrival_time: item?.return_date,
								marriage_group: null,
								overnight: true,
								departure_time: item?.travel_date,
								airport_from: item?.origin,
								booking_class: null,
								duration: 75,
								operating_airline: tripData?.flight[0]?.airline_logo
									.split("/")
									.pop()
									.split(".")[0],
								flight_number: item?.airline_number,
								cabin_type: item?.cabin_type,
								layover: null,
								airline_details: {
									logo: item?.airline_logo,
									name: item?.airline_name,
									code: tripData?.flight[0]?.airline_logo
										.split("/")
										.pop()
										.split(".")[0],
								},
							},
						],
						inbound: [],
						total_outbound_duration: 75,
						total_inbound_duration: null,
						outbound_stops: 0,
						inbound_stops: 0,
						pricing: {
							payable: tripData?.price,
							markup: {
								markup_value: 3000,
								markup_type: "fixed",
							},
						},
					})
				),
			});
		else {
			const breakPointInd = tripData?.flight?.findIndex(
				(item: any) => item?.destination === data?.destination
			);
			updateBigFlightData({
				selectedTraveller: tripData?.passengers?.map(
					(item: any) =>
						myTravellers?.filter(
							(travellerItem: any) =>
								travellerItem?.first_name === item?.first_name &&
								travellerItem?.middle_name === item?.middle_name &&
								travellerItem?.last_name === item?.last_name
						)[0]
				),
				showTripType:
					data?.trip_type === "oneway"
						? "One way"
						: data?.trip_type === "return"
						? "Roundtrip"
						: data?.trip_type === "multicity"
						? "Multicity"
						: "Roundtrip",
				route: tripData?.booking_category,
				departureCity: globalAirports?.filter(
					(item: any) => item.iata_code === data?.origin
				),
				arrivalCity: globalAirports?.filter(
					(item: any) => item.iata_code === data?.destination
				),
				bookingConfirmed: true,
				cabin: tripData?.flight[0]?.cabin_type,
				departure: data?.origin,
				departure_date: data?.departure_date,
				destination: data?.destination,
				flightSelected: true,
				multiCity:
					data?.trip_type === "multicity"
						? parseItinerary(tripData?.itenary).trips
						: [],
				paymentConfirmed: true,
				return_date: data?.return_date,
				flightReference: data?.kardinal_reference,
				// ss: tripData?.flight[0],
				selectedDeparture: {
					id: "placeholder, not null",
					fare_basis: null,
					amount: tripData?.price,
					tax: 0,
					travelers_price: [
						{
							adult: tripData?.price,
						},
					],
					price_summary: [
						{
							passenger_type: "adult",
							total_price: tripData?.price,
							quantity: 1,
						},
					],
					currency: "NGN",
					total_duration: 75,
					outbound: tripData?.flight
						.slice(0, breakPointInd + 1)
						?.map((item: any) => ({
							equipment_type: null,
							airport_to: item?.destination,
							marketing_airline: item?.airline_logo
								.split("/")
								.pop()
								.split(".")[0],
							baggage: item?.baggage,
							arrival_time: item?.return_date, // need this (ask AY)
							marriage_group: null,
							overnight: true,
							departure_time: item?.travel_date,
							airport_from: item?.origin,
							booking_class: null,
							duration: 75,
							operating_airline: item?.airline_logo
								.split("/")
								.pop()
								.split(".")[0],
							flight_number: item?.airline_number,
							cabin_type: item?.cabin_type,
							layover: null,
							airline_details: {
								logo: item?.airline_logo,
								name: item?.airline_name,
								code: item?.airline_logo.split("/").pop().split(".")[0],
							},
						})),
					outbound_return: tripData?.flight
						.slice(breakPointInd + 1)
						?.map((item: any) => ({
							equipment_type: null,
							airport_to: item?.destination,
							marketing_airline: item?.airline_logo
								.split("/")
								.pop()
								.split(".")[0],
							baggage: item?.baggage,
							arrival_time: item?.return_date, // need this (ask AY)
							marriage_group: null,
							overnight: true,
							departure_time: item?.travel_date,
							airport_from: item?.origin,
							booking_class: null,
							duration: 75,
							operating_airline: item?.airline_logo
								.split("/")
								.pop()
								.split(".")[0],
							flight_number: item?.airline_number,
							cabin_type: item?.cabin_type,
							layover: null,
							airline_details: {
								logo: item?.airline_logo,
								name: item?.airline_name,
								code: item?.airline_logo.split("/").pop().split(".")[0],
							},
						})),
					inbound: [],
					total_outbound_duration: 75,
					total_inbound_duration: null,
					outbound_stops: 0,
					inbound_stops: 0,
					pricing: {
						payable: tripData?.price,
						markup: {
							markup_value: 3000,
							markup_type: "fixed",
						},
					},
				},
				selectedArrival:
					data?.trip_type === "return"
						? {
								id: "placeholder, not null",
								fare_basis: null,
								amount: 0,
								tax: 0,
								travelers_price: [
									{
										adult: tripData?.price,
									},
								],
								price_summary: [
									{
										passenger_type: "adult",
										total_price: tripData?.price,
										quantity: 1,
									},
								],
								currency: "NGN",
								total_duration: 75,
								outbound: tripData?.flight
									.slice(0, breakPointInd + 1)
									?.map((item: any) => ({
										equipment_type: null,
										airport_to: item?.destination,
										marketing_airline: item?.airline_logo
											.split("/")
											.pop()
											.split(".")[0],
										baggage: item?.baggage,
										arrival_time: item?.return_date, // need this (ask AY)
										marriage_group: null,
										overnight: true,
										departure_time: item?.travel_date,
										airport_from: item?.origin,
										booking_class: null,
										duration: 75,
										operating_airline: item?.airline_logo
											.split("/")
											.pop()
											.split(".")[0],
										flight_number: item?.airline_number,
										cabin_type: item?.cabin_type,
										layover: null,
										airline_details: {
											logo: item?.airline_logo,
											name: item?.airline_name,
											code: item?.airline_logo.split("/").pop().split(".")[0],
										},
									})),
								outbound_return: tripData?.flight
									.slice(breakPointInd + 1)
									?.map((item: any) => ({
										equipment_type: null,
										airport_to: item?.destination,
										marketing_airline: item?.airline_logo
											.split("/")
											.pop()
											.split(".")[0],
										baggage: item?.baggage,
										arrival_time: item?.return_date, // need this (ask AY)
										marriage_group: null,
										overnight: true,
										departure_time: item?.travel_date,
										airport_from: item?.origin,
										booking_class: null,
										duration: 75,
										operating_airline: item?.airline_logo
											.split("/")
											.pop()
											.split(".")[0],
										flight_number: item?.airline_number,
										cabin_type: item?.cabin_type,
										layover: null,
										airline_details: {
											logo: item?.airline_logo,
											name: item?.airline_name,
											code: item?.airline_logo.split("/").pop().split(".")[0],
										},
									})),
								inbound: [],
								total_outbound_duration: 75,
								total_inbound_duration: null,
								outbound_stops: 0,
								inbound_stops: 0,
								pricing: {
									payable: tripData?.price,
									markup: {
										markup_value: 3000,
										markup_type: "fixed",
									},
								},
						  }
						: undefined,
				selectedMultiCityFlights: tripData?.flight?.map(
					(item: any, ind: number) => ({
						id: "placeholder, not null",
						fare_basis: null,
						amount: ind === 0 ? tripData?.price : 0,
						travelers_price: [
							{
								adult: tripData?.price,
							},
						],
						price_summary: [
							{
								passenger_type: "adult",
								total_price: tripData?.price,
								quantity: 1,
							},
						],
						currency: "NGN",
						total_duration: 75,
						outbound: [
							{
								equipment_type: null,
								airport_to: item?.destination,
								marketing_airline: tripData?.flight[0]?.airline_logo
									.split("/")
									.pop()
									.split(".")[0],
								baggage: item?.baggage,
								arrival_time: item?.return_date,
								marriage_group: null,
								overnight: true,
								departure_time: item?.travel_date,
								airport_from: item?.origin,
								booking_class: null,
								duration: 75,
								operating_airline: tripData?.flight[0]?.airline_logo
									.split("/")
									.pop()
									.split(".")[0],
								flight_number: item?.airline_number,
								cabin_type: item?.cabin_type,
								layover: null,
								airline_details: {
									logo: item?.airline_logo,
									name: item?.airline_name,
									code: tripData?.flight[0]?.airline_logo
										.split("/")
										.pop()
										.split(".")[0],
								},
							},
						],
						inbound: [],
						total_outbound_duration: 75,
						total_inbound_duration: null,
						outbound_stops: 0,
						inbound_stops: 0,
						pricing: {
							payable: tripData?.price,
							markup: {
								markup_value: 3000,
								markup_type: "fixed",
							},
						},
					})
				),
			});
		}
	};

	const populateBigCarData = () => {
		updateBigCarData({
			selectedTraveller: tripData?.passengers,
			service_type: tripData?.trip_type,
			carType: {
				id: 300,
				car_type: tripData?.carType,
				picture: "image/upload/v1684849378/djdd5rwosrilfposdexf.jpg",
				air_condition: tripData?.air_condition,
				no_of_seats: tripData?.no_of_seats,
			},
			date: new Date(tripData?.pickup_time).toISOString().slice(0, 10),
			ISODate: tripData?.pickup_time,
			time: {},
			pickUp: {
				id: 1,
				location_code: "ALB",
				location_name: tripData?.pickup,
			},
			dropOff: {
				id: 4,
				location_code: "MRY",
				location_name: tripData?.dropoff,
			},
			bookingConfirmed: true,
			searchResults: [
				{
					base_fare: tripData?.base_fare,
					booking_fee: tripData?.booking_fee,
					parking_fee: tripData?.parking_fee,
				},
			],
			kardinal_reference: tripData?.kardinal_reference,
			bookingDate: data?.created_at,
		});
	};

	return (
		<div className="TripInfoDrawer">
			<div
				className={
					ind !== openIndex
						? "TripInfoDrawer__Overview"
						: "TripInfoDrawer__Overview overviewOpen"
				}
				onClick={() => {
					setOpenIndex(ind === openIndex ? null : ind);
					// navigator.vibrate(1000);
				}}
			>
				<div className="topDrawer mobile-hide">
					{tripData?.status === "open" && (
						<p className="open-ticket">Open Ticket</p>
					)}
					{tripData?.status === "upcoming" &&
						data?.any_process &&
						data?.process_type === "open_ticket" && (
							<p className="open-ticket open-ticket--processing">Processing</p>
						)}
				</div>
				<div className="bottomDrawer">
					<div className="left-flex">
						<div className="big-icon">
							{data?.service === "flight" && <FlightIcon />}
							{data?.service === "cars" && <TripIcon />}
							{data?.service === "hotel" && <HotelIcon />}
						</div>
						<div className="plenty-text">
							<div className="namesNum">
								<p>{`${data?.passengers[0]?.first_name} ${data?.passengers[0]?.last_name}`}</p>
								{data?.passengers?.length > 1 && (
									<span> +{data?.passengers?.length - 1}</span>
								)}
							</div>
							<div className="title">
								{data?.service === "flight" &&
									(data?.trip_type === "multicity" ? (
										<p>Multi-city</p>
									) : (
										<>
											<p>
												{data?.origin_city} ({data?.origin})
											</p>
											{data?.trip_type === "oneway" ? (
												<OneWayArrowIcon />
											) : data?.trip_type === "return" ? (
												<RoundTripArrowIcon />
											) : (
												<></>
											)}
											<p>
												{data?.destination_city} ({data?.destination})
											</p>
										</>
									))}
								{data?.service === "hotel" && <p>{data?.hotelName}</p>}
								{data?.service === "cars" && (
									<>
										<p>{data?.pickup}</p>
										<OneWayArrowIcon />
										<p>{data?.dropoff}</p>
									</>
								)}
							</div>
							<div className="bottom">
								{data?.service === "flight" && (
									<p>
										{DateTime.fromISO(data?.departure_date).toLocaleString({
											day: "2-digit",
											month: "short",
										})}
										{data?.return_date &&
											" - " +
												DateTime.fromISO(data?.return_date).toLocaleString({
													day: "2-digit",
													month: "short",
												})}
									</p>
								)}
								{data?.service === "cars" && (
									<>
										<p>
											{DateTime.fromISO(data?.pickup_time).toLocaleString({
												day: "2-digit",
												month: "short",
											})}
										</p>
										<p>
											{DateTime.fromISO(data?.pickup_time).toLocaleString(
												DateTime.TIME_24_SIMPLE
											)}
										</p>
									</>
								)}

								<span className="dot">•</span>
								<p>
									{data?.no_of_passengers}{" "}
									{`passenger${data?.no_of_passengers === 1 ? "" : "s"}`}
								</p>
								<span className="dot">•</span>
								<p>
									{data?.service === "flight"
										? // ellipsisTruncation(data?.airline_name, 15)
										  data?.airline_name
										: data?.service === "cars"
										? data?.carType
										: `${data?.rooms} rooms`}
								</p>
							</div>
						</div>
					</div>
					<div className="center-flex desktop-hide">
						{tripData?.status === "open" && (
							<p className="ticket-status__Open">Open ticket</p>
						)}
						{tripData?.status === "upcoming" &&
							data?.any_process &&
							data?.process_type === "open_ticket" && (
								<p className="ticket-status__Open ticket-status__Open--processing">
									Processing
								</p>
							)}
					</div>
					<div className="right-flex">
						<div className="actions">
							<div>View trip</div>
							<div className="arrow">
								{ind === openIndex ? <UpArrowIcon /> : <DownArrowIcon />}
							</div>
						</div>
						{/* <div className="mobile-actions">
							<AiFillFolderOpen onClick={() => setIsOpen(!isOpen)} />
							<BsDownload />
						</div> */}
					</div>
				</div>
			</div>
			<div
				className={`TripInfoDrawer__MoreInfo ${
					ind !== openIndex && "TripInfoDrawer__MoreInfo--shut"
				}`}
			>
				{loading ? (
					<div
						style={{
							display: "grid",
							placeItems: "center",
							gridTemplateColumns: "unset",
							height: "100%",
							minHeight: "220px",
						}}
					>
						<SpinLoader height="150" />
					</div>
				) : (
					<>
						<div className="mobile-actions">
							<div>
								<BiDotsVerticalRounded
									onClick={() => {
										setOpenDrawer(true);
									}}
								/>

								{/* <ViewConfirmationIcon className="action--inactive" />
						<ChangeTicketIcon
							onClick={() => {
								setModalComponent(
									<TicketModal
										title="Change Ticket"
										buttonAction={() => setOpenModal(false)}
									/>
								);
								setOpenModal(true);
							}}
							className="action--active"
						/>
						<SendTicketIcon
							className="action--active"
							onClick={() => {
								setModalComponent(
									<InputEmailModal buttonAction={() => setOpenModal(false)} />
								);
								setOpenModal(true);
							}}
						/>
						<OpenTicketIcon
							onClick={() => {
								setModalComponent(
									<TicketModal
										title="Open Ticket"
										buttonAction={() => setOpenModal(false)}
									/>
								);
								setOpenModal(true);
							}}
							className="action--active"
						/>
						<CancelBookingIcon
							onClick={() => {
								setModalComponent(
									<TicketModal
										title="Cancel Ticket"
										buttonAction={() => setOpenModal(false)}
									/>
								);
								setOpenModal(true);
							}}
							className="action--red"
						/> */}
							</div>
						</div>
						<div>
							<div className="left">
								<p className="title">Travellers</p>
								<p className="names">
									{tripData?.passengers?.length
										? tripData?.passengers?.map(
												(item: any, ind: number) =>
													`${item?.first_name} ${item?.last_name}${
														ind < tripData?.passengers?.length - 1 ? ", " : ""
													}${
														ind === tripData?.passengers?.length - 2
															? "and "
															: ""
													}`
										  )
										: "No passengers"}
								</p>
								{data?.service === "flight" && (
									<div
										className="blue-link"
										onClick={() => {
											populateBigFlightData();
											if (document.documentElement.clientWidth > 780)
												setOpenDetailsModal(true);
											else setOpenMobileDetailsModal(true);
										}}
									>
										<p>View full Itinerary</p>
										<FiArrowUpRight />
									</div>
								)}
								<div className="total-amount">
									<p>Total Amount</p>
									<p>
										₦{" "}
										{(data?.service === "flight"
											? tripData?.price
											: data?.service === "cars"
											? tripData?.total_amount
											: tripData?.total_amount
										)
											?.toFixed(2)
											.replace(/\d(?=(\d{3})+\.)/g, "$&,")}
									</p>
								</div>
							</div>
							<div className="right">
								<div className="actions">
									<p className="actions__title">More actions</p>
									<div
										className="action action--active"
										onClick={() => {
											if (data?.service === "flight") {
												populateBigFlightData();
												setTimeout(() => {
													navigate("/trips/booking-complete");
												}, 500);
											}
											if (data?.service === "cars") {
												populateBigCarData();
												setTimeout(() => {
													navigate("/trips/car-complete");
												}, 500);
											}
										}}
									>
										<ViewConfirmationIcon />
										<p>View confirmation page</p>
									</div>
									<div
										className={`action action--${
											(data?.any_process &&
												data?.process_type === "cancel_booking") ||
											data?.status === "completed"
												? "inactive"
												: "active"
										}`}
										onClick={() => {
											if (
												data?.status !== "completed" &&
												!(
													data?.any_process &&
													(data?.process_type === "date_change" ||
														data?.process_type === "route_change" ||
														data?.process_type === "cancel_booking")
												)
											)
												setOpenChangeModal(true);
										}}
									>
										<ChangeTicketIcon />
										<p>
											{data?.any_process &&
											(data?.process_type === "date_change" ||
												data?.process_type === "route_change")
												? "Processing (Change ticket)"
												: "Change ticket"}
										</p>
									</div>
									<div
										className="action action--active"
										onClick={() => {
											setOpenSendEmailModal(true);
										}}
									>
										<SendTicketIcon />
										<p>Send ticket via email</p>
									</div>
									<div
										className={`action action--${
											data?.status === "completed" ||
											tripData?.status === "open" ||
											(data?.any_process &&
												data?.process_type === "cancel_booking")
												? "inactive"
												: "active"
										}`}
										onClick={() => {
											if (
												data?.status !== "completed" &&
												tripData?.status !== "open" &&
												!(
													data?.any_process &&
													(data?.process_type === "open_ticket" ||
														data?.process_type === "cancel_booking")
												)
											)
												setOpenTicketModal(true);
										}}
									>
										<OpenTicketIcon />
										<p>
											{data?.any_process && data?.process_type === "open_ticket"
												? "Processing (Open ticket)"
												: "Open ticket"}
										</p>
									</div>
									<div
										className={`action action--red ${
											data?.status === "completed" && "action--inactive"
										}`}
										onClick={() => {
											if (
												data?.status !== "completed" &&
												!(
													data?.any_process &&
													data?.process_type === "cancel_booking"
												)
											)
												setOpenCancelModal(true);
										}}
									>
										<CancelBookingIcon />
										<p>
											{data?.any_process &&
											data?.process_type === "cancel_booking"
												? "Processing (Cancel booking)"
												: "Cancel booking"}
										</p>
									</div>
									{/* <div className="open-cancel"></div> */}
								</div>
							</div>
						</div>
					</>
				)}
			</div>

			{/* <MUIModal open={openModal} handleClose={() => setOpenModal(false)}>
				{modalComponent}
			</MUIModal> */}

			{/* Change Ticket modal */}
			<MUIModal
				open={openChangeModal}
				handleClose={() => setOpenChangeModal(false)}
			>
				<ChangeTicketModal
					handleClose={() => setOpenChangeModal(false)}
					tripData={tripData}
					reload={() => setRefetch(!refetch)}
				/>
			</MUIModal>

			{/* Send ticket via email modal */}
			<MUIModal
				open={openSendEmailModal}
				handleClose={() => setOpenSendEmailModal(false)}
			>
				<InputEmailModal
					handleClose={() => setOpenSendEmailModal(false)}
					trip_type={data?.trip_type}
					tripData={tripData}
				/>
			</MUIModal>

			{/* Cancel booking modal */}
			<MUIModal
				open={openCancelModal}
				handleClose={() => setOpenCancelModal(false)}
			>
				<CancelBookingModal
					handleClose={() => setOpenCancelModal(false)}
					tripData={tripData}
					reload={() => setRefetch(!refetch)}
				/>
			</MUIModal>

			{/* Open ticket modal */}
			<MUIModal
				open={openTicketModal}
				handleClose={() => setOpenTicketModal(false)}
			>
				<OpenTicketModal
					handleClose={() => setOpenTicketModal(false)}
					tripData={tripData}
					reload={() => setRefetch(!refetch)}
				/>
			</MUIModal>
			{document.documentElement.clientWidth > 780 && (
				<MUIModal
					open={openDetailsModal}
					handleClose={() => setOpenDetailsModal(false)}
				>
					<FullItenaryModal />
				</MUIModal>
			)}
			{document.documentElement.clientWidth <= 780 && (
				<Drawer
					anchor="top"
					open={openMobileDetailsModal}
					onClose={toggleSideDrawer(false, setOpenMobileDetailsModal)}
					// onOpen={toggleSideDrawer(true, setShowTraveller)}
				>
					<FullItenaryMobileDrawer
						mobileIsOpen={openMobileDetailsModal}
						setMobileIsOpen={setOpenMobileDetailsModal}
						selected={false}
						onSelect={() => {}}
						setOpenShare={setOpenMobileDetailsModal}
						setOpenBreakdown={setOpenMobileDetailsModal}
						route={tripData?.booking_category}
					/>
				</Drawer>
			)}
			<Drawer
				anchor="bottom"
				open={openDrawer}
				onClose={toggleSideDrawer(false, setOpenDrawer)}
				// onOpen={toggleSideDrawer(true, setShowTraveller)}
			>
				<TripHistoryDrawer
					populateBigFlightData={populateBigFlightData}
					data={data}
					tripData={tripData}
					setOpenChangeModal={setOpenChangeModal}
					setOpenSendEmailModal={setOpenSendEmailModal}
					setOpenTicketModal={setOpenTicketModal}
					setOpenCancelModal={setOpenCancelModal}
				/>
			</Drawer>
		</div>
	);
};

export default TripInfoDrawer;
