import { FlagIcon, PolicyIcon } from "../pieces/svgs";
import "./style.scss";

interface PolicyFlagProps {
	status: boolean;
	variant: "short" | "long";
}

const renderText = (status: boolean, variant: "short" | "long") => {
	switch (variant) {
		case "short":
			return status ? "In-policy" : "Out of policy";
			break;

		case "long":
			return status
				? "This booking is in-policy"
				: "This booking is out of policy";
			break;

		default:
			break;
	}
};

const PolicyFlag = ({ status, variant }: PolicyFlagProps) => {
	return (
		<div
			className={`PolicyFlag PolicyFlag--${variant} PolicyFlag--${
				status ? "inPolicy" : "outOfPolicy"
			}`}
		>
			<FlagIcon />
			<span>{renderText(status, variant)}</span>
		</div>
	);
};

export default PolicyFlag;
