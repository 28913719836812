import { CarInfoBunch } from "../../../components/TripComponents/carPhases/CarPhase2";
import { FaRegSnowflake } from "react-icons/fa";
import { MdGroups, MdInfoOutline } from "react-icons/md";
import { useTripStore } from "../../../zustand/store";
import { DateTime } from "ts-luxon";

export const CarDetailsCard = () => {
	const bigCarData = useTripStore((state: any) => state.bigCarData);

	return (
		<div className="CarDetailsCard">
			<div className="carInfo">
				<div className="carInfo__row">
					<CarInfoBunch
						label="Pick-up:"
						value={bigCarData?.pickUp?.location_name}
					/>
					<CarInfoBunch
						label="Drop-off:"
						value={bigCarData?.dropOff?.location_name}
					/>
				</div>
				<div className="carInfo__row">
					<CarInfoBunch label="Pick-up date:" value={bigCarData?.date} />
					<CarInfoBunch
						label="Time:"
						value={DateTime.fromISO(bigCarData?.ISODate)
							.setLocale("en-US")
							.toLocaleString(DateTime.TIME_SIMPLE)}
					/>
				</div>
				<div className="carInfo__row">
					<CarInfoBunch
						label="Car information:"
						value={
							<div className="carInformation">
								{bigCarData?.carType?.air_condition && (
									<div className="carInformation__row">
										<FaRegSnowflake />
										<span>Air condition</span>
									</div>
								)}
								<div className="carInformation__row">
									<MdGroups />
									<span>{bigCarData?.carType?.no_of_seats} seaters</span>
								</div>
							</div>
						}
					/>
					<CarInfoBunch
						label="Car type:"
						value={bigCarData?.carType?.car_type}
					/>
				</div>
			</div>
		</div>
	);
};
