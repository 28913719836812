import "./style.scss";

interface PlainTableProps {
	tableHead: string | JSX.Element;
	tableBody: string | JSX.Element;
	style?: any;
}

const PlainTable = ({ tableHead, tableBody, style = {} }: PlainTableProps) => (
	<div className="PlainTable" style={style}>
		<div className="PlainTable__tableHead">{tableHead}</div>
		<div className="PlainTable__tableBody">{tableBody}</div>
	</div>
);

export default PlainTable;
