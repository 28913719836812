import { useTripStore } from "../../../zustand/store";
import { useState } from "react";
import { DateTime } from "ts-luxon";
import {
	diffObjToDuration,
	minutesToDuration,
	renderDuration,
} from "../../../helpers/helperFuncs";
import "./style.scss";

export const ShowDetailsDrawer = ({
	type,
	total_outbound_duration,
	outboundList,
}: {
	type?: "departure" | "return";
	outboundList: any[];
	total_outbound_duration: number | string;
}) => {
	const bigFlightData = useTripStore((state: any) => state.bigFlightData);
	const globalAirports = useTripStore((state: any) => state.globalAirports);

	const [isOpen, setIsOpen] = useState(false);
	// console.log("total_outbound_duration", total_outbound_duration);

	return (
		<>
			<div className="show-details">
				<span></span>
				<p onClick={() => setIsOpen(!isOpen)}>
					{isOpen ? "Hide" : "Show"} flight Details
				</p>
			</div>
			<div className={`moreDetails ${!isOpen && "moreDetails--shut"}`}>
				{outboundList?.map((trip: any, ind: number) => (
					<>
						<div className="moreDetailsInner">
							<img
								src={`https://image.tiqwa.com/airlines/${trip.airline_details.code}.png`}
								alt=""
							/>
							<div className="moreDetails__right">
								<div className="journey">
									<div className="names">
										<p>{trip.airport_from}</p>
										<p>
											{globalAirports
												.filter(
													(item: any) => item.iata_code === trip.airport_from
												)[0]
												?.city?.toUpperCase()}
										</p>
									</div>
									<div className="duration">
										<p>
											{/* {bigFlightData?.route === "domestic"
												? minutesToDuration(total_outbound_duration as number)
												: renderDuration(total_outbound_duration as string)} */}
											{diffObjToDuration(
												DateTime.fromISO(trip.arrival_time)
													.diff(DateTime.fromISO(trip.departure_time), [
														"days",
														"hours",
														"minutes",
													])
													.toObject()
											)}
										</p>
									</div>
									<div className="names">
										<p>{trip.airport_to}</p>
										<p>
											{globalAirports
												.filter(
													(item: any) => item.iata_code === trip.airport_to
												)[0]
												?.city?.toUpperCase()}
										</p>
									</div>
								</div>
								<div className="journey">
									<div className="times">
										<div>
											{DateTime.fromISO(trip.departure_time).toLocaleString(
												DateTime.TIME_24_SIMPLE
											)}{" "}
											hrs,{" "}
											{DateTime.fromISO(trip.departure_time).toLocaleString({
												weekday: "short",
												day: "2-digit",
												month: "short",
											})}
										</div>
									</div>
									<div className="cabin">
										<p>{trip?.cabin_type}</p>
									</div>
									<div className="times">
										<div>
											{DateTime.fromISO(trip.arrival_time).toLocaleString(
												DateTime.TIME_24_SIMPLE
											)}{" "}
											hrs,{" "}
											{DateTime.fromISO(trip.arrival_time).toLocaleString({
												weekday: "short",
												day: "2-digit",
												month: "short",
											})}
										</div>
									</div>
								</div>

								<div className="terminal">
									<p>Terminal-1</p>
									<p>{trip?.baggage}</p>
								</div>
							</div>
						</div>
						{ind < outboundList.length - 1 && (
							<div className="moreDetailsLayover">
								Change of planes,{" "}
								{diffObjToDuration(
									DateTime.fromISO(outboundList[ind + 1].departure_time)
										.diff(DateTime.fromISO(trip.arrival_time), [
											"days",
											"hours",
											"minutes",
										])
										.toObject()
								)}{" "}
								layover in{" "}
								{
									globalAirports.filter(
										(item: any) => item.iata_code === trip.airport_to
									)[0]?.city
								}
							</div>
						)}
					</>
				))}
			</div>
		</>
	);
};

export default ShowDetailsDrawer;
