import React, { useEffect } from "react";
import Modal from "react-modal";
import "./style.scss";
import MUIModal from "../MUIModal";
import topup from "../../resource/img/Topup.svg";
import useAxios from "../../hooks/dist/useAxios";
import { verifyPayment } from "../../requests";

import { usePaystackPayment } from "react-paystack";
import { addPaystackCharges } from "../../helpers/helperFuncs";

const customStyles = {
	// content: {
	top: "50%",
	left: "50%",
	right: "auto",
	bottom: "auto",
	marginRight: "-50%",
	transform: "translate(-50%, -50%)",
	background: "#FFFFFF",
	borderRadius: "8px",
	alignItems: "center",
	padding: "32px 67px",
	gap: "10px",
	// },
};

// Modal.setAppElement('#yourAppElement');

function ModalComponent(props: any) {
	// MODEL DEFINITION
	const [modalIsOpen, setIsOpen] = React.useState(false);
	const [amount, setAmount] = React.useState(0);
	const [reference, setReference] = React.useState(
		new Date().getTime().toString()
	);

	function openModal() {
		setIsOpen(true);
	}

	function closeModal() {
		setIsOpen(false);
	}

	const verfyFunc = async (reference: string) => {
		const res = await verifyPayment(reference);
		if (res) props?.setRefetch(!props?.refetch);
	};

	//   PAYSTACK CONFIGS
	let config = {
		reference: reference,
		email: props.email,
		amount: Math.ceil(
			addPaystackCharges(
				props.amount === undefined ? amount : Math.round(props?.amount)
			) * 100
		),
		publicKey: `${process.env.REACT_APP_PAYSTACK_KEY}`,
	};

	const onSuccess = (ref: any) => {
		console.log("refObj", ref);
		setReference(ref.reference);
		if (props.type === "fund_wallet") {
			verfyFunc(reference);
		} else if (props.type === "book_flight") {
			props.setPaymentSuccessful(true);
			props.setReference(reference);
		}

		closeModal();
	};

	const onClose = () => {
		console.log("closed");
	};
	console.log(props);

	useEffect(() => {
		setReference(new Date().getTime().toString());
	}, [modalIsOpen]);

	const PaystackHookExample = () => {
		const initializePayment: any = usePaystackPayment(config);
		return (
			<div>
				<button
					className="modal-button"
					onClick={(e) => {
						e.preventDefault();
						console.log("clicked");
						initializePayment(onSuccess, onClose);
					}}
				>
					Pay Now
				</button>
			</div>
		);
	};

	const fundWallet = () => {
		return (
			<div className="modal-box">
				<p className="modal_head_text">
					Fund your wallet and get your credit topped
				</p>
				<form>
					<input
						type="number"
						required
						placeholder="Amount"
						className="modal-form"
						onChange={(e) => setAmount(e.target.valueAsNumber)}
					/>
					<PaystackHookExample />
				</form>
				<div>
					<button className="payment-cancel modal-button" onClick={closeModal}>
						Cancel Payment
					</button>
				</div>
			</div>
		);
	};

	const book_flight = () => {
		return (
			<div className="modal-box">
				<p className="modal_head_text">
					We will charge your card for your flight booking
				</p>
				<PaystackHookExample />
				<div>
					<button className="payment-cancel modal-button" onClick={closeModal}>
						Cancel Payment
					</button>
				</div>
			</div>
		);
	};

	return (
		<div>
			{props.type === "fund_wallet" ? (
				<div
					className="fund-wallet"
					onClick={() => {
						openModal();
					}}
				>
					<img src={topup} alt="top" />
					<span>Fund your account</span>
				</div>
			) : props.type === "book_flight" ? (
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						gap: "0.5rem",
					}}
					onClick={() => {
						openModal();
					}}
				>
					Pay Now
				</div>
			) : (
				<button onClick={openModal}>Open Modal</button>
			)}
			<MUIModal
				open={modalIsOpen}
				// onAfterOpen={afterOpenModal}
				handleClose={closeModal}

				// contentLabel="Example Modal"
			>
				{props.type === "fund_wallet" ? fundWallet() : book_flight()}
			</MUIModal>
		</div>
	);
}

export default ModalComponent;
