import { useState } from "react";
import { CarPhase1, CarPhase2 } from "./carPhases";

const Cars = (props: any) => {
	const [carPhase, setCarPhase] = useState(1);

	switch (carPhase) {
		case 1:
			return <CarPhase1 {...props} setCarPhase={setCarPhase} />;
			break;
		case 2:
			return (
				<CarPhase2 {...props} setCarPhase={(val: number) => setCarPhase(val)} />
			);
			break;

		default:
			return <CarPhase1 {...props} setCarPhase={setCarPhase} />;
			break;
	}
};

export default Cars;
