import { request } from "../utils/axios";
import { setAuthToken } from "../config/helpers";
import { toast } from "react-toastify";

export const fetchFlightData = async (paramsObj) => {
	const {
		route,
		travellerCount,
		adults,
		children,
		infants,
		cabin,
		departure_date,
		destination,
		departure,
		emailRequest,
	} = paramsObj;

	try {
		const res = await request.get(
			route === "domestic"
				? `api/trips/domestic_flights?adults=${adults}&children=${children}&infants=${infants}&cabin=${cabin}&departure_date=${departure_date}&destination=${destination}&origin=${departure}`
				: `api/trips/international_flights?flight_type=oneway&from=${departure}&to=${destination}&departure_date=${departure_date}&adults=${adults}&children=${children}&infants=${infants}&cabin=${cabin}`
		);
		console.log(res);
		return res?.data;
	} catch (err) {
		console.log(err?.response?.data);
		console.log(err?.response?.data?.message);
		toast.error(err?.response?.data?.message);
		throw err;
	}
};

export const fetchMulticityFlightData = async (paramsObj) => {
	const { route, adults, children, infants, cabin, emailRequest, tripArray } =
		paramsObj;

	let tripsArrayParams = "";
	tripArray.forEach((tripItem, ind) => {
		tripsArrayParams += `from${ind + 1}=${tripItem.departure}&to${ind + 1}=${
			tripItem.destination
		}&departure_date${ind + 1}=${tripItem.departure_date}${
			ind < tripArray.length - 1 ? "&" : ""
		}`;
	});

	try {
		const res = await request.get(
			`api/trips/international_flights?flight_type=multicity&adults=${adults}&children=${children}&infants=${infants}&cabin=${cabin}&${tripsArrayParams}`
		);
		console.log(res);
		return res?.data;
	} catch (err) {
		console.log(err?.response?.data);
		console.log(err?.response?.data?.message);
		toast.error(err?.response?.data?.message);
		throw err;
	}
};

export const combinableSearch = async (paramsObj) => {
	const {
		adults,
		children,
		infants,
		cabin,
		departure_date,
		return_date,
		destination,
		departure,
		emailRequest,
	} = paramsObj;

	try {
		const res = await request.get(
			`api/trips/international_flights?flight_type=return&from1=${departure}&to1=${destination}&departure_date1=${departure_date}&adults=${adults}&children=${children}&infants=${infants}&from2=${destination}&to2=${departure}&departure_date2=${return_date}&cabin=${cabin}${
				emailRequest ? "&email=true" : ""
			}`
		);
		console.log(res);
		return res?.data;
	} catch (err) {
		console.log(err?.response?.data);
		console.log(err?.response?.data?.message);
		toast.error(err?.response?.data?.message);
		throw err;
	}
};

export const addSearchHistory = async (paramsObj, emailRequest) => {
	try {
		const res = await request.post(
			`api/trips/search_history/?email=${emailRequest ? "1" : "0"}`,
			paramsObj
		);
		console.log(res);
		return res?.data;
	} catch (err) {
		console.log(err?.response?.data);
		console.log(err?.response?.data?.message);
		toast.error(err?.response?.data?.message);
		throw err;
	}
};

export const fetchAirportList = async () => {
	try {
		const res = await request.get("api/trips/airports");
		console.log(res);
		return res;
	} catch (err) {
		console.log(err?.response?.data);
		console.log(err?.response?.data?.message);
		throw err;
	}
};

export const fetchIntlAirportList = async () => {
	try {
		const res = await request.get("api/trips/international/airports");
		console.log(res);
		return res;
	} catch (err) {
		console.log(err?.response?.data);
		console.log(err?.response?.data?.message);
		throw err;
	}
};

export const bookFlight = async (values) => {
	console.log("bookFlightValues", values);
	try {
		const res = await request.post("api/trips/book_domestic_flight/", values);
		console.log(res);
		return res;
	} catch (err) {
		console.log(err?.response?.data);
		console.log(err?.response?.data?.message);
		toast.error(err?.response?.data?.message);
		if (err?.response?.status >= 500) toast.error("Server Error");
		throw err;
	}
};

export const bookIntlFlight = async (values) => {
	console.log("bookIntlFlightValues", values);
	try {
		const res = await request.post(
			"api/trips/book_international_flight/",
			values
		);

		console.log(res);
		return res;
	} catch (err) {
		console.log(err);
		console.log("err?.response", err?.response);
		console.log(err?.response?.data?.message);
		toast.error(err?.response?.data?.message);
		if (err?.response?.status >= 500) toast.error("Server Error");
		throw err;
	}
};

export const confirmBookOnHold = async (kardinal_reference, values) => {
	console.log("confirmBookOnHold", values);
	try {
		const res = await request.post(
			`api/trips/confirm_onhold_booking/${kardinal_reference}/`,
			values
		);
		console.log(res);
		return res;
	} catch (err) {
		console.log(err?.response?.data);
		console.log(err?.response?.data?.message);
		toast.error(err?.response?.data?.message);
		if (err?.response?.status >= 500) toast.error("Server Error");
		throw err;
	}
};

export const fetchBookOnHoldDetails = async (values) => {
	console.log("fetchBookOnHoldDetails", values);
	try {
		const res = await request.get("api/trips/admin/book-details", values);
		console.log(res);
		return res;
	} catch (err) {
		console.log(err?.response?.data);
		console.log(err?.response?.data?.message);
		toast.error(err?.response?.data?.message);
		throw err;
	}
};

export const createTraveller = async (values) => {
	try {
		const res = await request.post("api/trips/passenger/", values);
		console.log(res);
		return res;
	} catch (err) {
		console.log(err?.response?.data);
		console.log(err?.response?.data?.message);
		toast.error(err?.response?.data?.message);
		throw err;
	}
};

export const updateTraveller = async (id, values) => {
	try {
		const res = await request.patch(`api/trips/passenger/${id}/`, values);
		console.log(res);
		return res;
	} catch (err) {
		console.log(err?.response?.data);
		console.log(err?.response?.data?.message);
		toast.error(err?.response?.data?.message);
		throw err;
	}
};

export const fetchTravellers = async () => {
	try {
		const res = await request.get("api/trips/passenger/");
		console.log(res);
		return res;
	} catch (err) {
		console.log(err?.response?.data);
		console.log(err?.response?.data?.message);
		throw err;
	}
};

export const deleteTraveller = async (id) => {
	try {
		const res = await request.delete(`api/trips/passenger/${id}/`);
		console.log(res);
		return res;
	} catch (err) {
		console.log(err?.response?.data);
		console.log(err?.response?.data?.message);
		throw err;
	}
};

export const verifyPayment = async (reference) => {
	try {
		const res = await request.get(`api/finance/verify_payment/${reference}`);
		console.log(res);
		return res;
	} catch (err) {
		console.log(err?.response?.data);
		console.log(err?.response?.data?.message);
		throw err;
	}
};

export const shareTripDetails = async (values) => {
	try {
		console.log("shareTripDetailsValues", values);
		const res = await request.post("api/trips/share/", values);
		console.log(res);
		return res;
	} catch (err) {
		console.log(err?.response?.data);
		console.log(err?.response?.data?.message);
		toast.error(err?.response?.data?.message);
		throw err;
	}
};

export const confirmDomesticFlight = async (flightId) => {
	try {
		const res = await request.get(
			`api/trips/confirm_domestic_flights/${flightId}`
		);
		console.log(res);
		return res;
	} catch (err) {
		console.log(err?.response?.data);
		console.log(err?.response?.data?.message);
		toast.error(err?.response?.data?.message);
		throw err;
	}
};

export const getTicketInfo = async (kardinal_reference) => {
	try {
		const res = await request.get(
			`api/trips/view/ticket/${kardinal_reference}`
		);
		console.log(res);
		return res;
	} catch (err) {
		console.log(err?.response?.data);
		console.log(err?.response?.data?.message);
		toast.error(err?.response?.data?.message);
		throw err;
	}
};
