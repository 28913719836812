import React, { Dispatch, SetStateAction, useState } from "react";
import "./style.scss";
import {
	BaggageIcon,
	RefundableIcon,
	NonRefundableIcon,
	DepartureIcon,
	ArrivalIcon,
} from "../pieces/svgs";
import { BsMoonStars, BsFillSunFill } from "react-icons/bs";
import {
	IoChevronDown,
	IoChevronUp,
	IoBookmarkSharp,
	IoClose,
} from "react-icons/io5";
import { IoMdShareAlt } from "react-icons/io";
import Button from "../Button";
import { Tooltip } from "@mui/material";
import { useTripStore } from "../../zustand/store";
import { DateTime, Duration } from "ts-luxon";
import {
	diffObjToDuration,
	isNightFlight,
	minutesToDuration,
	renderDuration,
} from "../../helpers/helperFuncs";
import { BlackTooltip } from ".";
import { confirmDomesticFlight } from "../../requests";
import { toast } from "react-toastify";

interface MobileFlightDrawerProps {
	mobileIsOpen: boolean;
	setMobileIsOpen: Dispatch<SetStateAction<boolean>>;
	flightInfo: any;
	selected?: boolean;
	onSelect?: () => void;
	setOpenShare: Dispatch<SetStateAction<boolean>>;
	setOpenBreakdown: Dispatch<SetStateAction<boolean>>;
	setVerifyingPrice: Dispatch<SetStateAction<boolean>>;
	route: string;
	changeAmount: (new_val: number) => void;
}

const MobileFlightDrawer = ({
	mobileIsOpen,
	setMobileIsOpen,
	flightInfo,
	selected,
	onSelect,
	setOpenShare,
	setOpenBreakdown,
	route,
	changeAmount,
	setVerifyingPrice,
}: MobileFlightDrawerProps) => {
	const globalAirports = useTripStore((state: any) => state.globalAirports);
	const bigFlightData = useTripStore((state: any) => state.bigFlightData);

	const isRoundTripAndIntl =
		bigFlightData?.showTripType === "Roundtrip" &&
		bigFlightData?.route === "international";

	const isMulticityAndIntl =
		bigFlightData.showTripType === "Multicity" &&
		bigFlightData.route === "international";

	const confirmFlightPricing = async (): Promise<{
		is_valid: boolean;
		new_price: number;
		error: boolean;
	}> => {
		if (bigFlightData?.route === "domestic") {
			setVerifyingPrice(true);
			try {
				const res = await confirmDomesticFlight(flightInfo?.id);
				console.log("confirmDomesticFlight", res);

				if (res.data?.data?.message === "Provided flight ID not found.") {
					return {
						is_valid: false,
						new_price: 0,
						error: true,
					};
				} else
					return {
						is_valid: res.data?.data?.amount === flightInfo?.amount,
						new_price: res.data?.data?.amount,
						error: false,
						// is_valid: 100000 === flightInfo?.amount,
						// new_price: 100000,
					};
			} catch (e) {
				console.log(e);
				toast.error("something went wrong");
				return {
					is_valid: false,
					new_price: 0,
					error: true,
				};
			} finally {
				setVerifyingPrice(false);
			}
		} else
			return {
				is_valid: true,
				new_price: flightInfo?.amount,
				error: false,
			};
	};

	const validatedSelect = onSelect
		? async () => {
				const priceValidation = await confirmFlightPricing();

				if (priceValidation.error) toast.error("Price validation error");
				else {
					if (priceValidation.is_valid) {
						onSelect();
						setMobileIsOpen(false);
					} else {
						toast.info(
							`Flight price has changed. The new price is ₦${priceValidation.new_price}`
						);
						changeAmount(priceValidation.new_price);
						onSelect();
						// flightInfo.amount = priceValidation.new_price;
					}
				}
		  }
		: () => {};

	return (
		<div
			className={`FlightInfoDrawer__MobileInfo ${
				!mobileIsOpen && "FlightInfoDrawer__MobileInfo--shut"
			}`}
		>
			<div className="FlightInfoNav">
				<p>Flight details</p>
				<IoClose onClick={() => setMobileIsOpen(false)} />
			</div>
			<div className="padding-wrapper">
				{isMulticityAndIntl ? (
					<></>
				) : (
					<div className="Departure_nightDay">
						{isNightFlight(flightInfo.outbound[0].departure_time) ? (
							<BlackTooltip title="Night Flight">
								<BsMoonStars color="#243256" />
							</BlackTooltip>
						) : (
							<BlackTooltip title="Morning Flight">
								<BsFillSunFill color="#243256" />
							</BlackTooltip>
						)}

						<p>
							{bigFlightData.selectedDeparture && route === "domestic"
								? "Return"
								: "Departure"}{" "}
							{/* {DateTime.fromISO(departure_date).toLocaleString({
                    weekday: "short",
                    day: "2-digit",
                    month: "short",
                })} */}
						</p>
					</div>
				)}
				<div className="mobileInfo_outbounds">
					{isMulticityAndIntl ? (
						<>
							{flightInfo.outbound.map((outboundItem: any, legInd: number) => (
								<>
									<div
										className="Departure_nightDay"
										style={{ marginBottom: 0 }}
									>
										{isNightFlight(outboundItem[0].departure_time) ? (
											<BlackTooltip title="Night Flight">
												<BsMoonStars color="#243256" />
											</BlackTooltip>
										) : (
											<BlackTooltip title="Morning Flight">
												<BsFillSunFill color="#243256" />
											</BlackTooltip>
										)}
										<p>
											Departure{" "}
											{/* {DateTime.fromISO(departure_date).toLocaleString({
				weekday: "short",
				day: "2-digit",
				month: "short",
			})} */}
										</p>
									</div>
									{outboundItem.map((trip: any, ind: number) => (
										<div key={ind}>
											<div className="grey-info">
												<span>
													{DateTime.fromISO(trip.departure_time).toLocaleString(
														{
															weekday: "short",
															day: "2-digit",
															month: "short",
														}
													)}
												</span>
												<span>•</span>
												<span>
													{trip.cabin_type.charAt(0).toUpperCase() +
														trip.cabin_type.slice(1)}
												</span>
												<span>•</span>
												{route === "domestic" ? (
													<span>
														{minutesToDuration(
															flightInfo.total_outbound_duration[legInd]
														)}
													</span>
												) : (
													<span>
														{diffObjToDuration(
															DateTime.fromISO(trip.arrival_time)
																.diff(DateTime.fromISO(trip.departure_time), [
																	"days",
																	"hours",
																	"minutes",
																])
																.toObject()
														)}
													</span>
												)}
											</div>
											<div className="plenty-info">
												<div
													className="flex-center"
													style={{ marginBottom: "15px" }}
												>
													<img
														src={`https://image.tiqwa.com/airlines/${trip.airline_details.code}.png`}
														alt=""
													/>
													<p style={{ color: "#655F5F" }}>
														{trip.airline_details.name}
													</p>
												</div>
												<div className="flex-start">
													<DepartureIcon />
													<div>
														<p className="black">
															{
																globalAirports.filter(
																	(item: any) =>
																		item.iata_code === trip.airport_from
																)[0]?.name
															}
														</p>
														<p>
															<span>{trip.airport_from}</span>
															<span className="black">
																{DateTime.fromISO(
																	trip.departure_time
																).toLocaleString(DateTime.TIME_24_SIMPLE)}
															</span>
														</p>
													</div>
												</div>
												<div className="flex-start">
													<ArrivalIcon />
													<div>
														<p className="black">
															{
																globalAirports.filter(
																	(item: any) =>
																		item.iata_code === trip.airport_to
																)[0]?.name
															}
														</p>
														<p>
															<span>{trip.airport_to}</span>
															<span className="black">
																{DateTime.fromISO(
																	trip.arrival_time
																).toLocaleString(DateTime.TIME_24_SIMPLE)}
															</span>
														</p>
													</div>
												</div>
												{ind === 0 && (
													<>
														<div className="flex-center">
															<BaggageIcon />
															<p>{trip.baggage}</p>
														</div>
														<div className="flex-center">
															<RefundableIcon />
															<p>Refundable</p>
														</div>
													</>
												)}
											</div>
											{ind < flightInfo.outbound[legInd].length - 1 && (
												<div className="blue-banner">
													<span></span>
													<div>
														<p>Layover at {trip.airport_to}</p>
														<p>
															{(DateTime.fromISO(
																flightInfo.outbound[legInd][ind + 1]
																	.departure_time
															)
																.diff(DateTime.fromISO(trip.arrival_time), [
																	"days",
																	"hours",
																	"minutes",
																])
																.toObject()?.hours as number) >= 4 ? (
																<p>Long Layover</p>
															) : (
																<></>
															)}
														</p>
														<p>
															{diffObjToDuration(
																DateTime.fromISO(
																	flightInfo.outbound[legInd][ind + 1]
																		.departure_time
																)
																	.diff(DateTime.fromISO(trip.arrival_time), [
																		"days",
																		"hours",
																		"minutes",
																	])
																	.toObject()
															)}
														</p>
													</div>
												</div>
											)}
										</div>
									))}
								</>
							))}
						</>
					) : (
						<>
							<div
								className="mobileInfo_outbounds__outbound"
								style={{
									borderBottom: isRoundTripAndIntl
										? "0.8px solid rgba(196, 196, 196, 0.5)"
										: 0,
								}}
							>
								{flightInfo.outbound.map((trip: any, ind: number) => (
									<div key={ind}>
										<div className="grey-info">
											<span>
												{DateTime.fromISO(trip.departure_time).toLocaleString({
													weekday: "short",
													day: "2-digit",
													month: "short",
												})}
											</span>
											<span>•</span>
											<span>
												{trip.cabin_type.charAt(0).toUpperCase() +
													trip.cabin_type.slice(1)}
											</span>
											<span>•</span>
											{route === "domestic" ? (
												<span>
													{minutesToDuration(
														flightInfo.total_outbound_duration
													)}
												</span>
											) : (
												<span>
													{diffObjToDuration(
														DateTime.fromISO(trip.arrival_time)
															.diff(DateTime.fromISO(trip.departure_time), [
																"days",
																"hours",
																"minutes",
															])
															.toObject()
													)}
												</span>
											)}
										</div>
										<div className="plenty-info">
											<div
												className="flex-center"
												style={{ marginBottom: "15px" }}
											>
												<img
													src={`https://image.tiqwa.com/airlines/${trip.airline_details.code}.png`}
													alt=""
												/>
												<p style={{ color: "#655F5F" }}>
													{trip.airline_details.name}
												</p>
											</div>
											<div className="flex-start">
												<DepartureIcon />
												<div>
													<p className="black">
														{
															globalAirports.filter(
																(item: any) =>
																	item.iata_code === trip.airport_from
															)[0]?.name
														}
													</p>
													<p>
														<span>{trip.airport_from}</span>
														<span className="black">
															{DateTime.fromISO(
																trip.departure_time
															).toLocaleString(DateTime.TIME_24_SIMPLE)}
														</span>
													</p>
												</div>
											</div>
											<div className="flex-start">
												<ArrivalIcon />
												<div>
													<p className="black">
														{
															globalAirports.filter(
																(item: any) =>
																	item.iata_code === trip.airport_to
															)[0]?.name
														}
													</p>
													<p>
														<span>{trip.airport_to}</span>
														<span className="black">
															{DateTime.fromISO(
																trip.arrival_time
															).toLocaleString(DateTime.TIME_24_SIMPLE)}
														</span>
													</p>
												</div>
											</div>
											{ind === 0 && (
												<>
													<div className="flex-center">
														<BaggageIcon />
														<p>{trip.baggage}</p>
													</div>
													<div className="flex-center">
														<RefundableIcon />
														<p>Refundable</p>
													</div>
												</>
											)}
										</div>
										{ind < flightInfo.outbound.length - 1 && (
											<div className="blue-banner">
												<span></span>
												<div>
													<p>Layover at {trip.airport_to}</p>
													<p>
														{(DateTime.fromISO(
															flightInfo.outbound[ind + 1].departure_time
														)
															.diff(DateTime.fromISO(trip.arrival_time), [
																"days",
																"hours",
																"minutes",
															])
															.toObject()?.hours as number) >= 4 ? (
															<p>Long Layover</p>
														) : (
															<></>
														)}
													</p>
													<p>
														{diffObjToDuration(
															DateTime.fromISO(
																flightInfo.outbound[ind + 1].departure_time
															)
																.diff(DateTime.fromISO(trip.arrival_time), [
																	"days",
																	"hours",
																	"minutes",
																])
																.toObject()
														)}
													</p>
												</div>
											</div>
										)}
									</div>
								))}
							</div>
							{isRoundTripAndIntl && (
								<div className="Departure_nightDay" style={{ marginBottom: 0 }}>
									{isNightFlight(
										flightInfo.outbound_return[0].departure_time
									) ? (
										<BlackTooltip title="Night Flight">
											<BsMoonStars color="#243256" />
										</BlackTooltip>
									) : (
										<BlackTooltip title="Morning Flight">
											<BsFillSunFill color="#243256" />
										</BlackTooltip>
									)}
									<p>
										Return{" "}
										{/* {DateTime.fromISO(departure_date).toLocaleString({
                    weekday: "short",
                    day: "2-digit",
                    month: "short",
                })} */}
									</p>
								</div>
							)}
							{isRoundTripAndIntl && (
								<div className="mobileInfo_outbounds__outbound">
									{flightInfo.outbound_return.map((trip: any, ind: number) => (
										<div key={ind}>
											<div className="grey-info">
												<span>
													{DateTime.fromISO(trip.departure_time).toLocaleString(
														{
															weekday: "short",
															day: "2-digit",
															month: "short",
														}
													)}
												</span>
												<span>•</span>
												<span>
													{trip.cabin_type.charAt(0).toUpperCase() +
														trip.cabin_type.slice(1)}
												</span>
												<span>•</span>
												{route === "domestic" ? (
													<span>
														{minutesToDuration(
															flightInfo.total_outbound_duration
														)}
													</span>
												) : (
													<span>
														{diffObjToDuration(
															DateTime.fromISO(trip.arrival_time)
																.diff(DateTime.fromISO(trip.departure_time), [
																	"days",
																	"hours",
																	"minutes",
																])
																.toObject()
														)}
													</span>
												)}
											</div>
											<div className="plenty-info">
												<div
													className="flex-center"
													style={{ marginBottom: "15px" }}
												>
													<img
														src={`https://image.tiqwa.com/airlines/${trip.airline_details.code}.png`}
														alt=""
													/>
													<p style={{ color: "#655F5F" }}>
														{trip.airline_details.name}
													</p>
												</div>
												<div className="flex-start">
													<DepartureIcon />
													<div>
														<p className="black">
															{
																globalAirports.filter(
																	(item: any) =>
																		item.iata_code === trip.airport_from
																)[0]?.name
															}
														</p>
														<p>
															<span>{trip.airport_from}</span>
															<span className="black">
																{DateTime.fromISO(
																	trip.departure_time
																).toLocaleString(DateTime.TIME_24_SIMPLE)}
															</span>
														</p>
													</div>
												</div>
												<div className="flex-start">
													<ArrivalIcon />
													<div>
														<p className="black">
															{
																globalAirports.filter(
																	(item: any) =>
																		item.iata_code === trip.airport_to
																)[0]?.name
															}
														</p>
														<p>
															<span>{trip.airport_to}</span>
															<span className="black">
																{DateTime.fromISO(
																	trip.arrival_time
																).toLocaleString(DateTime.TIME_24_SIMPLE)}
															</span>
														</p>
													</div>
												</div>
												{ind === 0 && (
													<>
														<div className="flex-center">
															<BaggageIcon />
															<p>{trip.baggage}</p>
														</div>
														<div className="flex-center">
															<RefundableIcon />
															<p>Refundable</p>
														</div>
													</>
												)}
											</div>
											{ind < flightInfo.outbound_return.length - 1 && (
												<div className="blue-banner">
													<span></span>
													<div>
														<p>Layover at {trip.airport_to}</p>
														<p>
															{(DateTime.fromISO(
																flightInfo.outbound_return[ind + 1]
																	.departure_time
															)
																.diff(DateTime.fromISO(trip.arrival_time), [
																	"days",
																	"hours",
																	"minutes",
																])
																.toObject()?.hours as number) >= 4 ? (
																<p>Long Layover</p>
															) : (
																<></>
															)}
														</p>
														<p>
															{diffObjToDuration(
																DateTime.fromISO(
																	flightInfo.outbound_return[ind + 1]
																		.departure_time
																)
																	.diff(DateTime.fromISO(trip.arrival_time), [
																		"days",
																		"hours",
																		"minutes",
																	])
																	.toObject()
															)}
														</p>
													</div>
												</div>
											)}
										</div>
									))}
								</div>
							)}
						</>
					)}
				</div>
				<div className="fare-breakdown">
					<span></span>
					<div>
						{/* <p onClick={() => setOpenBreakdown(true)}>Fare breakdown</p> */}
						<p></p>
						<div className="iconss">
							<Tooltip title="Share flight Details">
								<IoMdShareAlt
									size={18}
									onClick={() => {
										setOpenShare(true);
									}}
								/>
							</Tooltip>
							{/* <Tooltip title="Save flight Details">
								<IoBookmarkSharp size={14} />
							</Tooltip> */}
						</div>
					</div>
				</div>
				<div className="button-bottom">
					<p>
						₦
						{(
							flightInfo.amount +
							(bigFlightData?.route === "international" ? flightInfo?.tax : 0)
						)
							.toFixed(2)
							.replace(/\d(?=(\d{3})+\.)/g, "$&,")}
					</p>
					<Button className="pry" onClick={validatedSelect}>
						{selected ? "Selected" : "Select"}
					</Button>
				</div>
			</div>
		</div>
	);
};

export default MobileFlightDrawer;
