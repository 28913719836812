import { PlaneSelectIcon } from "../pieces/svgs";
import dubaiFlag from "../../resource/img/dubai-flag.png";
import ToggleSwitch from "../ToggleSwitch";
import "./style.scss";
import { useState, ChangeEvent } from "react";

const Restrictions = () => {
    const [vaccinated, setVaccinated] = useState(false);

    const tabList = [
        {
            name: (
                <div className="tabItem">
                    <span>LOS</span>
                    <PlaneSelectIcon />
                    <span>DXB</span>
                </div>
            ),
            component: <RestrictionsBody />,
        },
        {
            name: (
                <div className="tabItem">
                    <span>LOS</span>
                    <PlaneSelectIcon />
                    <span>DXB</span>
                </div>
            ),
            component: <RestrictionsBody />,
        },
    ];

    return (
        <div className="Restrictions">
            <div className="Restrictions__topbar">
                {/* <PlaneSelectIcon /> */}
                <div className="tabs">{tabList.map((item) => item.name)}</div>
                <div className="vaccine-toggle">
                    <ToggleSwitch
                        checked={vaccinated}
                        handleChange={(
                            event: ChangeEvent<HTMLInputElement>
                        ) => {
                            setVaccinated(event.target.checked);
                        }}
                    />
                    <p>Fully vaccinated?</p>
                </div>
            </div>
            <div className="Restrictions__body">
                <RestrictionsBody />
            </div>
        </div>
    );
};

export default Restrictions;

const RestrictionsBody = () => (
    <div>
        <div className="country-flag">
            <p>UNITED DUBAI EMIRATES</p>
            <img src={dubaiFlag} alt="dubai flag" />
        </div>
        <p className="time">Updated 8 hours ago</p>
        <div className="red">
            The information provided is for guidance only and subject to change.
            Please check with official sources before making any travel plans.
        </div>
        <div className="details">
            <p>COVID test: PCR upon arrival</p>
            <p>Public transport: Operating</p>
            <p>Attractions: Open</p>
            <p>Restaurants: Open</p>
            <p>Businesses: Open</p>
            <p>Quarantine: Until test results are in</p>
            <p>Face masks: Required</p>
        </div>
        <div>
            <p className="title">General</p>
            <p className="desc">
                UAE has opened its borders for visitors to Dubai from most
                countries, with health measures in place. domestically, most
                restrictions have been lifted.
            </p>
        </div>
    </div>
);
