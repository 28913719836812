import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { BiSearch } from "react-icons/bi";
import { getUniqueUserObjects } from "../../helpers/helperFuncs";
import useOutsideAlerter from "../../hooks/useOutsideAlerter";
import { useTripStore } from "../../zustand/store";
import "./style.scss";

interface SwitchPassengerComponentProps {
	addTravelerSearch: string;
	setAddTravelerSearch: Dispatch<SetStateAction<string>>;
	currTravellerId: number;
	handleClose: any;
}

const SwitchPassengerComponent = ({
	addTravelerSearch,
	setAddTravelerSearch,
	currTravellerId,
	handleClose,
}: SwitchPassengerComponentProps) => {
	const myTravellers = useTripStore((state: any) => state.myTravellers);
	const bigFlightData = useTripStore((state: any) => state.bigFlightData);
	const updateBigFlightData = useTripStore(
		(state: any) => state.updateBigFlightData
	);

	const wrapperRef = useRef<HTMLDivElement>(null);

	const [open, setOpen] = useState(false);
	const [availableTravellers, setAvailableTravellers] = useState(
		getUniqueUserObjects(
			myTravellers,
			bigFlightData?.selectedTraveller?.map((item: any) => item?.id)
		)
	);
	const [tempTravellers, setTempTravellers] = useState<any>([]);

	useOutsideAlerter(wrapperRef, () => {
		setOpen(false);
	});

	useEffect(() => {
		if (addTravelerSearch !== "")
			setTempTravellers(
				availableTravellers?.filter(
					(item: any) =>
						item?.first_name
							?.toLowerCase()
							?.startsWith(addTravelerSearch?.toLowerCase()) ||
						item?.last_name
							?.toLowerCase()
							?.startsWith(addTravelerSearch?.toLowerCase()) ||
						item?.middle_name
							?.toLowerCase()
							?.startsWith(addTravelerSearch?.toLowerCase()) ||
						item?.email
							?.toLowerCase()
							?.startsWith(addTravelerSearch?.toLowerCase())
				)
			);
		else setTempTravellers(availableTravellers);
	}, [addTravelerSearch]);

	return (
		<div className={`switchPassenger`} ref={wrapperRef}>
			<div
				className="switchPassenger__search"
				onClick={(e) => {
					console.log(open);
					setOpen(!open);
				}}
			>
				<BiSearch color="#40798C" />
				<input
					type="search"
					name=""
					id=""
					placeholder="Search existing travellers to to replace"
					value={addTravelerSearch}
					onChange={(e) => {
						setAddTravelerSearch(e.target.value);
					}}
				/>
			</div>

			<div
				className={`switchPassenger__popUp switchPassenger__popUp--${
					open && "open"
				}`}
			>
				{tempTravellers?.map((item: any, index: number) => {
					const currIndex = bigFlightData?.selectedTraveller?.findIndex(
						(passItem: any) => passItem?.id === currTravellerId
					);

					return (
						<PassengerItem
							item={item}
							onSelect={() => {
								console.log(
									"currIndex",
									currIndex,
									bigFlightData?.selectedTraveller?.slice(0, currIndex),
									bigFlightData?.selectedTraveller?.slice(currIndex + 1)
								);
								updateBigFlightData({
									selectedTraveller: [
										...bigFlightData?.selectedTraveller?.slice(0, currIndex),
										item,
										...bigFlightData?.selectedTraveller?.slice(currIndex + 1),
									],
								});
								handleClose();
							}}
						/>
					);
				})}
			</div>

			{/* <CustomDropdown
						onSelect={(val: any) => {
							// setNewPassenger({
							// 	...newPassenger,
							// 	identification_country: val,
							// });
						}}
						optionsList={myTravellers.map((item: any) => ({
							label: `${item?.first_name} ${item?.middle_name} ${item?.last_name}`,
							value: item?.first_name,
						}))}
						ind={3}
					/> */}
		</div>
	);
};

export default SwitchPassengerComponent;

const PassengerItem = ({
	item,
	onSelect,
}: {
	item: any;
	onSelect: () => void;
}) => {
	return (
		<div className="DropPassengerItem" onClick={() => onSelect()}>
			<div className="DropPassengerItem__img">
				{item?.first_name?.charAt(0).toUpperCase()}
				{item?.last_name?.charAt(0).toUpperCase()}
			</div>
			<div>
				<p className="DropPassengerItem__names">{`${item?.first_name} ${item?.middle_name} ${item?.last_name}`}</p>
				<p className="DropPassengerItem__email">{item?.email}</p>
			</div>
		</div>
	);
};
