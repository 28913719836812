import "../style.scss";
import { useState, useEffect } from "react";
import Button from "../../../components/Button";
import { BiSearch } from "react-icons/bi";
import { RiDeleteBin6Line } from "react-icons/ri";
import MUIModal from "../../../components/MUIModal";
import {
	AddNewTravellerModal,
	AddNewTravellerModalRewrite,
	DeleteModal,
} from "../../../modals";
import { useTripStore } from "../../../zustand/store";
import { fetchTravellers, deleteTraveller } from "../../../requests";
import SpinLoader from "../../../components/SpinLoader";
import { toast } from "react-toastify";
import EmptyState from "../../../components/EmptyState";
import LoadMore from "../../../components/LoadMore";
import useLoadMore from "../../../hooks/useLoadMore";
import useDebounce from "../../../hooks/useDebounce";
import { Drawer } from "@mui/material";
import { toggleSideDrawer } from "../../../helpers/helperFuncs";

const Travellers = () => {
	const myTravellers = useTripStore((state: any) => state.myTravellers);
	const setMyTravellers = useTripStore((state: any) => state.setMyTravellers);
	const [loading, setLoading] = useState(false);
	const [edit, setEdit] = useState(false);
	const [deleteLoading, setDeleteLoading] = useState(false);
	const [searchString, setSearchString] = useState("");

	const refetchFunc = async () => {
		setLoading(true);
		try {
			const travellerRes = await fetchTravellers();
			console.log("travellerRes", travellerRes);
			setMyTravellers(travellerRes?.data?.data);
		} catch (e) {
			console.log(e);
		} finally {
			setLoading(false);
		}
	};

	let [addNewTraveller, setAddNewTraveller] = useState(false);
	let [openDelete, setOpenDelete] = useState(false);
	let [currentTraveller, setCurrentTraveller] = useState({
		id: "",
		first_name: "",
		middle_name: "",
		last_name: "",
	});

	const deleteFunc = async () => {
		setDeleteLoading(true);
		try {
			const deleteRes = await deleteTraveller(currentTraveller?.id);
			console.log("deleteRes", deleteRes);
			if (deleteRes?.status === 204) {
				toast.success("Passenger deleted");
				setOpenDelete(false);
				refetchFunc();
			}
		} catch (e) {
			console.log(e);
			toast.error("Unable to delete passenger");
		} finally {
			setDeleteLoading(false);
		}
	};

	const debouncedSearch = useDebounce(searchString, 500);

	const [tempList, setTempList] = useState(myTravellers);

	let limit = 10;

	let next: any, currentData: any;
	let maxedOut: boolean;
	let reset: () => void;

	({ currentData, next, maxedOut, reset } = useLoadMore(tempList, limit));

	useEffect(() => {
		/*
			allows you to search by:
				- Passenger's first Name
				- Passenger's middle Name
				- Passenger's last Name

			PS: search is NOT case sensitive. Use whatever case pleases you
		*/

		if (debouncedSearch === "") setTempList(myTravellers);
		else
			setTempList(
				myTravellers.filter((item: any) => {
					return (
						item?.first_name
							?.toLowerCase()
							?.startsWith(debouncedSearch.toLowerCase()) ||
						item?.middle_name
							?.toLowerCase()
							?.startsWith(debouncedSearch.toLowerCase()) ||
						item?.last_name
							?.toLowerCase()
							?.startsWith(debouncedSearch.toLowerCase())
					);
				})
			);
	}, [debouncedSearch]);

	return (
		<div className="SettingsCrux">
			<div className="SettingsCrux__main">
				<div className="topbar">
					<div className="search-div">
						<input
							type="search"
							name=""
							id=""
							placeholder="Search"
							value={searchString}
							onChange={(e) => setSearchString(e.target.value)}
						/>
						<BiSearch />
					</div>
					<Button
						className="pry"
						onClick={() => {
							setEdit(false);
							setAddNewTraveller(true);
						}}
					>
						Add Passenger
					</Button>
				</div>
				{loading ? (
					<SpinLoader />
				) : (
					<div className="travellers">
						{myTravellers.length !== 0 ? (
							currentData()?.length !== 0 ? (
								currentData().map((item: any, ind: number) => (
									<TravellerRow
										travellerData={item}
										key={ind}
										triggerEditModal={() => {
											setEdit(true);
											setAddNewTraveller(true);
										}}
										triggerDeleteModal={() => setOpenDelete(true)}
										setCurrentTraveller={(val: any) => setCurrentTraveller(val)}
									/>
								))
							) : (
								<div style={{ textAlign: "center" }}>
									<EmptyState message="No results found for your search" />
								</div>
							)
						) : (
							<div style={{ textAlign: "center" }}>
								<EmptyState message='No travellers attached to this account. Click "Add new passenger" to create some' />
							</div>
						)}
					</div>
				)}

				{currentData()?.length !== 0 && (
					<div className="SettingsCrux__LoadMore">
						<LoadMore
							loadFunc={() => {
								next();
							}}
							disabled={maxedOut}
						/>
					</div>
				)}
			</div>
			<div className="SettingsCrux__aside"></div>

			{document.documentElement.clientWidth > 780 && (
				<MUIModal
					open={addNewTraveller}
					handleClose={() => setAddNewTraveller(false)}
					sticky={true}
				>
					<AddNewTravellerModalRewrite
						handleClose={() => setAddNewTraveller(false)}
						currentTraveller={currentTraveller}
						edit={edit}
						showAdd={false}
						refetchFunc={refetchFunc}
					/>
				</MUIModal>
			)}

			{document.documentElement.clientWidth <= 780 && (
				<Drawer
					anchor="top"
					open={addNewTraveller}
					onClose={toggleSideDrawer(false, setAddNewTraveller)}
					// onOpen={toggleSideDrawer(true, setShowTraveller)}
				>
					<AddNewTravellerModalRewrite
						handleClose={() => setAddNewTraveller(false)}
						currentTraveller={currentTraveller}
						edit={edit}
						showAdd={false}
						refetchFunc={refetchFunc}
					/>
				</Drawer>
			)}

			<MUIModal
				open={openDelete}
				handleClose={() => setOpenDelete(false)}
				sticky={true}
			>
				<DeleteModal
					handleClose={() => setOpenDelete(false)}
					deleteFunc={deleteFunc}
					name={`${currentTraveller.first_name} ${currentTraveller.middle_name} ${currentTraveller.last_name}`}
					deleteLoading={deleteLoading}
				/>
			</MUIModal>
		</div>
	);
};

export default Travellers;

const TravellerRow = ({
	travellerData,
	triggerEditModal,
	triggerDeleteModal,
	setCurrentTraveller,
}: {
	travellerData: any;
	triggerEditModal: () => void;
	triggerDeleteModal: () => void;
	setCurrentTraveller: (val: any) => void;
}) => {
	return (
		<div className="TravellerRow">
			<div className="TravellerRow__info">
				<img
					src={
						travellerData?.avatar ??
						`https://ui-avatars.com/api/?name=${travellerData?.first_name} ${travellerData?.last_name}`
					}
					alt="profile"
				/>
				<p>{`${travellerData?.first_name} ${travellerData?.middle_name} ${travellerData?.last_name}`}</p>
			</div>
			<div className="TravellerRow__actions">
				<span
					onClick={() => {
						setCurrentTraveller(travellerData);
						triggerEditModal();
					}}
				>
					Edit
				</span>
				<RiDeleteBin6Line
					onClick={() => {
						setCurrentTraveller(travellerData);
						triggerDeleteModal();
					}}
				/>
			</div>
		</div>
	);
};
