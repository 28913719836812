import { CloseIcon } from "../components/pieces/svgs";

interface SimpleModalLayoutProps {
	children: JSX.Element;
	title: string;
	text: string;
	handleClose: () => void;
}

export const SimpleModalLayout = ({
	children,
	title,
	text,
	handleClose,
}: SimpleModalLayoutProps) => (
	<div className="SimpleModalLayout">
		<div className="SimpleModalLayout__title">{title}</div>
		<div className="SimpleModalLayout__para">{text}</div>
		<div className="SimpleModalLayout__body">{children}</div>
		<button className="close-btn" onClick={() => handleClose()}>
			<CloseIcon />
		</button>
	</div>
);
