import React, {
	useState,
	useRef,
	Dispatch,
	SetStateAction,
	useEffect,
} from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import "./style.scss";
import { BellSVG, LogoIcon, MenuHamburger, MenuCancel } from "../pieces/svgs";
import {
	BsFillArrowUpRightCircleFill,
	BsTelephoneOutboundFill,
} from "react-icons/bs";
import { FaWhatsappSquare } from "react-icons/fa";
import { MdOutlineLogout, MdMail } from "react-icons/md";
import { IoIosSettings } from "react-icons/io";
import useOutsideAlerter from "../../hooks/useOutsideAlerter";
import { useTripStore } from "../../zustand/store";
import HeadwayWidget from "../HeadwayWidget";
import { ellipsisTruncation } from "../../helpers/helperFuncs";
import useNavigatorOnline from "../../hooks/useNavigatorOnline";
import { toast } from "react-toastify";

declare global {
	interface Window {
		fcWidget: any;
		fcSettings: any;
		Headway: any;
	}
}

window.fcSettings = {
	token: "976cfa0e-8b15-46ac-ae22-2529e667a29f",
	host: "https://wchat.freshchat.com",
	config: {
		headerProperty: {
			hideChatButton: true,
		},
	},
};

export default function Header({
	sideOpen,
	setSideOpen,
}: {
	sideOpen: boolean;
	setSideOpen: Dispatch<SetStateAction<boolean>>;
}) {
	const { pathname } = useLocation();
	const navigate = useNavigate();

	const userData = useTripStore((state: any) => state.userData);
	const updateBigFlightData = useTripStore(
		(state: any) => state.updateBigFlightData
	);
	const updateBigCarData = useTripStore((state: any) => state.updateBigCarData);

	const [openUserDrop, setOpenUserDrop] = useState(false);
	const [openHelpDrop, setOpenHelpDrop] = useState(false);
	const wrapperRef = useRef(null);
	const helpRef = useRef(null);

	useOutsideAlerter(wrapperRef, () => setOpenUserDrop(false));
	useOutsideAlerter(helpRef, () => setOpenHelpDrop(false));

	const isOnline = useNavigatorOnline(
		() => toast.success("You are back online!"),
		() =>
			toast.error(
				"Your device seems to be offline. Please check your internet connection."
			)
	);

	console.log(pathname);
	const navList = [
		{ label: "Dashboard", route: "/dashboard" },
		{ label: "Trips", route: "/trips" },
		{ label: "Trip History", route: "/trip-history" },
		{ label: "Invoicing", route: "/invoicing" },
		{ label: "Reporting", route: "/reporting" },
		// { label: "Events", route: "/events" },
	];
	const token = localStorage.getItem("lkzio");
	let userPicture = "https://randomuser.me/api/portraits/lego/2.jpg";

	const resetZustand = () => {
		//reset zustand
		updateBigFlightData({
			search: "",
			selectedTraveller: [],
			departure: "",
			destination: "",
			departure_date: "",
			return_date: null,
			cabin: "economy",
			showTripType: "Roundtrip",
			selectedDeparture: undefined,
			selectedArrival: undefined,
			flightSelected: false,
			bookingConfirmed: false,
			paymentConfirmed: false,
			departureCity: [],
			arrivalCity: [],
			flightReference: "",
			route: "domestic",
			session_id: "",
			session_id2: "",
			searchData: {},
			arrivalData: {},
			exchange_rate: 0,
			multiCity: [
				{
					departure: "",
					destination: "",
					departure_date: "",
				},
				{
					departure: "",
					destination: "",
					departure_date: "",
				},
			],
			selectedMultiCityFlights: [],
			multiSearchData: [],
		});
		updateBigCarData({
			selectedTraveller: [],
			service_type: "FULLDAY",
			carType: "",
			date: "",
			ISODate: "",
			time: "",
			pickUp: "",
			dropOff: "",
			bookingConfirmed: false,
			searchResults: [],
			kardinal_reference: "",
		});
	};

	return (
		<header className="header">
			<Link to="/" aria-label="Kardinal logo">
				<LogoIcon size={18} />
			</Link>
			{!pathname.startsWith("/login") &&
				!pathname.startsWith("/onboarding") && (
					<div className="link-wrapper">
						{token
							? navList.map((navItem, ind) => (
									<Link
										to={navItem.route}
										style={{
											color:
												pathname === "/" && ind === 0
													? "#40798C"
													: pathname.startsWith(navItem.route)
													? "#40798C"
													: "#655f5f",
										}}
										key={`nav-${ind}`}
										onClick={() => resetZustand()}
									>
										<p>{navItem.label}</p>
									</Link>
							  ))
							: null}
					</div>
				)}
			{!pathname.startsWith("/login") &&
				!pathname.startsWith("/onboarding") && (
					<div className="user-section">
						<div className="help" ref={helpRef}>
							<p
								className="help-text"
								onClick={() => setOpenHelpDrop(!openHelpDrop)}
							>
								Help
							</p>
							<HelpDrop
								openHelpDrop={openHelpDrop}
								setOpenHelpDrop={setOpenHelpDrop}
							/>
						</div>
						{/* <HeadwayWidget /> */}
						{token ? <HeadwayWidget /> : null}
						{token ? (
							<div className="pic-section" ref={wrapperRef}>
								<img
									className="user-pic"
									src={userPicture}
									alt=""
									onClick={() => setOpenUserDrop(!openUserDrop)}
								/>
								<div
									className={`pic-section__drop ${
										openUserDrop && "pic-section__drop--open"
									}`}
								>
									<p className="name">
										{ellipsisTruncation(
											`${userData?.first_name} ${userData?.last_name}`,
											15
										)}
									</p>
									<div className="options">
										<div
											className="option"
											onClick={() => {
												navigate("/settings");
												setOpenUserDrop(false);
											}}
										>
											<IoIosSettings size={14} />
											<p>Settings</p>
										</div>
										<div
											className="option"
											onClick={() => {
												navigate("/login");
												setOpenUserDrop(false);
											}}
										>
											<MdOutlineLogout size={14} />
											<p>Log out</p>
										</div>
									</div>
								</div>
							</div>
						) : null}
						{/* https://xsgames.co/randomusers/avatar.php?g=male */}
					</div>
				)}
			{pathname.startsWith("/login") || pathname.startsWith("/onboarding") ? (
				<div className="help" ref={helpRef}>
					<p
						onClick={() => setOpenHelpDrop(!openHelpDrop)}
						className="help-text"
					>
						Help
					</p>
					<HelpDrop
						openHelpDrop={openHelpDrop}
						setOpenHelpDrop={setOpenHelpDrop}
					/>
				</div>
			) : token ? (
				<div className="menu-hamburger" onClick={() => setSideOpen(!sideOpen)}>
					{sideOpen ? <MenuCancel /> : <MenuHamburger />}
				</div>
			) : null}
		</header>
	);
}

const HelpDrop = ({
	openHelpDrop,
	setOpenHelpDrop,
}: {
	openHelpDrop: boolean;
	setOpenHelpDrop: any;
}) => {
	const [supportClicked, setSupportClicked] = useState(false);
	const contact = [
		{
			title: "Chat us on whatsapp",
			icon: <FaWhatsappSquare />,
			link: "https://wa.me/+2348121463765",
		},
		{
			title: "+234 8121463765",
			icon: <BsTelephoneOutboundFill />,
			link: "tel:+2348121463765",
		},
		{
			title: "Support@gokardinal.com",
			icon: <MdMail />,
			link: "mailto:support@gokardinal.com",
		},
	];

	useEffect(() => {
		if (!openHelpDrop) setSupportClicked(false);
	}, [openHelpDrop]);

	return (
		<div
			className={`HelpDrop ${openHelpDrop && "HelpDrop--open"}`}
			style={{ right: supportClicked ? "0" : "" }}
		>
			{supportClicked ? (
				<div className="supportPhase">
					<div className="supportPhase__txt">
						<p className="supportPhase__txt__title">2474U</p>
						<p>Reach out to us anytime</p>
					</div>
					<div className="supportPhase__contact">
						{contact.map((contactItem) => (
							<a
								href={contactItem.link}
								target="_blank"
								rel="noreferrer noopener"
							>
								<div>
									{contactItem.icon}
									<p>{contactItem.title}</p>
								</div>
							</a>
						))}
					</div>
				</div>
			) : (
				<div className="options">
					<div className="option--help">
						<p
							onClick={() => {
								// navigate("/login");
								setSupportClicked(true);
							}}
						>
							Get support
						</p>
						<BsFillArrowUpRightCircleFill size={16} />
					</div>
					<div className="option--help">
						<p
							onClick={() => {
								window.fcWidget.open();
								window.fcWidget.show();
								setOpenHelpDrop(false);
							}}
						>
							Live chat
						</p>
						<BsFillArrowUpRightCircleFill size={16} />
					</div>
					{/* <div className="option--help">
		<p
			onClick={() => {
				// navigate("/login");
				setOpenHelpDrop(false);
			}}
		>
			Give feedback
		</p>
		<BsFillArrowUpRightCircleFill size={16} />
	</div> */}
				</div>
			)}
		</div>
	);
};
