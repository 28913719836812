import { useTripStore } from "../../../zustand/store";
import "./style.scss";

const PriceBreakdownBlock = ({
	fareCost,
	tax,
}: {
	fareCost: number;
	tax: number;
}) => {
	const bigFlightData = useTripStore((state: any) => state.bigFlightData);

	console.log("fareCost", fareCost);

	return (
		<div className="PriceBreakdownBlock">
			<h3>Price breakdown</h3>
			<div>
				<div className="double-col">
					<p className="gray">
						Adult X {bigFlightData.selectedTraveller.length}
					</p>
					<span className="gray">:</span>
					<p>₦ {fareCost.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}</p>
				</div>
				<div className="double-col">
					<p className="gray">Taxes and fees</p>
					<span className="gray">:</span>
					<p>₦ {tax.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}</p>
				</div>
				<div className="double-col">
					<p className="gray">Booking fee</p>
					<span className="gray">:</span>
					<p>Free</p>
				</div>
				{/* <div className="double-col">
					<p className="gray">
						CO<sub>2</sub>
					</p>
					<span className="gray">:</span>
					<p>1.45321i toones</p>
				</div> */}
			</div>
			<div className="double-col total">
				<p>Total amount</p>
				<span></span>
				<p>
					₦{(tax + fareCost).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}
				</p>
			</div>
		</div>
	);
};

export default PriceBreakdownBlock;
