import React, {
	useState,
	useMemo,
	useEffect,
	useRef,
	ChangeEvent,
} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Button from "../../components/Button";
import "./style.scss";
import useAxios from "axios-hooks";
import Select from "react-select";
import { BiSearch } from "react-icons/bi";
import { CgClose } from "react-icons/cg";
import { CheckBox } from "../TripComponents/tripData";
import LoadingAnimation from "../../components/LoadingAnimation";
// import CustomLoader from "../CustomLoader";
import { PlaneSelectIcon, DepartureIcon, ArrivalIcon } from "../pieces/svgs";
import { CustomSelect } from "../CustomSelect";
import DatePicker, {
	getAllDatesInRange,
	DateObject,
} from "react-multi-date-picker";
import CustomDropdown from "../CustomDropdown";
import { useTripStore } from "../../zustand/store";
import {
	fetchFlightData,
	combinableSearch,
	addSearchHistory,
	fetchMulticityFlightData,
} from "../../requests";
import CustomLoader from "../../view/CustomLoader";
import shallow from "zustand/shallow";
import opacity from "react-element-popper/animations/opacity";
import transition from "react-element-popper/animations/transition";
import { toast } from "react-toastify";
import CustomTooltip from "../CustomTooltip";
import ToggleSwitch from "../ToggleSwitch";
// import { searchFlight } from "../../helpers/searchFlightFlow";

export const EditFlightSearch = ({ onClose }: { onClose: () => {} }) => {
	const navigate = useNavigate();

	const [active, setActive] = useState(1);
	let [count, setCount] = useState(0);

	const arr = useMemo(() => new Array(count).fill(count), [count]);
	let [flightType, setFlightType] = useState(true);
	// let [emailRequest, setEmailRequest] = useState(false);
	let [directFlight, setDirectFlight] = useState(false);
	const [calendarNum, setCalendarNum] = useState(
		document.documentElement.clientWidth > 780 ? 2 : 1
	);

	const bigFlightData = useTripStore((state: any) => state.bigFlightData);

	const editFlightData = useTripStore((state: any) => state.editFlightData);
	const updateEditFlightData = useTripStore(
		(state: any) => state.updateEditFlightData
	);

	const localAirports = useTripStore((state: any) => state.localAirports);
	const globalAirports = useTripStore((state: any) => state.globalAirports);
	const searchLoading = useTripStore((state: any) => state.searchLoading);
	const setSearchLoading = useTripStore((state: any) => state.setSearchLoading);
	const arrivalLoading = useTripStore((state: any) => state.arrivalLoading);
	const setArrivalLoading = useTripStore(
		(state: any) => state.setArrivalLoading
	);
	const dropDownLoading = useTripStore((state: any) => state.dropDownLoading);

	const calendarRef = useRef<any>(null);
	const calendarRef1 = useRef<any>(null);
	const calendarRef2 = useRef<any>(null);
	const calendarRef3 = useRef<any>(null);
	const calendarRef4 = useRef<any>(null);
	const calendarRef5 = useRef<any>(null);

	let increaseCount = () => {
		if (editFlightData?.multiCity?.length >= 5) {
			return;
		}
		updateEditFlightData({
			multiCity: [
				...editFlightData?.multiCity,
				{
					departure: "",
					destination: "",
					departure_date: "",
				},
			],
		});
	};

	let decreaseCount = () => {
		if (editFlightData?.multiCity?.length <= 2) {
			return;
		}
		updateEditFlightData({
			multiCity: editFlightData?.multiCity?.slice(
				0,
				editFlightData?.multiCity?.length - 1
			),
		});
	};

	console.log(localAirports);
	console.log("editFlightData", editFlightData);
	console.log("bigFlightData", bigFlightData);

	useEffect(() => {
		setCalendarNum(document.documentElement.clientWidth > 780 ? 2 : 1);
	}, [document.documentElement.clientWidth]);

	useEffect(() => {
		if (bigFlightData.showTripType === "Multicity") {
			if (active === 3) calendarRef1.current?.openCalendar();
			if (active === 7) calendarRef2.current?.openCalendar();
			if (active === 10) calendarRef3.current?.openCalendar();
			if (active === 13) calendarRef4.current?.openCalendar();
			if (active === 16) calendarRef5.current?.openCalendar();
		} else {
			if (active === 3) {
				calendarRef.current?.openCalendar();
			} else {
				calendarRef.current?.closeCalendar();
			}
		}
	}, [active]);

	useEffect(() => {
		updateEditFlightData({ ...bigFlightData });
	}, []);

	return (
		<div className="EditFlightSearch">
			<div className="bottom">
				<div className="close-edit">
					<p>Search flights</p>
					<CgClose onClick={() => onClose()} size={18} />
				</div>
				<div className="actions">
					<div className="tripTypeToggle">
						<span
							className={`tripTypeLabel tripTypeLabel--${
								editFlightData?.route === "domestic" && "selected"
							}`}
							onClick={() => updateEditFlightData({ route: "domestic" })}
						>
							Domestic
						</span>
						<ToggleSwitch
							checked={editFlightData.route === "international"}
							handleChange={(event: ChangeEvent<HTMLInputElement>) => {
								// setVaccinated(event.target.checked);
								if (event.target.checked)
									updateEditFlightData({ route: "international" });
								else updateEditFlightData({ route: "domestic" });
							}}
						/>
						<span
							className={`tripTypeLabel tripTypeLabel--${
								editFlightData?.route === "international" && "selected"
							}`}
							onClick={() => updateEditFlightData({ route: "international" })}
						>
							International
						</span>
					</div>
					{/* <div className="flight-type">
						<p
							className={`hover ${
								editFlightData.route === "domestic" ? "current" : ""
							}`}
							onClick={() => {
								updateEditFlightData({ route: "domestic" });
							}}
						>
							Domestic Flight
						</p>
						<p
							className={`hover ${
								editFlightData.route === "international" ? "current" : ""
							}`}
							onClick={() => {
								updateEditFlightData({ route: "international" });
							}}
						>
							International Flight
						</p>
					</div> */}
				</div>
				<div className="form-section">
					{editFlightData.showTripType !== "Multicity" ? (
						<form>
							<div className="form-item">
								<p style={{ paddingBottom: "5px" }}>Departure</p>

								<CustomSelect
									icon={<DepartureIcon color="#9C9393" size={22} />}
									label="From"
									list={[localAirports, globalAirports]}
									listIndex={editFlightData.route === "domestic" ? 0 : 1}
									value={editFlightData.departure}
									active={active === 1}
									onChange={(val: any) => {
										updateEditFlightData({ departure: val });
										// setActive(2);
									}}
									onSelect={() => setActive(1)}
									loading={
										bigFlightData.route === "domestic"
											? dropDownLoading?.localAirports
											: dropDownLoading?.intlAirports
									}
									ind={1}
								/>
							</div>
							<div className="form-item">
								<p style={{ paddingBottom: "5px" }}>Destination</p>

								<CustomSelect
									icon={<ArrivalIcon color="#9C9393" size={22} />}
									label="To"
									list={[localAirports, globalAirports]}
									listIndex={editFlightData.route === "domestic" ? 0 : 1}
									value={editFlightData?.destination}
									onChange={(val: any) => {
										updateEditFlightData({ destination: val });
										// setActive(3);
									}}
									active={active === 2}
									onSelect={() => setActive(2)}
									loading={
										bigFlightData.route === "domestic"
											? dropDownLoading?.localAirports
											: dropDownLoading?.intlAirports
									}
									ind={2}
								/>
							</div>

							<div className="form-item">
								<p className="label" style={{ paddingBottom: "5px" }}>
									Trip type
								</p>

								<CustomDropdown
									onSelect={(val: any) => {
										updateEditFlightData({ showTripType: val });

										// if (val === "Multicity") setActive(1);
										// else setActive(4);
									}}
									defaultValue={editFlightData.showTripType}
									optionsList={[
										{ label: "Roundtrip", value: "Roundtrip" },
										{
											label: "One way",
											value: "One way",
										},
										{
											label: "Multicity",
											value: "Multicity",
										},
									]}
									active={active === 3}
									ind={1}
								/>
							</div>

							<div className="form-item">
								<p style={{ paddingBottom: "5px" }}>Date</p>

								{editFlightData.showTripType === "Roundtrip" ? (
									<DatePicker
										range
										className="teal"
										placeholder="departure  -  return"
										// value={[depDate, retDate]}
										onChange={(dateObjects: DateObject[]) => {
											const dateList = getAllDatesInRange(dateObjects, true);

											updateEditFlightData({
												departure_date: new Date(
													dateList[0].toString()
												).toLocaleDateString("sv-SE"),
												return_date: new Date(
													dateList[dateList.length - 1].toString()
												).toLocaleDateString("sv-SE"),
											});
											console.log("editFlightData", editFlightData);
											if (dateList.length > 1) setActive(4);
										}}
										onOpen={() => setActive(3)}
										onOpenPickNewDate={false}
										inputClass="custom-rmdp-input"
										ref={calendarRef}
										numberOfMonths={calendarNum}
										mapDays={(object: any) => {
											if (
												editFlightData.departure_date &&
												editFlightData.departure_date ===
													editFlightData.return_date
											)
												return {
													disabled:
														new Date(editFlightData.departure_date) >
														object.date
															? true
															: false,
												};
											else
												return {
													disabled: object.today > object.date ? true : false,
												};
										}}
										animations={[
											opacity(),
											transition({ from: 35, duration: 400 }),
										]}
									/>
								) : (
									<DatePicker
										className="teal"
										placeholder="departure"
										value={editFlightData.departure_date}
										onChange={(val: any) => {
											updateEditFlightData({
												departure_date: new Date(
													val.toString()
												).toLocaleDateString("sv-SE"),
												return_date: "",
											});
											setActive(4);
										}}
										onOpen={() => setActive(3)}
										onOpenPickNewDate={false}
										inputClass="custom-rmdp-input"
										ref={calendarRef}
										numberOfMonths={calendarNum}
										mapDays={(object: any) => {
											return {
												disabled: object.today > object.date ? true : false,
											};
										}}
										animations={[
											opacity(),
											transition({ from: 35, duration: 400 }),
										]}
									/>
								)}
							</div>
							<div className="form-item">
								<p style={{ paddingBottom: "5px" }}>Class</p>
								<CustomDropdown
									onSelect={(val: any) => {
										updateEditFlightData({ cabin: val });
									}}
									defaultValue={editFlightData.cabin}
									optionsList={[
										{ label: "Economy", value: "economy" },
										{
											label: "Premium Economy",
											value:
												editFlightData.route === "domestic"
													? "premium_economy"
													: "Premium",
										},
										{
											label: "Business",
											value: "business",
										},
										{ label: "First", value: "first" },
									]}
									active={active === 4}
									ind={0}
								/>
							</div>
						</form>
					) : (
						<div className="multi-trip">
							{editFlightData?.multiCity?.map((_: any, index: number) => (
								<form key={index}>
									<div className="form-item">
										<p className="label">Departure</p>
										<CustomSelect
											icon={<DepartureIcon color="#9C9393" size={22} />}
											label="From"
											list={[localAirports, globalAirports]}
											listIndex={editFlightData.route === "domestic" ? 0 : 1}
											value={editFlightData.multiCity[index].departure}
											onChange={(val: any) => {
												const newArr = editFlightData.multiCity;
												newArr[index].departure = val;
												updateEditFlightData({ multiCity: newArr });
												// setActive(
												// 	index === 0 ? 3 * (index + 1) - 1 : 3 * (index + 1)
												// );
											}}
											active={
												active ===
												(index === 0
													? 3 * (index + 1) - 2
													: 3 * (index + 1) - 1)
											}
											onSelect={() =>
												setActive(
													index === 0
														? 3 * (index + 1) - 2
														: 3 * (index + 1) - 1
												)
											}
											loading={
												bigFlightData.route === "domestic"
													? dropDownLoading?.localAirports
													: dropDownLoading?.intlAirports
											}
											ind={3}
										/>
									</div>
									<div className="form-item">
										<p className="label">Destination</p>
										<CustomSelect
											icon={<ArrivalIcon color="#9C9393" size={22} />}
											label="To"
											list={[localAirports, globalAirports]}
											listIndex={editFlightData.route === "domestic" ? 0 : 1}
											value={editFlightData.multiCity[index].destination}
											onChange={(val: any) => {
												const newArr = editFlightData.multiCity;
												newArr[index].destination = val;
												updateEditFlightData({ multiCity: newArr });
												// setActive(
												// 	index === 0 ? 3 * (index + 1) : 3 * (index + 1) + 1
												// );
											}}
											active={
												active ===
												(index === 0 ? 3 * (index + 1) - 1 : 3 * (index + 1))
											}
											onSelect={() =>
												setActive(
													index === 0 ? 3 * (index + 1) - 1 : 3 * (index + 1)
												)
											}
											loading={
												bigFlightData.route === "domestic"
													? dropDownLoading?.localAirports
													: dropDownLoading?.intlAirports
											}
											ind={4}
										/>
									</div>

									<div className="dateTypeWrap">
										{index === 0 && (
											<div className="form-item">
												<p className="label">Trip type</p>

												<CustomDropdown
													onSelect={(val: any) => {
														updateEditFlightData({ showTripType: val });
														// setActive(4);
														// setActive(1);
													}}
													defaultValue={editFlightData.showTripType}
													optionsList={[
														{ label: "Roundtrip", value: "Roundtrip" },
														{
															label: "One way",
															value: "One way",
														},
														{
															label: "Multicity",
															value: "Multicity",
														},
													]}
													active={active === 3}
													ind={1}
												/>
											</div>
										)}

										<div className="form-item">
											<p className="label">Date</p>

											<DatePicker
												className="teal"
												placeholder="departure"
												value={editFlightData.multiCity[index].departure_date}
												onChange={(dateObject: DateObject) => {
													const newArr = editFlightData.multiCity;
													newArr[index].departure_date = new Date(
														dateObject.toString()
													).toLocaleDateString("sv-SE");

													updateEditFlightData({ multiCity: newArr });

													setActive(
														index === 0
															? 3 * (index + 1) + 1
															: 3 * (index + 1) + 2
													);
												}}
												onOpen={() => {
													setActive(
														index === 0 ? 3 * (index + 1) : 3 * (index + 1) + 1
													);
												}}
												onOpenPickNewDate={false}
												inputClass="custom-rmdp-input"
												numberOfMonths={calendarNum}
												ref={eval(`calendarRef${index + 1}`)}
												mapDays={(object: any) => {
													return {
														disabled: object.today > object.date ? true : false,
													};
												}}
												animations={[
													opacity(),
													transition({ from: 35, duration: 400 }),
												]}
											/>
											{index > 0 ? (
												<CgClose
													className="del-trip"
													onClick={() => decreaseCount()}
												/>
											) : (
												<span />
											)}
										</div>
									</div>
									{index === 0 ? (
										<div className="form-item" style={{ minWidth: "174px" }}>
											<p className="label">Class</p>

											<CustomDropdown
												onSelect={(val: any) => {
													updateEditFlightData({ cabin: val });
													// setActive(5);
												}}
												defaultValue={editFlightData.cabin}
												optionsList={[
													{ label: "Economy", value: "economy" },
													{
														label: "Premium Economy",
														value:
															editFlightData.route === "domestic"
																? "premium_economy"
																: "Premium",
													},
													{
														label: "Business",
														value: "business",
													},
													{ label: "First", value: "first" },
												]}
												active={active === 4}
												ind={1}
											/>
										</div>
									) : (
										<span style={{ minWidth: "174px" }} />
									)}
								</form>
							))}
							<div className="multi-actions">
								{editFlightData?.multiCity?.length < 5 && (
									<p
										onClick={() => {
											increaseCount();
										}}
										className="add-trip hover"
									>
										+ add another flight
									</p>
								)}
								{editFlightData?.multiCity?.length > 2 && (
									<p onClick={() => decreaseCount()} className="cancel">
										Cancel
									</p>
								)}
							</div>
						</div>
					)}
				</div>

				<div className="button-container">
					<div className="email-request">
						<input
							type="checkbox"
							checked={directFlight}
							onChange={() => setDirectFlight((prev) => !prev)}
						/>
						<p>
							Direct Flight
							<CustomTooltip>
								Get flight results with no layovers.
							</CustomTooltip>
						</p>
						{/* <p>
Need a travel agent to assist with your request? Select this
button and we will respond in 5 minutes with options.
</p> */}
					</div>
					<Button
						className="pry"
						// title="Find Flights"
						onClick={(e: any) => {
							// setSearchLoading(true);

							onClose();
							navigate(`/trips/edit-loading?direct=${directFlight}`);
							// editSearchFlight();
						}}
					>
						{searchLoading ? <LoadingAnimation /> : "Find Flights"}
					</Button>
				</div>
			</div>
		</div>
	);
};
