import { request } from "../utils/axios";
import { toast } from "react-toastify";

export const fetchTransactionSummary = async () => {
	try {
		const res = await request.get("api/finance/transactions_summary");
		console.log(res);
		return res;
	} catch (err) {
		console.log(err?.response?.data);
		console.log(err?.response?.data?.message);
		throw err;
	}
};

export const fetchSearchHistory = async () => {
	try {
		const res = await request.get("api/trips/search_history/");
		console.log(res);
		return res;
	} catch (err) {
		console.log(err?.response?.data);
		console.log(err?.response?.data?.message);
		throw err;
	}
};

export const fetchOnHoldData = async () => {
	try {
		const res = await request.get("api/trips/onhold/bookings/");
		console.log(res);
		return res;
	} catch (err) {
		console.log(err?.response?.data);
		console.log(err?.response?.data?.message);
		throw err;
	}
};

export const convertPoints = async () => {
	try {
		const res = await request.get("api/finance/convert_points");
		console.log(res);
		return res;
	} catch (err) {
		console.log(err?.response?.data);
		console.log(err?.response?.data?.message);
		toast.error(err?.response?.data?.message);

		if (err?.response?.status >= 500) toast.error("Unable to convert points");
		throw err;
	}
};

export const addCredit = async (values) => {
	try {
		const res = await request.post("api/finance/creditrequest/", values);
		console.log(res);
		return res;
	} catch (err) {
		console.log(err?.response?.data);
		console.log(err?.response?.data?.message);
		toast.error(err?.response?.data?.message);

		if (err?.response?.status >= 500) toast.error("Unable to add credit");
		throw err;
	}
};
