import { request } from "../utils/axios";
import { setAuthToken } from "../config/helpers";
import { toast } from "react-toastify";

export const fetchPickupLoctions = async (service_type) => {
	try {
		const res = await request.get(
			`api/cars/pickups/?service_type=${service_type}`
		);
		console.log(res);
		return res;
	} catch (err) {
		console.log(err?.response?.data);
		console.log(err?.response?.data?.message);
		throw err;
	}
};

export const fetchCarLoctions = async () => {
	try {
		const res = await request.get(`api/cars/locations/`);
		console.log(res);
		return res;
	} catch (err) {
		console.log(err?.response?.data);
		console.log(err?.response?.data?.message);
		throw err;
	}
};

export const fetchCarList = async () => {
	try {
		const res = await request.get(`api/cars/list/`);
		console.log(res);
		return res;
	} catch (err) {
		console.log(err?.response?.data);
		console.log(err?.response?.data?.message);
		throw err;
	}
};

export const searchCars = async (params) => {
	const { service_type, pickup, dropoff, car_type } = params;
	try {
		const res = await request.get(
			`api/cars/car-service/search/?service_type=${service_type}&pickup=${pickup}&dropoff=${dropoff}&car_type=${car_type}`
		);
		console.log(res);
		return res;
	} catch (err) {
		console.log(err?.response?.data);
		console.log(err?.response?.data?.message);
		throw err;
	}
};

export const bookCar = async (values) => {
	try {
		const res = await request.post(`api/cars/car-service/book/`, values);
		console.log(res);
		return res;
	} catch (err) {
		console.log(err?.response?.data);
		toast.error(err?.response?.data?.message);
		console.log(err?.response?.data?.message);
		throw err;
	}
};
