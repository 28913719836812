import "./style.scss";

interface CustomCheckBoxProps {
	label: string;
	checked: boolean;
	onChange: () => void;
}

const CustomCheckBox = ({ label, checked, onChange }: CustomCheckBoxProps) => {
	return (
		<label
			htmlFor=""
			className={`CustomCheckBox CustomCheckBox--${checked && "checked"}`}
			onClick={() => onChange()}
		>
			<input type="checkbox" name="" id="" checked={checked} />
			{label}
		</label>
	);
};

export default CustomCheckBox;
