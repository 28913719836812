import React, { useEffect } from "react";
import { BellSVG } from "../pieces/svgs";
import "./style.scss";
import { IoNotificationsOutline } from "react-icons/io5";

const HeadwayWidget = (): JSX.Element => {
	useEffect(() => {
		if (document !== undefined) {
			var config = {
				selector: ".bell-wrappers", // CSS selector where to inject the badge
				account: "JlYzRy",
			};

			window.Headway.init(config);
			console.log("window.Headway", document);
			console.log("window.Headway", window.Headway);
		}
	}, []);

	return (
		<div className="bell-wrappers">
			<IoNotificationsOutline size={20} color="#40798C" />
			{/* <span className="headway-badge" id="headway-badge"></span> */}
		</div>
	);
};

export default HeadwayWidget;
