import React, { useState } from "react";

function useLoadMore(data: any[], itemsPerPage: number) {
	const [currentPage, setCurrentPage] = useState(1);
	const maxPage = Math.ceil(data.length / itemsPerPage);

	function currentData() {
		return data.slice(0, itemsPerPage * currentPage);
	}

	function next() {
		setCurrentPage((currentPage) => Math.min(currentPage + 1, maxPage));
	}

	function reset() {
		setCurrentPage(1);
	}

	// function prev() {
	// 	setCurrentPage((currentPage) => Math.max(currentPage - 1, 1));
	// }

	// function jump(page: number) {
	// 	const pageNumber = Math.max(1, page);
	// 	setCurrentPage((currentPage) => Math.min(pageNumber, maxPage));
	// }

	return { next, currentData, maxedOut: maxPage === currentPage, reset };
}

export default useLoadMore;
