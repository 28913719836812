import { useNavigate } from "react-router-dom";
import Button from "../Button";
import "./style.scss";

interface EmptyStateBigProps {
	title?: string;
	message?: string | JSX.Element;
	button?: { label: string; action?: () => void };
	height?: number;
}

const EmptyStateBig = ({
	title = "Bummer! 😔",
	message = "Nothing to see here.",
	button,
	height = 250,
}: EmptyStateBigProps) => {
	const navigate = useNavigate();

	return (
		<div className="EmptyStateBig">
			<p className="title">{title}</p>
			<div className="desc">{message}</div>
			{button && (
				<Button className="pry" onClick={button?.action}>
					{button.label}
				</Button>
			)}
		</div>
	);
};

export default EmptyStateBig;
