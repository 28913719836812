import React, { useState, useEffect, useRef } from "react";
import "./style.scss";
import PlainTable from "../../../components/PlainTable";
import { RiErrorWarningFill, RiErrorWarningLine } from "react-icons/ri";
import { HiPrinter } from "react-icons/hi";
import UserAvatar from "../../../components/UserAvatar";
import Button from "../../../components/Button";
import {
	FormControl,
	RadioGroup,
	Radio,
	FormLabel,
	FormControlLabel,
} from "@mui/material";
import useAxios from "axios-hooks";
import ModalComponent from "../../../components/Modal";
import LoadingAnimation from "../../../components/LoadingAnimation";
import { useTripStore } from "../../../zustand/store";
import { Navigate, useNavigate } from "react-router-dom";
import { CgArrowLongLeft } from "react-icons/cg";
import { HiOutlineCheckCircle } from "react-icons/hi";
import {
	fetchImageFromCity,
	bookFlight,
	confirmBookOnHold,
} from "../../../requests";
import { toast } from "react-toastify";
import MUIModal from "../../../components/MUIModal";
import { PaymentModal } from "../../../modals";
import { DateTime } from "ts-luxon";
import {
	diffObjToDuration,
	minutesToDuration,
} from "../../../helpers/helperFuncs";
import {
	FlightDetailsBit,
	ShowDetailsDrawer,
	FootNoteCard,
	PriceBreakdownBlock,
	PassengerBlock,
	Badge,
} from "../confirmationComponents";
import ConfirmationLayout from "../../../layouts/ConfirmationLayout";
import { PaymentMethodModal } from "../../../modals";
import LoadingModalsDebacle from "../../../components/LoadingModalsDebacle";
import SummarySkeleton from "../../../components/SummarySkeleton";

const OnHoldSummaryPage = (props: any) => {
	const navigate = useNavigate();
	const tripRef = useRef<HTMLDivElement>(null);

	const [imgUrl, setImgUrl] = useState("/images/fun-img.png");
	const [FormattedAddr, setFormattedAddr] = useState("/images/fun-img.png");
	const [initialRender, setInitialRender] = useState(false);
	//zustand global states
	const bigFlightData = useTripStore((state: any) => state.bigFlightData);
	const updateBigFlightData = useTripStore(
		(state: any) => state.updateBigFlightData
	);

	const isRoundTripAndIntl =
		bigFlightData?.showTripType === "Roundtrip" &&
		bigFlightData?.route === "international";

	const isMultiCityAndLocal =
		bigFlightData?.showTripType === "Multicity" &&
		bigFlightData?.route === "domestic";

	const isMulticityAndIntl =
		bigFlightData.showTripType === "Multicity" &&
		bigFlightData.route === "international";

	let fareCost =
		bigFlightData?.selectedDeparture?.amount +
		(bigFlightData?.selectedArrival === undefined
			? 0
			: bigFlightData?.selectedArrival?.amount);
	let tax =
		bigFlightData?.selectedDeparture?.tax === undefined
			? 0
			: bigFlightData?.selectedDeparture?.tax +
			  (bigFlightData?.selectedArrival === undefined
					? 0
					: bigFlightData?.selectedArrival?.tax);
	let bookingFee = 0;

	if (isRoundTripAndIntl || isMulticityAndIntl) {
		fareCost = bigFlightData.selectedDeparture?.amount;
		tax = bigFlightData.selectedDeparture.tax;
	}
	if (isMultiCityAndLocal) {
		fareCost = bigFlightData.selectedMultiCityFlights.reduce(
			(total: number, selectedObj: any) => total + selectedObj?.amount,
			0
		);
		tax = 0;
	}

	const amount = fareCost + tax;

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setInitialRender(true);
		}, 2000);

		return () => clearTimeout(timeoutId);
	}, []);

	useEffect(() => {
		if (initialRender) {
			if (bigFlightData.bookingConfirmed && !bigFlightData.paymentConfirmed)
				return;
			else {
				navigate("/trips");
			}
		}
	}, [initialRender]);

	if (bigFlightData.bookingConfirmed && !bigFlightData.paymentConfirmed)
		return (
			<>
				<div className="OnHoldSummaryPage">
					<ConfirmationLayout>
						<div>
							<h3>Your booking is on hold</h3>
							<p className="msg">
								See team and flight itinerary below. Please proceed to pay as
								this does not guarantee a ticket.
							</p>
							<PassengerBlock disabled={true} />

							<div className="left-tables">
								<div>
									<PlainTable
										tableHead={
											<div className="miniHead">Flight Information</div>
										}
										tableBody={<PriceBreakdownBody props={props} />}
										style={{ boxShadow: "unset" }}
									/>
								</div>
								<PlainTable
									tableHead={<div className="miniHead">Flight details</div>}
									tableBody={<FlightDetailsBody props={props} />}
									style={{ boxShadow: "unset" }}
								/>
								{/* <PaymentComponent props={props} /> */}
							</div>
							<FootNoteCard
								title="Flight Note"
								textCol="#FCA17D"
								bgCol="#FFF6F6"
								icon={<RiErrorWarningLine size={16} />}
							>
								<p>
									Note that this is not a confirmed ticket and cannot be used to
									travel. Verify your itinerary before paying and if you find
									any information incorrect, email{" "}
									<a href="mailto:tickets@gokardinal.com">
										tickets@gokardinal.com
									</a>{" "}
									or call us on{" "}
									<a href="tel:+2348026993460">+234 802 6993 460</a> or{" "}
									<a href="tel:+2348121463765">+234 802 5198 619</a> before
									payment.
								</p>
							</FootNoteCard>
						</div>
						<div>
							<div>
								<PriceBreakdownBlock tax={tax} fareCost={fareCost} />
								<PaymentComponent props={props} />
							</div>
						</div>
					</ConfirmationLayout>
				</div>
				<div className="OnHoldSummaryPage-mobile">
					<div>
						<h3>Your booking is on hold</h3>
						<p className="msg">
							See team and flight itinerary below. Please proceed to pay as this
							does not guarantee a ticket.
						</p>
					</div>

					<div className="FlightInformation">
						<p className="FlightInformation__title">Flight information</p>
						<div className="double-col">
							<p className="gray">Booking Reference</p>
							<span>:</span>
							<p>{bigFlightData.flightReference}</p>
						</div>
						<div className="double-col">
							<p className="gray">Booking date</p>
							<span>:</span>
							<p>
								{DateTime.fromISO(new Date().toISOString()).toLocaleString({
									weekday: "short",
									day: "2-digit",
									month: "short",
								})}
							</p>
						</div>
						<div className="double-col">
							<p className="gray">Payment Status</p>
							<span>:</span>
							<p style={{ color: "#B81E1E" }}>Pending</p>
						</div>
						<div className="double-col">
							<p className="gray">Tickets Expiration</p>
							<span>:</span>
							<p>
								{DateTime.fromISO(
									bigFlightData?.booking_deadline
								).toLocaleString({
									weekday: "short",
									day: "2-digit",
									month: "short",
								})}
								{"; "}
								{DateTime.fromISO(
									bigFlightData?.booking_deadline
								).toLocaleString(DateTime.TIME_24_SIMPLE)}
							</p>
						</div>
					</div>

					<div className="passengers">
						<PassengerBlock disabled={true} />
					</div>

					<FlightDetailsBit />

					<div className="price-breakdown">
						<PriceBreakdownBlock tax={tax} fareCost={fareCost} />
					</div>

					<FootNoteCard
						title="Flight Note"
						textCol="#FCA17D"
						bgCol="#FFF6F6"
						icon={<RiErrorWarningLine size={16} />}
					>
						<p>
							Note that this is not a confirmed ticket and cannot be used to
							travel. Verify your itinerary before paying and if you find any
							information incorrect, email{" "}
							<a href="mailto:tickets@gokardinal.com">tickets@gokardinal.com</a>{" "}
							or call us on <a href="tel:+2348026993460">+234 802 6993 460</a>{" "}
							or <a href="tel:+2348121463765">+234 802 5198 619</a> before
							payment.
						</p>
					</FootNoteCard>
					<PaymentComponent />
				</div>
				<div ref={tripRef}></div>
			</>
		);
	else return <SummarySkeleton />;
};

export default OnHoldSummaryPage;

// tableBody Components
const PriceBreakdownBody = (props: any) => {
	const bigFlightData = useTripStore((state: any) => state.bigFlightData);

	return (
		<div className="tableBody priceBreakdown">
			<div className="double-col">
				<p>Booking Date</p>
				<p style={{ color: "#252627" }}>
					{DateTime.fromISO(new Date().toISOString()).toLocaleString({
						weekday: "short",
						day: "2-digit",
						month: "short",
					})}
				</p>
			</div>
			<div className="double-col">
				<p>Ticket Reference</p>
				<p style={{ color: "#252627" }}>
					{bigFlightData.flightReference ?? "-"}
				</p>
			</div>
			<div className="double-col">
				<p>Payment Status</p>
				<p style={{ color: "#B81E1E" }}>Pending</p>
			</div>
			<div className="double-col">
				<p>Ticket Expiration</p>
				<p style={{ color: "#252627" }}>
					{DateTime.fromISO(bigFlightData?.booking_deadline).toLocaleString({
						weekday: "short",
						day: "2-digit",
						month: "short",
					})}
					{"; "}
					{DateTime.fromISO(bigFlightData?.booking_deadline).toLocaleString(
						DateTime.TIME_24_SIMPLE
					)}
				</p>
			</div>
			{/* <div className="double-col">
				<p></p>
				<p className="printer">
					<HiPrinter />
					<span>Print ticket</span>
				</p>
			</div> */}
		</div>
	);
};

const FlightDetailsBody = (props: any) => {
	const bigFlightData = useTripStore((state: any) => state.bigFlightData);
	const updateBigFlightData = useTripStore(
		(state: any) => state.updateBigFlightData
	);

	const isMultiCityAndLocal =
		bigFlightData?.showTripType === "Multicity" &&
		bigFlightData?.route === "domestic";

	const isMulticityAndIntl =
		bigFlightData.showTripType === "Multicity" &&
		bigFlightData.route === "international";

	return (
		<div className="flightDetails">
			{isMultiCityAndLocal ? (
				bigFlightData?.selectedMultiCityFlights?.map(
					(multiItem: any, ind: number) => (
						<SingleFlight
							outboundList={multiItem.outbound}
							selectedFlight={multiItem}
							badge={`Flight ${ind + 1}`}
						/>
					)
				)
			) : isMulticityAndIntl ? (
				bigFlightData.selectedDeparture?.outbound?.map(
					(multiItem: any, ind: number) => (
						<SingleFlight
							outboundList={multiItem}
							selectedFlight={bigFlightData.selectedDeparture}
							badge={`Flight ${ind + 1}`}
							ind={ind}
						/>
					)
				)
			) : (
				<>
					<SingleFlight
						outboundList={bigFlightData.selectedDeparture.outbound}
						selectedFlight={bigFlightData.selectedDeparture}
						badge="Departure"
					/>
					{bigFlightData.showTripType === "Roundtrip" ? (
						bigFlightData?.route === "domestic" ? (
							<SingleFlight
								outboundList={bigFlightData.selectedArrival.outbound}
								selectedFlight={bigFlightData.selectedArrival}
								badge="Return"
							/>
						) : (
							<SingleFlight
								outboundList={bigFlightData.selectedDeparture.outbound_return}
								selectedFlight={bigFlightData.selectedDeparture}
								badge="Return"
							/>
						)
					) : null}
				</>
			)}
		</div>
	);
};

export const SingleFlight = ({
	outboundList,
	selectedFlight,
	badge,
	ind,
}: {
	outboundList: any[];
	selectedFlight: any;
	badge: string;
	ind?: number;
}) => {
	const bigFlightData = useTripStore((state: any) => state.bigFlightData);

	const isMulticityAndIntl =
		bigFlightData.showTripType === "Multicity" &&
		bigFlightData.route === "international";

	console.log("outboundList", outboundList);

	return (
		<div
			style={{
				borderBottom: "0.8px solid rgba(196, 196, 196, 0.5)",
			}}
		>
			<div className="overview">
				<div className="badge-img">
					<Badge variant={badge} />
					<img
						src={`https://image.tiqwa.com/airlines/${outboundList[0].airline_details.code}.png`}
						alt=""
					/>
				</div>
				<div className="details">
					<div>
						<div>
							<p>
								<span className="black" style={{ marginRight: "0.4rem" }}>
									{outboundList[0].airport_from}
								</span>{" "}
								<span>
									{" "}
									<span>
										{new Date(outboundList[0].departure_time)
											.getHours()
											.toString().length === 1
											? "0" +
											  new Date(outboundList[0].departure_time).getHours()
											: new Date(outboundList[0].departure_time).getHours()}
									</span>
									:
									<span>
										{new Date(outboundList[0].departure_time).getMinutes() === 0
											? "00"
											: new Date(outboundList[0].departure_time).getMinutes()}
									</span>{" "}
									hrs
								</span>
							</p>
							<p>Flight arrives</p>
							<p>Duration</p>
						</div>
						<div>
							<p>
								<span className="black" style={{ marginRight: "0.4rem" }}>
									{outboundList[outboundList.length - 1].airport_to}
								</span>{" "}
								<span>
									{" "}
									<span>
										{new Date(
											outboundList[outboundList.length - 1].arrival_time
										)
											.getHours()
											.toString().length === 1
											? "0" +
											  new Date(
													outboundList[outboundList.length - 1].arrival_time
											  ).getHours()
											: new Date(
													outboundList[outboundList.length - 1].arrival_time
											  ).getHours()}
									</span>
									:
									<span>
										{new Date(
											outboundList[outboundList.length - 1].arrival_time
										).getMinutes() === 0
											? "00"
											: new Date(
													outboundList[outboundList.length - 1].arrival_time
											  ).getMinutes()}
									</span>{" "}
									hrs
								</span>
							</p>
							<p className="black">
								{DateTime.fromISO(
									outboundList[outboundList.length - 1].arrival_time
								).toLocaleString({
									weekday: "short",
									day: "2-digit",
									month: "short",
								})}
							</p>
							<p className="black">
								{" "}
								<span>
									{diffObjToDuration(
										DateTime.fromISO(
											outboundList[outboundList.length - 1].arrival_time
										)
											.diff(DateTime.fromISO(outboundList[0].departure_time), [
												"days",
												"hours",
												"minutes",
											])
											.toObject()
									)}
								</span>
							</p>
						</div>
					</div>
				</div>
			</div>
			<ShowDetailsDrawer
				type="return"
				outboundList={outboundList}
				total_outbound_duration={
					isMulticityAndIntl && ind !== undefined
						? selectedFlight?.total_outbound_duration[ind]
						: selectedFlight?.total_outbound_duration
				}
			/>
		</div>
	);
};

export const PaymentComponent = (props: any) => {
	const navigate = useNavigate();
	const userData = useTripStore((state: any) => state.userData);
	const bigFlightData = useTripStore((state: any) => state.bigFlightData);
	const updateBigFlightData = useTripStore(
		(state: any) => state.updateBigFlightData
	);

	const [paymentType, setPaymentType] = useState("pay_with_wallet");
	let [email, setEmail] = useState("");
	let [openPaymentModal, setOpenPaymentModal] = useState(false);
	let [openPaymentMobile, setOpenPaymentMobile] = useState(false);
	let [successPayment, setPaymentSuccessful] = useState(false);
	let [bookingLoading, setBookingLoading] = useState(false);
	const [successLoading, setSuccessLoading] = useState(false);
	let [reference, setReference] = useState("");
	let [successFunc, setSuccessFunc] = useState<() => void>(
		() => () => console.log("init")
	);

	const isRoundTripAndIntl =
		bigFlightData?.showTripType === "Roundtrip" &&
		bigFlightData?.route === "international";

	const isMultiCityAndLocal =
		bigFlightData?.showTripType === "Multicity" &&
		bigFlightData?.route === "domestic";

	const isMulticityAndIntl =
		bigFlightData.showTripType === "Multicity" &&
		bigFlightData.route === "international";

	let fareCost =
		bigFlightData?.selectedDeparture?.amount +
		(bigFlightData?.selectedArrival === undefined
			? 0
			: bigFlightData?.selectedArrival?.amount);
	let tax =
		bigFlightData?.selectedDeparture?.tax === undefined
			? 0
			: bigFlightData?.selectedDeparture?.tax +
			  (bigFlightData?.selectedArrival === undefined
					? 0
					: bigFlightData?.selectedArrival?.tax);
	let bookingFee = 0;

	if (isRoundTripAndIntl || isMulticityAndIntl) {
		fareCost = bigFlightData.selectedDeparture?.amount;
		tax = bigFlightData.selectedDeparture.tax;
	}
	if (isMultiCityAndLocal) {
		fareCost = bigFlightData.selectedMultiCityFlights.reduce(
			(total: number, selectedObj: any) => total + selectedObj?.amount,
			0
		);
		tax = 0;
	}

	const amount = fareCost + tax;

	console.log(paymentType);
	console.log(userData);
	console.log("reference", reference);

	const makePayment = async (updatedReference: string = "") => {
		setBookingLoading(true);
		let res1: any;

		try {
			// if (bigFlightData.route === "international") {
			// 	res1 = await bookFlight({
			// 		traveler_id: bigFlightData.selectedTraveller.map(
			// 			({ id }: { id: number }) => id
			// 		),
			// 		flight_id: bigFlightData.selectedDeparture?.id,
			// 		flight_id2: bigFlightData.selectedArrival?.id,
			// 		payment_method: paymentType,
			// 		reference: reference,
			// 		session_id: bigFlightData?.session_id,
			// 		type: bigFlightData?.route,
			// 		departure_date: bigFlightData.departure_date,
			// 	});
			// } else {
			res1 = await confirmBookOnHold(bigFlightData?.flightReference, {
				payment_method: paymentType,
				...(paymentType === "pay_with_card" && {
					paystack_refID: updatedReference,
				}),
			});
			// }
		} catch (e) {
			console.log(e);
			if (paymentType === "pay_with_wallet") setOpenPaymentModal(true);
		} finally {
			setBookingLoading(false);

			if (res1?.status === 200) {
				setSuccessFunc(() => () => {
					setTimeout(() => {
						updateBigFlightData({
							flightReference: res1?.data?.data,
							bookingConfirmed: true,
							paymentConfirmed: true,
						});
						navigate("/trips/booked-summary");
					}, 1000);
				});

				setSuccessLoading(true);
			} else if (res1?.status >= 500) {
				toast.error("Server error");
			} else {
				toast.error("Something went wrong");
			}
		}
	};

	const confirmBooking = async (
		payment_method: string,
		latestReference: string = ""
	) => {
		setBookingLoading(true);
		let res1: any;

		try {
			if (
				// bigFlightData.showTripType === "Roundtrip" &&
				bigFlightData.route === "international"
			) {
				res1 = await confirmBookOnHold(bigFlightData?.flightReference, {
					payment_method: payment_method,
					...(payment_method === "pay_with_card" && {
						paystack_refID: latestReference,
					}),
				});
				console.log("res1", res1);
			} else {
				res1 = await confirmBookOnHold(bigFlightData?.flightReference, {
					payment_method: payment_method,
					...(payment_method === "pay_with_card" && {
						paystack_refID: latestReference,
					}),
				});
			}
		} catch (e) {
			console.log(e);
		} finally {
			setBookingLoading(false);
			console.log("res1", res1);
			if (res1?.status === 200) {
				setSuccessFunc(() => () => {
					setTimeout(() => {
						updateBigFlightData({
							bookingConfirmed: true,
							flightReference: res1?.data?.message?.data?.kardinal_reference,
							booking_deadline:
								res1?.data?.message?.data?.departure?.booking_deadline,
							paymentConfirmed: payment_method !== "book_on_hold",
						});
						if (payment_method === "book_on_hold") {
							console.log(bigFlightData);
							navigate("/trips/on-hold-summary");
						} else {
							navigate("/trips/booked-summary");
						}
					}, 1000);
				});
				setSuccessLoading(true);
			} else if (res1?.status >= 500) {
				toast.error("Server error");
			} else {
				// toast.error("Something went wrong");
			}
		}
	};

	useEffect(() => {
		if (successPayment) {
			makePayment();
		}
	}, [successPayment]);

	if (bigFlightData.bookingConfirmed && !bigFlightData.paymentConfirmed)
		return (
			<>
				<div className="PaymentComponent">
					<p className="PaymentComponent__title">
						Choose your preferred payment method
					</p>
					<PaymentBody
						{...props}
						// reference={reference}
						// setReference={setReference}
						setPaymentSuccessful={setPaymentSuccessful}
						amount={amount}
						email={userData?.email}
						bookingLoading={bookingLoading}
						paymentType={paymentType}
						setPaymentType={setPaymentType}
						makePayment={makePayment}
					/>

					<div className="PaymentComponent__info">
						<HiOutlineCheckCircle color="#655F5F" />
						<p>
							By completing your purchase you agree to these{" "}
							<a href={undefined}>Terms of Service</a>.
						</p>
					</div>
					<div className="btnWrap">
						<Button
							className="pry"
							onClick={() => {
								if (paymentType === "pay_with_card") setOpenPaymentModal(true);
								else makePayment();
							}}
						>
							Pay Now
						</Button>
					</div>
				</div>
				<button
					className="PaymentComponent--mobile"
					onClick={() => {
						// if (paymentType === "pay_with_card")
						setOpenPaymentMobile(true);
						// else makePayment();
					}}
				>
					Pay Now
				</button>
				<MUIModal
					open={openPaymentModal}
					handleClose={() => setOpenPaymentModal(false)}
				>
					<PaymentModal
						type={paymentType}
						amount={amount}
						setPaymentSuccessful={setPaymentSuccessful}
						makePayment={makePayment}
						reference={reference}
						setReference={setReference}
						handleClose={() => setOpenPaymentModal(false)}
					/>
				</MUIModal>
				<MUIModal
					open={openPaymentMobile}
					handleClose={() => setOpenPaymentMobile(false)}
				>
					<PaymentMethodModal
						confirmBooking={confirmBooking}
						loading={bookingLoading}
						handleClose={() => setOpenPaymentMobile(false)}
						setReference={setReference}
						reference={reference}
						amount={amount}
						hideOnHold={true}
					/>
				</MUIModal>
				<LoadingModalsDebacle
					successLoading={successLoading}
					setSuccessLoading={setSuccessLoading}
					loading={bookingLoading}
					handleClose={() => {}}
					loadingMessage={"Hold on, we are confirming your balance"}
					successMessage={"Payment successful"}
					showSuccess={true}
					successFunc={successFunc}
				/>
			</>
		);
	else return <SummarySkeleton />;
};

export const PaymentBody = (props: any) => {
	// zustand states
	const bigFlightData = useTripStore((state: any) => state.bigFlightData);
	const updateBigFlightData = useTripStore(
		(state: any) => state.updateBigFlightData
	);

	console.log(props);

	return (
		<div className="PaymentBody">
			<FormControl className="radio-options">
				<div className="radio-option">
					<Radio
						checked={props.paymentType === "pay_with_wallet"}
						onChange={(e) => props.setPaymentType(e.target.value)}
						value="pay_with_wallet"
						name="payment-options"
						inputProps={{ "aria-label": "A" }}
					/>
					<div onClick={(e) => props.setPaymentType("pay_with_wallet")}>
						<p className="title">Pay with wallet</p>
						<p>Use your existing wallet balance to pay for this trip</p>
					</div>
				</div>
				<div className="radio-option">
					<Radio
						checked={props.paymentType === "pay_with_credit"}
						onChange={(e) => props.setPaymentType(e.target.value)}
						value="pay_with_credit"
						name="payment-options"
						inputProps={{ "aria-label": "B" }}
					/>
					<div onClick={(e) => props.setPaymentType("pay_with_credit")}>
						<p className="title">Pay with credit</p>
						<p>
							Standard rates apply (₦
							{bigFlightData.route === "domestic" ? 2000 : 10000} flat rate
							fee/passenger){" "}
						</p>
					</div>
				</div>
				<div className="radio-option">
					<Radio
						checked={props.paymentType === "pay_with_card"}
						onChange={(e) => props.setPaymentType(e.target.value)}
						value="pay_with_card"
						name="payment-options"
						inputProps={{ "aria-label": "C" }}
					/>
					<div onClick={(e) => props.setPaymentType("pay_with_card")}>
						<p className="title">Pay with card</p>
						<p>Standard rates apply</p>
					</div>
				</div>{" "}
			</FormControl>
		</div>
	);
};

export const MobilePaymentBody = (props: any) => {
	const navigate = useNavigate();
	const userData = useTripStore((state: any) => state.userData);
	const bigFlightData = useTripStore((state: any) => state.bigFlightData);
	const updateBigFlightData = useTripStore(
		(state: any) => state.updateBigFlightData
	);

	const [paymentType, setPaymentType] = useState("pay_with_wallet");
	let [email, setEmail] = useState("");
	let [openPaymentModal, setOpenPaymentModal] = useState(false);
	let [successPayment, setPaymentSuccessful] = useState(false);
	let [bookingLoading, setBookingLoading] = useState(false);
	let [reference, setReference] = useState("");

	const amount =
		bigFlightData.selectedDeparture?.amount +
		(bigFlightData.selectedArrival === undefined
			? 0
			: bigFlightData.selectedArrival?.amount) +
		(bigFlightData.selectedDeparture.tax
			? bigFlightData.selectedDeparture?.tax +
			  (bigFlightData.selectedArrival === undefined
					? 0
					: bigFlightData.selectedArrival?.tax)
			: 0);

	console.log(paymentType);
	console.log(userData);
	console.log("reference", reference);

	const makePayment = async (updatedReference: string = "") => {
		setBookingLoading(true);
		try {
			if (
				// bigFlightData.showTripType === "Roundtrip" &&
				bigFlightData.route === "international"
			) {
				const res1 = await bookFlight({
					traveler_id: bigFlightData.selectedTraveller.map(
						({ id }: { id: number }) => id
					),
					flight_id: bigFlightData.selectedDeparture?.id,
					flight_id2: bigFlightData.selectedArrival?.id,
					payment_method: paymentType,
					reference: reference,
					session_id: bigFlightData?.session_id,
					type: bigFlightData?.route,
					departure_date: bigFlightData.departure_date,
				});
				if (res1?.status === 200) {
					updateBigFlightData({
						flightReference: res1?.data?.message?.data?.kardinal_reference,
						bookingConfirmed: true,
						paymentConfirmed: true,
					});

					setTimeout(() => {
						navigate("/trips/booked-summary");
					}, 2000);
				}
			} else {
				const res1 = await confirmBookOnHold(bigFlightData?.flightReference, {
					payment_method: paymentType,
					...(paymentType === "pay_with_card" && {
						paystack_refID: updatedReference,
					}),
				});

				if (res1?.status === 200) {
					updateBigFlightData({
						flightReference: res1?.data?.data,
						bookingConfirmed: true,
						paymentConfirmed: true,
					});
					setTimeout(() => {
						navigate("/trips/booked-summary");
					}, 200);
				} else if (res1?.status >= 500) {
					toast.error("Server error");
				} else {
					toast.error("Something went wrong");
				}
			}
		} catch (e) {
			console.log(e);
			if (paymentType === "pay_with_wallet") setOpenPaymentModal(true);
		} finally {
			setBookingLoading(false);
		}
	};

	useEffect(() => {
		if (successPayment) {
			makePayment();
		}
	}, [successPayment]);
	console.log(props);

	return (
		<div className="PaymentBody">
			<FormControl>
				<div className="radio-option">
					<Radio
						checked={paymentType === "pay_with_wallet"}
						onChange={(e) => setPaymentType(e.target.value)}
						value="pay_with_wallet"
						name="payment-options"
						inputProps={{ "aria-label": "A" }}
					/>
					<div onClick={(e) => setPaymentType("pay_with_wallet")}>
						<p className="title">Pay with wallet</p>
						<p>Use your existing wallet balance to pay for this trip</p>
					</div>
				</div>
				<div className="radio-option">
					<Radio
						checked={paymentType === "pay_with_credit"}
						onChange={(e) => setPaymentType(e.target.value)}
						value="pay_with_credit"
						name="payment-options"
						inputProps={{ "aria-label": "B" }}
					/>
					<div onClick={(e) => setPaymentType("pay_with_credit")}>
						<p className="title">Pay with credit</p>
						<p>
							Standard rates apply (₦
							{bigFlightData.route === "domestic" ? 2000 : 10000} flat rate
							fee/passenger){" "}
						</p>
					</div>
				</div>
				<div className="radio-option">
					<Radio
						checked={paymentType === "pay_with_card"}
						onChange={(e) => setPaymentType(e.target.value)}
						value="pay_with_card"
						name="payment-options"
						inputProps={{ "aria-label": "C" }}
					/>
					<div onChange={(e) => setPaymentType("pay_with_card")}>
						<p className="title">Pay with card</p>
						<p>Standard rates apply</p>
					</div>
				</div>{" "}
			</FormControl>
		</div>
	);
};
