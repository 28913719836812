import React, { useState } from "react";
import "./style.scss";
import "./sub-page.scss";
import useAxios from "../../hooks/useAxios";
import {
	SignIn,
	Left,
	ForgotPassword,
	Confirmation,
	NewPassword,
} from "./sub-pages";
import planeglobe from "../../resource/img/planeglobe.svg";
import { useNavigate } from "react-router-dom";
import LoadingAnimation from "../../components/LoadingAnimation";
import AuthLayout from "../../layouts/AuthLayout";
import { toast } from "react-toastify";

export default function Login() {
	let navigate = useNavigate();
	let [page, setPage] = useState(1);
	let [email, setEmail] = useState("");
	let [password, setPassword] = useState("");
	let [confirmPassword, setConfirmPassword] = useState("");
	let [confirmationToken, setConfirmationToken] = useState("");

	let forgotData = { email, setEmail };
	let confirmationData = {
		email,
		setEmail,
		confirmationToken,
		setConfirmationToken,
	};
	let newPasswordData = {
		password,
		setPassword,
		confirmPassword,
		setConfirmPassword,
		confirmationToken,
	};

	// handle forgot password

	let {
		error: forgotError,
		loading: forgotLoading,
		sendData: sendForgot,
		setLoading: setForgotLoading,
		response: forgotResponse,
	} = useAxios({
		method: "post",
		url: `/auth/password_reset/`,
		headers: {},
		data: {
			email: email,
		},
	});

	React.useEffect(() => {
		if (!forgotLoading && !forgotError) {
			setForgotLoading(true);
			setPage(3);
		}
		if (forgotError) {
			console.log(forgotError);
		}
	}, [forgotLoading, setForgotLoading, forgotError]);
	console.log(forgotResponse);

	let handleForgot = (e: any) => {
		// e.preventDefault();
		sendForgot();
	};

	// handlePasswordConfirmation

	let {
		error: confirmPasswordResetError,
		loading: confirmPasswordResetLoading,
		sendData: sendConfirmPasswordReset,
		setLoading: setConfirmPasswordResetLoading,
	} = useAxios({
		method: "post",
		url: `/auth/password_reset/confirm/`,
		headers: {},
		data: {
			password,
			token: confirmationToken,
		},
	});

	React.useEffect(() => {
		if (!confirmPasswordResetLoading && !confirmPasswordResetError) {
			toast.success("Password Reset Successful");
			setConfirmPasswordResetLoading(true);
			setPage(1);
		}
		if (confirmPasswordResetError) {
			toast.error("Password Reset Failed, Please Check Token");
		}
	}, [
		confirmPasswordResetLoading,
		setConfirmPasswordResetLoading,
		confirmPasswordResetError,
		navigate,
	]);

	let handleConfirmPasswordReset = (e: any) => {
		e.preventDefault();
		sendConfirmPasswordReset();
	};

	return (
		<AuthLayout
			title="Welcome back!"
			desc="The real action happens in person. Book a trip."
		>
			<div className="login-right">
				{page <= 1 && <SignIn setPage={setPage} />}
				{page === 2 && (
					<ForgotPassword
						data={forgotData}
						handleForgot={handleForgot}
						setPage={setPage}
					/>
				)}
				{page === 3 && (
					<Confirmation data={confirmationData} setPage={setPage} />
				)}
				{page >= 4 && (
					<NewPassword
						data={newPasswordData}
						handleConfirmPasswordReset={handleConfirmPasswordReset}
						setPage={setPage}
					/>
				)}
			</div>
		</AuthLayout>
	);
}
