import { request } from "../utils/axios";
import axios from "axios";
import { setAuthToken } from "../config/helpers";
import { toast } from "react-toastify";

export const fetchExchangeRate = async () => {
	try {
		const res = axios(
			"https://v6.exchangerate-api.com/v6/a005fc47ff8053badd3c9034/pair/USD/NGN/"
		);
		console.log(res);
		return res;
	} catch (err) {
		console.log(err?.response?.data);
		console.log(err?.response?.data?.message);
		throw err;
	}
};

export const fetchImageFromCity = async (city) => {
	try {
		const res = axios.get(
			`https://maps.googleapis.com/maps/api/place/findplacefromtext/json?input=${city}&inputtype=textquery&fields=photos&key=AIzaSyAneqgMiaoYudxVh54VdTAiWc1IHPPFMBI`
		);
		console.log(res);
		return res;
	} catch (err) {
		console.log(err?.response?.data);
		console.log(err?.response?.data?.message);
		throw err;
	}
};

export const fetchCountryList = async () => {
	try {
		const res = axios.get(
			`https://restcountries.com/v3.1/all`
		);
		console.log(res);
		return res;
	} catch (err) {
		console.log(err?.response?.data);
		console.log(err?.response?.data?.message);
		throw err;
	}
};