import "./style.scss";
import Button from "../../components/Button";
import { useEffect, useState } from "react";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import {
	fetchInvoiceDetails,
	mergeInvoice,
	separateInvoice,
	settleInvoice,
} from "../../requests";
import SpinLoader from "../../components/SpinLoader";
import {
	addPaystackCharges,
	capitalizeFirstLetter,
	formatDateRange,
	longNumToMoney,
} from "../../helpers/helperFuncs";
import { DateTime } from "ts-luxon";
import { useTripStore } from "../../zustand/store";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { toast } from "react-toastify";
import { usePaystackPayment } from "react-paystack";
import ButtonDropdown from "../../components/ButtonDropdown";
import LoadingModalsDebacle from "../../components/LoadingModalsDebacle";
import CustomTooltip from "../../components/CustomTooltip";
import { Link } from "react-router-dom";
import { LogoIcon } from "../../components/pieces/svgs";

const SingleInvoice = () => {
	const navigate = useNavigate();
	const { invoice_id } = useParams();
	let [searchParams, setSearchParams] = useSearchParams();

	const userData = useTripStore((state: any) => state.userData);

	const [loading, setLoading] = useState(false);
	const [refetch, setRefetch] = useState(false);
	const [successLoading, setSuccessLoading] = useState(false);
	const [settleLoading, setSettleLoading] = useState(false);
	const [splitLoading, setSplitLoading] = useState(false);
	const [invoiceAction, setInvoiceAction] = useState<
		"settle" | "separate" | "merge"
	>("settle");
	const [fetched, setFetched] = useState(false);
	const [invoiceDetails, setInvoiceDetails] = useState<any>({});
	const [selectedActivities, setSelectedActivities] = useState<any[]>([]);

	const tableData = {
		headers: [
			{
				title: "Items",
				key: "item",
				render: (obj: any) => capitalizeFirstLetter(obj?.activity_type),
			},
			{
				title: "Quantity",
				key: "quantity",
				render: (obj: any) =>
					`${obj?.traveler?.first_name} ${obj?.traveler?.middle_name} ${obj?.traveler?.last_name} (${obj?.route})`,
			},
			{
				title: "Kardinal Ref.",
				key: "pnr",
				render: (obj: any) => obj?.kardinal_reference,
			},
			{
				title: "Amount",
				key: "amount",
				render: (obj: any) => `₦ ${longNumToMoney(obj?.amount)}`,
			},
		],
		body: invoiceDetails?.activities,
	};

	const fetchFunc = async () => {
		setLoading(true);
		try {
			const res = await fetchInvoiceDetails(invoice_id);
			console.log("fetchInvoiceDetails", res.data);
			setInvoiceDetails(res.data);
			setFetched(true);
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchFunc();
	}, [refetch]);

	const downloadPdf = () => {
		html2canvas(document.querySelector(".InvoiceCard") as HTMLElement).then(
			(canvas) => {
				const imgData = canvas.toDataURL("image/svg");

				const pdf = new jsPDF();

				// get pdf width
				var width = pdf.internal.pageSize.width;
				var height = pdf.internal.pageSize.height;

				pdf.addImage(imgData, "SVG", 0, 0, width, height - 60);
				pdf.save(`Kardinal Invoice - ${invoice_id}.pdf`);
			}
		);
	};

	useEffect(() => {
		if (fetched && searchParams.get("action") === "download") {
			setTimeout(() => {
				downloadPdf();
				navigate(-1);
			}, 1000);
		}
	}, [fetched]);

	const settleFunc = async (
		payment_method: "pay_with_wallet" | "pay_with_card",
		paystack_refID: string | null = null
	) => {
		setInvoiceAction("settle");
		setSettleLoading(true);
		try {
			const res = await settleInvoice(invoice_id, {
				payment: {
					payment_method: payment_method,
					paystack_refID: paystack_refID,
				},
			});
			console.log("settleInvoice", res);
			if (res.status === 200) {
				toast.success("Payment Successful");
				setRefetch(!refetch);
			}
		} catch (error) {
			console.log(error);
			if ((error as any)?.response?.status > 500) toast.error("Payment failed");
		} finally {
			setSettleLoading(false);
		}
	};

	const separateFunc = async () => {
		setInvoiceAction("separate");
		setSplitLoading(true);
		try {
			const res = await separateInvoice(invoice_id);
			console.log("separateInvoice", res);
			if (res.status === 200) {
				toast.success("Invoice separation successful");
				navigate("/invoicing");
			}
		} catch (error) {
			console.log(error);
			if ((error as any)?.response?.status >= 500)
				toast.error("Unable to separate invoices");
		} finally {
			setSplitLoading(false);
		}
	};

	const mergeFunc = async () => {
		setInvoiceAction("merge");
		setSplitLoading(true);
		if (selectedActivities?.length >= 1) {
			try {
				const res = await mergeInvoice(invoice_id, {
					activities_id: selectedActivities,
				});
				console.log("mergeInvoice", res);
				if (res.status === 200) {
					toast.success("Invoice merge successful");
					navigate("/invoicing");
				}
			} catch (error) {
				console.log(error);
				if ((error as any)?.response?.status >= 500)
					toast.error("Unable to merge invoices");
			} finally {
				setSplitLoading(false);
			}
		} else toast.error("Please select at least one activity");
	};

	const initializePayment: any = usePaystackPayment({
		reference: new Date().getTime().toString(),
		email: userData?.email,
		amount: Math.ceil(addPaystackCharges(invoiceDetails?.total_amount) * 100),
		publicKey: `${process.env.REACT_APP_PAYSTACK_KEY}`,
	});

	return loading ? (
		<SpinLoader height="100vh" />
	) : fetched ? (
		<div className="SingleInvoice">
			<div className="InvoiceCard">
				<div className="InvoiceCard__companyInfo">
					<Link to="/" aria-label="Kardinal logo">
						<LogoIcon size={40} />
					</Link>

					<div className="left-text">
						<div>
							<p className="timeline">
								Trip activities from{" "}
								<span className="w700">
									{formatDateRange(invoiceDetails?.creationRange)}
								</span>
							</p>
							<p className="grey">Invoice {invoice_id}</p>
						</div>
						<div>
							<p className="companyName w500">Kardinal Technologies, Inc</p>
							<p className="grey">2a Alara</p>
							<p className="grey">Yaba, Lagos</p>
							<p className="grey">Nigeria</p>
						</div>
					</div>
				</div>
				<div className="InvoiceCard__line"></div>
				<div className="InvoiceCard__Status">
					<p className="status status__unpaid">
						{capitalizeFirstLetter(invoiceDetails?.status)}
					</p>
				</div>

				<div className="InvoiceCard__billing">
					<div>
						<div className="names">
							<p style={{ color: "#9c9393", marginBottom: "10px" }}>BILL TO</p>
							<p className="w500">{userData?.company_name}</p>
							<p>{`${userData?.first_name} ${userData?.last_name}`}</p>
						</div>
						<div>
							<p className="grey">{userData?.phone_number}</p>
							<p className="grey">{userData?.email}</p>
						</div>
					</div>
					<div>
						<div className="two-grid">
							<span>Invoice Date: </span>
							<span>
								{DateTime.fromISO(invoiceDetails?.created_date).toFormat(
									"LLL dd, yyyy"
								)}
							</span>
						</div>
						<div className="two-grid">
							<span>Payment Due: </span>
							<span>
								{DateTime.fromISO(invoiceDetails?.due_date).toFormat(
									"LLL dd, yyyy"
								)}
							</span>
						</div>
						<div className="two-grid">
							<span>Amount Due (NGN): </span>
							<span className="w700">{`₦ ${longNumToMoney(
								invoiceDetails?.total_amount
							)}`}</span>
						</div>
						<div className="two-grid buttons">
							<span
								style={{
									display: "flex",
									justifyContent: "flex-end",
								}}
							>
								<Button className="pry light" onClick={() => downloadPdf()}>
									Download
								</Button>
							</span>
							<span>
								{/* <Button className="pry dark">Settle Instantly</Button> */}
								<ButtonDropdown
									label={
										invoiceDetails?.status === "paid"
											? "Settled"
											: "Settle Instantly"
									}
									optionList={[
										{
											label: {
												title: "Pay with card",
												desc: "Standard rates apply",
											},
											action: () => {
												initializePayment(
													(ref: any) => {
														settleFunc("pay_with_card", ref?.reference);
													},
													() => {}
												);
											},
										},
										{
											label: {
												title: "Pay with wallet",
												desc: "Use your existing wallet balance to pay for this trip",
											},
											action: () => {
												settleFunc("pay_with_wallet");
											},
										},
									]}
									disabled={invoiceDetails?.status === "paid"}
								/>
							</span>
						</div>
						<div className="splitWrap">
							<ButtonDropdown
								label={"Split invoice"}
								optionList={[
									{
										label: {
											title: "Separate invoice",
											desc: "Get a separate invoice for each ",
										},
										action: () => {
											separateFunc();
										},
									},
									{
										label: {
											title: "Merge invoice",
											desc: "Merge your invoice",
										},
										action: () => {
											mergeFunc();
										},
									},
								]}
								disabled={
									invoiceDetails?.activities?.length === 1 ||
									invoiceDetails?.status === "paid"
								}
							/>
							<CustomTooltip>
								Split your invoice into multiple parts.
							</CustomTooltip>
						</div>
					</div>
				</div>

				{/* <div>
					<span>
						check and uncheck the invocing you want to seperate or merge before
						perfoming this action or click here to cancel this action{" "}
					</span>
				</div> */}
				<table className="InvoiceCard__table">
					<thead className="InvoiceCard__table__header">
						<tr>
							{tableData.headers.map((header, ind) => (
								<th>
									{ind === 0 && (
										<input
											type="checkbox"
											name=""
											id=""
											style={{ marginRight: "0.5rem" }}
										/>
									)}
									{header.title}
								</th>
							))}
						</tr>
					</thead>
					<tbody className="InvoiceCard__table__body">
						{tableData.body.map((bodyItem: any) => (
							<tr className="record">
								{tableData.headers.map((headerItem, ind) => (
									<td>
										{ind === 0 && (
											<input
												type="checkbox"
												name=""
												id=""
												style={{ marginRight: "0.5rem" }}
												checked={selectedActivities.includes(bodyItem?.id)}
												onChange={(e) => {
													if (
														e.target.checked &&
														selectedActivities.length <
															invoiceDetails?.activities?.length - 1
													)
														setSelectedActivities([
															...selectedActivities,
															bodyItem?.id,
														]);
													else {
														let newArr = [...selectedActivities];
														const index = newArr.indexOf(bodyItem?.id);
														const x = newArr.splice(index, 1);

														setSelectedActivities(newArr);
													}
												}}
											/>
										)}
										{headerItem?.render
											? headerItem?.render(bodyItem)
											: bodyItem[headerItem.key as keyof typeof bodyItem] ?? ""}
									</td>
								))}
							</tr>
						))}
						<tr className="total-record">
							<td></td>
							<td></td>
							<td className="grey">Total</td>
							<td>{`₦ ${longNumToMoney(invoiceDetails?.total_amount)}`}</td>
						</tr>
						<tr className="total-record">
							<td></td>
							<td></td>
							<td className="grey">Amount Due (NGN):</td>
							<td className="w500">{`₦ ${longNumToMoney(
								invoiceDetails?.total_amount
							)}`}</td>
						</tr>
					</tbody>
				</table>
				<div className="InvoiceCard__notes">
					<p className="title">Notes/ Terms</p>
					<p style={{ color: "#9C9393", marginBottom: "5px" }}>
						For transfers, pay to:
					</p>
					<p>1008833334</p>
					<p>VFD Microfinance Bank</p>
					<p>Kardinal Corporate Services Limited</p>
				</div>
				<div className="InvoiceCard__enquiry">
					<p>
						For any enquiry, reach out via email at wecare@gokardinal.com or
						call on +234 812 146 3765 TAX ID - 24174426-001
					</p>
				</div>
			</div>
			<LoadingModalsDebacle
				successLoading={successLoading}
				setSuccessLoading={setSuccessLoading}
				loading={invoiceAction === "settle" ? settleLoading : splitLoading}
				handleClose={() => {}}
				loadingMessage={
					invoiceAction === "settle"
						? "Hold on, we are confirming your purchase"
						: invoiceAction === "separate"
						? "Hold on, we are separating your invoice"
						: "Hold on, we are merging your invoice"
				}
				successMessage={
					invoiceAction === "settle"
						? "Payment successful"
						: invoiceAction === "separate"
						? "Separation successful"
						: "Merge successful"
				}
				showSuccess={true}
				successFunc={() => {}}
			/>
		</div>
	) : (
		<></>
	);
};

export default SingleInvoice;
