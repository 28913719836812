import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

interface MUIBasicSelectProps {
    label: string;
    list: { value: string; label: string }[];
    width?: number | string;
    transparent?: boolean;
}

const MUIBasicSelect = ({
    label,
    list,
    width,
    transparent,
}: MUIBasicSelectProps) => {
    const [age, setAge] = React.useState("");

    const handleChange = (event: SelectChangeEvent) => {
        setAge(event.target.value as string);
    };

    return (
        <Box
            sx={{ borderColor: "white" }}
            style={{ width: width === "100%" ? "100%" : "" }}
        >
            <FormControl
                fullWidth
                sx={{ borderColor: "white" }}
                size="small"
                style={{
                    width: width === "100%" ? "100%" : "max-content",
                    minWidth: width || 80,
                }}
            >
                <InputLabel
                    id="demo-simple-select-label"
                    sx={{
                        fontSize: "13.59px",
                        lineHeight: "17px",
                        width: "100%",
                        "@media (max-width: 780px)": {
                            // eslint-disable-line no-useless-computed-key
                            fontSize: "10.2667px",
                            lineHeight: "13px",
                        },
                    }}
                >
                    {label}
                </InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={age}
                    label="Age"
                    onChange={handleChange}
                    IconComponent={KeyboardArrowDownIcon}
                    sx={{
                        borderColor: "white",
                        borderRadius: transparent ? "3px" : "7.012px",
                        border: "0",
                        width: "100%",
                        fontSize: "13.59px",
                        lineHeight: "17px",
                        background: transparent
                            ? "transparent"
                            : "rgba(196, 196, 196, 0.15)",

                        "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: transparent
                                ? "#c4c4c4"
                                : "transparent",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: transparent
                                ? "#c4c4c4"
                                : "transparent",
                        },
                        "@media (max-width: 780px)": {
                            // eslint-disable-line no-useless-computed-key
                            fontSize: "10.2667px",
                            lineHeight: "13px",
                        },
                    }}
                >
                    {list.map((item, ind) => (
                        <MenuItem
                            value={item.value}
                            key={ind}
                            sx={{
                                "@media (max-width: 780px)": {
                                    // eslint-disable-line no-useless-computed-key
                                    fontSize: "10.2667px",
                                    lineHeight: "13px",
                                    minHeight: "28px",
                                },
                            }}
                        >
                            {item.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
};

export default MUIBasicSelect;
