import React, { useState, useEffect, useRef, useMemo } from "react";
import Button from "../../Button";
import choosesvg from "../../../resource/img/choosesvg.svg";
import "../style.scss";
import { CgClose } from "react-icons/cg";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { CheckBox } from "../tripData";
import MUIModal from "../../MUIModal";
import {
	AdditionalNoteModal,
	AddNewTravellerModal,
	AddNewTravellerModalRewrite,
	SelectTravellerModal,
} from "../../../modals";
import DatePicker, {
	getAllDatesInRange,
	DateObject,
} from "react-multi-date-picker";
import CustomDropdown from "../../CustomDropdown";
import CustomTooltip from "../../CustomTooltip";
import { useTripStore } from "../../../zustand/store";
import { toast } from "react-toastify";
import opacity from "react-element-popper/animations/opacity";
import transition from "react-element-popper/animations/transition";
import { searchCars } from "../../../requests/cars";
import { Drawer, SwipeableDrawer } from "@mui/material";
import { toggleSideDrawer } from "../../../helpers/helperFuncs";

export const CarPhase1 = (props: any) => {
	const [active, setActive] = useState(0);
	let [showTraveller, setShowTraveller] = useState(false);
	let [addNewTraveller, setAddNewTraveller] = useState(false);
	let [count, setCount] = useState(0);
	let [searchLoading, setSearchLoading] = useState(false);

	const bigCarData = useTripStore((state: any) => state.bigCarData);
	const updateBigCarData = useTripStore((state: any) => state.updateBigCarData);
	const carLocations = useTripStore((state: any) => state.carLocations);
	const carList = useTripStore((state: any) => state.carList);
	const dropDownLoading = useTripStore((state: any) => state.dropDownLoading);

	const [calendarNum, setCalendarNum] = useState(
		document.documentElement.clientWidth > 780 ? 2 : 1
	);

	let increaseCount = () => {
		if (count > 4) {
			return;
		}
		setCount(count + 1);
	};

	let decreaseCount = () => {
		if (count < 0) {
			return;
		}
		setCount(count - 1);
	};

	let [tempSelected, setTempSelected] = useState(bigCarData.selectedTraveller);

	const setSelectedTravellers = (traveller: any[]) => {
		updateBigCarData({ selectedTraveller: traveller });
	};

	const handleSelectedTravellers = () => {
		setSelectedTravellers(tempSelected);
	};

	const calendarRef = useRef<any>(null);

	useEffect(() => {
		setCalendarNum(document.documentElement.clientWidth > 780 ? 2 : 1);
	}, [document.documentElement.clientWidth]);

	useEffect(() => {
		if (active === 4) {
			calendarRef.current?.openCalendar();
		}
	}, [active]);

	const locations = [
		"Ikeja",
		"Bariga",
		"Lekki",
		"Ajah",
		"Berger",
		"Victoria Island",
	];

	console.log("bigCarData", bigCarData);
	console.log("carLocations", carLocations);

	const searchFunc = async () => {
		setSearchLoading(true);
		try {
			const res1 = await searchCars({
				service_type: bigCarData?.service_type,
				pickup: bigCarData?.pickUp?.id,
				dropoff: bigCarData?.dropOff?.id,
				car_type: bigCarData?.carType?.id,
			});
			console.log("searchFunc", res1);

			if (res1.status === 200) {
				if (res1.data?.data?.length === 0)
					toast.error("No cars available for this trip");
				else {
					toast.success("Search complete. Please select a pick-up time");
					updateBigCarData({ searchResults: res1.data?.data });
					props.setCarPhase(2);
				}
			}
		} catch (e) {
			console.log(e);
		} finally {
			setSearchLoading(false);
		}
	};

	return (
		<>
			<div className="top-wrapper">
				<div className="top">
					<div className="choose-travellers">
						<p>Select up to 5 travelers</p>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								// width: "100%",
								boxSizing: "border-box",
							}}
						>
							<div
								className="choose-div hover"
								onClick={() => {
									setShowTraveller(true);
								}}
							>
								<img src={choosesvg} alt="choose" />
								<p>Choose</p>
							</div>
							{bigCarData.selectedTraveller?.length === 5 ? (
								<p
									className="new-travellers hover"
									onClick={() => {
										// setAddNewTraveller(true);
										// setShowTraveller(true);
									}}
									style={{ color: " #9c9393" }}
								>
									+ Add new traveller
								</p>
							) : (
								<p
									className="new-travellers hover"
									onClick={() => {
										setAddNewTraveller(true);
										// setShowTraveller(true);
									}}
								>
									+ Add new traveller
								</p>
							)}
						</div>
					</div>
					<div className="travellers" style={{ boxSizing: "border-box" }}>
						{bigCarData.selectedTraveller?.length > 0 && (
							<>
								<p className="mob-none">Travellers</p>

								<div className="travellers-block">
									{bigCarData.selectedTraveller?.map(
										(item: any, index: any) => {
											let { first_name, middle_name, last_name } = item;
											let nameArray = first_name.split(" ");
											console.log(bigCarData.selectedTraveller);
											return (
												<div key={index} className="traveller-item">
													<div className="intitialsImg">
														{first_name?.charAt(0)?.toUpperCase()}
														{last_name.charAt(0).toUpperCase()}
													</div>
													<p>{`${first_name} ${middle_name} ${last_name}`}</p>
													<CgClose
														size={12}
														className="close-icon"
														strokeWidth={2}
														onClick={() => {
															setTempSelected(
																[...tempSelected].filter(
																	(travellerItem) =>
																		travellerItem?.id !== item?.id
																)
															);

															setSelectedTravellers(
																[...bigCarData.selectedTraveller].filter(
																	(travellerItem) =>
																		travellerItem?.id !== item?.id
																)
															);
														}}
													/>
												</div>
											);
										}
									)}
								</div>
							</>
						)}
					</div>
				</div>
			</div>
			<div className="bottom bottom--car">
				<div className="actions">
					<div className="trip-type">
						<CheckBox
							title="Full day"
							value="FULLDAY"
							tripType={{
								showTripType: bigCarData.service_type,
								setShowTripType: (val: any) =>
									updateBigCarData({ service_type: val }),
							}}
						/>
						<CheckBox
							title="Airport Transfer"
							value="AIRPORT_TRANSFER"
							tripType={{
								showTripType: bigCarData.service_type,
								setShowTripType: (val: any) =>
									updateBigCarData({ service_type: val }),
							}}
						/>
					</div>
				</div>
				<div className="form-section form-section--car">
					<form>
						<div className="form-item" style={{ minWidth: "174px" }}>
							<p className="label">Select Car Type</p>
							<CustomDropdown
								placeholder="Choose an option"
								onSelect={(val: any) => {
									updateBigCarData({ carType: val });
									setActive(2);
								}}
								defaultValue={bigCarData.carType}
								optionsList={
									// 	[
									// 	{ label: "Saloon", value: "Saloon" },
									// 	{ label: "Mini SUV", value: "Mini SUV" },
									// 	{ label: "SUV", value: "SUV" },
									// 	{ label: "14 Seater Bus", value: "14 Seater Bus" },
									// 	{ label: "18 Seater Bus", value: "18 Seater Bus" },
									// ]
									carList.map((carItem: any) => ({
										label: carItem?.car_type,
										value: carItem,
									}))
								}
								active={active === 1}
								loading={dropDownLoading?.carList}
								ind={0}
							/>
						</div>
						<div className="form-item">
							<p className="label">Pick-up Location</p>
							<CustomDropdown
								icon={<HiOutlineLocationMarker color="#9C9393" size={17} />}
								onSelect={(val: any) => {
									updateBigCarData({ pickUp: val });
									setActive(3);
								}}
								defaultValue={bigCarData.pickUp}
								optionsList={carLocations.map((location: any) => ({
									label: location?.location_name,
									value: location,
								}))}
								active={active === 2}
								loading={dropDownLoading?.carLocations}
								ind={1}
							/>
						</div>

						<div className="form-item">
							<p className="label">Drop-off Location</p>

							<CustomDropdown
								icon={<HiOutlineLocationMarker color="#9C9393" size={17} />}
								onSelect={(val: any) => {
									updateBigCarData({ dropOff: val });
									setActive(4);
								}}
								defaultValue={bigCarData.dropOff}
								optionsList={carLocations.map((location: any) => ({
									label: location?.location_name,
									value: location,
								}))}
								active={active === 3}
								loading={dropDownLoading?.carLocations}
								ind={2}
							/>
						</div>
						<div className="form-item">
							<p className="label">Choose Date</p>
							<DatePicker
								className="teal"
								placeholder="departure"
								value={bigCarData.date}
								onChange={(dateObject: DateObject) => {
									console.log(
										new Date(dateObject.toString()).toLocaleDateString("sv-SE")
									);
									updateBigCarData({
										date: new Date(dateObject.toString()).toLocaleDateString(
											"sv-SE"
										),
									});
								}}
								onOpenPickNewDate={false}
								inputClass="custom-rmdp-input"
								ref={calendarRef}
								mapDays={(object: any) => {
									return {
										disabled: object.today > object.date ? true : false,
									};
								}}
								animations={[
									opacity(),
									transition({ from: 35, duration: 400 }),
								]}
							/>
						</div>
					</form>
				</div>

				<div className="button-container">
					<div className="email-request">
						<input type="checkbox" />
						<p>
							Email Request{" "}
							<CustomTooltip>
								Need a travel agent to assist with your request? Select this
								button and we will respond in 5 minutes with options.
							</CustomTooltip>
						</p>
					</div>
					<Button
						className="pry"
						onClick={(e: any) => {
							if (bigCarData?.selectedTraveller?.length === 0)
								toast.error("Please select at least one traveller.");
							else if (!bigCarData?.carType?.car_type)
								toast.error("Please select a car type");
							else if (!bigCarData?.pickUp?.location_code)
								toast.error("Please select a pick-up location");
							else if (!bigCarData?.dropOff?.location_code)
								toast.error("Please select a drop-off location");
							else if (bigCarData?.date === "")
								toast.error("Please select a date");
							else searchFunc();
						}}
						loading={searchLoading}
					>
						Next
					</Button>
				</div>
			</div>
			{document.documentElement.clientWidth < 780 && (
				<Drawer
					anchor="top"
					open={showTraveller}
					onClose={toggleSideDrawer(false, setShowTraveller)}
					// onOpen={toggleSideDrawer(true, setShowTraveller)}
				>
					<SelectTravellerModal
						setActive={setActive}
						handleClose={() => setShowTraveller(false)}
						setAddNewTraveller={setAddNewTraveller}
						setShowTraveller={setShowTraveller}
						handleSelectedTravellers={handleSelectedTravellers}
						tempSelected={tempSelected}
						setTempSelected={setTempSelected}
					/>
				</Drawer>
			)}
			{document.documentElement.clientWidth > 780 && (
				<MUIModal
					open={showTraveller}
					handleClose={() => setShowTraveller(false)}
					sticky={true}
				>
					<SelectTravellerModal
						setActive={setActive}
						handleClose={() => setShowTraveller(false)}
						setAddNewTraveller={setAddNewTraveller}
						setShowTraveller={setShowTraveller}
						handleSelectedTravellers={handleSelectedTravellers}
						tempSelected={tempSelected}
						setTempSelected={setTempSelected}
					/>
				</MUIModal>
			)}
			{document.documentElement.clientWidth > 780 && (
				<MUIModal
					open={addNewTraveller}
					handleClose={() => setAddNewTraveller(false)}
					// sticky={true}
					paddingTop="120px"
				>
					<AddNewTravellerModalRewrite
						setSelectedTraveller={props.setSelectedTraveller}
						handleClose={() => setAddNewTraveller(false)}
					/>
				</MUIModal>
			)}

			{document.documentElement.clientWidth <= 780 && (
				<Drawer
					anchor="top"
					open={addNewTraveller}
					onClose={toggleSideDrawer(false, setAddNewTraveller)}
					// onOpen={toggleSideDrawer(true, setShowTraveller)}
				>
					<AddNewTravellerModalRewrite
						setSelectedTraveller={props.setSelectedTraveller}
						handleClose={() => setAddNewTraveller(false)}
					/>
				</Drawer>
			)}
		</>
	);
};
