import MUIModal from "../MUIModal";
import { LoadingModal, LoadingSuccessModal } from "../../modals";
import { useEffect, useState, Dispatch, SetStateAction } from "react";

interface LoadingModalsDebacleProps {
	loading: boolean;
	successLoading: boolean;
	setSuccessLoading: Dispatch<SetStateAction<boolean>>;
	handleClose: () => void;
	loadingMessage: string;
	successMessage?: string;
	showSuccess?: boolean;
	successFunc?: () => void;
}

const LoadingModalsDebacle = ({
	loading,
	successLoading,
	setSuccessLoading,
	handleClose,
	loadingMessage,
	successMessage = "",
	showSuccess = true,
	successFunc = () => {},
}: LoadingModalsDebacleProps) => {
	const [shownLoad, setShownLoad] = useState(false);

	useEffect(() => {
		if (!loading && shownLoad) {
			// setSuccessLoading(true);
		}
	}, [loading]);

	if (showSuccess) {
		return loading ? (
			<MUIModal open={loading} handleClose={handleClose} cantClose={true}>
				<LoadingModal
					loadingMessage={loadingMessage}
					setShownLoad={() => setShownLoad(true)}
				/>
			</MUIModal>
		) : (
			<MUIModal
				open={successLoading}
				handleClose={() => setSuccessLoading(false)}
				cantClose={true}
			>
				<LoadingSuccessModal
					successMessage={successMessage}
					successFunc={successFunc}
					handleClose={() => setSuccessLoading(false)}
				/>
			</MUIModal>
		);
	} else
		return (
			<MUIModal open={loading} handleClose={handleClose} cantClose={true}>
				<LoadingModal
					loadingMessage={loadingMessage}
					setShownLoad={() => setShownLoad(true)}
				/>
			</MUIModal>
		);
};

export default LoadingModalsDebacle;
