import { RiErrorWarningFill, RiErrorWarningLine } from "react-icons/ri";

interface FootNoteCardProps {
	title: string | JSX.Element;
	children: string | JSX.Element;
	textCol: string;
	bgCol: string;
	icon: JSX.Element;
}

const FootNoteCard = ({
	title,
	children,
	textCol,
	bgCol,
	icon,
}: FootNoteCardProps) => {
	return (
		<div className="FootNoteCard" style={{ backgroundColor: bgCol }}>
			<p className="title" style={{ color: textCol }}>
				{icon}
				<span>{title}</span>
			</p>
			<div>{children}</div>
		</div>
	);
};

export default FootNoteCard;
