import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { RiLoader3Fill } from "react-icons/ri";
import { ImSpinner10 } from "react-icons/im";
import "./style.scss";

const SpinLoader = ({ height }: { height?: string }) => {
	return (
		<div className="SpinLoader" style={{ height: height ? height : "" }}>
			<ImSpinner10 color="rgb(64, 121, 140)" size={36} />
		</div>
	);
};

export default SpinLoader;
