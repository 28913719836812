import { useEffect, useRef, useState } from "react";
import { countryCodeList } from "../../helpers/countryCodeList";
import { splitNumber } from "../../helpers/helperFuncs";
import CustomDropdown from "../CustomDropdown";
import "./style.scss";

interface PhoneDropdownProps {
	value: string;
	updateValue: (val: string) => void;
	ind?: number;
}

const PhoneDropdown = ({ value, updateValue, ind = 1 }: PhoneDropdownProps) => {
	const [code, setCode] = useState(
		value.includes("+") ? splitNumber(value)?.code : "+234"
	);
	const [digits, setDigits] = useState(
		value.includes("+") ? splitNumber(value)?.number : value?.slice(1) ?? ""
	);
	const [active, setActive] = useState(0);

	const phoneInputRef1 = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (active === 2) phoneInputRef1.current?.focus();
	}, [active]);

	return (
		<div className="PhoneDropdown">
			<CustomDropdown
				onSelect={(val: any) => {
					setCode(val);
					updateValue(`${val}${digits}`);
					setActive(2);
				}}
				placeholder="Search..."
				defaultValue={code}
				optionsList={countryCodeList.map((item: any, ind: number) => ({
					label: `${item?.name} ${item?.dial_code}`,
					value: item?.dial_code,
					displayField: item?.dial_code,
				}))}
				ind={4}
				onOpen={() => setActive(1)}
				// active={active === 1}
			/>
			<input
				type="text"
				name="phone_number"
				value={digits}
				onChange={(e) => {
					setDigits(e.target.value);
					updateValue(`${code}${e.target.value}`);
				}}
				required={true}
				className="PhoneDropdown__textInput"
				ref={eval(`phoneInputRef${ind}`)}
			/>
		</div>
	);
};

export default PhoneDropdown;
