import React, { useCallback, useState, useRef, useEffect } from "react";
import "./style.scss";
import caretdown from "../../../resource/img/caretdown.svg";
import restrictionBadge from "../../../resource/img/restrictionBadge.svg";
import restrictionArrow from "../../../resource/img/restrictionArrow.svg";
import arrow3 from "../../../resource/img/Arrow-3.svg";
import arrow4 from "../../../resource/img/Arrow-4.svg";
import magnify from "../../../resource/img/magnify.svg";
import takeoff from "../../../resource/img/takeoff.svg";
import touchdown from "../../../resource/img/touchdown.svg";
import Button from "../../../components/Button";
import { BiSearch } from "react-icons/bi";
import { BsArrowRight } from "react-icons/bs";
import BigToggle from "../../../components/BigToggle";
import FlightInfoDrawer from "../../../components/FlightInfoDrawer";
import SwipeableDrawer, {
	SwipeableDrawerProps,
} from "@mui/material/SwipeableDrawer";
import { Drawer } from "@mui/material";
import { Restrictions, MobileFilters } from "../../../components/Drawers";
import { EditFlightSearch } from "../../../components/Drawers/EditFlightSearch";
import CustomFilter from "../../../components/Filter";
import { toast } from "react-toastify";
import EmptyStateBig from "../../../components/EmptyStateBig";
import usePagination from "../../../hooks/usePagination";
import PaginationComponent from "../../../components/PaginationComponent";
import MUIModal from "../../../components/MUIModal";
import { useTripStore } from "../../../zustand/store";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DateTime } from "ts-luxon";
import {
	capitalizeFirstLetter,
	isTimeBetween,
} from "../../../helpers/helperFuncs";
import {
	renderDuration,
	convertDuration,
	compareDurationV2,
	minutesToDuration,
} from "../../../helpers/helperFuncs";
import CustomLoader from "../../CustomLoader";
import { toggleSideDrawer } from "../../../helpers/helperFuncs";
import { FilterIcon } from "../../../components/pieces/svgs";
import { SearchExpiredModal } from "../../../modals";
import { searchFlight } from "../../../helpers/searchFlightFlow";
import { timeFilterOptions } from "../../../helpers/constants";

interface SearchResult {
	amount: number;
	id: string;
	outbound: any;
	outbound_return?: any;
	departureJourney: any;
	returnJourney: any;
	outbound_stops: number | any[];
	total_duration: number | any[];
	total_outbound_duration: number | any[];
	outbound_stops_return?: number;
	total_duration_return?: number;
	total_outbound_duration_return?: number;
	tax: number;
}

export default function FlightPage(props: any) {
	console.log(props);
	const navigate = useNavigate();

	const [searchParams, setSearchParms] = useSearchParams();

	// Local States
	const [openSide, setOpenSide] = useState(false);
	const [openFilters, setOpenFilters] = useState(false);
	const [openTop, setOpenTop] = useState(false);
	const [openExpired, setOpenExpired] = useState(false);
	const [triggerExpired, setTriggerExpired] = useState(false);
	const [triggerFilter, setTriggerFilter] = useState(false);
	const [openedIndex, setOpenedIndex] = useState<any>(null);
	const [airlineFilters, setAirlineFilters] = useState<any>([]);
	const [stopFilter, setStopFilter] = useState(
		searchParams.get("direct") === "true" ? 0 : null
	);
	const [timeFilters, setTimeFilters] = useState<{
		departure: Array<"early" | "morning" | "afternoon" | "night">;
		arrival: Array<"early" | "morning" | "afternoon" | "night">;
	}>({
		departure: [],
		arrival: [],
	});

	// zustand global states
	const bigFlightData = useTripStore((state: any) => state.bigFlightData);
	const updateBigFlightData = useTripStore(
		(state: any) => state.updateBigFlightData
	);
	const updateEditFlightData = useTripStore(
		(state: any) => state.updateEditFlightData
	);

	const globalAirports = useTripStore((state: any) => state.globalAirports);
	const searchLoading = useTripStore((state: any) => state.searchLoading);
	const setSearchLoading = useTripStore((state: any) => state.setSearchLoading);
	const arrivalLoading = useTripStore((state: any) => state.arrivalLoading);
	const setArrivalLoading = useTripStore(
		(state: any) => state.setArrivalLoading
	);

	const handleTouchStart = (event: Event) => {
		event.preventDefault();
	};

	const handleTouchMove = (event: Event) => {
		event.preventDefault();
	};

	const isMulticityAndIntl =
		bigFlightData.showTripType === "Multicity" &&
		bigFlightData.route === "international";

	const isMulticityAndLocal =
		bigFlightData.showTripType === "Multicity" &&
		bigFlightData.route === "domestic";

	const refactorResult = (flightResult: any) => {
		let data: any[] = [];
		console.log(flightResult);
		let result = flightResult?.data?.PricedItineraries;

		flightResult?.data?.PricedItineraries?.forEach((flightItem: any) => {
			// const outboundresult : string[] = [];
			let item: SearchResult = {
				amount: 0,
				id: "",
				departureJourney: {},
				returnJourney: {},
				outbound: [],
				outbound_return: [],
				outbound_stops: 0,
				total_duration: 0,
				total_outbound_duration: 0,
				outbound_stops_return: 0,
				total_duration_return: 0,
				total_outbound_duration_return: 0,
				tax: 0,
			};
			item.amount = flightItem?.AirItineraryPricingInfo?.BasePrice;
			// * bigFlightData?.exchange_rate;
			item.id = flightItem?.Id;
			// item.outbound: {}[] = [];

			if (bigFlightData.showTripType !== "Multicity") {
				let outbound =
					flightItem?.AirItinerary?.OriginDestinationOptions[0]?.FlightSegments;

				//---------

				flightItem?.AirItinerary?.OriginDestinationOptions[0]?.FlightSegments?.forEach(
					(segmentItem: any) => {
						let outboundItems = {
							airline_details: { code: "", logo: "", name: "" },
							airport_from: "",
							airport_to: "",
							arrival_time: "",
							baggage: "",
							booking_class: "",
							cabin_type: "",
							departure_time: "",
							duration: 0,
							equipment_type: "",
							flight_number: "",
							layover: 0,
						};

						outboundItems.airline_details.code =
							segmentItem?.MarketingAirlineCode;
						outboundItems.airline_details.logo =
							"http://rest.resvoyage.com/" + segmentItem?.AirlineLogo;
						outboundItems.airline_details.name =
							segmentItem?.MarketingAirlineName;
						outboundItems.airport_from = segmentItem?.DepartureAirport;
						outboundItems.airport_to = segmentItem?.ArrivalAirport;
						outboundItems.arrival_time = segmentItem?.ArrivalDate;
						outboundItems.baggage = segmentItem?.FreeBaggages
							? `${segmentItem?.FreeBaggages[0]?.FreeQuantity}` +
							  `${segmentItem?.FreeBaggages[0]?.BagAllowanceUnit}` +
							  " " +
							  `${segmentItem?.FreeBaggages[0]?.BagAllowanceType}`
							: "nothing here";

						outboundItems.booking_class = segmentItem?.BookingClass;
						outboundItems.cabin_type = segmentItem?.Cabin;
						outboundItems.departure_time = segmentItem?.DepartureDate;
						outboundItems.duration = segmentItem?.Duration;
						outboundItems.flight_number = segmentItem?.FlightNumber;
						item.outbound.push(outboundItems);
					}
				);
				if (bigFlightData?.showTripType === "Roundtrip")
					flightItem?.AirItinerary?.OriginDestinationOptions[1]?.FlightSegments?.forEach(
						(segmentItem: any) => {
							let outboundItems = {
								airline_details: { code: "", logo: "", name: "" },
								airport_from: "",
								airport_to: "",
								arrival_time: "",
								baggage: "",
								booking_class: "",
								cabin_type: "",
								departure_time: "",
								duration: 0,
								equipment_type: "",
								flight_number: "",
								layover: 0,
							};

							outboundItems.airline_details.code =
								segmentItem?.MarketingAirlineCode;
							outboundItems.airline_details.logo =
								"http://rest.resvoyage.com/" + segmentItem?.AirlineLogo;
							outboundItems.airline_details.name =
								segmentItem?.MarketingAirlineName;
							outboundItems.airport_from = segmentItem?.DepartureAirport;
							outboundItems.airport_to = segmentItem?.ArrivalAirport;
							outboundItems.arrival_time = segmentItem?.ArrivalDate;
							outboundItems.baggage = segmentItem?.FreeBaggages
								? `${segmentItem?.FreeBaggages[0]?.FreeQuantity}` +
								  `${segmentItem?.FreeBaggages[0]?.BagAllowanceUnit}` +
								  " " +
								  `${segmentItem?.FreeBaggages[0]?.BagAllowanceType}`
								: "nothing here";

							outboundItems.booking_class = segmentItem?.BookingClass;
							outboundItems.cabin_type = segmentItem?.Cabin;
							outboundItems.departure_time = segmentItem?.DepartureDate;
							outboundItems.duration = segmentItem?.Duration;
							outboundItems.flight_number = segmentItem?.FlightNumber;
							item.outbound_return.push(outboundItems);
						}
					);

				item.outbound_stops =
					flightItem?.AirItinerary?.OriginDestinationOptions[0]?.FlightSegments
						?.length - 1;
				item.total_duration =
					flightItem?.AirItinerary?.OriginDestinationOptions[0]?.JourneyTotalDuration;
				item.total_outbound_duration =
					flightItem?.AirItinerary?.OriginDestinationOptions[0]?.JourneyTotalDuration;

				item.outbound_stops_return =
					flightItem?.AirItinerary?.OriginDestinationOptions[1]?.FlightSegments
						?.length - 1;
				item.total_duration_return =
					flightItem?.AirItinerary?.OriginDestinationOptions[1]?.JourneyTotalDuration;
				item.total_outbound_duration_return =
					flightItem?.AirItinerary?.OriginDestinationOptions[1]?.JourneyTotalDuration;

				item.tax = flightItem?.AirItineraryPricingInfo.Tax;

				//  * bigFlightData?.exchange_rate;
				data.push(item);
			} else {
				//---------
				item.outbound_stops = [];
				item.total_duration = [];
				item.total_outbound_duration = [];

				flightItem?.AirItinerary?.OriginDestinationOptions.forEach(
					(destinationOption: any) => {
						let outboundItems: any[] = [];
						destinationOption?.FlightSegments?.forEach((segmentItem: any) => {
							let outboundItem = {
								airline_details: { code: "", logo: "", name: "" },
								airport_from: "",
								airport_to: "",
								arrival_time: "",
								baggage: "",
								booking_class: "",
								cabin_type: "",
								departure_time: "",
								duration: 0,
								equipment_type: "",
								flight_number: "",
								layover: 0,
							};

							outboundItem.airline_details.code =
								segmentItem?.MarketingAirlineCode;
							outboundItem.airline_details.logo =
								"http://rest.resvoyage.com/" + segmentItem?.AirlineLogo;
							outboundItem.airline_details.name =
								segmentItem?.MarketingAirlineName;
							outboundItem.airport_from = segmentItem?.DepartureAirport;
							outboundItem.airport_to = segmentItem?.ArrivalAirport;
							outboundItem.arrival_time = segmentItem?.ArrivalDate;
							outboundItem.baggage = segmentItem?.FreeBaggages
								? `${segmentItem?.FreeBaggages[0]?.FreeQuantity}` +
								  `${segmentItem?.FreeBaggages[0]?.BagAllowanceUnit}` +
								  " " +
								  `${segmentItem?.FreeBaggages[0]?.BagAllowanceType}`
								: "nothing here";

							outboundItem.booking_class = segmentItem?.BookingClass;
							outboundItem.cabin_type = segmentItem?.Cabin;
							outboundItem.departure_time = segmentItem?.DepartureDate;
							outboundItem.duration = segmentItem?.Duration;
							outboundItem.flight_number = segmentItem?.FlightNumber;
							outboundItems.push(outboundItem);
						});
						item.outbound.push(outboundItems);

						if (
							Array.isArray(item.outbound_stops) &&
							Array.isArray(item.total_duration) &&
							Array.isArray(item.total_outbound_duration)
						) {
							item.outbound_stops.push(
								destinationOption?.FlightSegments?.length - 1
							);
							item.total_duration.push(destinationOption?.JourneyTotalDuration);
							item.total_outbound_duration.push(
								destinationOption?.JourneyTotalDuration
							);
						}
					}
				);

				item.tax = flightItem?.AirItineraryPricingInfo.Tax;

				data.push(item);
			}
		});

		let searchResult = { data, sessionId: flightResult?.data?.SessionId };
		// console.log(bigFlightData.session_id);
		return searchResult;
	};

	let searchResult =
		bigFlightData.route === "domestic"
			? bigFlightData.showTripType === "Multicity"
				? bigFlightData.multiSearchData[
						bigFlightData.selectedMultiCityFlights?.length
				  ]
				: bigFlightData.searchData
			: refactorResult(bigFlightData.searchData);

	if (bigFlightData.route === "international")
		searchResult = {
			...searchResult,
			data: searchResult?.data,
		};

	let arrivalResult =
		bigFlightData.route === "domestic"
			? bigFlightData.arrivalData
			: refactorResult(bigFlightData.arrivalData);

	if (
		bigFlightData.route === "international" &&
		bigFlightData.showTripType === "Roundtrip"
	)
		arrivalResult = {
			...arrivalResult,
			data: arrivalResult?.data,
		};
	if (arrivalResult?.data === undefined)
		arrivalResult = {
			...arrivalResult,
			data: [],
		};

	console.log("searchResult", searchResult);
	console.log("arrivalResult", arrivalResult);

	const pageRef = useRef<HTMLDivElement>(document.createElement("div"));

	const toggleTopDrawer = (open: boolean) => (event: any) => {
		if (
			event &&
			event.type === "keydown" &&
			(event.key === "Tab" || event.key === "Shift")
		) {
			return;
		}

		setOpenTop(open);
	};

	// console.log(
	// 	"sortIsh",
	// 	searchResult?.data,
	// 	[...searchResult?.data]?.map((d: any) => {
	// 		// console.log("d", d, "isMulticityAndIntl", isMulticityAndIntl);
	// 		return {
	// 			...d,
	// 			convertedDuration:
	// 				bigFlightData.route === "international"
	// 					? convertDuration(
	// 							isMulticityAndIntl ? d.total_duration[0] : d.total_duration
	// 					  )
	// 					: minutesToDuration(d.total_duration),
	// 		};
	// 	})
	// );

	let min_duration;
	if (searchResult?.data.length !== 0)
		min_duration =
			// arrivalResult !== undefined &&
			bigFlightData.selectedDeparture &&
			bigFlightData.showTripType === "Roundtrip"
				? [...arrivalResult?.data]
						?.map((d: any) => ({
							...d,
							convertedDuration:
								bigFlightData.route === "international"
									? convertDuration(
											isMulticityAndIntl
												? d.total_duration[0]
												: d.total_duration
									  )
									: minutesToDuration(d.total_duration),
						}))
						.sort(compareDurationV2)?.[0]?.total_duration ?? ""
				: [...searchResult?.data]
						?.map((d: any) => {
							// console.log("d", d, "isMulticityAndIntl", isMulticityAndIntl);
							return {
								...d,
								convertedDuration:
									bigFlightData.route === "international"
										? convertDuration(
												isMulticityAndIntl
													? d.total_duration[0]
													: d.total_duration
										  )
										: minutesToDuration(d.total_duration),
							};
						})
						.sort(compareDurationV2)[0].total_duration;

	let min_amount =
		// arrivalResult !== undefined &&
		bigFlightData.selectedDeparture &&
		bigFlightData.showTripType === "Roundtrip"
			? Math.min(...arrivalResult?.data?.map((d: any) => d.amount))
			: Math.min(...searchResult?.data?.map((d: any) => d.amount));
	const Fastest =
		// arrivalResult !== undefined &&
		bigFlightData.selectedDeparture &&
		bigFlightData.showTripType === "Roundtrip"
			? bigFlightData.route === "international"
				? [...arrivalResult?.data]
						?.map((d: any) => ({
							...d,
							convertedDuration: convertDuration(
								isMulticityAndIntl ? d.total_duration[0] : d.total_duration
							),
						}))
						.sort(compareDurationV2)
				: [...arrivalResult?.data]
						?.map((d: any) => ({
							...d,
							convertedDuration: minutesToDuration(d.total_duration),
						}))
						.sort((a, b) => a?.total_duration - b?.total_duration)
			: bigFlightData.route === "international"
			? [...searchResult?.data]
					?.map((d: any) => ({
						...d,
						convertedDuration: convertDuration(
							isMulticityAndIntl ? d.total_duration[0] : d.total_duration
						),
					}))
					.sort(compareDurationV2)
			: [...searchResult?.data]
					?.map((d: any) => ({
						...d,
						convertedDuration: minutesToDuration(d.total_duration),
					}))
					.sort((a, b) => a?.total_duration - b?.total_duration);
	const Cheapest =
		// arrivalResult !== undefined &&
		bigFlightData.selectedDeparture &&
		bigFlightData.showTripType === "Roundtrip"
			? arrivalResult?.data?.filter((min: any) => min.amount === min_amount)
			: searchResult?.data?.filter((min: any) => min.amount === min_amount);

	console.log("Fastest", Fastest);
	console.log("min_duration", min_duration);

	const speedFilters = [
		{
			label: "Fastest",
			extra: `₦${Math.round(
				Fastest[0]?.amount + (Fastest[0]?.tax ?? 0)
			).toLocaleString("en-US")} · ${
				bigFlightData.route === "international"
					? renderDuration(
							min_duration
								? isMulticityAndIntl
									? min_duration[0]
									: min_duration
								: "00:00"
					  )
					: minutesToDuration(min_duration ? min_duration : "00:00")
			}`,
		},
		{
			label: "All",
			extra: `₦${
				// arrivalResult !== undefined &&
				bigFlightData.selectedDeparture &&
				bigFlightData.showTripType === "Roundtrip"
					? Math.round(
							(arrivalResult?.data?.[0]?.amount ?? 0) +
								(arrivalResult?.data?.[0]?.tax ?? 0) ?? 0
					  ).toLocaleString("en-US")
					: searchResult?.data?.length > 0
					? Math.round(
							searchResult?.data[0].amount + (searchResult?.data[0]?.tax ?? 0)
					  ).toLocaleString("en-US")
					: 0
			} · ${
				// arrivalResult !== undefined &&
				bigFlightData.selectedDeparture &&
				bigFlightData.showTripType === "Roundtrip"
					? bigFlightData.route === "international"
						? renderDuration(
								isMulticityAndIntl
									? arrivalResult.data[0]?.total_duration[0]
									: arrivalResult.data[0]?.total_duration
						  )
						: minutesToDuration(arrivalResult.data[0]?.total_duration)
					: searchResult?.data?.length > 0
					? bigFlightData.route === "international"
						? renderDuration(
								isMulticityAndIntl
									? searchResult.data[0]?.total_duration[0]
									: searchResult.data[0]?.total_duration
						  )
						: minutesToDuration(searchResult.data[0]?.total_duration)
					: "0m"
			}`,
		},
		{
			label: "Cheapest",
			extra: `₦${Math.round(
				Cheapest[0]?.amount + (Cheapest[0]?.tax ?? 0)
			).toLocaleString("en-US")} · ${
				bigFlightData.route === "international"
					? renderDuration(
							isMulticityAndIntl
								? Cheapest[0]?.total_duration[0]
								: Cheapest[0]?.total_duration
					  )
					: minutesToDuration(Cheapest[0]?.total_duration)
			}`,
		},
	];

	const [selectedSpeed, setSelectedSpeed] = useState(1);
	const selectArrival = (item: any) => {
		if (bigFlightData.route === "international") {
			if (bigFlightData.showTripType === "Roundtrip") {
				updateBigFlightData({
					flightSelected: true,
					selectedDeparture: item,
					selectedArrival: item,
				});
				navigate("/trips/flight-confirmation");
			}
			if (bigFlightData.showTripType === "One way") {
				updateBigFlightData({ flightSelected: true, selectedDeparture: item });
				navigate("/trips/flight-confirmation");
			}
			if (bigFlightData.showTripType === "Multicity") {
				updateBigFlightData({
					flightSelected: true,
					selectedDeparture: item,
					selectedArrival: item,
				});
				navigate("/trips/flight-confirmation");
			}
		} else {
			if (
				bigFlightData.showTripType === "Roundtrip" &&
				!bigFlightData.selectedDeparture
			) {
				updateBigFlightData({ selectedDeparture: item });
			}
			if (
				bigFlightData.showTripType === "Roundtrip" &&
				bigFlightData.selectedDeparture
			) {
				updateBigFlightData({ flightSelected: true, selectedArrival: item });
				navigate("/trips/flight-confirmation");
			}
			if (bigFlightData.showTripType === "One way") {
				updateBigFlightData({ flightSelected: true, selectedDeparture: item });
				navigate("/trips/flight-confirmation");
			}
			if (bigFlightData.showTripType === "Multicity") {
				updateBigFlightData({
					selectedMultiCityFlights: [
						...bigFlightData.selectedMultiCityFlights,
						item,
					],
				});

				if (
					bigFlightData.selectedMultiCityFlights.length ===
					bigFlightData.multiCity.length - 1
				) {
					updateBigFlightData({
						flightSelected: true,
					});
					navigate("/trips/flight-confirmation");
				}
			}
		}

		if (pageRef.current) {
			console.log("pageRef");
			pageRef.current.scrollTop = 0;
		}
	};
	const updateState = () => {
		if (selectedSpeed === 0) {
			return Fastest;
		}
		if (selectedSpeed === 2) {
			return Cheapest;
		} else {
			console.log("arrivalResult !== undefined", arrivalResult !== undefined);
			// return arrivalResult !== undefined &&
			return bigFlightData.selectedDeparture &&
				bigFlightData.showTripType === "Roundtrip"
				? arrivalResult.data
				: searchResult.data;
		}
	};

	const runFilters = (resultsArray: any[]) => {
		// jump(1);
		let filteredResults = resultsArray;
		if (airlineFilters.length > 0) {
			console.log("resultsArray", resultsArray);
			if (isMulticityAndIntl)
				filteredResults = filteredResults.filter((item: any) =>
					airlineFilters.includes(item?.outbound[0][0]?.airline_details?.name)
				);
			else
				filteredResults = filteredResults.filter((item: any) =>
					airlineFilters.includes(item?.outbound[0]?.airline_details?.name)
				);
		}
		if (stopFilter !== null) {
			console.log("resultsArray", resultsArray);
			if (isMulticityAndIntl)
				filteredResults = filteredResults.filter(
					(item: any) => item?.outbound[0]?.length <= stopFilter + 1
				);
			else
				filteredResults = filteredResults.filter(
					(item: any) => item?.outbound?.length <= stopFilter + 1
				);
		}

		if (timeFilters.departure.length > 0 || timeFilters.arrival.length > 0) {
			const tempTimeResults: any[] = [];
			console.log("filteredResults", filteredResults);

			// if (timeFilters.departure.length > 0) {
			// 	timeFilters.departure.forEach((option) => {
			// 		tempTimeResults.push(
			// 			filteredResults.filter((resultItem) =>
			// 				isTimeBetween(
			// 					resultItem?.outbound[0]?.departure_time,
			// 					timeFilterOptions[option].startTime,
			// 					timeFilterOptions[option].endTime
			// 				)
			// 			)
			// 		);
			// 	});
			// }

			filteredResults = filteredResults.filter((resultItem) => {
				const bools: boolean[] = [];

				// generate an array of booleans from testing each result

				timeFilters.departure.forEach((option) => {
					bools.push(
						isTimeBetween(
							resultItem?.outbound[0]?.departure_time,
							timeFilterOptions[option].startTime,
							timeFilterOptions[option].endTime
						)
					);
				});

				timeFilters.arrival.forEach((option) => {
					bools.push(
						isTimeBetween(
							resultItem?.outbound[0]?.arrival_time,
							timeFilterOptions[option].startTime,
							timeFilterOptions[option].endTime
						)
					);
				});

				//join the booleans with an OR, and evaluate.
				return eval(bools.join("||"));
			});

			// filteredResults = tempTimeResults.flat();
			// console.log("tempTimeResults", tempTimeResults);

			// filteredResults = filteredResults.filter(
			// 	(item: any) => item?.outbound?.length <= stopFilter + 1
			// );
		}

		return filteredResults;
	};

	console.log(props);
	console.log("bigFlightData?.departureCity", bigFlightData?.departureCity);

	console.log("pageRef", pageRef);
	console.log("bigFlightData", bigFlightData);
	console.log("arrivalResult", arrivalResult);
	console.log("searchResult", searchResult);
	console.log("airlineFilters", airlineFilters);
	console.log("timeFilters", timeFilters);

	let airlineArr;
	if (isMulticityAndIntl)
		airlineArr = [
			...new Set(
				(bigFlightData?.selectedDeparture
					? arrivalResult
					: searchResult
				)?.data?.map(
					(searchItem: any) => searchItem?.outbound[0][0]?.airline_details?.name
				)
			),
		];
	else
		airlineArr = [
			...new Set(
				(bigFlightData?.selectedDeparture
					? arrivalResult
					: searchResult
				)?.data?.map(
					(searchItem: any) => searchItem?.outbound[0]?.airline_details?.name
				)
			),
		];
	console.log("airlineArr", airlineArr);

	// searchResult?.data.length === 0
	let next: any,
		prev: any,
		jump: any,
		currentData: any,
		currentPage: any,
		maxPage: any;

	({ next, prev, jump, currentData, currentPage, maxPage } = usePagination(
		runFilters(updateState()),
		20
	));

	// useEffect(() => {
	// 	runFilters();
	// }, [triggerFilter]);

	useEffect(() => {
		//search expires after 10 mins
		setTimeout(() => {
			setOpenExpired(true);
		}, 900000);
	}, [triggerExpired]);

	useEffect(() => {
		updateEditFlightData({ ...bigFlightData });
	}, []);

	if (searchLoading || arrivalLoading)
		return (
			<CustomLoader
				info={
					bigFlightData.showTripType === "Multicity"
						? bigFlightData.multiCity?.map((infoItem: any) => ({
								locations: `${
									globalAirports?.filter(
										(item: any) => item.iata_code === infoItem.departure
									)[0]?.city
								} (${infoItem.departure}) - ${
									globalAirports?.filter(
										(item: any) => item.iata_code === infoItem.destination
									)[0]?.city
								} (${infoItem?.destination})`,
								dates: `${new Date(infoItem.departure_date).toDateString()}`,
								other: `${bigFlightData.selectedTraveller?.length} adults, ${bigFlightData.cabin}`,
						  }))
						: {
								locations: `${bigFlightData?.departureCity[0]?.city} (${bigFlightData.departure}) - ${bigFlightData?.arrivalCity[0]?.city} (${bigFlightData?.destination})`,
								dates: `${new Date(
									bigFlightData.departure_date
								).toDateString()} ${
									bigFlightData.return_date
										? " - " + new Date(bigFlightData.return_date).toDateString()
										: ""
								}`,
								other: `${bigFlightData.selectedTraveller?.length} adults, ${bigFlightData.cabin}`,
						  }
				}
			/>
		);

	return (
		<div className="flight-page" ref={pageRef}>
			<div className="search-query">
				<div className="search-left">
					<div className="up">
						<p>
							{bigFlightData.showTripType === "Multicity"
								? `${
										globalAirports.filter(
											(item: any) =>
												item.iata_code ===
												bigFlightData?.multiCity[0]?.departure
										)[0]?.city
								  } (${bigFlightData?.multiCity[0]?.departure}
									)`
								: `${bigFlightData?.departureCity[0]?.city} (${bigFlightData.departure}
							)`}
						</p>
						<div>
							<img src={arrow3} alt="arrow" />
							{bigFlightData.showTripType === "Roundtrip" && (
								<img src={arrow4} alt="arrow" />
							)}
						</div>
						<p>
							{bigFlightData.showTripType === "Multicity"
								? `${
										globalAirports.filter(
											(item: any) =>
												item.iata_code ===
												bigFlightData?.multiCity[
													bigFlightData?.multiCity?.length - 1
												]?.destination
										)[0]?.city
								  } (${
										bigFlightData?.multiCity[
											bigFlightData?.multiCity?.length - 1
										]?.destination
								  }
									)`
								: `${bigFlightData?.arrivalCity[0]?.city} (${bigFlightData.destination}
							)`}
						</p>
					</div>
					<div className="down">
						<span>
							{DateTime.fromISO(
								bigFlightData.showTripType === "Multicity"
									? bigFlightData.multiCity[0].departure_date
									: bigFlightData.departure_date
							).toLocaleString({
								...(document.documentElement.clientWidth > 780 && {
									weekday: "short",
								}),
								day: "2-digit",
								month: "short",
							})}{" "}
							-{" "}
							{bigFlightData.return_date &&
								DateTime.fromISO(bigFlightData.return_date).toLocaleString({
									...(document.documentElement.clientWidth > 780 && {
										weekday: "short",
									}),
									day: "2-digit",
									month: "short",
								})}
						</span>
						<span className="dot">{"•"}</span>
						<span>{bigFlightData.selectedTraveller?.length} passengers</span>
						<span className="dot">{"•"}</span>
						<span>{capitalizeFirstLetter(bigFlightData.cabin)}</span>
					</div>
				</div>
				<div className="search-right" onClick={() => setOpenTop(true)}>
					<BiSearch color="#9C9393" />
					<p>Edit Search</p>
				</div>
			</div>
			<SwipeableDrawer
				anchor="top"
				open={openTop}
				onClose={toggleSideDrawer(false, setOpenTop)}
				onOpen={toggleSideDrawer(true, setOpenTop)}
				sx={{
					".MuiPaper-root": {
						overflowY: "unset",
					},
				}}
			>
				<EditFlightSearch {...props} onClose={() => setOpenTop(false)} />
			</SwipeableDrawer>

			<div className="filter-options">
				<div className="result-filter">
					<div>
						<p
							className="advanced"
							onClick={() => {
								pageRef.current.scrollTop = 0;
							}}
						>
							Advanced Filter
						</p>
						<div className="filters">
							{/* {searchFilters.map((filterItem, ind) => (
                            <MUIBasicSelect
                                label={filterItem.label}
                                list={filterItem.list}
                                key={ind}
                            />
                        ))} */}
							<CustomFilter
								placeholder="Time"
								title="Flight Time"
								optionsList={[]}
								type="time"
								timeFilters={timeFilters}
								setTimeFilters={setTimeFilters}
							/>
							<CustomFilter
								placeholder="Stops"
								title="Stops"
								optionsList={[
									{ label: "Non-stop", value: 0 },
									{
										label: "1 stop or fewer",
										value: 1,
									},
									{
										label: "2 stop or fewer",
										value: 2,
									},
								]}
								type="radio"
								selectedRadio={stopFilter}
								onSelect={(val: any) => {
									setStopFilter(val);
								}}
							/>
							<CustomFilter
								placeholder="Airline"
								title="Airline"
								optionsList={airlineArr?.map((item) => ({
									label: item,
									value: item,
								}))}
								type="checkbox"
								onSelect={(val: any) => {
									jump(1);
									setTriggerFilter(!triggerFilter);
									if (airlineFilters.includes(val))
										setAirlineFilters([
											...airlineFilters?.filter((item: any) => item !== val),
										]);
									else setAirlineFilters([...airlineFilters, val]);
								}}
								selectedList={airlineFilters}
							/>
							<CustomFilter
								placeholder="Price"
								title="Price"
								optionsList={[
									{ label: "Refundable", value: "Refundable" },
									{
										label: "Non-Refundable",
										value: "Non-Refundable",
									},
									{
										label: "Mixed",
										value: "Mixed",
									},
								]}
								type="radio"
							/>
							{/* <CustomFilter
								placeholder="Class"
								title="Class"
								optionsList={[
									{ label: "Economy", value: "economy" },
									{
										label: "Premium-Economy",
										value: "premium_economy",
									},
									{
										label: "Business",
										value: "business",
									},
									{ label: "First", value: "first" },
								]}
								type="radio"
							/> */}
						</div>
					</div>
				</div>
				<div className="filter-div">
					<button onClick={() => setOpenFilters(true)}>
						<span>Filter</span>
						<FilterIcon />
					</button>
				</div>
				{searchResult.data?.length > 0 && (
					<BigToggle
						list={speedFilters}
						selectedVal={selectedSpeed}
						setSelectedVal={setSelectedSpeed}
						onClick={() => {
							jump(1);
							setAirlineFilters([]);
							setStopFilter(null);
							// updateState();
						}}
					/>
				)}
			</div>
			{(bigFlightData.selectedDeparture &&
			bigFlightData.showTripType === "Roundtrip"
				? arrivalResult
				: searchResult
			)?.data?.length !== 0 ? (
				<>
					{/* <div className="restrictions">
						<div className="restrictions__left"></div>
						<img src={restrictionBadge} alt="restriction" />
						<div className="restrictions__text">
							<div>
								<p style={{ color: "#252627" }}>Travel Restictions!</p>
								<div style={{ color: "#655F5F" }}>
									<p>
										Check out these requirements if you are travelling to{" "}
										{bigFlightData.destination}
									</p>
									<div
										className="restrictions__see_full restrictions__see_full--mobile"
										onClick={() => setOpenSide(true)}
									>
										<p>See full restrictions</p>
										<BsArrowRight strokeWidth={1} />
									</div>
								</div>
							</div>
							<div
								className="restrictions__see_full restrictions__see_full--web"
								onClick={() => setOpenSide(true)}
							>
								<p>See full restrictions</p>
								<BsArrowRight strokeWidth={1} />
							</div>
						</div>
					</div> */}
					{bigFlightData.selectedDeparture && (
						<div style={{ marginBottom: "0.5rem" }}>
							<div className="flight-selected">
								<p>
									{"Departure selected, " +
										new Date(bigFlightData.departure_date)?.toLocaleDateString(
											"en-us",
											{
												weekday: "short",
												day: "2-digit",
												month: "short",
											}
										)}
								</p>
								<p
									className="change"
									onClick={() => {
										setStopFilter(null);
										setAirlineFilters([]);
										updateBigFlightData({
											flightSelected: false,
											selectedDeparture: undefined,
										});
										jump(1);
									}}
								>
									Change
								</p>
							</div>

							<FlightInfoDrawer
								flightInfo={bigFlightData.selectedDeparture}
								selected={true}
								// searchResult={searchResult}
								departure_date={bigFlightData.departure_date}
								return_date={bigFlightData.return_date}
								departure={bigFlightData.departure}
								departureCity={bigFlightData?.departureCity[0]}
								arrival={bigFlightData.destination}
								arrivalCity={bigFlightData?.arrivalCity[0]}
								route={bigFlightData.route}
								currIndex="selected"
								openedIndex={openedIndex}
								onOpen={() => setOpenedIndex("selected")}
								resetOpen={() => setOpenedIndex(null)}
								changeAmount={(new_val: number) => {}}
							/>
						</div>
					)}
					{bigFlightData.selectedDeparture &&
					bigFlightData.showTripType !==
						"Roundtrip" ? null : bigFlightData.selectedDeparture &&
					  bigFlightData.route === "international" ? (
						<></>
					) : (
						<div className="select-departure">
							{bigFlightData.route === "international" ? (
								<></>
							) : (
								<p className="circle">
									{bigFlightData.showTripType === "Multicity" &&
									bigFlightData.route === "domestic"
										? bigFlightData.selectedMultiCityFlights?.length + 1
										: bigFlightData.selectedDeparture &&
										  bigFlightData.showTripType === "Roundtrip"
										? "2"
										: "1"}
								</p>
							)}
							<p>
								{bigFlightData.route === "international"
									? "Select your flight"
									: bigFlightData.showTripType === "Multicity"
									? "Select flight leg"
									: `Select 
								${
									bigFlightData.selectedDeparture &&
									bigFlightData.showTripType === "Roundtrip"
										? bigFlightData.showTripType === "Roundtrip"
											? "return"
											: "a new departure"
										: "departure"
								} 
								flight`}
							</p>
							<p style={{ marginLeft: "4px" }}>
								<span className="web-time">
									{new Date(
										bigFlightData.showTripType === "Multicity"
											? bigFlightData.multiCity[
													bigFlightData.selectedMultiCityFlights.length
											  ].departure_date
											: bigFlightData.showTripType === "Roundtrip" &&
											  bigFlightData.selectedDeparture
											? bigFlightData.return_date
											: bigFlightData.departure_date
									)?.toLocaleDateString("en-us", {
										weekday: "short",
										day: "2-digit",
										month: "short",
									})}
								</span>
								<span className="mobile-time">
									{new Date(
										bigFlightData.showTripType === "Multicity"
											? bigFlightData.multiCity[
													bigFlightData.selectedMultiCityFlights.length
											  ].departure_date
											: bigFlightData.departure_date
									)
										?.toDateString()
										.slice(0, -5)}
								</span>
							</p>
						</div>
					)}
					{bigFlightData.selectedDeparture &&
					bigFlightData.showTripType === "Roundtrip" &&
					bigFlightData.route === "domestic" ? (
						<div className="search-result-wrapper">
							{currentData()?.length > 0 ? (
								currentData()?.map((item: any, index: number) => {
									return (
										<FlightInfoDrawer
											flightInfo={item}
											key={index}
											onSelect={() => {
												selectArrival(item);

												setStopFilter(null);
												setAirlineFilters([]);

												jump(1);
												toast.success(
													"Arrival selected. Please proceed with payment"
												);
											}}
											selected={item.id === bigFlightData.selectedDeparture?.id}
											// searchResult={searchResult}
											departure_date={bigFlightData.departure_date}
											return_date={bigFlightData.return_date}
											departure={bigFlightData.destination}
											arrival={bigFlightData.departure}
											route={bigFlightData.route}
											departureCity={bigFlightData?.departureCity[0]}
											arrivalCity={bigFlightData?.arrivalCity[0]}
											currIndex={index}
											openedIndex={openedIndex}
											onOpen={() => setOpenedIndex(index)}
											resetOpen={() => setOpenedIndex(null)}
											changeAmount={(new_val: number) => {
												currentData()[index].amount = new_val;
											}}
										/>
									);
								})
							) : (
								<EmptyStateBig
									title="Bummer! 😔"
									message={
										<p>
											We currently have no{" "}
											<span style={{ fontWeight: 600 }}>direct flights</span>{" "}
											available for this route.
											<br />
											Click the <span style={{ fontWeight: 600 }}>
												Stops
											</span>{" "}
											filter above to explore options with layovers.
										</p>
									}
									button={{
										label: "Start Over",
										action: () => {
											updateBigFlightData({
												flightSelected: false,
												selectedDeparture: undefined,
												selectedArrival: undefined,
												selectedMultiCityFlights: [],
											});
											navigate("/trips");
										},
									}}
								/>
							)}
						</div>
					) : bigFlightData.selectedDeparture &&
					  bigFlightData.showTripType !==
							"Roundtrip" ? null : bigFlightData.selectedDeparture &&
					  bigFlightData.showTripType === "Roundtrip" &&
					  bigFlightData.route === "international" ? (
						<></>
					) : (
						<div className="search-result-wrapper">
							{currentData()?.length > 0 ? (
								currentData()?.map((item: any, index: number) => {
									return (
										<FlightInfoDrawer
											flightInfo={item}
											key={index}
											onSelect={() => {
												console.log("selectedFlight", item);
												selectArrival(item);

												setStopFilter(null);
												setAirlineFilters([]);

												if (isMulticityAndLocal)
													toast.success(
														`Flight ${
															bigFlightData.selectedMultiCityFlights?.length + 1
														} selected.`
													);
												else if (isMulticityAndIntl)
													toast.success(
														"Flight selected. Please proceed with payment."
													);
												else if (bigFlightData.showTripType === "Roundtrip")
													toast.success(
														bigFlightData?.route === "domestic"
															? "Departure selected. Please select a return flight"
															: "Flight selected. Please proceed with payment."
													);
												else
													toast.success(
														"Flight selected. Please proceed with payment."
													);
												jump(1);
											}}
											// selected={item.id === selectedFlight?.id}
											// searchResult={searchResult}
											departure_date={bigFlightData.departure_date}
											return_date={bigFlightData.return_date}
											departure={bigFlightData.departure}
											arrival={bigFlightData.destination}
											route={bigFlightData.route}
											departureCity={bigFlightData?.departureCity[0]}
											arrivalCity={bigFlightData?.arrivalCity[0]}
											currIndex={index}
											openedIndex={openedIndex}
											onOpen={() => setOpenedIndex(index)}
											resetOpen={() => setOpenedIndex(null)}
											changeAmount={(new_val: number) => {
												currentData()[index].amount = new_val;
											}}
										/>
									);
								})
							) : (
								<EmptyStateBig
									title="Bummer! 😔"
									message={
										<p>
											We currently have no{" "}
											<span style={{ fontWeight: 600 }}>direct flights</span>{" "}
											available for this route.
											<br />
											Click the <span style={{ fontWeight: 600 }}>
												Stops
											</span>{" "}
											filter above to explore options with layovers.
										</p>
									}
									button={{
										label: "Start Over",
										action: () => {
											updateBigFlightData({
												flightSelected: false,
												selectedDeparture: undefined,
												selectedArrival: undefined,
												selectedMultiCityFlights: [],
											});
											navigate("/trips");
										},
									}}
								/>
							)}
						</div>
					)}

					{/* ---------- Pagination ---------- */}
					<PaginationComponent
						next={() => {
							next();
							setOpenedIndex(null);
							setTimeout(() => {
								if (pageRef.current) {
									console.log("pageRef");
									pageRef.current.scrollTop = 0;
								}
							}, 200);
						}}
						prev={() => {
							prev();
							setOpenedIndex(null);
							setTimeout(() => {
								if (pageRef.current) {
									console.log("pageRef");
									pageRef.current.scrollTop = 0;
								}
							}, 200);
						}}
						jump={(page: number) => {
							jump(page);
							setOpenedIndex(null);
							setTimeout(() => {
								if (pageRef.current) {
									console.log("pageRef");
									pageRef.current.scrollTop = 0;
								}
							}, 200);
						}}
						currentData={currentData}
						currentPage={currentPage}
						maxPage={maxPage}
						total={updateState()?.length}
						limit={20}
					/>
					{/* -------------------------------- */}

					{/* ---------- Drawers ---------- */}
					<SwipeableDrawer
						anchor="right"
						open={openSide}
						onClose={toggleSideDrawer(false, setOpenSide)}
						onOpen={toggleSideDrawer(true, setOpenSide)}
					>
						<Restrictions />
					</SwipeableDrawer>
					<Drawer
						anchor="top"
						open={openFilters}
						onClose={toggleSideDrawer(false, setOpenFilters)}
					>
						<MobileFilters
							triggerFilter={triggerFilter}
							setTriggerFilter={setTriggerFilter}
							stopFilter={stopFilter}
							setStopFilter={setStopFilter}
							airlineArr={airlineArr}
							airlineFilters={airlineFilters}
							setAirlineFilters={setAirlineFilters}
							timeFilters={timeFilters}
							setTimeFilters={setTimeFilters}
							setOpenFilters={setOpenFilters}
							jump={jump}
							resultCount={runFilters(updateState())?.length || 0}
						/>
					</Drawer>
					{/* -------------------------------- */}

					{/* ---------- Modals ---------- */}
					<MUIModal
						open={openExpired}
						handleClose={() => setOpenExpired(false)}
						cantClose={true}
					>
						<SearchExpiredModal
							searchAgain={async () => {
								try {
									await searchFlight(
										false,
										searchLoading,
										setSearchLoading,
										arrivalLoading,
										setArrivalLoading,
										bigFlightData,
										updateBigFlightData,
										navigate
									);
								} catch (err) {
									console.error(err);
								} finally {
									setOpenExpired(false);
									setTriggerExpired(!triggerExpired);
								}
							}}
						/>
					</MUIModal>
					{/* -------------------------------- */}
				</>
			) : (
				<EmptyStateBig
					title="Bummer! 😔"
					message={
						<p>
							We searched everywhere but could not find what you were looking
							for.
							<br />
							Wanna try modifying your search?
						</p>
					}
					button={{
						label: "Start Over",
						action: () => {
							updateBigFlightData({
								flightSelected: false,
								selectedDeparture: undefined,
								selectedArrival: undefined,
								selectedMultiCityFlights: [],
							});
							navigate("/trips");
						},
					}}
				/>
			)}
		</div>
	);
}
