import React, { useState } from "react";
import "./style.scss";
import restrictionBadge from "../../../resource/img/restrictionBadge.svg";
import coffeeIcon from "../../../resource/img/coffee 1.png";
import wifiIcon from "../../../resource/img/ri_wifi-line.png";
import dumbellIcon from "../../../resource/img/dumbell 1.png";
import { AiFillStar } from "react-icons/ai";
import { BiSearch } from "react-icons/bi";
import { BsArrowRight, BsChevronDown } from "react-icons/bs";
import { IoMdHeart, IoMdHeartEmpty } from "react-icons/io";
import { GiCoffeeCup } from "react-icons/gi";
import { HiOutlineWifi } from "react-icons/hi";
import { IoBarbellOutline } from "react-icons/io5";
import MUIBasicSelect from "../../../components/MUISelect";
import { AvailableRooms, Details } from "./tabComponents";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { Restrictions } from "../../../components/Drawers";

const HotelPage = () => {
    const [openSide, setOpenSide] = useState(false);
    const toggleDrawer = (open: boolean) => (event: any) => {
        if (
            event &&
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }

        setOpenSide(open);
    };

    let tripInfo = {
        departureDate: "Mon, 28 Feb",
        arrivalCity: "Abuja",
        arrival: "ABJ",
        departureCity: "Lagos",
        departure: "LOS",
        arrivalDate: "Sat, 26 Mar ",
        passengerNo: 5,
        roomCount: 4,
    };

    const searchFilters = [
        {
            label: "Work trip",
            list: [
                { label: "9am", value: "9am" },
                { label: "12pm", value: "12pm" },
            ],
        },
        {
            label: "Hotel name",
            list: [
                { label: "Sheraton", value: "Sheraton" },
                { label: "Radisson Blu", value: "Radisson Blu" },
            ],
        },
        {
            label: "Reveiw score",
            list: [
                { label: "0-2", value: "0-2" },
                { label: "3-5", value: "3-5" },
            ],
        },
        {
            label: "Accomodation type",
            list: [
                { label: "< ₦100000", value: "< ₦100000" },
                { label: "₦100000 - ₦200000", value: "₦100000 - ₦200000" },
                { label: "> ₦500000", value: "> ₦500000" },
            ],
        },
        {
            label: "Popular ameneties",
            list: [
                { label: "Pool", value: "Pool" },
                { label: "Game Room", value: "Game Room" },
            ],
        },
    ];

    const searchResults = [
        {
            image: "/images/hotel-1.png",
            name: "Beckett suites",
            price: "₦50,000",
            distance: "8km",
            rating: 5,
            liked: false,
        },
        {
            image: "/images/hotel-2.png",
            name: "Beckett suites",
            price: "₦50,000",
            distance: "8km",
            rating: 5,
            liked: false,
        },
        {
            image: "/images/hotel-3.png",
            name: "Beckett suites",
            price: "₦50,000",
            distance: "8km",
            rating: 2,
            liked: false,
        },
        {
            image: "/images/hotel-4.png",
            name: "Beckett suites",
            price: "₦50,000",
            distance: "8km",
            rating: 3,
            liked: false,
        },
        {
            image: "/images/hotel-5.png",
            name: "Beckett suites",
            price: "₦50,000",
            distance: "8km",
            rating: 4,
            liked: false,
        },
    ];

    const tabData = [
        { name: "Available rooms", component: <AvailableRooms /> },
        { name: "Details", component: <Details /> },
    ];

    return (
        <div className="flight-page">
            <div className="search-query">
                <div className="search-left">
                    <div className="up" style={{ marginBottom: "0.3rem" }}>
                        <p>Title of Hotel Search</p>
                    </div>
                    <div className="down">
                        <span>
                            {tripInfo.departureDate} - {tripInfo.arrivalDate}
                        </span>
                        <span className="dot">{"•"}</span>
                        <span>{tripInfo.passengerNo} passengers</span>
                        <span className="dot">{"•"}</span>
                        <span>{tripInfo.roomCount} rooms</span>
                    </div>
                    <p
                        className="down"
                        style={{ color: "#9C9393", marginTop: "0.3rem" }}
                    >
                        Abuja: 199 properties found all at the best rates that
                        are only available online
                    </p>
                </div>
                <div className="search-right">
                    <BiSearch color="#9C9393" />
                    <p>Edit Search</p>
                </div>
            </div>
            <div className="filter-options">
                <p className="advanced">Advanced Filter</p>
                <div className="result-filter">
                    <div
                        className="filters"
                        style={{ width: "100%", justifyContent: "flex-start" }}
                    >
                        {searchFilters.map((filterItem, ind) => (
                            <MUIBasicSelect
                                label={filterItem.label}
                                list={filterItem.list}
                                key={ind}
                            />
                        ))}
                    </div>
                </div>
            </div>
            <div className="restrictions">
                <div className="restrictions__left"></div>
                <img src={restrictionBadge} alt="restriction" />
                <div className="restrictions__text">
                    <div>
                        <p style={{ color: "#252627" }}>Travel Restictions!</p>
                        <p style={{ color: "#655F5F" }}>
                            Check out these requirements if you are travelling
                            to Dubai
                        </p>
                    </div>
                    <div
                        className="restrictions__see_full"
                        onClick={() => setOpenSide(true)}
                    >
                        <p>See full restrictions</p>
                        <BsArrowRight strokeWidth={1} />
                    </div>
                </div>
            </div>
            <div className="hotels-main">
                <div className="hotel-results">
                    {searchResults.map((searchItem, ind) => (
                        <HotelResultComponent
                            searchItem={searchItem}
                            key={ind}
                        />
                    ))}
                </div>
                <div className="map">
                    <img src="/images/map.png" alt="map" />
                </div>
            </div>
            <SwipeableDrawer
                anchor="right"
                open={openSide}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
            >
                <Restrictions />
            </SwipeableDrawer>
        </div>
    );
};

export default HotelPage;

const HotelResultComponent = ({ searchItem }: { searchItem: any }) => {
    const tabData = [
        { name: "Available rooms", component: <AvailableRooms /> },
        { name: "Details", component: <Details /> },
    ];
    const [openShelf, setOpenShelf] = useState(false);

    return (
        <div>
            <div className="hotel-result">
                <div>
                    <div className="image-wrapper">
                        <img src={searchItem.image} alt="" />
                    </div>
                    <div className="main-content">
                        <div className="features">
                            <div className="amenities">
                                <div>
                                    <p>Breakfast</p>
                                    <GiCoffeeCup />
                                </div>
                                <div>
                                    <p>Fitness</p>
                                    <IoBarbellOutline />
                                </div>
                                <div>
                                    <p>Wifi</p>
                                    <HiOutlineWifi />
                                </div>
                            </div>
                            <div className="rest">
                                <p>8.81 km from city center </p>
                                <div className="ratings">
                                    {Array(searchItem.rating)
                                        .fill(0)
                                        .map((star, ind) => (
                                            <AiFillStar key={ind} />
                                        ))}
                                </div>
                            </div>
                        </div>
                        {searchItem.liked ? (
                            <IoMdHeart color="#E69597" size={15} />
                        ) : (
                            <IoMdHeartEmpty color="#E69597" size={15} />
                        )}
                    </div>
                </div>
                <div>
                    <div className="name-price">
                        <p>{searchItem.name}</p>
                        <div>
                            <p className="gray">1 Adult / 11 nights</p>
                            <p>{searchItem.price}</p>
                        </div>
                    </div>
                    <p
                        className="blue-link"
                        onClick={() => setOpenShelf(!openShelf)}
                    >
                        See options +
                    </p>
                </div>
            </div>
            <div
                className={`hotelOptions ${!openShelf && "hotelOptions--shut"}`}
            >
                <TabComponent tabData={tabData} />
            </div>
        </div>
    );
};

const TabComponent = ({
    tabData,
}: {
    tabData: { name: string; component: JSX.Element }[];
}) => {
    const [active, setActive] = useState(0);

    return (
        <div className="tabComponent">
            <div className="top">
                <div className="tabs">
                    {tabData.map((item, ind) => (
                        <p
                            className={`tab ${
                                active === ind ? "tab--active" : ""
                            }`}
                            key={ind}
                            onClick={() => setActive(ind)}
                        >
                            {item.name}
                        </p>
                    ))}
                </div>
                <div className="more-filter">
                    More Filter <BsChevronDown />
                </div>
            </div>
            <div className="body">{tabData[active].component}</div>
        </div>
    );
};
