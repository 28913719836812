import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoClose } from "react-icons/io5";
import CustomLoader from "../CustomLoader";
import TripSelector from "../../components/TripSelector";
import "./style.scss";
import tripbg from "../../resource/img/tripBackgroud.png";
import { useTripStore } from "../../zustand/store";
import {
	fetchExchangeRate,
	fetchFlightData,
	fetchMulticityFlightData,
	combinableSearch,
	addSearchHistory,
} from "../../requests";
import { toast } from "react-toastify";
import { searchFlight } from "../../helpers/searchFlightFlow";

export default function Trip(props: any) {
	console.log("LoadProps", props);

	const navigate = useNavigate();

	const userData = useTripStore((state: any) => state.userData);
	const bigFlightData = useTripStore((state: any) => state.bigFlightData);
	const updateBigFlightData = useTripStore(
		(state: any) => state.updateBigFlightData
	);
	const globalAirports = useTripStore((state: any) => state.globalAirports);
	const searchLoading = useTripStore((state: any) => state.searchLoading);
	const arrivalLoading = useTripStore((state: any) => state.arrivalLoading);

	const handleSelectedTravellers = (traveller: []) => {
		updateBigFlightData({ selectedTraveller: traveller });
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				const res = await fetchExchangeRate();
				console.log(res);
				updateBigFlightData({ exchange_rate: res.data?.conversion_rate });
			} catch (e) {
				console.log(e);
			}
		};
		fetchData();
	}, []);

	// useEffect(() => {
	// 	//reset zustand
	// 	if (bigFlightData.bookingConfirmed || bigFlightData.paymentConfirmed)
	// 		updateBigFlightData({
	// 			search: "",
	// 			selectedTraveller: [],
	// 			departure: "",
	// 			destination: "",
	// 			departure_date: "",
	// 			return_date: null,
	// 			cabin: "economy",
	// 			showTripType: "Roundtrip",
	// 			selectedDeparture: undefined,
	// 			selectedArrival: undefined,
	// 			flightSelected: false,
	// 			bookingConfirmed: false,
	// 			paymentConfirmed: false,
	// 			departureCity: [],
	// 			arrivalCity: [],
	// 			flightReference: "",
	// 			route: "domestic",
	// 			session_id: "",
	// 			session_id2: "",
	// 			searchData: {},
	// 			arrivalData: {},
	// 			exchange_rate: 0,
	// 		});
	// }, []);

	if (searchLoading || arrivalLoading)
		return (
			<CustomLoader
				info={
					bigFlightData.showTripType === "Multicity"
						? bigFlightData.multiCity?.map((infoItem: any) => ({
								locations: `${
									globalAirports?.filter(
										(item: any) => item.iata_code === infoItem.departure
									)[0]?.city
								} (${infoItem.departure}) - ${
									globalAirports?.filter(
										(item: any) => item.iata_code === infoItem.destination
									)[0]?.city
								} (${infoItem?.destination})`,
								dates: `${new Date(infoItem.departure_date).toDateString()}`,
								other: `${bigFlightData.selectedTraveller?.length} adults, ${bigFlightData.cabin}`,
						  }))
						: {
								locations: `${bigFlightData?.departureCity[0]?.city} (${bigFlightData.departure}) - ${bigFlightData?.arrivalCity[0]?.city} (${bigFlightData?.destination})`,
								dates: `${new Date(
									bigFlightData.departure_date
								).toDateString()} ${
									bigFlightData.return_date
										? " - " + new Date(bigFlightData.return_date).toDateString()
										: ""
								}`,
								other: `${bigFlightData.selectedTraveller?.length} adults, ${bigFlightData.cabin}`,
						  }
				}
			/>
		);

	return (
		<div className="TripPage">
			{/* <div className="TripPage__header">
				<p>Search flights</p>
				<IoClose color="#252627" size={19} onClick={() => navigate("/")} />
			</div> */}
			<div className="welcome-message">
				<p className="welcome">
					Welcome back,{" "}
					{userData?.username
						? userData?.username[0].toUpperCase() + userData?.username.slice(1)
						: ""}
				</p>
				<p className="link">Need to book a business trip? Let's go</p>
			</div>
			<div className="trip">
				<div className="trip-wrap">
					<TripSelector
						{...props}
						setSelectedTraveller={handleSelectedTravellers}
					/>
				</div>
				<img className="tripbg" src={tripbg} alt="" />
			</div>
		</div>
	);

	// portLoading && globalAirports?.length === 0 ? (
	// 	<div className="PortLoader">
	// 		<AiOutlineLoading3Quarters color="rgb(64, 121, 140)" />
	// 	</div>
	// ) : (

	// );
}
