import React, { useState } from "react";
import "./style.scss";
import "./sub-pages.scss";
import { Page1, Page2, Page3, Page4, Page6, Left } from "./sub-pages";
import planeglobe from "../../resource/img/planeglobe.svg";
import useAxios from "../../hooks/useAxios";
import AuthLayout from "../../layouts/AuthLayout";
import { toast } from "react-toastify";

export default function Register() {
	let [email, setEmail] = useState("");
	let [firstName, setFirstName] = useState("");
	let [lastName, setLastName] = useState("");
	let [username, setUsername] = useState("");
	let [phone, setPhone] = useState("");
	let [password, setPassword] = useState("");
	let [confirmPassword, setConfirmPassword] = useState("");
	let [pageCount, setPageCount] = useState(1);
	let [country, setCountry] = useState("");
	let [role, setRole] = useState("");
	let [companySize, setCompanySize] = useState("");
	let [companyName, setCompanyName] = useState("");
	let [credit, setCredit] = useState(false);
	let [creditFacility, setCreditFacility] = useState("");
	let [creditAmount, setCreditAmount] = React.useState(0);
	let [creditPeriod, setCreditPeriod] = React.useState(0);
	let [travelVolume, setTravelVolume] = React.useState(0);
	let [travelBudget, setTravelBudget] = React.useState(0);
	let page1Data = { email, setEmail };
	let page2Data = { email, setEmail };
	let page3Data = {
		firstName,
		setFirstName,
		lastName,
		setLastName,
		username,
		setUsername,
		phone,
		setPhone,
		password,
		setPassword,
		confirmPassword,
		setConfirmPassword,
	};
	let page4Data = {
		email,
		username,
		password,
		phone,
		confirmPassword,
		creditAmount,
		setCreditAmount,
		creditPeriod,
		setCreditPeriod,
		travelVolume,
		setTravelVolume,
		travelBudget,
		setTravelBudget,
		country,
		setCountry,
		role,
		setRole,
		companySize,
		setCompanySize,
		companyName,
		setCompanyName,
		creditFacility,
		credit,
		setCreditFacility,
		setCredit,
		firstName,
		lastName,
	};
	let changePage = (e: any) => {
		// e.preventDefault();
		if (pageCount >= 5) {
			setPageCount(5);
		}
		setPageCount(pageCount + 1);
	};
	let reducePage = (e: any) => {
		// e.preventDefault();
		if (pageCount === 1) {
			setPageCount(1);
		}
		setPageCount(pageCount - 1);
	};
	let pageControl = {
		changePage,
		reducePage,
	};

	// return statements

	if (pageCount <= 1)
		return (
			<AuthLayout
				title="Experience the new way of business trip"
				desc="One platform for all your bookings."
			>
				<Page1 pageControl={pageControl} data={page1Data} />
			</AuthLayout>
		);
	else if (pageCount === 2)
		return (
			<AuthLayout
				title="Insights on the go!"
				desc="Invoicing and reporting for when you need it."
			>
				<Page2 pageControl={pageControl} data={page2Data} />
			</AuthLayout>
		);
	else if (pageCount === 3)
		return (
			<AuthLayout
				title="Seamless payment"
				desc="Pay with card or get our personalized credit facility."
			>
				<Page3 pageControl={pageControl} data={page3Data} />
			</AuthLayout>
		);
	else if (pageCount === 4)
		return (
			<AuthLayout
				title="Travel support like never before"
				desc="Experience K-Care, your own travel assistant."
			>
				<Page4 pageControl={pageControl} data={page4Data} />
			</AuthLayout>
		);
	else if (pageCount === 6)
		return (
			<AuthLayout
				title="Travel support like never before"
				desc="Experience K-Care, your own travel assistant."
			>
				<Page6 pageControl={pageControl} />
			</AuthLayout>
		);
	else
		return (
			<AuthLayout
				title="Travel support like never before"
				desc="Experience K-Care, your own travel assistant."
			>
				<Page6 pageControl={pageControl} />
			</AuthLayout>
		);
}
