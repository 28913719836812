export const jobRoleList = [
	{ label: "Business Owner", value: "Business Owner" },
	{ label: "Admin", value: "Admin" },
	{ label: "HR", value: "HR" },
	{ label: "Finance", value: "Finance" },
	{ label: "Procurement", value: "Procurement" },
	{ label: "Others", value: "Others" },
];

export const industriesList = [
	"Transport",
	"Aerospace",
	"Agriculture",
	"Telecommunication",
	"Education",
	"Construction",
	"Electronics",
	"Logistics",
	"Travel",
	"Information Technology",
	"Manufacturing",
	"Energy",
	"Pharmaceutical",
	"Healthcare",
	"Food",
	"Entertainment",
	"Music",
	"News Media",
	"Mining",
	"Hospitality",
];

export const emailListRegex =
	/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+(,\s*([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4}))*$/;

const layover = [
	{ 0: ["1h", "1h", ""] },
	{ 1: ["1h", "1h", ""] },
	{ 2: ["1h", "1h", ""] },
	{ 3: ["1h", "1h", ""] },
];

export const timeFilterOptions = {
	early: { startTime: 0, endTime: 6 },
	morning: { startTime: 6, endTime: 12 },
	afternoon: { startTime: 12, endTime: 18 },
	night: { startTime: 18, endTime: 24 },
};
