import { AiOutlineLoading3Quarters } from "react-icons/ai";
import "./style.scss";

const PortLoader = () => {
	return (
		<div className="PortLoader">
			<AiOutlineLoading3Quarters color="rgb(64, 121, 140)" />
		</div>
	);
};

export default PortLoader;
