import { Modal, Box } from "@mui/material";

interface MUIModalProps {
	children: JSX.Element;
	open: boolean;
	handleClose: () => void;
	sticky?: boolean;
	cantClose?: boolean;
	paddingTop?: string;
}

const MUIModal = ({
	children,
	open,
	handleClose,
	sticky = false,
	cantClose = false,
	paddingTop = "2.5rem",
}: MUIModalProps) => (
	<Modal
		open={open}
		onClose={() => {
			if (!cantClose) handleClose();
		}}
		aria-labelledby="modal-modal-title"
		aria-describedby="modal-modal-description"
		sx={{
			overflow: "scroll",
			padding: "2.5rem",
			paddingTop: paddingTop,
			display: "flex",
			flexDirection: "row",
			alignItems: "center",
			justifyContent: "center",

			"@media (max-width: 780px)": {
				padding: sticky ? "0" : "2.5rem 0.4rem",
				paddingTop: paddingTop,
				alignItems: sticky ? "flex-start" : "center",
			},
		}}
	>
		<Box
			sx={{
				outline: 0,
				maxHeight: "85vh",

				"@media (max-width: 780px)": {
					// eslint-disable-line no-useless-computed-key
					top: 0,
					// transform: sticky ? "unset" : "translate(-50%, 50%)",
					transform: sticky ? "unset" : "unset",
					maxHeight: "95vh",
					paddingBottom: sticky ? "2.5rem" : "0",
				},
			}}
		>
			{children}
		</Box>
	</Modal>
);

export default MUIModal;
