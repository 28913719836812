import React, {
	useState,
	useEffect,
	useRef,
	useMemo,
	ChangeEvent,
} from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import choosesvg from "../../resource/img/choosesvg.svg";
import "./style.scss";
import { BiSearch } from "react-icons/bi";
import { CgClose } from "react-icons/cg";
import { CheckBox } from "./tripData";
import LoadingAnimation from "../../components/LoadingAnimation";
// import CustomLoader from "../CustomLoader";
import { PlaneSelectIcon, DepartureIcon, ArrivalIcon } from "../pieces/svgs";
import { CustomSelect } from "../CustomSelect";
import MUIModal from "../MUIModal";
import {
	AddNewTravellerModal,
	SelectTravellerModal,
	LoadingModal,
	LoadingSuccessModal,
	AddNewTravellerModalRewrite,
} from "../../modals";
import DatePicker, {
	getAllDatesInRange,
	DateObject,
} from "react-multi-date-picker";
import CustomDropdown from "../CustomDropdown";
import CustomTooltip from "../CustomTooltip";
import { useTripStore } from "../../zustand/store";
import { toast } from "react-toastify";
import opacity from "react-element-popper/animations/opacity";
import transition from "react-element-popper/animations/transition";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import {
	addPaystackCharges,
	toggleSideDrawer,
} from "../../helpers/helperFuncs";
import { searchFlight } from "../../helpers/searchFlightFlow";
import { Drawer } from "@mui/material";
import ToggleSwitch from "../ToggleSwitch";

const Flights = (props: any) => {
	const navigate = useNavigate();

	const [active, setActive] = useState(0);
	let [showTraveller, setShowTraveller] = useState(false);
	let [completed, setCompleted] = useState(false);

	let [addNewTraveller, setAddNewTraveller] = useState(false);
	let [emailRequest, setEmailRequest] = useState(false);
	let [directFlight, setDirectFlight] = useState(false);

	interface Travellers {
		id: number;
		first_name: string;
		last_name: string;
		email: string;
	}

	const [calendarNum, setCalendarNum] = useState(
		document.documentElement.clientWidth > 780 ? 2 : 1
	);

	//zustand state
	const localAirports = useTripStore((state: any) => state.localAirports);
	const globalAirports = useTripStore((state: any) => state.globalAirports);
	const bigFlightData = useTripStore((state: any) => state.bigFlightData);
	const updateBigFlightData = useTripStore(
		(state: any) => state.updateBigFlightData
	);
	const searchLoading = useTripStore((state: any) => state.searchLoading);
	const setSearchLoading = useTripStore((state: any) => state.setSearchLoading);
	const arrivalLoading = useTripStore((state: any) => state.arrivalLoading);
	const setArrivalLoading = useTripStore(
		(state: any) => state.setArrivalLoading
	);
	const dropDownLoading = useTripStore((state: any) => state.dropDownLoading);

	const calendarRef = useRef<any>(null);
	const calendarRef1 = useRef<any>(null);
	const calendarRef2 = useRef<any>(null);
	const calendarRef3 = useRef<any>(null);
	const calendarRef4 = useRef<any>(null);
	const calendarRef5 = useRef<any>(null);

	let increaseCount = () => {
		if (bigFlightData?.multiCity?.length >= 5) {
			return;
		}
		updateBigFlightData({
			multiCity: [
				...bigFlightData?.multiCity,
				{
					departure: "",
					destination: "",
					departure_date: "",
				},
			],
		});
	};

	let decreaseCount = () => {
		if (bigFlightData?.multiCity?.length <= 2) {
			return;
		}
		updateBigFlightData({
			multiCity: bigFlightData?.multiCity?.slice(
				0,
				bigFlightData?.multiCity?.length - 1
			),
		});
	};

	let [tempSelected, setTempSelected] = useState(
		bigFlightData.selectedTraveller
	);

	let handleSelectedTravellers = () => {
		props.setSelectedTraveller(tempSelected);
	};

	const searchFlights = async () => {
		console.log("props", props);
		try {
			await searchFlight(
				emailRequest,
				searchLoading,
				setSearchLoading,
				arrivalLoading,
				setArrivalLoading,
				bigFlightData,
				updateBigFlightData,
				navigate,
				directFlight
			);
		} catch (err) {
			console.error(err);
		}
	};

	useEffect(() => {
		setCalendarNum(document.documentElement.clientWidth > 780 ? 2 : 1);
	}, [document.documentElement.clientWidth]);

	useEffect(() => {
		if (bigFlightData.showTripType === "Multicity") {
			if (active === bigFlightData?.multiCity?.length * 3 + 3)
				setShowTraveller(true);
		} else {
			if (active === 6) setShowTraveller(true);
		}
	}, [active]);

	console.log("active", active);
	console.log("modal-box", addPaystackCharges(100000));

	useEffect(() => {
		if (bigFlightData.showTripType === "Multicity") {
			if (active === 4) calendarRef1.current?.openCalendar();
			if (active === 8) calendarRef2.current?.openCalendar();
			if (active === 11) calendarRef3.current?.openCalendar();
			if (active === 14) calendarRef4.current?.openCalendar();
			if (active === 17) calendarRef5.current?.openCalendar();
		} else {
			if (active === 4) {
				calendarRef.current?.openCalendar();
			} else {
				calendarRef.current?.closeCalendar();
			}
		}
	}, [active]);

	console.log("globalAirports", globalAirports);

	return (
		<>
			<div className="bottom">
				<div className="actions">
					<div className="tripTypeToggle">
						<span
							className={`tripTypeLabel tripTypeLabel--${
								bigFlightData?.route === "domestic" && "selected"
							}`}
							onClick={() => updateBigFlightData({ route: "domestic" })}
						>
							Domestic
						</span>
						<ToggleSwitch
							checked={bigFlightData.route === "international"}
							handleChange={(event: ChangeEvent<HTMLInputElement>) => {
								// setVaccinated(event.target.checked);
								if (event.target.checked)
									updateBigFlightData({ route: "international" });
								else updateBigFlightData({ route: "domestic" });
							}}
						/>
						<span
							className={`tripTypeLabel tripTypeLabel--${
								bigFlightData?.route === "international" && "selected"
							}`}
							onClick={() => updateBigFlightData({ route: "international" })}
						>
							International
						</span>
					</div>
					{/* <div className="flight-type">
						<p
							className={` hover ${
								bigFlightData.route === "domestic" ? "current" : ""
							}`}
							onClick={() => {
								updateBigFlightData({ route: "domestic" });
							}}
						>
							Domestic Flight
						</p>
						<p
							className={` hover ${
								bigFlightData.route === "international" ? "current" : ""
							}`}
							onClick={() => {
								updateBigFlightData({ route: "international" });
							}}
						>
							International Flight
						</p>
					</div> */}
				</div>
				<div className="form-section">
					{bigFlightData.showTripType !== "Multicity" ? (
						<form>
							<div className="form-item">
								<p className="label">Departure</p>
								<CustomSelect
									icon={<DepartureIcon color="#9C9393" size={22} />}
									label="From"
									list={[localAirports, globalAirports]}
									listIndex={bigFlightData.route === "domestic" ? 0 : 1}
									value={bigFlightData.departure}
									onChange={(val: any) => {
										updateBigFlightData({ departure: val });
										updateBigFlightData({
											multiCity: [
												{
													departure: val,
													destination:
														bigFlightData?.multiCity?.[0]?.destination,
													departure_date:
														bigFlightData?.multiCity?.[0]?.departure_date,
												},
											],
										});

										if (!completed) setActive(2);
									}}
									active={active === 1}
									onSelect={() => setActive(1)}
									loading={
										bigFlightData.route === "domestic"
											? dropDownLoading?.localAirports
											: dropDownLoading?.intlAirports
									}
									ind={1}
								/>
							</div>
							<div className="form-item">
								<p className="label">Destination</p>
								<CustomSelect
									icon={<ArrivalIcon color="#9C9393" size={22} />}
									label="To"
									list={[localAirports, globalAirports]}
									listIndex={bigFlightData.route === "domestic" ? 0 : 1}
									value={bigFlightData.destination}
									onChange={(val: any) => {
										updateBigFlightData({ destination: val });
										updateBigFlightData({
											multiCity: [
												{
													departure: bigFlightData?.multiCity?.[0]?.departure,
													destination: val,
													departure_date:
														bigFlightData?.multiCity?.[0]?.departure_date,
												},
											],
										});
										if (!completed) setActive(3);
									}}
									active={active === 2}
									onSelect={() => setActive(2)}
									loading={
										bigFlightData.route === "domestic"
											? dropDownLoading?.localAirports
											: dropDownLoading?.intlAirports
									}
									ind={2}
								/>
							</div>

							<div className="form-item">
								<p className="label">Trip type</p>

								<CustomDropdown
									onSelect={(val: any) => {
										updateBigFlightData({ showTripType: val });

										if (val === "Multicity") {
											// setActive(1);
											setActive(4);
										} else setActive(4);
									}}
									defaultValue={bigFlightData.showTripType}
									optionsList={[
										{ label: "Roundtrip", value: "Roundtrip" },
										{
											label: "One way",
											value: "One way",
										},
										{
											label: "Multicity",
											value: "Multicity",
										},
									]}
									active={active === 3}
									ind={1}
								/>
							</div>

							<div className="form-item">
								<p className="label">Date</p>

								{bigFlightData.showTripType === "Roundtrip" ? (
									<DatePicker
										range
										className="teal"
										placeholder="departure  -  return"
										// value={[
										// 	bigFlightData.departure_date,
										// 	bigFlightData.return_date,
										// 	// ...(bigFlightData.return_date
										// 	// 	? [bigFlightData.return_date]
										// 	// 	: []),
										// ]}
										onChange={(dateObjects: DateObject[]) => {
											const dateList = getAllDatesInRange(dateObjects, true);
											console.log(
												new Date(dateList[0].toString()).toLocaleDateString(
													"sv-SE"
												),
												new Date(
													dateList[dateList.length - 1].toString()
												).toLocaleDateString("sv-SE")
											);
											console.log("dateList", dateList);

											updateBigFlightData({
												departure_date: new Date(
													dateList[0].toString()
												).toLocaleDateString("sv-SE"),
												return_date: new Date(
													dateList[dateList.length - 1].toString()
												).toLocaleDateString("sv-SE"),
											});

											if (dateList.length > 1 && !completed) setActive(5);
										}}
										onOpen={() => setActive(4)}
										onOpenPickNewDate={false}
										inputClass={`custom-rmdp-input`}
										numberOfMonths={calendarNum}
										ref={calendarRef}
										mapDays={(object: any) => {
											if (
												bigFlightData.departure_date &&
												bigFlightData.departure_date ===
													bigFlightData.return_date
											)
												return {
													disabled:
														new Date(bigFlightData.departure_date) > object.date
															? true
															: false,
												};
											else
												return {
													disabled: object.today > object.date ? true : false,
												};
										}}
										animations={[
											opacity(),
											transition({ from: 35, duration: 400 }),
										]}
									/>
								) : (
									<DatePicker
										className="teal"
										placeholder="departure"
										value={bigFlightData.departure_date}
										onChange={(dateObject: DateObject) => {
											console.log(
												new Date(dateObject.toString()).toLocaleDateString(
													"sv-SE"
												)
											);

											updateBigFlightData({
												departure_date: new Date(
													dateObject.toString()
												).toLocaleDateString("sv-SE"),
												return_date: "",
											});
											if (!completed) setActive(5);
										}}
										onOpen={() => setActive(4)}
										onOpenPickNewDate={false}
										inputClass="custom-rmdp-input"
										numberOfMonths={calendarNum}
										ref={calendarRef}
										mapDays={(object: any) => {
											return {
												disabled: object.today > object.date ? true : false,
											};
										}}
										animations={[
											opacity(),
											transition({ from: 35, duration: 400 }),
										]}
									/>
								)}
							</div>
							<div className="form-item" style={{ minWidth: "174px" }}>
								<p className="label">Class</p>

								<CustomDropdown
									onSelect={(val: any) => {
										updateBigFlightData({ cabin: val });

										if (bigFlightData?.showTripType === "Multicity") {
										} else setActive(6);

										setCompleted(true);
									}}
									defaultValue={bigFlightData.cabin}
									optionsList={[
										{ label: "Economy", value: "economy" },
										{
											label: "Premium Economy",
											value:
												bigFlightData.route === "domestic"
													? "premium_economy"
													: "Premium",
										},
										{
											label: "Business",
											value: "business",
										},
										{ label: "First", value: "first" },
									]}
									active={active === 5}
									ind={0}
								/>
							</div>
						</form>
					) : (
						<span />
					)}
					{bigFlightData.showTripType === "Multicity" ? (
						<div className="multi-trip">
							{bigFlightData?.multiCity?.map((_: any, index: number) => (
								<form key={index}>
									<div className="form-item">
										<p className="label">Departure</p>
										<CustomSelect
											icon={<DepartureIcon color="#9C9393" size={22} />}
											label="From"
											list={[localAirports, globalAirports]}
											listIndex={bigFlightData.route === "domestic" ? 0 : 1}
											value={bigFlightData.multiCity[index].departure}
											onChange={(val: any) => {
												const newArr = bigFlightData.multiCity;
												newArr[index].departure = val;
												updateBigFlightData({ multiCity: newArr });
												// if (!completed)
												setActive(
													index === 0
														? 3 * (index + 1) - 1
														: 3 * (index + 1) + 1
												);
											}}
											active={
												active ===
												(index === 0 ? 3 * (index + 1) - 2 : 3 * (index + 1))
											}
											onSelect={() =>
												setActive(
													index === 0 ? 3 * (index + 1) - 2 : 3 * (index + 1)
												)
											}
											loading={
												bigFlightData.route === "domestic"
													? dropDownLoading?.localAirports
													: dropDownLoading?.intlAirports
											}
											ind={3}
										/>
									</div>
									<div className="form-item">
										<p className="label">Destination</p>
										<CustomSelect
											icon={<ArrivalIcon color="#9C9393" size={22} />}
											label="To"
											list={[localAirports, globalAirports]}
											listIndex={bigFlightData.route === "domestic" ? 0 : 1}
											value={bigFlightData.multiCity[index].destination}
											onChange={(val: any) => {
												const newArr = bigFlightData.multiCity;
												newArr[index].destination = val;
												updateBigFlightData({ multiCity: newArr });
												// if (!completed)
												setActive(
													index === 0
														? 3 * (index + 1) + 1
														: 3 * (index + 1) + 2
												);
											}}
											active={
												active ===
												(index === 0
													? 3 * (index + 1) - 1
													: 3 * (index + 1) + 1)
											}
											onSelect={() =>
												setActive(
													index === 0
														? 3 * (index + 1) - 1
														: 3 * (index + 1) + 1
												)
											}
											loading={
												bigFlightData.route === "domestic"
													? dropDownLoading?.localAirports
													: dropDownLoading?.intlAirports
											}
											ind={4}
										/>
									</div>

									<div className="dateTypeWrap">
										{index === 0 && (
											<div className="form-item">
												<p className="label">Trip type</p>

												<CustomDropdown
													onSelect={(val: any) => {
														updateBigFlightData({ showTripType: val });
														// setActive(4);
														setActive(1);
													}}
													defaultValue={bigFlightData.showTripType}
													optionsList={[
														{ label: "Roundtrip", value: "Roundtrip" },
														{
															label: "One way",
															value: "One way",
														},
														{
															label: "Multicity",
															value: "Multicity",
														},
													]}
													active={active === 3}
													ind={1}
												/>
											</div>
										)}

										<div className="form-item">
											<p className="label">Date</p>
											<DatePicker
												className="teal"
												placeholder="departure"
												value={bigFlightData.multiCity[index].departure_date}
												onChange={(dateObject: DateObject) => {
													console.log(
														"dateeee",
														new Date(dateObject.toString())
													);
													const newArr = bigFlightData.multiCity;
													newArr[index].departure_date = new Date(
														dateObject.toString()
													).toLocaleDateString("sv-SE");

													updateBigFlightData({ multiCity: newArr });

													if (bigFlightData?.showTripType === "Multicity") {
													} else
														setActive(
															index === 0
																? 3 * (index + 1) + 2
																: 3 * (index + 1) + 3
														);
												}}
												onOpen={() => {
													setActive(
														index === 0
															? 3 * (index + 1) + 1
															: 3 * (index + 1) + 2
													);
												}}
												onOpenPickNewDate={false}
												inputClass="custom-rmdp-input"
												numberOfMonths={calendarNum}
												ref={eval(`calendarRef${index + 1}`)}
												mapDays={(object: any) => {
													return {
														disabled: object.today > object.date ? true : false,
													};
												}}
												animations={[
													opacity(),
													transition({ from: 35, duration: 400 }),
												]}
											/>

											{index > 0 ? (
												<CgClose
													className="del-trip"
													onClick={() => decreaseCount()}
												/>
											) : (
												<span />
											)}
										</div>
									</div>

									{index === 0 ? (
										<div className="form-item" style={{ minWidth: "174px" }}>
											<p className="label">Class</p>

											<CustomDropdown
												onSelect={(val: any) => {
													updateBigFlightData({ cabin: val });
													if (bigFlightData?.showTripType === "Multicity") {
													} else setActive(6);
													// if (!completed) setActive(5);
													// setCompleted(true);
												}}
												defaultValue={bigFlightData.cabin}
												optionsList={[
													{ label: "Economy", value: "economy" },
													{
														label: "Premium Economy",
														value:
															bigFlightData.route === "domestic"
																? "premium_economy"
																: "Premium",
													},
													{
														label: "Business",
														value: "business",
													},
													{ label: "First", value: "first" },
												]}
												active={active === 5}
												ind={1}
											/>
										</div>
									) : (
										<span style={{ minWidth: "174px" }} />
									)}
								</form>
							))}

							<div className="multi-actions">
								{bigFlightData?.multiCity?.length < 5 && (
									<p
										onClick={() => {
											increaseCount();
										}}
										className="add-trip hover"
									>
										+ add another flight
									</p>
								)}
								{bigFlightData?.multiCity?.length > 2 && (
									<p onClick={() => decreaseCount()} className="cancel">
										Cancel
									</p>
								)}
							</div>
						</div>
					) : (
						<></>
					)}
				</div>

				<div className="selectAndButton">
					<div className="choose-travellers">
						<p>Select up to 5 travelers</p>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								// width: "100%",
								boxSizing: "border-box",
							}}
						>
							<div
								className="choose-div hover"
								onClick={() => {
									setShowTraveller(true);
								}}
							>
								<img src={choosesvg} alt="choose" />
								<p>Choose</p>
							</div>
							{bigFlightData.selectedTraveller?.length === 5 ? (
								<p
									className="new-travellers hover"
									onClick={() => {
										// setAddNewTraveller(true);
										// setShowTraveller(true);
									}}
									style={{ color: " #9c9393" }}
								>
									+ Add new traveller
								</p>
							) : (
								<p
									className="new-travellers hover"
									onClick={() => {
										setAddNewTraveller(true);
										// setShowTraveller(true);
									}}
								>
									+ Add new traveller
								</p>
							)}
						</div>
					</div>

					<div className="button-container">
						<div className="email-request">
							<input
								type="checkbox"
								// checked={emailRequest}
								// onChange={() => setEmailRequest(!emailRequest)}
								checked={directFlight}
								onChange={() => setDirectFlight((prev) => !prev)}
							/>
							<p>
								Direct Flight{" "}
								<CustomTooltip>
									Get flight results with no layovers.
								</CustomTooltip>
							</p>
							{/* <p>
Need a travel agent to assist with your request? Select this
button and we will respond in 5 minutes with options.
</p> */}
						</div>
						<Button
							className="pry"
							onClick={(e: any) => {
								if (bigFlightData.selectedTraveller.length === 0)
									toast.error("Please select at least one traveller.");
								else if (
									!bigFlightData.departure &&
									!bigFlightData.multiCity[0].departure
								)
									toast.error("Please select a departure city.");
								else if (
									!bigFlightData.destination &&
									!bigFlightData.multiCity[0].destination
								)
									toast.error("Please select a destination.");
								else if (
									!bigFlightData.departure_date &&
									!bigFlightData.return_date &&
									!bigFlightData.multiCity[0].departure_date
								)
									toast.error(
										`Please select a ${
											bigFlightData.showTripType === "Roundtrip"
												? "range of dates"
												: "date"
										}.`
									);
								else searchFlights();
							}}
						>
							{searchLoading ? <LoadingAnimation /> : "Find Flights"}
						</Button>
					</div>
				</div>
			</div>
			<div className="top-wrapper">
				<div className="top">
					<div className="travellers" style={{ boxSizing: "border-box" }}>
						{bigFlightData.selectedTraveller?.length > 0 && (
							<>
								<p className="mob-none">Travellers</p>

								<div className="travellers-block">
									{bigFlightData.selectedTraveller?.map(
										(item: any, index: any) => {
											let { first_name, middle_name, last_name } = item;
											let nameArray = first_name.split(" ");
											console.log(bigFlightData.selectedTraveller);
											return (
												<div key={index} className="traveller-item">
													<div className="intitialsImg">
														{first_name?.charAt(0)?.toUpperCase()}
														{last_name.charAt(0).toUpperCase()}
													</div>
													<p>{`${first_name} ${middle_name} ${last_name}`}</p>
													<CgClose
														size={12}
														className="close-icon"
														strokeWidth={2}
														onClick={() => {
															setTempSelected(
																[...tempSelected].filter(
																	(travellerItem) =>
																		travellerItem?.id !== item?.id
																)
															);

															props.setSelectedTraveller(
																[...bigFlightData.selectedTraveller].filter(
																	(travellerItem) =>
																		travellerItem?.id !== item?.id
																)
															);
														}}
													/>
												</div>
											);
										}
									)}
								</div>
							</>
						)}
					</div>
				</div>
			</div>
			{document.documentElement.clientWidth <= 780 && (
				<Drawer
					anchor="top"
					open={showTraveller}
					onClose={toggleSideDrawer(false, setShowTraveller)}
					// onOpen={toggleSideDrawer(true, setShowTraveller)}
				>
					<SelectTravellerModal
						setActive={completed ? (num: number) => {} : setActive}
						handleClose={() => setShowTraveller(false)}
						setAddNewTraveller={setAddNewTraveller}
						setShowTraveller={setShowTraveller}
						handleSelectedTravellers={handleSelectedTravellers}
						tempSelected={tempSelected}
						setTempSelected={setTempSelected}
					/>
				</Drawer>
			)}
			{document.documentElement.clientWidth > 780 && (
				<MUIModal
					open={showTraveller}
					handleClose={() => setShowTraveller(false)}
					sticky={true}
				>
					<SelectTravellerModal
						setActive={completed ? (num: number) => {} : setActive}
						handleClose={() => setShowTraveller(false)}
						setAddNewTraveller={setAddNewTraveller}
						setShowTraveller={setShowTraveller}
						handleSelectedTravellers={handleSelectedTravellers}
						tempSelected={tempSelected}
						setTempSelected={setTempSelected}
					/>
				</MUIModal>
			)}

			{document.documentElement.clientWidth > 780 && (
				<MUIModal
					open={addNewTraveller}
					handleClose={() => setAddNewTraveller(false)}
					// sticky={true}
					paddingTop="120px"
				>
					<AddNewTravellerModalRewrite
						setSelectedTraveller={props.setSelectedTraveller}
						handleClose={() => setAddNewTraveller(false)}
					/>
				</MUIModal>
			)}

			{document.documentElement.clientWidth <= 780 && (
				<Drawer
					anchor="top"
					open={addNewTraveller}
					onClose={toggleSideDrawer(false, setAddNewTraveller)}
					// onOpen={toggleSideDrawer(true, setShowTraveller)}
				>
					<AddNewTravellerModalRewrite
						setSelectedTraveller={props.setSelectedTraveller}
						handleClose={() => setAddNewTraveller(false)}
					/>
				</Drawer>
			)}
		</>
	);
};

export default Flights;
