const RentalInfoDrawer = () => {
	return (
		<div className="RentalInfoDrawer">
			<div className="RentalInfoDrawer__pt1">
				<h3>Rental Conditions</h3>
				<ul>
					<li>Please note that our working period is between 6am –6pm.</li>
					<li>Normal Overtime Rate (7pm–12midnight) - N2000 perhour</li>
					<li>Abnormal Overtime Rate (12midnight— 6am) - N3000 perhour</li>
					<li>Toll/Parking/Airport ticket is excluded</li>
					<li>Outstation allowance </li>
					<li>Travel Allowance - N6,000 per day</li>
					<li>Public holiday allowance - N5,000 perday</li>
					<li>Weekend Allowance - N3,500 perday</li>
				</ul>
			</div>
			<div className="RentalInfoDrawer__pt2">
				<h3>Cancellation Policy:</h3>
				<p>
					Any Cancellation done before 24hrs of the itinerary start date on a
					confirmed reservation (note - confirmation relates to a paid car
					service booking using either of our payment options) will be refunded
					as a credit and reflected on the user's credit balance.
				</p>
				<p>
					Any Cancellation done Less than 24hrs of the itinerary start date on a
					confirmed booking (note – confirmation relates to a paid car service
					booking using either of our payment options) will have the value of up
					to the current day's car service rate withheld as a cancellation
					penalty, while any other confirmed days (if available, will be
					refunded as a credit and reflected on the user's credit balance).
				</p>
			</div>
		</div>
	);
};

export default RentalInfoDrawer;
