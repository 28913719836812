import { memo, useEffect, useRef, useState, useMemo } from "react";
import { Menu, MenuButton, MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import { PlaneSelectIcon } from "../pieces/svgs";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import "./style.scss";
import useOutsideAlerter from "../../hooks/useOutsideAlerter";
import { focusAndOpenKeyboard } from "../../helpers/helperFuncs";
import PortLoader from "../PortLoader";

interface CustomSelectProps {
	// placeholder?: string;
	icon: JSX.Element;
	label?: string;
	list: any[];
	listIndex: number;
	value: string;
	onSelect?: () => void;
	onChange: (val: any) => void;
	active?: boolean;
	loading?: boolean;
	ind: number;
}

export const CustomSelect = memo(
	({
		icon,
		label,
		list,
		listIndex,
		value,
		onSelect,
		active,
		onChange,
		loading = false,
		ind = 0,
	}: CustomSelectProps) => {
		const [tempList, setTempList] = useState(list[listIndex] ?? []);
		const [menuOpen, setMenuOpen] = useState(false);
		const [typeActive, setTypeActive] = useState(false);
		const [typedText, setTypedText] = useState("");
		const [city, setCity] = useState(value ? value : "");

		const wrapperRef1 = useRef(null);
		const wrapperRef2 = useRef(null);
		const wrapperRef3 = useRef(null);
		const wrapperRef4 = useRef(null);
		const wrapperRef5 = useRef(null);
		const wrapperRef6 = useRef(null);

		//input refs. Might need to create more if there's more than 6 on the page
		const inputRef1 = useRef<HTMLInputElement>(null);
		const inputRef2 = useRef<HTMLInputElement>(null);
		const inputRef3 = useRef<HTMLInputElement>(null);
		const inputRef4 = useRef<HTMLInputElement>(null);
		const inputRef5 = useRef<HTMLInputElement>(null);
		const inputRef6 = useRef<HTMLInputElement>(null);

		useOutsideAlerter(eval(`wrapperRef${ind}`), () => {
			setTypeActive(false);
			setMenuOpen(false);
		});

		useMemo(() => {
			if (typedText !== "")
				setTempList(
					list[listIndex]?.filter(
						(item: any) =>
							item?.iata_code
								?.toLowerCase()
								?.startsWith(typedText?.toLowerCase()) ||
							item?.city?.toLowerCase()?.startsWith(typedText?.toLowerCase()) ||
							item?.name?.toLowerCase()?.startsWith(typedText?.toLowerCase())
					)
				);
		}, [typedText]);

		useMemo(() => {
			if (active) {
				setMenuOpen(true);
				setTypeActive(true);

				setTimeout(() => {
					if (eval(`inputRef${ind}`).current) {
						eval(`inputRef${ind}`).current?.focus();
					}
				}, 500);
			}
		}, [active]);

		useMemo(() => {
			setTempList([]);
			setTypedText("");
		}, [listIndex]);

		console.log("typeActive", typeActive);

		return (
			<div className="menu" ref={eval(`wrapperRef${ind}`)}>
				<button
					className="menu-button"
					type="button"
					onClick={(e) => {
						onSelect && onSelect();
						setMenuOpen(true);
						setTypeActive(true);
						setTimeout(() => {
							if (eval(`inputRef${ind}`).current) {
								// eval(`inputRef${ind}`).current?.click();
							}
						}, 500);
					}}
				>
					{icon && icon}

					<input
						type="text"
						value={typeActive ? typedText : city || label}
						onChange={(ev) => setTypedText(ev.target.value)}
						ref={eval(`inputRef${ind}`)}
						id="real-input"
						style={{
							color: typeActive ? "#000000" : city ? "#000000" : "#9c9393",
						}}
						// onBlur={() => {
						//     setTypeActive(false);
						// }}
						// onClick={(e) => (e.target as HTMLInputElement).focus()}
					/>
				</button>
				{tempList?.length !== 0 && (
					<div className={`menuList ${!menuOpen && "menuList--shut"}`}>
						{loading ? (
							<>
								<PortLoader />
							</>
						) : (
							tempList?.map((option: any, ind: number) => (
								<div
									className="menuList__item"
									// value={option?.iata_code}
									onClick={(e) => {
										onChange(option?.iata_code);
										setCity(option?.city);
										console.log(`[MenuItem] ${option?.iata_code} clicked`);
										setTypeActive(false);
										setMenuOpen(false);
										setTypedText("");
									}}
									key={ind}
								>
									<div className="ls-item">
										<PlaneSelectIcon />
										<div className="space-between">
											<div>
												<p className="airport">{option?.name}</p>
												<p className="city">{option?.city}</p>
											</div>
											<p className="code">{option?.iata_code}</p>
										</div>
									</div>
								</div>
							))
						)}
					</div>
				)}
			</div>
		);
	}
);
