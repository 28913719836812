import React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Button from "../../components/Button";
import { MailIcon } from "../../components/pieces/svgs";
import { BiSearch } from "react-icons/bi";
import "./style.scss";
import ComingSoon from "../ComingSoon";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { fetchTripHistorySummary } from "../../requests/trip-history";
import { DateTime } from "ts-luxon";
import SpinLoader from "../../components/SpinLoader";
import TripInfoDrawer from "./TripInfoDrawer";
import useDebounce from "../../hooks/useDebounce";
import LoadMore from "../../components/LoadMore";
import useLoadMore from "../../hooks/useLoadMore";

const TripHistory = () => {
	const navigate = useNavigate();

	const [searchParams, setSearchParams] = useSearchParams();

	const [activeTab, setActiveTab] = useState(0);
	const [openIndex, setOpenIndex] = useState<number | null>(null);
	const [loading, setLoading] = useState(false);
	const [refetch, setRefetch] = useState(false);
	const [allTrips, setAllTrips] = useState([]);

	const tabList = [
		{
			name: "All",
			tripList: allTrips,
			emptyPage: {
				title: "No Booking has been made yet",
				desc: "Your trips will appear here once you make a booking. Ready to start flying?",
			},
		},
		{
			name: "Upcoming Trips",
			tripList: allTrips?.filter((item: any) => item?.status === "upcoming"),
			emptyPage: {
				title: "You don’t have any upcoming trips",
				desc: "Your upcoming trips will appear here when you make a booking.",
			},
		},
		{
			name: "Past Trips",
			tripList: allTrips?.filter((item: any) => item?.status === "completed"),
			emptyPage: {
				title: "You don’t have any past trips",
				desc: "Your past trips will appear here when you make a booking.",
			},
		},
		{
			name: "Open Tickets",
			tripList: allTrips?.filter((item: any) => item?.status === "open"),
			emptyPage: {
				title: "You don’t have any Open tickets",
				desc: "Your open tickets will appear here when you open a booking.",
			},
		},
		{
			name: "Pending Tickets",
			tripList: allTrips?.filter((item: any) => item?.any_process === true),
			emptyPage: {
				title: "You don’t have any Open tickets",
				desc: "Your open tickets will appear here when you open a booking.",
			},
		},
	];

	const [tempList, setTempList] = useState(tabList[activeTab].tripList);

	let limit = 5;

	let next: any, currentData: any;
	let maxedOut: boolean;
	let reset: () => void;

	({ currentData, next, maxedOut, reset } = useLoadMore(tempList, limit));

	useEffect(() => {
		const fetchTripHistoryList = async () => {
			setLoading(true);
			try {
				const res = await fetchTripHistorySummary();
				console.log("res", res);
				console.log("fetchTripHistory", res.data?.data);
				if (res.data?.message === "success") {
					setAllTrips(res.data?.data);
				}
			} catch (error) {
				console.log(error);
			} finally {
				setLoading(false);
			}
		};

		fetchTripHistoryList();
		reset();
	}, [refetch]);

	const debouncedSearch = useDebounce(searchParams.get("query") ?? "", 500);

	useEffect(() => {
		setTempList(tabList[activeTab].tripList);
	}, [loading, activeTab]);

	useEffect(() => {
		if (activeTab) setSearchParams({ query: "" });
		setOpenIndex(null);
		reset();
	}, [activeTab]);

	useEffect(() => {
		/*
			allows you to search by:
				- Passenger's first Name
				- Passenger's middle Name
				- Passenger's lase Name
				- Origin city (e.g. Lagos)
				- Destination city (e.g Abuja)
				- Origin (e.g. LOS)
				- Destination (e.g. ABV)
				- Kardinal Reference
				- Airline Name

			PS: search is NOT case sensitive. Use whatever case pleases you
		*/

		if (debouncedSearch === "") setTempList(tabList[activeTab].tripList);
		else
			setTempList(
				tabList[activeTab].tripList.filter((item: any) => {
					let firstNameList = item?.passengers?.map((passengerObj: any) =>
						passengerObj?.first_name?.toLowerCase()
					);
					let middleNameList = item?.passengers?.map((passengerObj: any) =>
						passengerObj?.middle_name?.toLowerCase()
					);
					let lastNameList = item?.passengers?.map((passengerObj: any) =>
						passengerObj?.last_name?.toLowerCase()
					);

					return (
						item?.origin_city
							?.toLowerCase()
							?.startsWith(debouncedSearch.toLowerCase()) ||
						item?.destination_city
							?.toLowerCase()
							?.startsWith(debouncedSearch.toLowerCase()) ||
						item?.kardinal_reference
							?.toLowerCase()
							?.startsWith(debouncedSearch.toLowerCase()) ||
						item?.airline_name
							?.toLowerCase()
							?.startsWith(debouncedSearch.toLowerCase()) ||
						firstNameList?.filter((fName: any) =>
							fName.startsWith(debouncedSearch.toLowerCase())
						)?.length > 0 ||
						middleNameList?.filter((mName: any) =>
							mName.startsWith(debouncedSearch.toLowerCase())
						)?.length > 0 ||
						lastNameList?.filter((lName: any) =>
							lName.startsWith(debouncedSearch.toLowerCase())
						)?.length > 0
					);
				})
			);
	}, [debouncedSearch, activeTab, loading]);

	console.log("tempList", tempList);
	console.log("debouncedSearch", searchParams.get("querySSS"));

	return (
		<div className="TripHistory">
			<div className="big-title">
				<h4>Trip History</h4>
				{tabList[activeTab].tripList?.length > 0 ? (
					<Button className="pry" onClick={() => navigate("/trips")}>
						New Trip
					</Button>
				) : (
					<div />
				)}
				<div className="desk-search">
					<BiSearch style={{ color: "#C4C4C4", margin: "5px 0" }} />
					<input
						type="text"
						name=""
						id=""
						placeholder="Search trips, travellers or location"
						value={searchParams.get("query") as string}
						onChange={(e) => {
							setSearchParams({ query: e.target.value });
						}}
					/>
				</div>
			</div>
			<div className="mobile-search">
				<BiSearch style={{ color: "#C4C4C4", margin: "5px 0" }} />
				<input
					type="text"
					name=""
					id=""
					placeholder="Search trips, travellers or location"
					value={searchParams.get("query") as string}
					onChange={(e) => {
						setSearchParams({ query: e.target.value });
					}}
				/>
			</div>
			<div className="TripHistory__tabSection">
				<div className="TripHistory__tabs">
					{tabList.map((tabItem, ind) => (
						<p
							className={`tab ${activeTab === ind && "tab--active"}`}
							key={ind}
							onClick={() => setActiveTab(ind)}
						>
							{tabItem.name} ({tabItem.tripList.length})
						</p>
					))}
				</div>
				<div>
					{tabList[activeTab].tripList?.length > 0 ? (
						<Button className="pry" onClick={() => navigate("/trips")}>
							Start New Trip
						</Button>
					) : (
						<div />
					)}
				</div>
			</div>
			<div className="TripHistory__tabSectionMobile mobile-hide">
				<div className="TripHistory__tabs__tabsMobile">
					<Select
						value={tabList[activeTab].name}
						className="mobileDropDown"
						sx={{
							".MuiOutlinedInput-notchedOutline": {
								border: 0,
							},
						}}
					>
						{tabList.map((tabItem, ind) => (
							<MenuItem
								value={tabItem.name}
								className={`tab ${activeTab === ind && "tab--active"}`}
								key={ind}
								onClick={() => setActiveTab(ind)}
							>
								{tabItem.name} ({tabItem.tripList.length}){" "}
							</MenuItem>
						))}
					</Select>
				</div>
			</div>

			{loading ? (
				<SpinLoader height="150" />
			) : (
				<div>
					{tabList[activeTab].tripList?.length > 0 ? (
						tempList?.length > 0 ? (
							currentData()?.map((tripItem: any, ind: number) => (
								<TripInfoDrawer
									data={tripItem}
									key={ind}
									refetch={refetch}
									setRefetch={setRefetch}
									ind={ind}
									openIndex={openIndex}
									setOpenIndex={setOpenIndex}
								/>
							))
						) : (
							<HistoryEmptyState
								title={"No results found"}
								desc={
									"Try adjusting your search or filter to find what you're looking for."
								}
							/>
						)
					) : (
						<>
							<HistoryEmptyState
								title={tabList[activeTab].emptyPage.title}
								desc={tabList[activeTab].emptyPage.desc}
							/>
						</>
					)}
				</div>
			)}
			{tempList?.length > limit && (
				<div className="TripHistory__LoadMore">
					<LoadMore
						loadFunc={() => {
							setOpenIndex(null);
							next();
						}}
						disabled={maxedOut}
					/>
				</div>
			)}
		</div>
	);
};

export default TripHistory;

interface HistoryEmptyStateProps {
	title: String;
	desc: string;
}

export const HistoryEmptyState = ({ title, desc }: HistoryEmptyStateProps) => {
	const navigate = useNavigate();

	return (
		<div className="HistoryEmptyState">
			<div className="HistoryEmptyState__main">
				<p className="title">{title}</p>
				<p className="desc">{desc}</p>
				<Button className="pry" onClick={() => navigate("/trips")}>
					Start New Trip
				</Button>
			</div>
			<div className="HistoryEmptyState__email-request">
				<div>
					<div className="title">
						<MailIcon />
						<p>Email request?</p>
					</div>
					<p>
						Send your flight, hotel, car and other external corporate requests
						to{" "}
						<a
							href="mailto:booking@gokardinal.com"
							target="_blank"
							rel="noreferrer"
						>
							booking@gokardinal.com
						</a>
					</p>
				</div>
			</div>
		</div>
	);
};
