import registerTick from "../resource/img/register-tick.svg";
import planeglobe from "../resource/img/planeglobe.svg";
import "./layouts.scss";
import bg from "../resource/img/Onboarding-background.svg";

interface AuthLayoutProps {
	title: string;
	desc: string;
	children: JSX.Element;
}

const AuthLayout = ({ title, desc, children }: AuthLayoutProps) => (
	<div className="AuthLayout">
		<div className="AuthLayout__body">
			<div className="AuthCard">
				<div className="AuthCard__left">
					<div className="up-left">
						<h4>{title}</h4>
						<div className="left-check">
							<img src={registerTick} alt="register-tick" />
							<p>{desc}</p>
						</div>
					</div>
					<div className="down-left">
						<img src={planeglobe} alt="globe" />
					</div>
				</div>
				<div className="AuthCard__right">{children}</div>
			</div>
		</div>
		<img src={bg} alt="" className="half-bg" />
	</div>
);

export default AuthLayout;
