import { useRef, useState } from "react";
import useOutsideAlerter from "../../hooks/useOutsideAlerter";
import Button from "../Button";
import "./style.scss";

interface ButtonDropdownProps {
	label: string;
	optionList: { label: { title: string; desc?: string }; action: () => void }[];
	disabled?: boolean;
	ind?: number; // allows us to use multiple instances without glitching
	// openAction?: () => void;
}

const ButtonDropdown = ({
	label,
	optionList,
	disabled = false,
	ind = 0,
}: // openAction,
ButtonDropdownProps) => {
	const [open, setOpen] = useState(false);

	const wrapperRef = useRef<HTMLDivElement>(null);

	useOutsideAlerter(wrapperRef, () => {
		setOpen(false);
	});

	return (
		<div className="ButtonDropdown" ref={wrapperRef}>
			<Button
				className={`ButtonDropdown__button ButtonDropdown__button--${
					disabled ? "inActive" : "active"
				}`}
				onClick={() => {
					if (!disabled) setOpen((isOpen) => !isOpen);
				}}
			>
				{label}
			</Button>
			<div
				className={`ButtonDropdown__drop ButtonDropdown__drop--${
					open && "open"
				}`}
			>
				{optionList.map((item) => (
					<div
						className="ButtonDropdown__drop__item"
						onClick={() => {
							item.action();
							setOpen(false);
						}}
					>
						<p className="ButtonDropdown__drop__item__title">
							{item.label.title}
						</p>
						{item.label?.desc && (
							<p className="ButtonDropdown__drop__item__desc">
								{item.label.desc}
							</p>
						)}
					</div>
				))}
			</div>
		</div>
	);
};

export default ButtonDropdown;
