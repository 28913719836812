import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

const getOnlineStatus = (): boolean => {
	return typeof navigator !== undefined && typeof navigator.onLine === "boolean"
		? navigator.onLine
		: true;
};

const useNavigatorOnline = (
	onlineFunc: () => void,
	offlineFunc: () => void
) => {
	const [status, setStatus] = useState<boolean>(getOnlineStatus());
	const location = useLocation();

	const setOnline = () => {
		setStatus(true);
		onlineFunc();
		setTimeout(() => {
			// window.location.reload();
		}, 1000);
	};
	const setOffline = () => {
		setStatus(false);
		offlineFunc();
	};

	useEffect(() => {
		window.addEventListener("online", setOnline);
		window.addEventListener("offline", setOffline);

		return () => {
			window.removeEventListener("online", setOnline);
			window.removeEventListener("offline", setOffline);
		};
	}, []);

	useEffect(() => {
		if (!status) offlineFunc();
	}, [location]);

	return status;
};

export default useNavigatorOnline;
