import { useState, useRef, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import "./style.scss";
import { BiSearch } from "react-icons/bi";
import MUIModal from "../../components/MUIModal";
import { SuccessfulModal } from "../../modals";
import ComingSoon from "../ComingSoon";
import {
	DownArrowIcon,
	DownloadIcon,
	EditIcon,
	FilterIcon,
	FlightIcon,
	HotelIcon,
	OutstandingComponentIcon,
	TripIcon,
	UpArrowIcon,
} from "../../components/pieces/svgs";
import { ReactComponent as PhWarningIcon } from "../../assets/svgs/ph_warning-bold.svg";
import Button from "../../components/Button";
import { MenuItem, Select } from "@mui/material";
import {
	bulkSettleOverdue,
	bulkSettleUnpaid,
	fetchInvoices,
	fetchInvoiceSummary,
	fetchInvoicingActivities,
	generateInvoice,
	settleInvoice,
	updateInvoiceGenerationPreference,
} from "../../requests";
import { DateTime } from "ts-luxon";
import {
	addPaystackCharges,
	capitalizeFirstLetter,
	formatDateRange,
	itineraryExpandCities,
	parseItinerary,
} from "../../helpers/helperFuncs";
import { useTripStore } from "../../zustand/store";
import SpinLoader from "../../components/SpinLoader";
import { toast } from "react-toastify";
import ButtonDropdown from "../../components/ButtonDropdown";
import { usePaystackPayment } from "react-paystack";
import LoadingModalsDebacle from "../../components/LoadingModalsDebacle";
import InvoiceFilterComponent from "./InvoiceFilterComponent";
import useDebounce from "../../hooks/useDebounce";
import { useStateWithCallback } from "../../hooks/useStateWithCallback";

const InvoicingPage = () => {
	const filterList = [
		{ label: "All", value: "all" },
		{ label: "Paid", value: "paid" },
		{ label: "Unpaid", value: "unpaid" },
		{ label: "Overdue", value: "overdue" },
	];

	//zustand
	const globalAirports = useTripStore((state: any) => state.globalAirports);
	const dropDownLoading = useTripStore((state: any) => state.dropDownLoading);
	const userData = useTripStore((state: any) => state.userData);

	const [activeTab, setActiveTab] = useState(0);
	const [amountToPay, setAmountToPay] = useState(0);
	const [refetch, setRefetch] = useState(false);
	const [loading, setLoading] = useState(false);
	const [generateLoading, setGenerateLoading] = useState(false);
	const [generateAndPayLoading, setGenerateAndPayLoading] = useState(false);
	const [settleLoading, setSettleLoading] = useState(false);
	const [settleOneLoading, setSettleOneLoading] = useState(false);
	const [successLoading, setSuccessLoading] = useState(false);
	const [openSettle, setOpenSettle] = useState(false);
	const [openOverdue, setOpenOverdue] = useState(false);
	const [openUnpaid, setOpenUnpaid] = useState(false);
	const [openPreferences, setOpenPreferences] = useState(false);
	const [selectedFilter, setSelectedFilter] = useState(filterList[0].value);
	const [searchString, setSearchString] = useState("");
	const [bulkType, setBulkType] = useStateWithCallback<"overdue" | "unpaid">(
		"overdue"
	);

	const [invoiceSummary, setInvoiceSummary] = useState<{
		overdue: { total_amount: number | null; count: number | null };
		unpaid: { total_amount: number | null; count: number | null };
	}>({
		overdue: { total_amount: 0, count: 0 },
		unpaid: { total_amount: 0, count: 0 },
	});
	const [activityList, setActivityList] = useState([]);
	const [invoiceList, setInvoiceList] = useState([]);
	const [filteredInvoiceList, setFilteredInvoiceList] = useState([]);
	const [tempInvoiceList, setTempInvoiceList] = useState([]);
	const [selectedActivities, setSelectedActivities] = useState<number[]>([]);
	const [isOpen, setIsOpen] = useState(true);

	const navigate = useNavigate();

	const debouncedSearch = useDebounce(searchString, 500);

	const tabList: {
		name: string;
		headers: any;
		headers_mobile: any;
		tableBody: any;
	}[] = [
		{
			name: "Invoice",
			headers: [
				{ label: "Date", key: "date" },
				{ label: "Invoice ID", key: "invoice_id" },
				{ label: "Amount", key: "amount" },
				{ label: "Status", key: "status" },
				{ label: "Action", key: "Action" },
			],
			headers_mobile: [
				{ label: "Invoice details", key: "invoice_details" },
				{ label: "Amount", key: "amount" },
			],
			tableBody: tempInvoiceList,
		},
		{
			name: "Activities",
			headers: [
				{ label: "Date", key: "date" },
				{ label: "Name of traveller", key: "name_of_traveller" },
				{ label: "Route", key: "route" },
				{ label: "Type", key: "type" },
				{ label: "Reference", key: "reference" },
				{ label: "Amount", key: "amount" },
			],
			headers_mobile: [
				{ label: "Activity details", key: "activity_details" },
				{ label: "Amount", key: "amount" },
			],
			tableBody: activityList,
		},
	];

	useEffect(() => {
		const fetchFunc = async () => {
			setLoading(true);
			try {
				const res1 = await fetchInvoicingActivities();
				setActivityList(res1.data);

				const res2 = await fetchInvoices();
				setInvoiceList(res2.data);

				const res3 = await fetchInvoiceSummary();
				console.log("res3", res3);
				setInvoiceSummary(res3.data);
			} catch (error) {
				console.log(error);
			} finally {
				setLoading(false);
			}
		};

		fetchFunc();
	}, [refetch]);

	useEffect(() => {
		console.log(
			"selectedFilter",
			invoiceList.filter((item: any) => item?.status === selectedFilter)
		);
		if (!loading)
			if (selectedFilter === "all") setFilteredInvoiceList(invoiceList);
			else
				setFilteredInvoiceList(
					invoiceList.filter((item: any) => item?.status === selectedFilter)
				);
	}, [selectedFilter, loading]);

	useEffect(() => {
		/*
			allows you to search by:
				- Passenger's first Name
				- Passenger's middle Name
				- Passenger's lase Name
				- Origin city (e.g. Lagos)
				- Destination city (e.g Abuja)
				- Origin (e.g. LOS)
				- Destination (e.g. ABV)
				- Invoice id
				- Kardinal Reference
				- Airline Name
				- Itenary/Route (e.g LOS-ABV-LOS)

			PS: search is NOT case sensitive. Use whatever case pleases you
		*/

		if (filteredInvoiceList.length > 0)
			if (debouncedSearch === "") setTempInvoiceList(filteredInvoiceList);
			else
				setTempInvoiceList(
					filteredInvoiceList.filter((item: any) => {
						let firstNameList = item?.activities?.map((activityObj: any) =>
							activityObj?.traveler?.first_name?.toLowerCase()
						);
						let middleNameList = item?.activities?.map((activityObj: any) =>
							activityObj?.traveler?.middle_name?.toLowerCase()
						);
						let lastNameList = item?.activities?.map((activityObj: any) =>
							activityObj?.traveler?.last_name?.toLowerCase()
						);
						let routeList = item?.activities?.map((activityObj: any) =>
							activityObj?.route?.toLowerCase()
						);
						let airlineList = item?.activities?.map((activityObj: any) =>
							activityObj?.airline?.toLowerCase()
						);

						return (
							item?.invoice_id
								?.toLowerCase()
								?.startsWith(debouncedSearch.toLowerCase()) ||
							item?.activities[0]?.kardinal_reference
								?.toLowerCase()
								?.startsWith(debouncedSearch.toLowerCase()) ||
							item?.airline_name
								?.toLowerCase()
								?.startsWith(debouncedSearch.toLowerCase()) ||
							parseItinerary(item?.activities[0]?.route)
								?.departure?.toLowerCase()
								?.startsWith(debouncedSearch.toLowerCase()) ||
							parseItinerary(item?.activities[0]?.route)
								?.destination?.toLowerCase()
								?.startsWith(debouncedSearch.toLowerCase()) ||
							firstNameList?.filter((fName: any) =>
								fName.startsWith(debouncedSearch.toLowerCase())
							)?.length > 0 ||
							middleNameList?.filter((mName: any) =>
								mName.startsWith(debouncedSearch.toLowerCase())
							)?.length > 0 ||
							lastNameList?.filter((lName: any) =>
								lName.startsWith(debouncedSearch.toLowerCase())
							)?.length > 0 ||
							routeList?.filter((route: any) =>
								route.startsWith(debouncedSearch.toLowerCase())
							)?.length > 0 ||
							airlineList?.filter((airline: any) =>
								airline.startsWith(debouncedSearch.toLowerCase())
							)?.length > 0
						);
					})
				);
	}, [debouncedSearch, activeTab, filteredInvoiceList.length]);

	console.log("temptempInvoiceList", tempInvoiceList);

	console.log("activityList", activityList);
	console.log("invoiceList", invoiceList);
	console.log("selectedActivities", selectedActivities);
	console.log("amountToPay", amountToPay);

	const generateFunc = async () => {
		setGenerateLoading(true);
		try {
			const res = await generateInvoice({
				activities_id: selectedActivities,
			});
			console.log("generateInvoice", res);
			if (res.status === 201) {
				toast.success("Invoice generated");
				setRefetch(!refetch);
			}
		} catch (error) {
			console.log(error);
			toast.error("Unable to generate invoice");
		} finally {
			setGenerateLoading(false);
		}
	};

	const generateAndPayFunc = async (
		payment_method: "pay_with_wallet" | "pay_with_card",
		paystack_refID: string | null = null
	) => {
		setGenerateAndPayLoading(true);
		try {
			const res = await generateInvoice({
				activities_id: selectedActivities,
				payment: {
					payment_method: payment_method,
					paystack_refID: paystack_refID,
				},
			});
			console.log("generateInvoice", res);
			if (res.status === 201) {
				toast.success("Invoice generated");
				setRefetch(!refetch);
				setSelectedActivities([]);
			}
		} catch (error) {
			console.log(error);
			if ((error as any)?.response?.status > 500)
				toast.error("Unable to generate invoice");
		} finally {
			setGenerateAndPayLoading(false);
		}
	};

	const settleOverdueFunc = async (
		payment_method: "pay_with_wallet" | "pay_with_card",
		paystack_refID: string | null = null
	) => {
		setSettleLoading(true);
		try {
			const res = await bulkSettleOverdue({
				payment: {
					payment_method: payment_method,
					paystack_refID: paystack_refID,
				},
			});
			console.log("bulkSettleOverdue", res);
			if (res.status === 200) {
				toast.success("Payment Successful");
				// setRefetch(!refetch);
				window.location.reload();
			}
		} catch (error) {
			console.log(error);
			if ((error as any)?.response?.status > 500) toast.error("Payment failed");
		} finally {
			setSettleLoading(false);
		}
	};

	const settleUnpaidFunc = async (
		payment_method: "pay_with_wallet" | "pay_with_card",
		paystack_refID: string | null = null
	) => {
		setSettleLoading(true);
		try {
			const res = await bulkSettleUnpaid({
				payment: {
					payment_method: payment_method,
					paystack_refID: paystack_refID,
				},
			});
			console.log("bulkSettleUnpaid", res);
			if (res.status === 200) {
				toast.success("Payment Successful");
				// setRefetch(!refetch);
				window.location.reload();
			}
		} catch (error) {
			console.log(error);
			if ((error as any)?.response?.status > 500) toast.error("Payment failed");
		} finally {
			setSettleLoading(false);
		}
	};

	const settleOneFunc = async (
		invoice_id: string,
		payment_method: "pay_with_wallet" | "pay_with_card",
		paystack_refID: string | null = null
	) => {
		setSettleOneLoading(true);
		try {
			const res = await settleInvoice(invoice_id, {
				payment: {
					payment_method: payment_method,
					paystack_refID: paystack_refID,
				},
			});
			console.log("settleInvoice", res);
			if (res.status === 200) {
				toast.success("Payment Successful");
				setRefetch(!refetch);
			}
		} catch (error) {
			console.log(error);
			if ((error as any)?.response?.status > 500) toast.error("Payment failed");
		} finally {
			setSettleOneLoading(false);
		}
	};

	const initializePayment: any = usePaystackPayment({
		reference: new Date().getTime().toString(),
		email: userData?.email,
		amount: Math.ceil(addPaystackCharges(amountToPay) * 100),
		publicKey: `${process.env.REACT_APP_PAYSTACK_KEY}`,
	});

	const initializeBulkOverduePayment: any = usePaystackPayment({
		reference: new Date().getTime().toString(),
		email: userData?.email,
		amount: Math.ceil(
			addPaystackCharges(invoiceSummary?.overdue?.total_amount as number) * 100
		),

		publicKey: `${process.env.REACT_APP_PAYSTACK_KEY}`,
	});

	const initializeBulkUnpaidPayment: any = usePaystackPayment({
		reference: new Date().getTime().toString(),
		email: userData?.email,
		amount: Math.ceil(
			addPaystackCharges(invoiceSummary?.unpaid?.total_amount as number) * 100
		),
		publicKey: `${process.env.REACT_APP_PAYSTACK_KEY}`,
	});

	return (
		<>
			{
				<div className="InvoicingPage">
					<div className="InvoicingPage__top">
						<h3>Invoicing</h3>
						{tabList[activeTab].name === "Invoice" ? (
							<div className="totalCard">
								<div
									className="hideOnDesktop"
									onClick={() => setIsOpen(!isOpen)}
								>
									<p>
										Total outstanding{" "}
										{!isOpen ? (
											<DownArrowIcon color={"#9C9393"} />
										) : (
											<UpArrowIcon color={"#9C9393"} />
										)}
									</p>
								</div>
								<div
									className={`TripInfoDrawer__MoreInfo ${
										!isOpen && "TripInfoDrawer__MoreInfo--shut"
									}`}
									style={{ overflow: "hidden" }}
								>
									<div className="card">
										<div onClick={() => setOpenOverdue(!openOverdue)}>
											<OutstandingComponentIcon />
											<div className="overdueTotal">
												<p className="total overdue">Overdue total</p>
												<p className="money">
													{`₦ ${
														invoiceSummary?.overdue?.total_amount?.toLocaleString() ??
														0
													}`}
													{!openOverdue ? (
														<DownArrowIcon color={"#9C9393"} />
													) : (
														<UpArrowIcon color={"#9C9393"} />
													)}{" "}
												</p>

												<div
													className={`settleShutter settleShutter--${
														!openOverdue && "shut"
													}`}
													onClick={(e) => e.stopPropagation()}
												>
													<ButtonDropdown
														label="Settle payment"
														optionList={[
															{
																label: {
																	title: "Pay with card",
																	desc: "Standard rates apply",
																},
																action: () =>
																	initializeBulkOverduePayment((ref: any) => {
																		settleOverdueFunc(
																			"pay_with_card",
																			ref?.reference
																		);
																	}),
															},
															{
																label: {
																	title: "Pay with wallet",
																	desc: "Use your existing wallet balance to pay for this trip",
																},
																action: () => {
																	settleOverdueFunc("pay_with_wallet");
																},
															},
														]}
														disabled={!invoiceSummary?.overdue?.total_amount}
													/>
												</div>
											</div>
										</div>
										<div onClick={() => setOpenUnpaid(!openUnpaid)}>
											<OutstandingComponentIcon />
											<div>
												<p className="total unpaid">Unpaid total</p>
												<p className="money">
													{`₦ ${
														invoiceSummary?.unpaid?.total_amount?.toLocaleString() ??
														0
													}`}
													{!openUnpaid ? (
														<DownArrowIcon color={"#9C9393"} />
													) : (
														<UpArrowIcon color={"#9C9393"} />
													)}{" "}
												</p>
												<div
													className={`settleShutter settleShutter--${
														!openUnpaid && "shut"
													}`}
													onClick={(e) => e.stopPropagation()}
												>
													<ButtonDropdown
														label="Settle payment"
														optionList={[
															{
																label: {
																	title: "Pay with card",
																	desc: "Standard rates apply",
																},
																action: () =>
																	initializeBulkUnpaidPayment((ref: any) => {
																		settleUnpaidFunc(
																			"pay_with_card",
																			ref?.reference
																		);
																	}),
															},
															{
																label: {
																	title: "Pay with wallet",
																	desc: "Use your existing wallet balance to pay for this trip",
																},
																action: () => {
																	settleUnpaidFunc("pay_with_wallet");
																},
															},
														]}
														disabled={!invoiceSummary?.unpaid?.total_amount}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						) : (
							<>
								<div
									onClick={() => setOpenPreferences(true)}
									className="hideOnMobile"
								>
									<p className="setPreferences">
										<EditIcon size={20} />
										Set your invoice generation preferences
									</p>
								</div>
							</>
						)}
					</div>

					<div className="InvoicingPage__head">
						<div className="InvoicingPage__tabs">
							{tabList.map((tabItem, ind) => (
								<p
									className={`tab ${activeTab === ind && "tab--active"}`}
									key={ind}
									onClick={() => setActiveTab(ind)}
								>
									{tabItem.name}
								</p>
							))}

							<Select
								value={tabList[activeTab].name}
								className="mobileDropDown"
							>
								{tabList.map((tabItem, ind) => (
									<MenuItem
										value={tabItem.name}
										className={`tab ${activeTab === ind && "tab--active"}`}
										key={ind}
										onClick={() => setActiveTab(ind)}
									>
										{tabItem.name}{" "}
									</MenuItem>
								))}
							</Select>
						</div>
						{tabList[activeTab].name === "Invoice" ? (
							<div className="search-filter">
								<div className="search">
									<BiSearch style={{ color: "#40798C" }} />
									<input
										type="search"
										name=""
										id=""
										placeholder="Search invoice"
										value={searchString}
										onChange={(e) => setSearchString(e.target.value)}
									/>
								</div>

								<InvoiceFilterComponent
									filterList={filterList}
									selectedFilter={selectedFilter}
									setSelectedFilter={setSelectedFilter}
								/>
							</div>
						) : (
							<div className="generateButtons">
								<Button
									className={`generatePay generatePay--${
										selectedActivities.length > 0 ? "active" : "inActive"
									}`}
									onClick={() => generateFunc()}
									loading={generateLoading}
									disabled={selectedActivities.length <= 0}
								>
									Generate
								</Button>
								{/* <Button
									className={`generatePay generatePay--${
										selectedActivities.length > 0 ? "active" : "inActive"
									}`}
									onClick={() => generateAndPayFunc()}
									loading={generateAndPayLoading}
									disabled={selectedActivities.length <= 0}
								>
									Generate and pay
								</Button> */}
								<ButtonDropdown
									label="Generate and pay"
									optionList={[
										{
											label: {
												title: "Pay with card",
												desc: "Standard rates apply",
											},
											action: () => {
												initializePayment(
													(ref: any) => {
														generateAndPayFunc("pay_with_card", ref?.reference);
													},
													() => {}
												);
											},
										},
										{
											label: {
												title: "Pay with wallet",
												desc: "Use your existing wallet balance to pay for this trip",
											},
											action: () => {
												generateAndPayFunc("pay_with_wallet");
											},
										},
									]}
									disabled={selectedActivities.length <= 0}
								/>
							</div>
						)}
					</div>

					{loading ? (
						<SpinLoader height="150" />
					) : tabList[activeTab].tableBody?.length > 0 ? (
						tabList[activeTab].name === "Invoice" ? (
							<>
								<div className="InvoicingPage__table hideOnMobile">
									<div className="InvoicingPage__table__head">
										{tabList[activeTab].headers.map((header: any) => (
											<div>{header.label}</div>
										))}
									</div>
									<div className="InvoicingPage__table__body">
										{tabList[activeTab].tableBody.map((bodyItem: any) => (
											<div className="record">
												<div>
													<p>{formatDateRange(bodyItem.creationRange)}</p>
													<p>
														<span>•</span> Generated on {bodyItem.created_date}
													</p>
												</div>
												<p>{bodyItem.invoice_id}</p>
												<p>{`₦ ${bodyItem.total_amount?.toLocaleString()}`}</p>
												<p className={`status status--${bodyItem.status}`}>
													{bodyItem.status === "overdue"
														? bodyItem?.payment_status
														: capitalizeFirstLetter(bodyItem.status)}
												</p>
												<ActionFieldRecord invoice_id={bodyItem.invoice_id} />
											</div>
										))}
									</div>
								</div>
								{/* MOBILE INVOICE TABLE */}
								<div className="InvoicingPage__table hideOnDesktop">
									<div className="mobileWarning">
										<PhWarningIcon />
										<p>
											For invoice generation, splitting and more flexibility,
											please view this page on a larger screen
										</p>
									</div>
									<div className="InvoicingPage__table__head">
										{tabList[activeTab].headers_mobile.map(
											(headers_mobile: any) => (
												<div>{headers_mobile.label}</div>
											)
										)}
									</div>
									<div className="InvoicingPage__table__body">
										{tabList[activeTab].tableBody.map((bodyItem: any) => (
											<div className="mobileHover">
												<p className="generatedDate">
													<span>•</span> Generated on {bodyItem.created_date}
												</p>
												<div className="record">
													<div className="invoiceDetailsSide">
														<p>{bodyItem.invoice_id}</p>
														<p>{formatDateRange(bodyItem.creationRange)}</p>
														<p className={`status status--${bodyItem.status}`}>
															{bodyItem.status === "overdue"
																? bodyItem?.payment_status
																: capitalizeFirstLetter(bodyItem.status)}
														</p>
													</div>

													<div className="amountSide">
														<p style={{ textAlign: "right" }}>
															{`₦ ${bodyItem.total_amount?.toLocaleString()}`}
														</p>
														<div className="actions">
															{/* <a>Pay Now</a> */}
															<ButtonDropdown
																label={
																	bodyItem?.payment_status === "paid"
																		? "Paid"
																		: "Pay Now"
																}
																optionList={[
																	{
																		label: {
																			title: "Pay with card",
																			desc: "Standard rates apply",
																		},
																		action: () => {
																			// change initializePayment to one that has the updated price
																			// initializePayment(
																			// 	(ref: any) => {
																			// 		settleOneFunc(
																			// 			bodyItem?.invoice_id,
																			// 			"pay_with_card",
																			// 			ref?.reference
																			// 		);
																			// 	},
																			// 	() => {}
																			// );
																		},
																	},
																	{
																		label: {
																			title: "Pay with wallet",
																			desc: "Use your existing wallet balance to pay for this trip",
																		},
																		action: () => {
																			settleOneFunc(
																				bodyItem?.invoice_id,
																				"pay_with_wallet"
																			);
																		},
																	},
																]}
																disabled={bodyItem?.payment_status === "paid"}
															/>
															<Link
																to={`/invoicing/${bodyItem.invoice_id}?action=download`}
															>
																<DownloadIcon />
															</Link>
														</div>
													</div>
												</div>
											</div>
										))}
									</div>
								</div>
								{/* MOBILE INVOICE TABLE END */}
							</>
						) : (
							<>
								<div className="InvoicingPage__table hideOnMobile">
									<div className="InvoicingPage__table__head activities">
										<input type="checkbox" name="test" id="" />
										{tabList[activeTab].headers.map((header: any) => (
											<div>{header.label}</div>
										))}
									</div>
									<div className="InvoicingPage__table__body">
										{tabList[activeTab].tableBody.map(
											(bodyItem: any, ind: number) => (
												<div className="record activities" key={ind}>
													<input
														type="checkbox"
														name="test"
														className="checkbox"
														checked={selectedActivities.includes(bodyItem?.id)}
														onChange={(e) => {
															if (e.target.checked) {
																setSelectedActivities([
																	...selectedActivities,
																	bodyItem?.id,
																]);
																setAmountToPay(
																	(amount) => amount + bodyItem?.amount
																);
															} else {
																let newArr = [...selectedActivities];
																const index = newArr.indexOf(bodyItem?.id);
																const x = newArr.splice(index, 1);

																setSelectedActivities(newArr);
																setAmountToPay(
																	(amount) => amount - bodyItem?.amount
																);
															}
														}}
													/>
													<p>
														{DateTime.fromISO(bodyItem?.created_at, {
															zone: "utc",
														}).toFormat("dd-MM-yyyy")}
													</p>
													<p>{`${bodyItem?.traveler?.first_name} ${bodyItem?.traveler?.last_name}`}</p>
													<div>
														<p>
															{bodyItem?.activity_type === "flight"
																? bodyItem?.route === "Multi-city"
																	? "Multi-city"
																	: itineraryExpandCities(
																			bodyItem?.route,
																			globalAirports
																	  )
																: bodyItem?.route}
														</p>
														<p>{bodyItem.airline}</p>
													</div>
													<p style={{ textTransform: "capitalize" }}>
														{bodyItem?.activity_type}
													</p>
													<p>{bodyItem?.kardinal_reference}</p>
													<p className="amount">{`₦ ${bodyItem.amount?.toLocaleString()}`}</p>
												</div>
											)
										)}
									</div>
								</div>
								{/* MOBILE ACTIVITY TABLE */}
								<div className="InvoicingPage__table hideOnDesktop">
									<div className="InvoicingPage__table__head">
										{tabList[activeTab].headers_mobile.map(
											(headers_mobile: any) => (
												<div>{headers_mobile.label}</div>
											)
										)}
									</div>
									<p className="activityDate">Mar 12 - 19, 2023</p>
									<div className="InvoicingPage__table__body">
										{tabList[activeTab].tableBody.map((bodyItem: any) => (
											<div className="record activities">
												<div className="activityDetails">
													<FlightIcon
														size={33}
														color={"#84878B"}
														fill={"#DADADA80"}
													/>
													<div>
														<p className="nameOfTraveller">
															{`${bodyItem?.traveler?.first_name} ${bodyItem?.traveler?.last_name}`}
														</p>
														<p className="route">
															{bodyItem?.route === "Multi-city"
																? "Multi-city"
																: itineraryExpandCities(
																		bodyItem?.route,
																		globalAirports
																  )}
														</p>
														<p className="reference">
															{bodyItem?.kardinal_reference}
														</p>
													</div>
												</div>
												<p className="amount">{`₦ ${bodyItem.amount?.toLocaleString()}`}</p>
											</div>
										))}
									</div>
								</div>
								{/* MOBILE ACITIVITY TABLE END */}
								<div className="InvoicingPage__table__end">
									<p className="results">1 - 20 of 340 results</p>
									<div>pagination</div>
								</div>
							</>
						)
					) : tabList[activeTab].name === "Invoice" ? (
						<InvoicingEmptyState
							title="No Invoices yet"
							desc="Your company’s trip invoices will appear here."
						/>
					) : (
						<InvoicingEmptyState
							title="No Activities yet"
							desc="Your company’s trip activities will appear here."
						/>
					)}
				</div>
			}
			<MUIModal open={openSettle} handleClose={() => setOpenSettle(false)}>
				<SuccessfulModal
					afterAction={() => {
						navigate("/flight/summary-booked");
					}}
					message="Payment successful"
				/>
			</MUIModal>
			<MUIModal
				open={openPreferences}
				handleClose={() => setOpenPreferences(false)}
			>
				<InvoiceGenerationModal handleClose={() => setOpenPreferences(false)} />
			</MUIModal>
			<LoadingModalsDebacle
				successLoading={successLoading}
				setSuccessLoading={setSuccessLoading}
				loading={generateAndPayLoading}
				handleClose={() => {}}
				loadingMessage={"Hold on, we are confirming your balance"}
				successMessage={"Payment successful"}
				showSuccess={true}
				successFunc={() => {}}
			/>
			<LoadingModalsDebacle
				successLoading={successLoading}
				setSuccessLoading={setSuccessLoading}
				loading={settleLoading}
				handleClose={() => {}}
				loadingMessage={"Hold on, we are confirming your balance"}
				successMessage={"Payment successful"}
				showSuccess={true}
				successFunc={() => {}}
			/>
			<LoadingModalsDebacle
				successLoading={successLoading}
				setSuccessLoading={setSuccessLoading}
				loading={settleOneLoading}
				handleClose={() => {}}
				loadingMessage={"Hold on, we are confirming your balance"}
				successMessage={"Payment successful"}
				showSuccess={true}
				successFunc={() => {}}
			/>
		</>
	);
};

export default InvoicingPage;

const ActionFieldRecord = ({ invoice_id }: { invoice_id: string }) => {
	const navigate = useNavigate();

	return (
		<>
			<div className="actions">
				<Link to={`/invoicing/${invoice_id}?action=view`}>View</Link>
				<span>|</span>
				<Link to={`/invoicing/${invoice_id}?action=download`}>Download</Link>
			</div>
		</>
	);
};

export const InvoicingEmptyState = (props: any) => {
	return (
		<div className="InvoicingEmptyState">
			<div className="InvoicingEmptyState__main">
				<p className="title">{props.title}</p>
				<p className="desc">{props.desc}</p>
			</div>
		</div>
	);
};

const InvoiceGenerationModal = ({
	handleClose,
}: {
	handleClose: () => void;
}) => {
	const preferenceList: { title: string; desc: string; val: string }[] = [
		{
			title: "After a booking",
			desc: "Invoices are automatically generated after each booking.",
			val: "after_booking",
		},
		{
			title: "24 hours",
			desc: "Your travel invoices are automatically generated after 24 hours.",
			val: "one_day",
		},
		{
			title: "7 days",
			desc: "Your travel invoices are automatically generated every 7 days.",
			val: "one_week",
		},
		{
			title: "14 days",
			desc: "Your travel invoices are automatically generated every 14 days.",
			val: "two_weeks",
		},
	];

	const [selectedPreference, setSelectedPreference] = useState("after_booking");
	const [loading, setLoading] = useState(false);

	const updateFunc = async () => {
		setLoading(true);
		try {
			const res = await updateInvoiceGenerationPreference({
				change_type: selectedPreference,
			});
			console.log(res);
			if (res.status === 201) {
				toast.success(
					"Details sent. Our team will reach out to you once your request is processed."
				);
				handleClose();
			} else toast.error("Server error");
		} catch (error) {
			console.log(error);
			toast.error("Something went wrong");
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className="InvoiceGenerationModal">
			<div className="InvoiceGenerationModal__head">
				<h2>Invoice generation preferences</h2>
			</div>
			<div className="InvoiceGenerationModal__modalBody">
				{preferenceList.map((preferenceItem) => (
					<label className="paymentMethod">
						<input
							type="radio"
							name=""
							id=""
							checked={selectedPreference === preferenceItem.val}
							onChange={(e) => {
								setSelectedPreference(preferenceItem.val);
							}}
						/>
						<div>
							<p className="paymentMethod__name">{preferenceItem.title}</p>
							<p className="paymentMethod__desc">{preferenceItem.desc}</p>
						</div>
					</label>
				))}

				<div className="paymentMethod__btn">
					<Button
						className="pry"
						onClick={() => updateFunc()}
						loading={loading}
					>
						Save
					</Button>
				</div>
			</div>
		</div>
	);
};
