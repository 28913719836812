import "./style.scss";
import { IoClose } from "react-icons/io5";

interface CategoryTagProps {
	label: string;
	deleteFunc: () => void;
}

const CategoryTag = ({ label, deleteFunc }: CategoryTagProps) => {
	const colorList = ["#D5EBFF", "#CBF4C9", "#FEE2DD", "#F2F5FE"];

	return (
		<span
			className="CategoryTag"
			style={{
				backgroundColor:
					colorList[Math.floor(Math.random() * colorList.length)],
			}}
		>
			{label}
			<IoClose color="#9C9393" onClick={deleteFunc} />
		</span>
	);
};

export default CategoryTag;
