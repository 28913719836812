import { useTripStore } from "../../../zustand/store";
import "./style.scss";

const PriceBreakdownBlockCar = ({
	base_fare,
	booking_fee,
	parking_fee,
}: {
	base_fare: number;
	booking_fee: number;
	parking_fee: number;
}) => {
	const bigCarData = useTripStore((state: any) => state.bigCarData);

	console.log("base_fare", base_fare);

	return (
		<div className="PriceBreakdownBlock">
			<h3>Price breakdown</h3>
			<div>
				<div className="double-col">
					<p className="gray">Adult X {bigCarData.selectedTraveller.length}</p>
					<span className="gray">:</span>
					<p>₦ {base_fare.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}</p>
				</div>
				<div className="double-col">
					<p className="gray">Parking Fee</p>
					<span className="gray">:</span>
					<p>₦ {parking_fee.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}</p>
				</div>
				<div className="double-col">
					<p className="gray">Booking fee</p>
					<span className="gray">:</span>
					<p>{booking_fee || "Free"}</p>
				</div>
			</div>
			<div className="double-col total">
				<p>Total amount</p>
				<span></span>
				<p>
					₦
					{(base_fare + booking_fee + parking_fee)
						.toFixed(2)
						.replace(/\d(?=(\d{3})+\.)/g, "$&,")}
				</p>
			</div>
		</div>
	);
};

export default PriceBreakdownBlockCar;
