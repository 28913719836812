import React from "react";
import "./style.scss";

export default function LoadingAnimation() {
  return (
    <div className="parent">
      <div className="loader"></div>
    </div>
  );
}
