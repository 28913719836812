import React, { useState } from "react";
import "./style.scss";
import MUIBasicSelect from "../../components/MUISelect";
import AliceCarousel from "react-alice-carousel";
import Button from "../../components/Button";
import { BsArrowRight } from "react-icons/bs";
import LineChart from "./LineChart";
import BarChart from "./BarChart";
import { hideCardNumber } from "../../helpers/helperFuncs";
import { MastercardIcon } from "../../components/pieces/svgs";
import ComingSoon from "../ComingSoon";

const ReportingPage = () => {
	const [activeCard, setActiveCard] = useState(2);
	const monthList = [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
	].map((item) => ({ label: item, value: item }));

	const activitiesList = [
		{ title: "Total number of trips", value: "14" },
		{ title: "Average ticket price", value: "N805,000" },
		{ title: "Bookings on hold", value: "6" },
		{ title: "Cancelled bookings", value: "19" },
	];

	const FrequentTravellersData = [
		{ label: "Sodiq Akanbi", data1: "1,708,594", data2: 1 },
		{ label: "Sodiq Akanbi", data1: "1,708,594", data2: 0 },
		{ label: "Sodiq Akanbi", data1: "1,708,594", data2: 0 },
		{ label: "Sodiq Akanbi", data1: "1,708,594", data2: 0 },
	];

	const TopDestinationsData = [
		{ label: "Mumbai (BOM)", data1: "26.3%", data2: 1 },
		{ label: "Sodiq Akanbi", data1: "21.1%", data2: 0 },
		{ label: "Abuja (ABV)", data1: "15.8%", data2: 0 },
		{ label: "Washington,DC (IAD)", data1: "10.5%", data2: 0 },
	];

	const TotalDepositData = {
		labels: [
			"Jan",
			"Feb",
			"Mar",
			"Apr",
			"May",
			"Jun",
			"July",
			"Aug",
			"Sep",
			"Oct",
			"Nov",
			"Dec",
		],
		datasets: [
			{
				label: "Wallet",
				data: [
					20000, 300000, 40000, 100000, 140000, 30000, 70000, 30000, 120000,
					50000, 80000, 200000,
				],
				fill: true,
				backgroundColor: "transparent",
				borderColor: "#2B59CE",
			},
			{
				label: "Credit",
				data: [
					30000, 200000, 20000, 10000, 140000, 30000, 170000, 30000, 20000,
					50000, 30000, 90000,
				],
				fill: true,
				backgroundColor: "transparent",
				borderColor: "#18A0FB",
			},
		],
	};

	const BookingsPerClassData = {
		labels: ["Economy", "Business", "Premium Economy"],
		datasets: [
			{
				label: "Bookings Per Class",
				data: [2, 10, 4],
				fill: true,
				backgroundColor: ["#243256", "#C4C4C4", "#2B59CE"],
				borderColor: ["#243256", "#C4C4C4", "#2B59CE"],
			},
		],
	};

	const cardList = [
		{
			label: "Credit Balance",
			value: 230000,
			card_number: 4209121132008836,
			bg_color:
				"radial-gradient(37.04% 108.16% at 50% 50%, #243256 26.18%, #2A417C 100%)",
		},
		{
			label: "Wallet Balance",
			value: 270000,
			card_number: 4209121132008836,
			bg_color:
				"radial-gradient(56.33% 101.13% at 40.72% 29.75%, #239BC4 0%, #1D5C71 100%)",
		},
		{
			label: "Total Balance",
			value: 230000,
			card_number: 4209121132008836,
			bg_color:
				"radial-gradient(56.33% 101.13% at 40.72% 29.75%, #363535 0%, #000000 100%)",
		},
	];

	const AmountPerClassData = {
		labels: ["Economy", "Business", "Premium Economy"],
		datasets: [
			{
				label: "Amount Per Class",
				data: [200000, 1000000, 400000],
				fill: true,
				backgroundColor: ["#243256", "#C4C4C4", "#2B59CE"],
				borderColor: ["#243256", "#C4C4C4", "#2B59CE"],
			},
		],
	};
	const mobileCards = cardList.map((cardData, ind) => (
		<div
			key={`card-${ind}`}
			className={`card`}
			style={{
				background: cardData.bg_color,
			}}
		>
			<p className="label">{cardData.label}</p>
			<p className="card_number">{hideCardNumber(cardData.card_number)}</p>
			<div className="value">
				<p>NGN {Number(cardData.value).toLocaleString()}</p>
				<MastercardIcon />
			</div>
		</div>
	));

	return true ? (
		<ComingSoon />
	) : (
		<div className="ReportingPage">
			<h3 className="page-title">Reporting</h3>
			<div className="mobile-cards">
				<AliceCarousel
					mouseTracking
					items={mobileCards}
					// autoPlay={true}
					infinite={true}
					autoPlayInterval={5000}
				/>
			</div>
			<div className="mobile-activities_filters">
				<div className="filters">
					<MUIBasicSelect
						label="Select Dates"
						list={monthList}
						width={"100%"}
						transparent={true}
					/>
					{/* <Button className="pry">
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                gap: "10px",
                                fontSize: "13px",
                                lineHeight: "16px",
                            }}
                        >
                            Clear filters
                            <BsArrowRight />
                        </div>
                    </Button> */}
				</div>
				<div className="activitiesCard">
					<p className="title">Activities</p>
					<div className="activities">
						{activitiesList.map((item, ind) => (
							<div className="activity" key={`activity-${ind}`}>
								<div>
									<div className="icon-wrapper"></div>
									<p>{item.title}</p>
								</div>
								<p className="grey">{item.value}</p>
							</div>
						))}
					</div>
				</div>
			</div>
			<div>
				<ReportingTable
					title="Frequent Travellers"
					desc="Top travellers in your organisation"
					dataList={FrequentTravellersData}
				/>
				<ReportingTable
					title="Top Destinations"
					desc="Most booked cities"
					dataList={TopDestinationsData}
				/>
				<div className="charts">
					<div>
						<LineChart data={TotalDepositData} />
					</div>
					<div>
						<BarChart data={BookingsPerClassData} />
					</div>
					<div>
						<BarChart data={AmountPerClassData} />
					</div>
				</div>
			</div>
			<div className="balanceCard">
				<div className="balanceCard__creditCards">
					<div className="cards">
						{cardList.map((cardData, ind) => (
							<div
								key={`card-${ind}`}
								className={`card ${activeCard === ind ? "card--active" : ""}`}
								style={{
									background: cardData.bg_color,
									marginTop:
										activeCard === ind
											? 0
											: `${
													ind - activeCard >= 0
														? ((ind - activeCard) % 3) * 16
														: ((ind - activeCard + 3) % 3) * 16
											  }px`,
									// (1,2) (2,0) (0,1)
									zIndex:
										ind === activeCard
											? 5
											: ind - activeCard >= 0
											? 5 - ((ind - activeCard) % 3)
											: 5 - ((ind - activeCard + 3) % 3),
								}}
								onClick={() => setActiveCard(ind)}
							>
								<p className="label">{cardData.label}</p>
								<p className="card_number">
									{hideCardNumber(cardData.card_number)}
								</p>
								<div className="value">
									<p>NGN {Number(cardData.value).toLocaleString()}</p>
									<MastercardIcon />
								</div>
							</div>
						))}
					</div>
				</div>
				<div className="balanceCard__body">
					<p className="title">Filter by booking dates</p>
					<div className="filters">
						<MUIBasicSelect
							label="Select Dates"
							list={monthList}
							width={148}
							transparent={true}
						/>
						<Button className="pry">
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									gap: "10px",
									fontSize: "13px",
									lineHeight: "16px",
								}}
							>
								Clear filters
								<BsArrowRight />
							</div>
						</Button>
					</div>
					<p className="title">Activities</p>
					<div className="activities">
						{activitiesList.map((item, ind) => (
							<div className="activity" key={`activity-${ind}`}>
								<div>
									<div className="icon-wrapper"></div>
									<p>{item.title}</p>
								</div>
								<p>{item.value}</p>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ReportingPage;

const ReportingTable = ({
	title,
	desc,
	dataList,
}: {
	title: string;
	desc: string;
	dataList: {
		label: string;
		data1: string | number;
		data2?: string | number;
	}[];
}) => {
	const typeList = ["Hotel", "Flight"].map((item) => ({
		label: item,
		value: item,
	}));

	return (
		<div className="ReportingTable">
			<div className="top">
				<p className="title">{title}</p>
				<MUIBasicSelect
					label="Select Type"
					list={typeList}
					width={148}
					transparent={true}
				/>
			</div>
			<div className="second">
				<p className="desc">{desc}</p>
				<MUIBasicSelect
					label="Select Type"
					list={typeList}
					width={100}
					transparent={true}
				/>
			</div>
			<div className="main-content">
				{dataList.map((item, ind) => (
					<div className="row" key={`row-${ind}`}>
						<p>{item.label}</p>
						<p>{item.data1}</p>
						<div className="line-grid">
							<div className="line">
								<div></div>
							</div>
						</div>
						<p className="data2">
							{item.data2 !== undefined ? item.data2 : ""}
						</p>
					</div>
				))}
			</div>
		</div>
	);
};
