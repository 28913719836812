import "./style.scss";
import hotelRoomImg from "../../../resource/img/hotel-room.png";
import {
    CutleryIcon,
    CancelEventIcon,
    ParkingIcon,
    PoolIcon,
    MeetingRoomIcon,
    TwentyFourHourIcon,
    BusinessCentreIcon,
    RoomServiceIcon,
    LaundryIcon,
} from "../../../components/pieces/svgs";
import Button from "../../../components/Button";
import { AiOutlineWifi, AiFillCar } from "react-icons/ai";
import { FaParking } from "react-icons/fa";
import { FiArrowUpRight } from "react-icons/fi";

export const AvailableRooms = () => {
    const roomList = Array(3)
        .fill("")
        .map((item) => ({
            name: "Lower class room 1",
            price: 50000,
            bed: "1 Queen bed",
            size: "92",
            breakfast: true,
            freeCancel: true,
            images: Array(6)
                .fill("")
                .map((ind) => hotelRoomImg),
        }));

    return (
        <div>
            {roomList.map((roomItem, ind) => (
                <div className="roomItem" key={ind}>
                    <div className="roomItem__nameprice">
                        <p>{roomItem.name}</p>
                        <p>₦{roomItem.price}</p>
                    </div>
                    <div className="roomItem__bedsize">
                        <p>{roomItem.bed}</p>
                        <span>•</span>
                        <p>
                            {roomItem.size}m<sup>2</sup>
                        </p>
                    </div>
                    <div className="roomItem__imgfeatures">
                        <div className="img-grid">
                            {roomItem.images.map((image) => (
                                <img src={image} alt="hotel room" />
                            ))}
                        </div>
                        <div>
                            <div className="feature">
                                <CutleryIcon size={20} />
                                <p>Breakfast included</p>
                            </div>
                            <div className="feature">
                                <CancelEventIcon />
                                <p>Free cancellation</p>
                            </div>
                        </div>
                    </div>
                    <div className="roomItem__buttonDiv">
                        <Button className="pry" onClick={(e: any) => {}}>
                            Select
                        </Button>
                    </div>
                </div>
            ))}
        </div>
    );
};

export const Details = () => {
    const amenities = [
        {
            name: "Wi-Fi available",
            icon: <AiOutlineWifi />,
            status: true,
        },
        {
            name: "Airport shuttle",
            icon: <AiFillCar />,
            status: false,
        },
        {
            name: "Restaurant",
            icon: <CutleryIcon />,
            status: true,
        },
        {
            name: "24h reception",
            icon: <TwentyFourHourIcon />,
            status: true,
        },
        {
            name: "Parking",
            icon: <FaParking />,
            status: true,
        },
        {
            name: "Business centre",
            icon: <BusinessCentreIcon />,
            status: true,
        },
        {
            name: "Pool",
            icon: <PoolIcon />,
            status: false,
        },
        {
            name: "Room service",
            icon: <RoomServiceIcon />,
            status: false,
        },
        {
            name: "Meeting rooms",
            icon: <MeetingRoomIcon />,
            status: true,
        },
        {
            name: "Laundry service",
            icon: <LaundryIcon />,
            status: false,
        },
    ];
    const images = Array(6)
        .fill("")
        .map((ind) => hotelRoomImg);

    return (
        <div className="Details">
            <div className="Details__section1">
                <p className="title">Hotel Address</p>
                <p className="address">
                    NG Abuja Plot 123 Cadastral Zone A0, Central Business Dis
                    900103, Abuja, Nigeria
                </p>
                <p className="blue-link">
                    <span>Read description</span>
                    <FiArrowUpRight />
                </p>
                <p className="see-where">See where you’ll be staying</p>
                <div className="img-list">
                    {images.map((image) => (
                        <img src={image} alt="hotel room" />
                    ))}
                </div>
            </div>
            <div className="Details__section2">
                <p className="title">Essential Amenities include</p>
                <div className="amenities">
                    {amenities.map((item) => (
                        <div
                            className={`amenities__item ${
                                item.status && "amenities__item--available"
                            }`}
                        >
                            {item.icon}
                            <p>{item.name}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
