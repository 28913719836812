import { Dispatch, SetStateAction, useRef, useState } from "react";
import { BsFilter } from "react-icons/bs";
import { InvoiceFilter } from "../../components/pieces/svgs";
import useOutsideAlerter from "../../hooks/useOutsideAlerter";

interface InvoiceFilterComponentProps {
	filterList: { label: string; value: string }[];
	selectedFilter: string;
	setSelectedFilter: Dispatch<SetStateAction<string>>;
}

const InvoiceFilterComponent = ({
	filterList,
	selectedFilter,
	setSelectedFilter,
}: InvoiceFilterComponentProps) => {
	const [openFilterDrop, setOpenFilterDrop] = useState(false);

	const wrapperRef = useRef<HTMLDivElement>(null);

	useOutsideAlerter(wrapperRef, () => {
		setOpenFilterDrop(false);
	});

	return (
		<div className="filter" ref={wrapperRef}>
			<div
				className="filter__inner"
				onClick={() => setOpenFilterDrop(!openFilterDrop)}
			>
				<p className="hideOnMobile">Filter </p>
				<BsFilter className="hideOnMobile" size={20} />
				<div className="hideOnDesktop">
					<InvoiceFilter />
				</div>
			</div>
			<div className={`options ${!openFilterDrop && "options__shut"}`}>
				{filterList.map((item) => (
					<div>
						<label>
							<input
								type="radio"
								name="filter"
								checked={item.value === selectedFilter}
								onChange={(e) => {
									setSelectedFilter(item.value);
								}}
							/>
							{item.label}
						</label>
					</div>
				))}
			</div>
		</div>
	);
};

export default InvoiceFilterComponent;
