import { Dispatch, SetStateAction, useState } from "react";
import { SimpleModalLayout } from "../../layouts/ModalLayouts";
import "./style.scss";
import Button from "../../components/Button";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import {
	ArrivalIcon,
	CalenderIcon,
	ChangeTicketPlaneIcon,
	DepartureIcon,
	ViewConfirmationIcon,
	OpenTicketIcon,
	ChangeTicketIcon,
	CancelBookingIcon,
	SendTicketIcon,
} from "../../components/pieces/svgs";
import { useTripStore } from "../../zustand/store";
import CustomDropdown from "../../components/CustomDropdown";
import {
	cancelBooking,
	changeTicket,
	openTicket,
	sendTicket,
} from "../../requests/trip-history";
import { toast } from "react-toastify";
import DatePicker, {
	getAllDatesInRange,
	DateObject,
} from "react-multi-date-picker";
import opacity from "react-element-popper/animations/opacity";
import transition from "react-element-popper/animations/transition";
import { DateTime } from "ts-luxon";
import { CustomSelect } from "../../components/CustomSelect";
import { parseCommaSeperate, validateEmails } from "../../helpers/helperFuncs";
import { useNavigate } from "react-router-dom";

interface TicketModalProps {
	title: string;
	text: string;
	buttonAction?: () => void;
}

export const OpenTicketModal = ({
	handleClose,
	tripData,
	reload,
}: {
	handleClose: () => void;
	tripData: any;
	reload: () => void;
}) => {
	// zustand
	const userData = useTripStore((state: any) => state.userData);

	// react state
	const [submitted, setSubmitted] = useState(false);
	const [loading, setLoading] = useState(false);
	const [formData, setFormData] = useState({
		user: userData?.id,
		kardinal_reference: tripData?.kardinal_reference,
		change_type: "open_ticket",
	});

	return (
		<SimpleModalLayout
			title="Open ticket"
			text="Open this ticket for future use"
			handleClose={handleClose}
		>
			{submitted ? (
				<div className="TicketSuccess">
					<p>Your details have been recieved. You will be updated shortly.</p>
					<Button className="pry" onClick={() => handleClose()}>
						Go back
					</Button>
				</div>
			) : (
				<div className="modalForm">
					<form
						onSubmit={async (e) => {
							e.preventDefault();

							setLoading(true);
							try {
								const res = await openTicket(formData);

								if (res.status === 201) {
									toast.success("Details Received");
									setSubmitted(true);
									reload();
								}
							} catch (e) {
								console.log(e);
							} finally {
								setLoading(false);
							}
						}}
					>
						<div className="modalFormLabel">
							<label className="formLabel" htmlFor="">
								Surname
								<input
									type="text"
									name=""
									id=""
									placeholder="Onyekachi"
									value={tripData?.passengers[0]?.last_name}
								/>
							</label>
							<label className="formLabel" htmlFor="">
								Booking reference
								<input
									type="text"
									name=""
									id=""
									placeholder="KN-45639334"
									value={formData.kardinal_reference}
								/>
							</label>
						</div>

						<p className="form-para">
							Modification of tickets are subjected to conditions; by
							proceeding, you accept these{" "}
							<a href="https://example.com">Terms of Service</a>
						</p>
						<Button
							type="submit"
							className="pry form-btn"
							style={{
								background: "auto",
							}}
							onClick={() => {}}
							loading={loading}
						>
							Open this ticket
						</Button>
					</form>
				</div>
			)}
		</SimpleModalLayout>
	);
};

export const CancelBookingModal = ({
	handleClose,
	tripData,
	reload,
}: {
	handleClose: () => void;
	tripData: any;
	reload: () => void;
}) => {
	// zustand
	const userData = useTripStore((state: any) => state.userData);

	// react state
	const [submitted, setSubmitted] = useState(false);
	const [loading, setLoading] = useState(false);

	const [formData, setFormData] = useState({
		user: userData?.id,
		kardinal_reference: tripData?.kardinal_reference,
		change_type: "cancel_booking",
		refund_method: "wallet",
		notes: "",
	});

	const handleChange = (name: "refund_method" | "notes", value: any) => {
		setFormData({ ...formData, [name]: value });
	};

	return (
		<SimpleModalLayout
			title="Cancel booking"
			text="Cancel booking for personal reasons"
			handleClose={handleClose}
		>
			{submitted ? (
				<div className="TicketSuccess">
					<p>Your details have been recieved. You will be updated shortly.</p>
					<Button className="pry" onClick={() => handleClose()}>
						Go back
					</Button>
				</div>
			) : (
				<div className="modalForm">
					<form
						onSubmit={async (e) => {
							e.preventDefault();

							if (formData.refund_method) {
								setLoading(true);
								try {
									const res = await cancelBooking(formData);

									if (res.status === 201) {
										toast.success("Details Received");
										setSubmitted(true);
										reload();
									}
								} catch (e) {
									console.log(e);
								} finally {
									setLoading(false);
								}
							}
						}}
					>
						<div className="modalFormLabel">
							<label className="formLabel" htmlFor="">
								Surname
								<input
									type="text"
									name=""
									id=""
									placeholder="Onyekachi"
									value={tripData?.passengers[0]?.last_name}
								/>
							</label>
							<label className="formLabel" htmlFor="">
								Booking reference
								<input
									type="text"
									name=""
									id=""
									value={formData?.kardinal_reference}
								/>
							</label>
						</div>

						<div className="options">
							<label className="formLabel" htmlFor="">
								Refund Method
							</label>
							<CustomDropdown
								onSelect={(val: any) => {
									handleChange("refund_method", val);
								}}
								defaultValue={"wallet"}
								optionsList={[
									{ label: "Kardinal Wallet", value: "wallet" },
									{ label: "Bank Transfer", value: "transfer" },
								]}
								ind={0}
							/>
						</div>
						<div className="textArea">
							<div className="boxes">
								<label className="formLabel" htmlFor="">
									Reason for Cancellation
								</label>
								<p className="formLabel">Optional</p>
							</div>

							<TextareaAutosize
								className="textBox"
								minRows={5}
								placeholder="I am cancelling this booking because...."
								value={formData.notes}
								onChange={(e) => handleChange("notes", e.target.value)}
							/>
						</div>

						<p className="form-para">
							Modification of tickets are subjected to conditions; by
							proceeding, you accept these{" "}
							<a href="https://example.com">Terms of Service</a>
						</p>
						<Button
							type="submit"
							className="pry form-btn"
							style={{
								background: "#DB1919",
							}}
							onClick={() => {}}
							loading={loading}
						>
							Request cancellation
						</Button>
					</form>
				</div>
			)}
		</SimpleModalLayout>
	);
};

export const ChangeTicketModal = ({
	handleClose,
	tripData,
	reload,
}: {
	handleClose: () => void;
	tripData: any;
	reload: () => void;
}) => {
	//zustand
	const localAirports = useTripStore((state: any) => state.localAirports);
	const globalAirports = useTripStore((state: any) => state.globalAirports);
	const dropDownLoading = useTripStore((state: any) => state.dropDownLoading);
	const userData = useTripStore((state: any) => state.userData);

	const [loading, setLoading] = useState(false);
	const [submitted, setSubmitted] = useState(false);
	const [newRoute, setNewRoute] = useState("");
	const [flightsSelected, setFlightsSelected] = useState<number[]>([]);
	const [changeTypes, setChangeTypes] = useState<string[]>([]);

	const [formData, setFormData] = useState({
		user: userData?.id,
		kardinal_reference: tripData?.kardinal_reference,
		change_type: "",

		changes: tripData?.flight?.map((item: any) => ({
			departure: item?.origin,
			departure_city: item?.origin_city,
			departure_airport: item?.origin_airport,
			departure_date: item?.travel_date,
			arrival: item?.destination,
			arrival_city: item?.destination_city,
			arrival_airport: item?.destination_airport,
		})),
		notes: "",
	});

	const handleChange = (
		name: "change_type" | "changes" | "notes",
		value: any
	) => {
		setFormData({ ...formData, [name]: value });
	};

	const handleRouteChange = (event: SelectChangeEvent) => {
		setNewRoute(event.target.value);
	};

	console.log("localAirports", localAirports);
	console.log("formData", formData);
	console.log("flightsSelected", flightsSelected);

	return (
		<SimpleModalLayout
			title="Change ticket"
			text="Change routes or dates on this tickets"
			handleClose={handleClose}
		>
			{submitted ? (
				<div className="TicketSuccess">
					<p>Your details have been recieved. You will be updated shortly.</p>
					<Button className="pry" onClick={() => handleClose()}>
						Go back
					</Button>
				</div>
			) : (
				<div className="modalForm">
					<form
						onSubmit={async (e) => {
							e.preventDefault();
							console.log("formData", formData);

							if (changeTypes.length > 0 && flightsSelected.length !== 0) {
								setLoading(true);
								try {
									const res = await changeTicket({
										...formData,
										change_type:
											changeTypes.length === 1 ? changeTypes[0] : "date_route",
									});

									if (res.status === 201) {
										toast.success("Details Received");
										setSubmitted(true);
										reload();
									}
								} catch (e) {
									console.log(e);
								} finally {
									setLoading(false);
								}
							}
						}}
					>
						<div className="modalFormLabel">
							<label className="formLabel" htmlFor="">
								Surname
								<input
									type="text"
									name=""
									id=""
									placeholder="Onyekachi"
									value={tripData?.passengers[0]?.last_name}
								/>
							</label>
							<label className="formLabel" htmlFor="">
								Booking reference
								<input
									type="text"
									name=""
									id=""
									value={formData?.kardinal_reference}
								/>
							</label>
						</div>

						<div className="changeTicket desktop-hide">
							<p className="changeType">Pick change type</p>
							<div className="boxes">
								<div>
									<input
										type="checkbox"
										name=""
										id=""
										checked={changeTypes.includes("date_change")}
										onChange={(e) => {
											if (e.target.checked)
												setChangeTypes([...changeTypes, "date_change"]);
											else {
												let newArr = [...changeTypes];
												const index = newArr.indexOf("date_change");
												const x = newArr.splice(index, 1);

												setChangeTypes(newArr);
											}
										}}
									/>
									<label className="formLabel" htmlFor="">
										Date Change
									</label>
								</div>
								<div>
									<input
										type="checkbox"
										name=""
										id=""
										checked={changeTypes.includes("route_change")}
										onChange={(e) => {
											if (e.target.checked)
												setChangeTypes([...changeTypes, "route_change"]);
											else {
												let newArr = [...changeTypes];
												const index = newArr.indexOf("route_change");
												const x = newArr.splice(index, 1);

												setChangeTypes(newArr);
											}
										}}
									/>
									<label className="formLabel" htmlFor="">
										Route change
									</label>
								</div>
							</div>
							{changeTypes.length > 0 && (
								<>
									<p className="changeType">Choose the flight to change</p>
									<div className="innerBox">
										<div className="newDate">
											<div className="boxes">
												{tripData?.flight?.map((item: any, ind: number) => (
													<div>
														<input
															type="checkbox"
															name=""
															id=""
															checked={flightsSelected.includes(ind)}
															onChange={(e) => {
																if (e.target.checked)
																	setFlightsSelected([...flightsSelected, ind]);
																else {
																	let newArr = [...flightsSelected];
																	const index = newArr.indexOf(ind);
																	const x = newArr.splice(index, 1);

																	setFlightsSelected(newArr);
																}
															}}
														/>
														<ChangeTicketPlaneIcon />
														<div className="innerLabels">
															<label
																className={`formLabel formLabel${
																	changeTypes.includes("route_change") &&
																	flightsSelected.includes(ind)
																		? "--strikethrough"
																		: ""
																}`}
																htmlFor=""
															>
																{item?.origin_city} to {item?.destination_city}
															</label>
															<label
																className={`formLabel formLabel--time formLabel${
																	changeTypes.includes("date_change") &&
																	flightsSelected.includes(ind)
																		? "--strikethrough"
																		: ""
																}`}
																htmlFor=""
															>
																{DateTime.fromISO(
																	item.travel_date
																).toLocaleString(DateTime.TIME_24_SIMPLE)}
																,{" "}
																{DateTime.fromISO(item.travel_date).toFormat(
																	"LLL dd, yyyy"
																)}
															</label>
														</div>
													</div>
												))}
											</div>
										</div>
										<div className="flightsSelectedEdits">
											{flightsSelected.length !== 0 &&
												flightsSelected.map((item, ind) => (
													<div>
														<p className="flightNum">FLIGHT {item + 1}</p>
														{changeTypes.includes("date_change") && (
															<>
																<p className="changeType">Select New Date</p>
																<div className="datePicker">
																	<div className="boxes">
																		<DatePicker
																			className="teal"
																			placeholder="date"
																			value={
																				new Date(
																					formData?.changes[
																						item
																					]?.departure_date
																				)
																			}
																			onChange={(dateObject: DateObject) => {
																				console.log(
																					new Date(
																						dateObject.toString()
																					).toLocaleDateString("sv-SE")
																				);

																				const changesArr = [
																					...formData.changes,
																				];
																				changesArr[item] = {
																					...changesArr[item],
																					departure_date: new Date(
																						dateObject.toString()
																					).toLocaleDateString("sv-SE"),
																				};

																				handleChange("changes", changesArr);
																			}}
																			onOpenPickNewDate={false}
																			// inputClass=""
																			numberOfMonths={1}
																			mapDays={(object: any) => {
																				return {
																					disabled:
																						object.today > object.date
																							? true
																							: false,
																				};
																			}}
																			animations={[
																				opacity(),
																				transition({
																					from: 35,
																					duration: 400,
																				}),
																			]}
																			render={(
																				value: any,
																				openCalendar: any
																			) => {
																				return (
																					<div
																						onClick={openCalendar}
																						className="custom-rmdp-input"
																					>
																						<CalenderIcon />
																						<p>{value}</p>
																					</div>
																				);
																			}}
																		/>
																	</div>
																</div>
															</>
														)}

														{changeTypes.includes("route_change") && (
															<>
																<p className="changeType">Select new route</p>
																<div className="changeRoute">
																	<div className="boxes">
																		<CustomSelect
																			icon={
																				<DepartureIcon
																					color="#9C9393"
																					size={22}
																				/>
																			}
																			label="From"
																			list={[localAirports, globalAirports]}
																			listIndex={
																				tripData?.booking_category ===
																				"domestic"
																					? 0
																					: 1
																			}
																			value={formData?.changes[item]?.departure}
																			onChange={(val: any) => {
																				const changesArr = [
																					...formData.changes,
																				];
																				changesArr[item] = {
																					...changesArr[item],
																					departure: val,
																					departure_city: localAirports?.filter(
																						(item: any) =>
																							item?.iata_code === val
																					)[0]?.city,
																					departure_airport:
																						localAirports?.filter(
																							(item: any) =>
																								item?.iata_code === val
																						)[0]?.name,
																				};

																				handleChange("changes", changesArr);
																			}}
																			loading={
																				tripData?.booking_category ===
																				"domestic"
																					? dropDownLoading?.localAirports
																					: dropDownLoading?.intlAirports
																			}
																			ind={1}
																		/>
																		<CustomSelect
																			icon={
																				<DepartureIcon
																					color="#9C9393"
																					size={22}
																				/>
																			}
																			label="To"
																			list={[localAirports, globalAirports]}
																			listIndex={
																				tripData?.booking_category ===
																				"domestic"
																					? 0
																					: 1
																			}
																			value={formData?.changes[item]?.arrival}
																			onChange={(val: any) => {
																				const changesArr = [
																					...formData.changes,
																				];
																				changesArr[item] = {
																					...changesArr[item],
																					arrival: val,
																					arrival_city: localAirports?.filter(
																						(item: any) =>
																							item?.iata_code === val
																					)[0]?.city,
																					arrival_airport:
																						localAirports?.filter(
																							(item: any) =>
																								item?.iata_code === val
																						)[0]?.name,
																				};

																				handleChange("changes", changesArr);
																			}}
																			loading={
																				tripData?.booking_category ===
																				"domestic"
																					? dropDownLoading?.localAirports
																					: dropDownLoading?.intlAirports
																			}
																			ind={2}
																		/>
																	</div>
																</div>
															</>
														)}
													</div>
												))}
										</div>
									</div>
								</>
							)}
							<div className="textArea">
								<div className="boxes">
									<label className="formLabel" htmlFor="">
										Notes
									</label>
									<p className="formLabel">Optional</p>
								</div>
								<TextareaAutosize
									className="textBox"
									minRows={3}
									value={formData.notes}
									onChange={(e) => handleChange("notes", e.target.value)}
								/>
							</div>
						</div>

						<p className="form-para">
							Modification of tickets are subjected to conditions; by
							proceeding, you accept these{" "}
							<a href="https://example.com">Terms of Service</a>
						</p>
						<Button
							type="submit"
							className="pry form-btn"
							style={{
								background: "auto",
							}}
							disabled={
								!(changeTypes.length > 0 && flightsSelected.length !== 0)
							}
							loading={loading}
						>
							Request Change
						</Button>
					</form>
				</div>
			)}
		</SimpleModalLayout>
	);
};

export const InputEmailModal = ({
	handleClose,
	trip_type,
	tripData,
}: {
	handleClose: () => void;
	trip_type: string;
	tripData: any;
}) => {
	const [loading, setLoading] = useState(false);
	const [emails, setEmails] = useState("");

	const [formData, setFormData] = useState({
		emails: [],
		trip_type: trip_type,
		booking_category: tripData?.booking_category,
	});

	const handleChange = (name: "emails" | "haha", value: string) => {
		setFormData({ ...formData, [name]: value });
	};

	return (
		<div className="EmailModal">
			<form
				className="modalForm"
				onSubmit={async (e) => {
					e.preventDefault();
					// buttonAction && buttonAction();
					if (!validateEmails(parseCommaSeperate(emails)))
						toast.error(
							"Please enter a comma-seperated list of valid email addresses"
						);
					else {
						setLoading(true);
						try {
							const res = await sendTicket(
								{ ...formData, emails: parseCommaSeperate(emails) },
								tripData?.kardinal_reference
							);

							if (res.status === 200) {
								toast.success("Ticket(s) Sent");
							}
						} catch (e) {
							console.log(e);
							toast.error("Something went wrong");
						} finally {
							setLoading(false);
						}
					}
				}}
			>
				<label htmlFor="">
					Input email address
					<input
						type="text"
						name=""
						id=""
						placeholder="separate emails by comma"
						value={emails}
						onChange={(e) => setEmails(e.target.value)}
					/>
				</label>
				<div className="email-btn">
					<Button type="submit" className="pry" loading={loading}>
						Send
					</Button>
				</div>
			</form>
		</div>
	);
};

export const TripHistoryDrawer = ({
	populateBigFlightData,
	data,
	tripData,
	setOpenChangeModal,
	setOpenSendEmailModal,
	setOpenTicketModal,
	setOpenCancelModal,
}: {
	populateBigFlightData: () => void;
	data: any;
	tripData: any;
	setOpenChangeModal: Dispatch<SetStateAction<boolean>>;
	setOpenSendEmailModal: Dispatch<SetStateAction<boolean>>;
	setOpenTicketModal: Dispatch<SetStateAction<boolean>>;
	setOpenCancelModal: Dispatch<SetStateAction<boolean>>;
}) => {
	const navigate = useNavigate();

	return (
		<div className="mobileMenu">
			<div className="actions">
				<div
					className="action action--active"
					onClick={() => {
						populateBigFlightData();
						setTimeout(() => {
							navigate("/trips/booking-complete");
						}, 500);
					}}
				>
					<ViewConfirmationIcon />
					<p>View confirmation page</p>
				</div>
				<div
					className={`action action--${
						(data?.any_process && data?.process_type === "cancel_booking") ||
						data?.status === "completed"
							? "inactive"
							: "active"
					}`}
					onClick={() => {
						if (
							data?.status !== "completed" &&
							!(
								data?.any_process &&
								(data?.process_type === "date_change" ||
									data?.process_type === "route_change" ||
									data?.process_type === "cancel_booking")
							)
						)
							setOpenChangeModal(true);
					}}
				>
					<ChangeTicketIcon />
					<p>
						{data?.any_process &&
						(data?.process_type === "date_change" ||
							data?.process_type === "route_change")
							? "Processing (Change ticket)"
							: "Change ticket"}
					</p>
				</div>
				<div
					className="action action--active"
					onClick={() => {
						setOpenSendEmailModal(true);
					}}
				>
					<SendTicketIcon />
					<p>Send ticket via email</p>
				</div>
				<div className="open-cancel">
					<div
						className={`action action--${
							data?.status === "completed" ||
							tripData?.status === "open" ||
							(data?.any_process && data?.process_type === "cancel_booking")
								? "inactive"
								: "active"
						}`}
						onClick={() => {
							if (
								data?.status !== "completed" &&
								tripData?.status !== "open" &&
								!(
									data?.any_process &&
									(data?.process_type === "open_ticket" ||
										data?.process_type === "cancel_booking")
								)
							)
								setOpenTicketModal(true);
						}}
					>
						<OpenTicketIcon />
						<p>
							{data?.any_process && data?.process_type === "open_ticket"
								? "Processing (Open ticket)"
								: "Open ticket"}
						</p>
					</div>
					<div
						className={`action action--red ${
							data?.status === "completed" && "action--inactive"
						}`}
						onClick={() => {
							if (
								data?.status !== "completed" &&
								!(data?.any_process && data?.process_type === "cancel_booking")
							)
								setOpenCancelModal(true);
						}}
					>
						<CancelBookingIcon />
						<p>
							{data?.any_process && data?.process_type === "cancel_booking"
								? "Processing (Cancel booking)"
								: "Cancel booking"}
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};
