import { useEffect } from "react";
import { useTripStore } from "../../zustand/store";
import { Outlet } from "react-router-dom";
import { fetchAirportList, fetchIntlAirportList } from "../../requests";
import { fetchCarList, fetchCarLoctions } from "../../requests/cars";

export default function TravelPage() {
	//zustand global states
	const bigFlightData = useTripStore((state: any) => state.bigFlightData);
	const updateBigFlightData = useTripStore(
		(state: any) => state.updateBigFlightData
	);
	const setLocalAirports = useTripStore((state: any) => state.setLocalAirports);
	const globalAirports = useTripStore((state: any) => state.globalAirports);
	const setGlobalAirports = useTripStore(
		(state: any) => state.setGlobalAirports
	);
	const setCarLocations = useTripStore((state: any) => state.setCarLocations);
	const setCarList = useTripStore((state: any) => state.setCarList);

	const setDropDownLoading = useTripStore(
		(state: any) => state.setDropDownLoading
	);

	useEffect(() => {
		// fetch local airports
		const fetchAirports = async () => {
			setDropDownLoading({ localAirports: true });
			try {
				const res1 = await fetchAirportList();
				console.log("fetchAirportList", res1);

				setLocalAirports(
					res1.data?.filter((item: any) => item.country === "Nigeria")
				);
			} catch (e) {
				console.log(e);
			} finally {
				setDropDownLoading({ localAirports: false });
			}
		};
		fetchAirports();
	}, []);

	useEffect(() => {
		// fetch international airports
		const fetchAirports = async () => {
			setDropDownLoading({ intlAirports: true });
			try {
				const res2 = await fetchIntlAirportList();

				console.log("res2", res2);
				setGlobalAirports(res2.data);

				console.log("globalAirports", globalAirports);
				console.log(
					"emptyAirports",
					res2.data?.filter((item: any) => item?.city === "")
				);
			} catch (e) {
				console.log("res2Err", e);
			} finally {
				setDropDownLoading({ intlAirports: false });
			}
		};
		fetchAirports();
	}, []);

	useEffect(() => {
		// fetch car locations
		const fetchFunc = async () => {
			setDropDownLoading({ carLocations: true });
			try {
				const res3 = await fetchCarLoctions();

				setCarLocations(res3.data);
			} catch (e) {
				console.log(e);
			} finally {
				setDropDownLoading({ carLocations: false });
			}
		};
		fetchFunc();
	}, []);

	useEffect(() => {
		// fetch car list
		const fetchFunc = async () => {
			setDropDownLoading({ carList: true });
			try {
				const res4 = await fetchCarList();
				console.log("res4", res4.data);

				setCarList(res4.data);
			} catch (e) {
				console.log(e);
			} finally {
				setDropDownLoading({ carList: false });
			}
		};
		fetchFunc();
	}, []);

	//jekjkjr

	// useEffect(() => {
	// 	const fetchAirports = async () => {
	// 		setPortLoading(true);
	// 		try {
	// 			const res1 = await fetchAirportList();
	// 			const res2 = await fetchIntlAirportList();
	// 			const res3 = await fetchCarLoctions();
	// 			const res4 = await fetchCarList();

	// 			console.log("res4", res4.data);

	// 			console.log("fetchAirportList", res1);
	// 			setGlobalAirports(res2.data);
	// 			setLocalAirports(
	// 				res1.data?.filter((item: any) => item.country === "Nigeria")
	// 			);

	// 			setCarLocations(res3.data);
	// 			setCarList(res4.data);
	// 			console.log("globalAirports", globalAirports);
	// 			console.log(
	// 				"emptyAirports",
	// 				res2.data?.filter((item: any) => item?.city === "")
	// 			);
	// 		} catch (e) {
	// 			console.log(e);
	// 		} finally {
	// 			setPortLoading(false);
	// 		}
	// 	};
	// 	fetchAirports();
	// }, []);

	useEffect(() => {
		updateBigFlightData({
			departureCity: globalAirports?.filter(
				(item: any) => item.iata_code === bigFlightData.departure
			),
		});
		updateBigFlightData({
			arrivalCity: globalAirports?.filter(
				(item: any) => item.iata_code === bigFlightData.destination
			),
		});
	}, [globalAirports, bigFlightData.departure, bigFlightData.destination]);

	console.log("bigFlightData", bigFlightData);

	return <Outlet />;
}
