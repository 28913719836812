import { useTheme } from "@mui/material/styles";
import { useState } from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableFooter,
	TablePagination,
	Paper,
	Box,
	IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import EmptyState from "../EmptyState";
import "./style.scss";

interface MUITableProps {
	headers: { label: string; key: string }[];
	bodyData: any[];
	stripped?: boolean;
	showPagination?: boolean;
	specialStyles?: { key: string; styles: any }[];
	specialActions?: { key: string; action: (ind: number) => void }[];
	emptyState?: {
		message?: string;
		button?: { label: string; action?: () => void };
	};
}

const MUITable = ({
	headers = [],
	bodyData = [],
	stripped = true,
	showPagination = true,
	specialStyles = [{ key: "", styles: {} }],
	specialActions = [],
	emptyState,
}: MUITableProps): JSX.Element => {
	const keyList = headers.map((header) => header.key);
	console.log("bodyData", bodyData);

	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);

	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - bodyData.length) : 0;

	const handleChangePage = (
		event: React.MouseEvent<HTMLButtonElement> | null,
		newPage: number
	) => {
		setPage(newPage);
	};
	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	return (
		<TableContainer
			component={Paper}
			sx={{
				boxShadow: 0,
				border: "0.8px solid rgba(196, 196, 196, 0.5)",
				borderRadius: "8px",
			}}
		>
			<Table sx={{ width: "100%" }} aria-label="simple table" style={{}}>
				<TableHead>
					<TableRow>
						{headers.map(
							(header: { label: string; key: string }, ind: number) => (
								<TableCell key={`table-header-${ind}`}>
									{header.label}
								</TableCell>
							)
						)}
					</TableRow>
				</TableHead>

				{bodyData.length !== 0 ? (
					<>
						<TableBody>
							{(rowsPerPage > 0
								? bodyData.slice(
										page * rowsPerPage,
										page * rowsPerPage + rowsPerPage
								  )
								: bodyData
							).map((row, rowInd) => (
								<TableRow
									key={rowInd}
									sx={{
										"&:last-child td, &:last-child th": {
											border: 0,
										},
									}}
									style={{
										background:
											stripped && rowInd % 2 == 1 ? "#E9EBEE" : "#fff",
									}}
								>
									{keyList.map((key, ind) => {
										const specialIndex = specialStyles.findIndex(
											(obj) => obj.key === key
										);
										return (
											<TableCell
												align="left"
												style={
													specialIndex !== -1
														? specialStyles[specialIndex].styles
														: null
												}
												onClick={() => {
													specialActions.map((item) => {
														if (item.key === key) {
															console.log("rowInd", rowInd);
															item.action(rowInd);
														}
													});
												}}
												key={ind}
											>
												{row[key]}
											</TableCell>
										);
									})}
								</TableRow>
							))}
							{emptyRows > 0 && (
								<TableRow style={{ height: 53 * emptyRows }}>
									<TableCell colSpan={keyList.length} />
								</TableRow>
							)}
						</TableBody>

						{showPagination && (
							<TableFooter>
								<TableRow>
									<TablePagination
										rowsPerPageOptions={[
											5,
											10,
											25,
											{ label: "All", value: -1 },
										]}
										colSpan={keyList.length}
										count={bodyData.length}
										rowsPerPage={rowsPerPage}
										page={page}
										SelectProps={{
											inputProps: {
												"aria-label": "rows per page",
											},
											native: true,
										}}
										onPageChange={handleChangePage}
										onRowsPerPageChange={handleChangeRowsPerPage}
										ActionsComponent={TablePaginationActions}
									/>
								</TableRow>
							</TableFooter>
						)}
					</>
				) : (
					<TableBody>
						<TableRow>
							<TableCell colSpan={headers.length} sx={{ borderBottom: 0 }}>
								<EmptyState
									message={emptyState?.message || "Nothing to see here"}
									button={
										emptyState?.button && {
											label: emptyState?.button?.label || "Empty button",
											action: emptyState?.button?.action,
										}
									}
								/>
							</TableCell>
						</TableRow>
					</TableBody>
				)}
			</Table>
		</TableContainer>
	);
};

export default MUITable;

interface TablePaginationActionsProps {
	count: number;
	page: number;
	rowsPerPage: number;
	onPageChange: (
		event: React.MouseEvent<HTMLButtonElement>,
		newPage: number
	) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
	const theme = useTheme();
	const { count, page, rowsPerPage, onPageChange } = props;

	const handleFirstPageButtonClick = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		onPageChange(event, 0);
	};

	const handleBackButtonClick = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		onPageChange(event, page - 1);
	};

	const handleNextButtonClick = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		onPageChange(event, page + 1);
	};

	const handleLastPageButtonClick = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	return (
		<Box sx={{ flexShrink: 0, ml: 2.5 }}>
			<IconButton
				onClick={handleFirstPageButtonClick}
				disabled={page === 0}
				aria-label="first page"
			>
				{theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
			</IconButton>
			<IconButton
				onClick={handleBackButtonClick}
				disabled={page === 0}
				aria-label="previous page"
			>
				{theme.direction === "rtl" ? (
					<KeyboardArrowRight />
				) : (
					<KeyboardArrowLeftIcon />
				)}
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="next page"
			>
				{theme.direction === "rtl" ? (
					<KeyboardArrowLeftIcon />
				) : (
					<KeyboardArrowRight />
				)}
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="last page"
			>
				{theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
			</IconButton>
		</Box>
	);
}
