import { GlobeIcon, PlaneIcon } from "../../components/pieces/svgs";
import "./style.scss";

interface CustomLoaderProps {
	info?:
		| { locations: string; dates: string; other: string }
		| { locations: string; dates: string; other: string }[];
}

const CustomLoader = ({ info }: CustomLoaderProps) => {
	console.log("info", info);

	return (
		<div className="CustomLoader">
			<div className="banner">
				<div className="dotted-circle">
					<div className="globe">
						<GlobeIcon />
					</div>
					<div className="plane-wrapper">
						<div className="plane">
							<PlaneIcon />
						</div>
					</div>
				</div>
			</div>
			{Array.isArray(info) ? (
				<>
					<div className="info">
						<h5 className="locations">
							{info.map((infoItem) => `${infoItem.locations}; `)}
						</h5>
						<p className="dates">
							on {info.map((infoItem) => `${infoItem.dates}; `)}
						</p>
						<p className="other">
							{info.map((infoItem) => `${infoItem.other}; `)}
						</p>
					</div>
				</>
			) : (
				info && (
					<div className="info">
						<h5 className="locations">{info.locations}</h5>
						<p className="dates">on {info.dates}</p>
						<p className="other">{info.other}</p>
					</div>
				)
			)}
		</div>
	);
};

export default CustomLoader;
