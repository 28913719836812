import "./style.scss";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { FormControl, Radio } from "@mui/material";
import PlainTable from "../components/PlainTable";
import Button from "../components/Button";
import {
	BaggageIcon,
	RefundableIcon,
	DepartureIcon,
	ArrivalIcon,
	NonRefundableIcon,
	KachiSpinner,
} from "../components/pieces/svgs";
import CustomDropdown from "../components/CustomDropdown";
import { BiSearch, BiCreditCardFront } from "react-icons/bi";
import {
	BsMoonStars,
	BsFillSunFill,
	BsFillCheckCircleFill,
} from "react-icons/bs";
import { GrSearch } from "react-icons/gr";
import { HiArrowLeft } from "react-icons/hi";
import { RiLoader5Fill, RiCheckboxCircleLine } from "react-icons/ri";
import { IoMdShareAlt } from "react-icons/io";
import { ImSpinner8 } from "react-icons/im";
import { FiCheck } from "react-icons/fi";
import { FaRegCheckCircle } from "react-icons/fa";
import { TbCopy } from "react-icons/tb";
import {
	IoChevronDown,
	IoChevronUp,
	IoBookmarkSharp,
	IoClose,
} from "react-icons/io5";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import emirates from "../resource/img/emirates.svg";
import LoadingAnimation from "../components/LoadingAnimation";
import SpinLoader from "../components/SpinLoader";
import useAxios from "axios-hooks";
import { toast } from "react-toastify";
import { usePaystackPayment } from "react-paystack";
import { useTripStore } from "../zustand/store";
// import Tooltip from "@mui/material";
import { DateTime } from "ts-luxon";
import DatePicker, {
	getAllDatesInRange,
	DateObject,
} from "react-multi-date-picker";
import {
	createTraveller,
	updateTraveller,
	verifyPayment,
	shareTripDetails,
} from "../requests";
import passengercheck from "../resource/img/passengercheck.svg";
import back from "../resource/img/back.svg";
import {
	addPaystackCharges,
	diffObjToDuration,
	isNightFlight,
	minutesToDuration,
} from "../helpers/helperFuncs";
import { emailListRegex } from "../helpers/constants";
import opacity from "react-element-popper/animations/opacity";
import transition from "react-element-popper/animations/transition";
import { useNavigate } from "react-router-dom";
import { addCredit, convertPoints } from "../requests/dash";
import { BlackTooltip } from "../components/FlightInfoDrawer";
import { Tooltip } from "@mui/material";
import { ReactComponent as AddUserIcon } from "../assets/svgs/add-user.svg";
import { ReactComponent as PhWarningIcon } from "../assets/svgs/ph_warning-bold.svg";
import { CgClose } from "react-icons/cg";
import DateDropDown from "../components/DateDropDown";
import SwitchPassengerComponent from "../components/SwitchPassengerComponent";
import PhoneDropdown from "../components/PhoneDropdown/PhoneDropdown";
import { FRONTEND_URL } from "../config/env";

export const FareBreakdownModal = () => (
	<div className="FareBreakdownModal">
		<div className="body">
			<div className="text-row">
				<p>5x Adult</p>
				<p>₦406,390 NGN</p>
			</div>
			<div className="text-row">
				<p>Taxes and Fees</p>
				<p>₦406,390 NGN</p>
			</div>
			<div className="text-row">
				<p>All Passengers</p>
				<p>₦406,390 NGN</p>
			</div>
		</div>
		<div style={{ paddingLeft: 0, paddingRight: 0 }}>
			<div className="total">
				<p>Grand Total</p>
				<p>₦406,390 NGN</p>
			</div>
		</div>
	</div>
);
export default FareBreakdownModal;

export const ShareModal = ({ flightInfo }: { flightInfo: any }) => {
	const [copied, setCopied] = useState(false);
	const bigFlightData = useTripStore((state: any) => state.bigFlightData);
	const localAirports = useTripStore((state: any) => state.localAirports);
	const globalAirports = useTripStore((state: any) => state.globalAirports);

	const isRoundTripAndIntl =
		bigFlightData?.showTripType === "Roundtrip" &&
		bigFlightData?.route === "international";

	const isMulticityAndIntl =
		bigFlightData.showTripType === "Multicity" &&
		bigFlightData.route === "international";

	const airportList =
		bigFlightData?.route === "domestic" ? localAirports : globalAirports;

	const generateCopy = () => {
		let text = "";

		text += isMulticityAndIntl
			? flightInfo?.outbound.map(
					(outboundArr: any, ind: number) =>
						`${outboundArr[0]?.airline_details?.name}${
							ind < flightInfo?.outbound?.length - 1 ? " / " : ""
						}`
			  ) + "\n"
			: `${flightInfo?.outbound[0]?.airline_details?.name}${
					isRoundTripAndIntl
						? ` / ${flightInfo?.outbound_return[0]?.airline_details?.name}`
						: ""
			  }` + "\n";
		text +=
			"Fare: " +
			(
				flightInfo?.amount +
				(bigFlightData?.route === "international" ? flightInfo?.tax : 0)
			)
				?.toFixed(2)
				?.replace(/\d(?=(\d{3})+\.)/g, "$&,") +
			" NGN\n";

		text +=
			"Class: " +
			(isMulticityAndIntl
				? flightInfo?.outbound[0][0]?.cabin_type
				: flightInfo?.outbound[0]?.cabin_type) +
			"\n" +
			"\n";

		if (isMulticityAndIntl)
			flightInfo?.outbound?.forEach((outboundArr: any, legInd: number) => {
				text += `Flight ${legInd + 1}: \n`;
				outboundArr?.forEach((route: any, ind: number) => {
					text +=
						airportList.filter(
							(item: any) => item?.iata_code === route?.airport_from
						)[0]?.city +
						" to " +
						airportList.filter(
							(item: any) => item?.iata_code === route?.airport_to
						)[0]?.city +
						"\n";

					text +=
						DateTime.fromISO(route.departure_time)
							.setLocale("en-US")
							.toLocaleString({
								// weekday: "short",
								day: "2-digit",
								month: "short",
							}) +
						" | " +
						DateTime.fromISO(route.departure_time)
							.setLocale("en-US")
							.toLocaleString(DateTime.TIME_SIMPLE) +
						" - " +
						DateTime.fromISO(route.arrival_time)
							.setLocale("en-US")
							.toLocaleString(DateTime.TIME_SIMPLE) +
						"\n" +
						(ind < flightInfo?.outbound[legInd]?.length - 1 ? "" : "\n");
					if (ind < flightInfo?.outbound[legInd]?.length - 1)
						text +=
							diffObjToDuration(
								DateTime.fromISO(
									flightInfo.outbound[legInd][ind + 1].departure_time
								)
									.diff(DateTime.fromISO(route.arrival_time), [
										"days",
										"hours",
										"minutes",
									])
									.toObject()
							) +
							" layover" +
							"\n" +
							"\n";
				});
			});
		else {
			text += "Departure Flight:\n";
			flightInfo?.outbound?.forEach((route: any, ind: number) => {
				text +=
					airportList.filter(
						(item: any) => item?.iata_code === route?.airport_from
					)[0]?.city +
					" " +
					"to" +
					" " +
					airportList.filter(
						(item: any) => item?.iata_code === route?.airport_to
					)[0]?.city +
					"\n";

				text +=
					DateTime.fromISO(route.departure_time)
						.setLocale("en-US")
						.toLocaleString({
							// weekday: "short",
							day: "2-digit",
							month: "short",
						}) +
					" | " +
					DateTime.fromISO(route.departure_time)
						.setLocale("en-US")
						.toLocaleString(DateTime.TIME_SIMPLE) +
					" - " +
					DateTime.fromISO(route.arrival_time)
						.setLocale("en-US")
						.toLocaleString(DateTime.TIME_SIMPLE) +
					"\n" +
					(ind < flightInfo?.outbound?.length - 1 ? "" : "\n");

				if (ind < flightInfo?.outbound?.length - 1)
					text +=
						diffObjToDuration(
							DateTime.fromISO(flightInfo.outbound[ind + 1].departure_time)
								.diff(DateTime.fromISO(route.arrival_time), [
									"days",
									"hours",
									"minutes",
								])
								.toObject()
						) +
						" layover" +
						"\n" +
						"\n";
			});
		}
		if (isRoundTripAndIntl) {
			text += "Return Flight:\n";
			flightInfo?.outbound_return?.forEach((route: any, ind: number) => {
				text +=
					airportList.filter(
						(item: any) => item?.iata_code === route?.airport_from
					)[0]?.city +
					" " +
					"to" +
					" " +
					airportList.filter(
						(item: any) => item?.iata_code === route?.airport_to
					)[0]?.city +
					"\n";

				text +=
					DateTime.fromISO(route.departure_time)
						.setLocale("en-US")
						.toLocaleString({
							// weekday: "short",
							day: "2-digit",
							month: "short",
						}) +
					" | " +
					DateTime.fromISO(route.departure_time)
						.setLocale("en-US")
						.toLocaleString(DateTime.TIME_SIMPLE) +
					" - " +
					DateTime.fromISO(route.arrival_time)
						.setLocale("en-US")
						.toLocaleString(DateTime.TIME_SIMPLE) +
					"\n" +
					(ind < flightInfo?.outbound_return?.length - 1 ? "" : "\n");

				if (ind < flightInfo?.outbound_return?.length - 1)
					text +=
						diffObjToDuration(
							DateTime.fromISO(
								flightInfo.outbound_return[ind + 1].departure_time
							)
								.diff(DateTime.fromISO(route.arrival_time), [
									"days",
									"hours",
									"minutes",
								])
								.toObject()
						) +
						" layover" +
						"\n" +
						"\n";
			});
		}

		return text;
	};

	console.log("share", flightInfo);

	return (
		<div className="ShareModal">
			<div>
				<p className="title">
					{isMulticityAndIntl
						? flightInfo?.outbound.map(
								(outboundArr: any, ind: number) =>
									`${outboundArr[0]?.airline_details?.name}${
										ind < flightInfo?.outbound?.length - 1 ? " / " : ""
									}`
						  )
						: `${flightInfo?.outbound[0]?.airline_details?.name}${
								isRoundTripAndIntl
									? ` / ${flightInfo?.outbound_return[0]?.airline_details?.name}`
									: ""
						  }`}
				</p>
				<div className="text-row">
					<p>Fare:&nbsp;</p>
					<p>
						{" "}
						{(
							flightInfo?.amount +
							(bigFlightData?.route === "international" ? flightInfo?.tax : 0)
						)
							?.toFixed(2)
							?.replace(/\d(?=(\d{3})+\.)/g, "$&,")}{" "}
						NGN
					</p>
				</div>
				<div className="text-row">
					<p>Class:&nbsp;</p>
					<p>
						{isMulticityAndIntl
							? flightInfo?.outbound[0][0]?.cabin_type
							: flightInfo?.outbound[0]?.cabin_type}
					</p>
				</div>
				{/* <div className="text-row">
					<p></p>
					<p>
						{DateTime.fromISO(
							flightInfo?.outbound[0]?.departure_time
						).toLocaleString({
							// weekday: "short",
							day: "2-digit",
							month: "short",
						})}
					</p>
				</div> */}
				<div className="trips">
					{isMulticityAndIntl ? (
						<>
							{flightInfo?.outbound.map(
								(outboundArr: any[], legInd: number) => {
									return (
										<>
											<p className="flightTitle">Flight {legInd + 1}</p>
											{outboundArr?.map((route: any, ind: number) => (
												<FlightBatch
													route={route}
													layover={
														ind < flightInfo?.outbound[legInd]?.length - 1
															? diffObjToDuration(
																	DateTime.fromISO(
																		flightInfo.outbound[legInd][ind + 1]
																			.departure_time
																	)
																		.diff(
																			DateTime.fromISO(route.arrival_time),
																			["days", "hours", "minutes"]
																		)
																		.toObject()
															  )
															: ""
													}
													showLayover={
														ind < flightInfo?.outbound[legInd]?.length - 1
													}
												/>
											))}
										</>
									);
								}
							)}
						</>
					) : (
						<>
							<p className="flightTitle">Departure Flight</p>
							{flightInfo?.outbound?.map((route: any, ind: number) => (
								<FlightBatch
									route={route}
									layover={
										ind < flightInfo?.outbound?.length - 1
											? diffObjToDuration(
													DateTime.fromISO(
														flightInfo.outbound[ind + 1].departure_time
													)
														.diff(DateTime.fromISO(route.arrival_time), [
															"days",
															"hours",
															"minutes",
														])
														.toObject()
											  )
											: ""
									}
									showLayover={ind < flightInfo?.outbound?.length - 1}
								/>
							))}

							{isRoundTripAndIntl && (
								<>
									<p className="flightTitle">Return Flight</p>
									{flightInfo?.outbound_return?.map(
										(route: any, ind: number) => (
											<FlightBatch
												route={route}
												layover={
													ind < flightInfo?.outbound_return?.length - 1
														? diffObjToDuration(
																DateTime.fromISO(
																	flightInfo.outbound_return[ind + 1]
																		.departure_time
																)
																	.diff(DateTime.fromISO(route.arrival_time), [
																		"days",
																		"hours",
																		"minutes",
																	])
																	.toObject()
														  )
														: ""
												}
												showLayover={ind < flightInfo?.outbound?.length - 1}
											/>
										)
									)}
								</>
							)}
						</>
					)}
				</div>
			</div>
			<div className={`copy-text ${copied && "copy-text--copied"}`}>
				<button
					onClick={() => {
						navigator.clipboard.writeText(generateCopy());
						setCopied(true);

						setTimeout(() => {
							setCopied(false);
						}, 1500);
					}}
				>
					{copied ? "Copied to clipboard!" : "Copy Text"}
				</button>
			</div>
		</div>
	);
};

const FlightBatch = ({
	route,
	layover,
	showLayover,
}: {
	route: any;
	layover: string;
	showLayover: boolean;
}) => {
	const bigFlightData = useTripStore((state: any) => state.bigFlightData);
	const localAirports = useTripStore((state: any) => state.localAirports);
	const globalAirports = useTripStore((state: any) => state.globalAirports);

	const airportList =
		bigFlightData?.route === "domestic" ? localAirports : globalAirports;

	return (
		<div className="flight-batch">
			<div className="text-row">
				{/* <p>Route{flightInfo?.outbound?.length > 1 && "1"}</p> */}
				<p></p>
				<p>
					{
						airportList.filter(
							(item: any) => item?.iata_code === route?.airport_from
						)[0]?.city
					}{" "}
					to{" "}
					{
						airportList.filter(
							(item: any) => item?.iata_code === route?.airport_to
						)[0]?.city
					}
				</p>
			</div>

			<div className="text-row">
				<p></p>
				<p style={{ color: "#655f5f" }}>
					<span style={{ color: "#252627" }}>
						{DateTime.fromISO(route.departure_time)
							.setLocale("en-US")
							.toLocaleString({
								// weekday: "short",
								day: "2-digit",
								month: "short",
							})}
					</span>
					{" | "}
					<span>
						{DateTime.fromISO(route.departure_time)
							.setLocale("en-US")
							.toLocaleString(DateTime.TIME_SIMPLE)}
					</span>
					{" - "}
					<span>
						{DateTime.fromISO(route.arrival_time)
							.setLocale("en-US")
							.toLocaleString(DateTime.TIME_SIMPLE)}
					</span>
				</p>
			</div>
			{showLayover && (
				<div className="text-row">
					<p style={{ color: "#252627" }}>{layover} layover</p>
				</div>
			)}
		</div>
	);
};

export const PaymentMethodModal = ({
	confirmBooking,
	loading,
	handleClose,
	reference,
	setReference,
	amount,
	hideOnHold = false,
}: {
	confirmBooking: (val: string, latestReference?: string) => void;
	loading: boolean;
	handleClose: () => void;
	reference: string;
	setReference: any;
	amount: number;
	hideOnHold?: boolean;
}) => {
	const [selected, setSelected] = useState("");

	const [fundAmount, setFundAmount] = useState(0);
	const [localreference, setLocalReference] = useState(
		new Date().getTime().toString()
	);

	const userData = useTripStore((state: any) => state.userData);
	const bigFlightData = useTripStore((state: any) => state.bigFlightData);
	const updateBigFlightData = useTripStore(
		(state: any) => state.updateBigFlightData
	);

	//   PAYSTACK CONFIGS
	let config = {
		reference: localreference,
		email: userData?.email,
		amount: Math.ceil(
			addPaystackCharges(
				selected === "pay_with_wallet" ? fundAmount : Math.round(amount)
			) * 100
		),
		publicKey: `${process.env.REACT_APP_PAYSTACK_KEY}`,
	};

	const onSuccess = (ref: any) => {
		console.log("refObj", ref);
		console.log("paystackRef", ref);
		setLocalReference(ref.reference);
		setReference(ref.reference);
		console.log("reference", reference);
		if (selected === "pay_with_card") {
			updateBigFlightData({
				paymentConfirmed: true,
			});
			confirmBooking("pay_with_card", ref.reference);
		} else {
		}
	};

	const otherMethodPaymentFunc = async (selected: string) => {
		try {
			confirmBooking(selected);
		} catch (e) {
			console.log("Something went wrong.");
		}
	};

	const onClose = () => {
		console.log("closed");
	};

	const PaystackHookExample = ({ label }: { label: string }) => {
		const initializePayment: any = usePaystackPayment(config);
		return (
			<Button
				className="pry"
				onClick={(e: any) => {
					initializePayment(onSuccess, onClose);
				}}
				disabled={selected === ""}
			>
				{label}
			</Button>
		);
	};

	const fundWallet = () => {
		return (
			<div className="modal-box">
				<p className="modal_head_text">
					Fund your wallet and get your credit topped
				</p>
				<form>
					<input
						type="number"
						required
						placeholder="Amount"
						className="modal-form"
						onChange={(e) => setFundAmount(e.target.valueAsNumber)}
					/>
					<PaystackHookExample label="Fund wallet" />
				</form>
				<div>
					<button
						className="payment-cancel modal-button"
						onClick={() => handleClose()}
					>
						Cancel Payment
					</button>
				</div>
			</div>
		);
	};

	useEffect(() => {
		console.log(selected);
	}, [selected]);

	return (
		<div className="PaymentMethodModal">
			<div className="PaymentMethodModal__head">
				<p>Choose your preferred payment method</p>
			</div>
			<div className="PaymentMethodModal__body">
				<FormControl style={{ display: "flex", alignItems: "strwtch" }}>
					{!hideOnHold && (
						<div className="radio-option">
							<Radio
								checked={selected === "book_on_hold"}
								onChange={(e) => {
									setSelected("book_on_hold");
								}}
								value="book_on_hold"
								name="payment-options"
								inputProps={{ "aria-label": "A" }}
							/>
							<div
								style={{ width: "100%" }}
								onClick={(e) => {
									setSelected("book_on_hold");
								}}
							>
								<p className="title">Book on hold</p>
								<p>Hold this trip pending confirmation. (T & C applies)</p>
							</div>
						</div>
					)}
					<div className="radio-option">
						<Radio
							checked={selected === "pay_with_wallet"}
							onChange={(e) => {
								setSelected("pay_with_wallet");
							}}
							value="pay_with_wallet"
							name="payment-options"
							inputProps={{ "aria-label": "A" }}
						/>
						<div
							onClick={(e) => {
								setSelected("pay_with_wallet");
							}}
						>
							<p className="title">Pay with wallet</p>
							<p>Use your existing wallet balance to pay for this trip</p>
						</div>
					</div>
					<div className="radio-option">
						<Radio
							checked={selected === "pay_with_credit"}
							onChange={(e) => setSelected("pay_with_credit")}
							value="pay_with_credit"
							name="payment-options"
							inputProps={{ "aria-label": "B" }}
						/>
						<div onClick={(e) => setSelected("pay_with_credit")}>
							<p className="title">Pay with credit</p>
							<p>
								Standard rates apply (₦
								{bigFlightData.route === "domestic" ? 2000 : 10000} flat rate
								fee/passenger){" "}
							</p>
						</div>
					</div>
					<div className="radio-option">
						<Radio
							checked={selected === "pay_with_card"}
							onChange={(e) => setSelected("pay_with_card")}
							value="pay_with_card"
							name="payment-options"
							inputProps={{ "aria-label": "C" }}
						/>
						<div onClick={(e) => setSelected("pay_with_card")}>
							<p className="title">Pay with card</p>
							<p>Standard rates apply</p>
						</div>
					</div>
				</FormControl>
				<div className="btn_wrapper">
					{selected === "pay_with_card" ? (
						<PaystackHookExample label="Proceed" />
					) : (
						<Button
							className="pry"
							onClick={() => {
								otherMethodPaymentFunc(selected);
							}}
							disabled={selected === ""}
						>
							Proceed
						</Button>
					)}
				</div>
			</div>
		</div>
	);
};

export const PassengerDetailsModal = ({
	userInfo,
	handleClose,
	refetchFunc,
}: {
	userInfo: any;
	handleClose: () => void;
	refetchFunc: () => void;
}) => {
	const bigFlightData = useTripStore((state: any) => state.bigFlightData);
	const updateBigFlightData = useTripStore(
		(state: any) => state.updateBigFlightData
	);
	const countryList = useTripStore((state: any) => state.countryList);

	const [saveLoading, setSaveLoading] = useState(false);
	const [passengerData, setPassengerData] = useState({ ...userInfo });

	console.log("countryList", countryList);

	return (
		<div className="PassengerDetailsModal">
			<div className="PassengerDetailsModal__head">
				<p>Passenger's Details</p>
			</div>
			<div className="PassengerDetailsModal__body">
				<div className="form-section">
					<form
						onSubmit={async (e: any) => {
							e.preventDefault();
							console.log(passengerData);

							setSaveLoading(true);
							try {
								const { id, ...newPassengerRest } = passengerData;
								const res = await updateTraveller(
									passengerData?.id,
									newPassengerRest
								);
								console.log(res);
								if (res?.status === 201 || res?.status === 200) {
									toast.success("Traveller information successfully updated.");

									const newArr = [...bigFlightData.selectedTraveller];
									const ind = newArr.findIndex((obj: any) => obj?.id === id);
									newArr[ind] = passengerData;
									updateBigFlightData({ selectedTraveller: newArr });
									refetchFunc && refetchFunc();

									handleClose();
								}
							} catch (e) {
								console.log(e);
								toast.error("Something went wrong.");
							} finally {
								setSaveLoading(false);
							}
						}}
					>
						<div className="form-row">
							<div className="form-item">
								<p>Title</p>
								<CustomDropdown
									onSelect={(val: any) => {
										setPassengerData({
											...passengerData,
											title: val,
										});
									}}
									defaultValue={passengerData.title}
									optionsList={[
										{ label: "Mr", value: "Mr" },
										{ label: "Mrs", value: "Mrs" },
										{ label: "Ms", value: "Ms" },
									]}
									ind={0}
								/>
							</div>
							<div className="form-item">
								<p>First Name</p>
								<input
									type="text"
									value={passengerData.first_name}
									onChange={(e) => {
										setPassengerData({
											...passengerData,
											first_name: e.target.value,
										});
									}}
								/>
							</div>
						</div>
						<div className="form-row">
							<div className="form-item">
								<p>Middle Name</p>
								<input
									type="text"
									value={passengerData.middle_name}
									onChange={(e) => {
										setPassengerData({
											...passengerData,
											middle_name: e.target.value,
										});
									}}
									required={false}
								/>
							</div>
							<div className="form-item">
								<p>Surname</p>
								<input
									type="text"
									value={passengerData.last_name}
									onChange={(e) => {
										setPassengerData({
											...passengerData,
											last_name: e.target.value,
										});
									}}
								/>
							</div>
						</div>
						<div className="form-row">
							<div className="form-item">
								<p>Gender</p>
								<CustomDropdown
									onSelect={(val: any) => {
										setPassengerData({
											...passengerData,
											gender: val,
										});
									}}
									defaultValue={passengerData.gender}
									optionsList={[
										{ label: "Male", value: "male" },
										{ label: "Female", value: "female" },
									]}
									ind={1}
								/>
							</div>
							<div className="form-item">
								<p>Date of Birth</p>
								<DatePicker
									className="teal"
									placeholder="select..."
									// value={newPassenger.date_of_birth}
									value={new Date(
										passengerData.date_of_birth
									).toLocaleDateString("sv-SE")}
									onChange={(dateObject: DateObject) => {
										setPassengerData({
											...passengerData,
											date_of_birth: new Date(
												dateObject.toString()
											).toLocaleDateString("sv-SE"),
										});
									}}
									inputClass="custom-rmdp-input"
									numberOfMonths={1}
									mapDays={(object: any) => {
										return {
											disabled: object.today < object.date ? true : false,
										};
									}}
									required={true}
									animations={[
										opacity(),
										transition({ from: 35, duration: 400 }),
									]}
								/>
							</div>
						</div>
						<div className="form-row">
							<div className="form-item">
								<p>Phone number</p>
								<input
									type="text"
									value={passengerData.phone_number}
									onChange={(e) => {
										setPassengerData({
											...passengerData,
											phone_number: e.target.value,
										});
									}}
								/>
							</div>
							<div className="form-item">
								<p>Email address</p>
								<input
									type="text"
									value={passengerData.email}
									onChange={(e) => {
										setPassengerData({
											...passengerData,
											email: e.target.value,
										});
									}}
								/>
							</div>
						</div>
						<div className="form-row">
							<div className="form-item">
								<p>Passport number</p>
								<input
									type="text"
									value={passengerData.identification_number}
									onChange={(e) => {
										setPassengerData({
											...passengerData,
											identification_number: e.target.value,
										});
									}}
								/>
							</div>
							{/* <div className="form-item">
								<p>Issue date</p>
								<DatePicker
									className="teal"
									placeholder="select..."
									value={new Date(
										passengerData.identification_issue
									).toLocaleDateString("sv-SE")}
									onChange={(dateObject: DateObject) => {
										setPassengerData({
											...passengerData,
											identification_issue: new Date(
												dateObject.toString()
											).toLocaleDateString("sv-SE"),
										});
									}}
									inputClass="custom-rmdp-input"
									numberOfMonths={1}
									mapDays={(object: any) => {
										return {
											disabled: object.today < object.date ? true : false,
										};
									}}
									required={true}
									animations={[
										opacity(),
										transition({ from: 35, duration: 400 }),
									]}
								/>
							</div> */}
						</div>
						<div className="form-row">
							<div className="form-item">
								<p>Expiry date</p>
								<DatePicker
									className="teal"
									placeholder="select..."
									value={new Date(
										passengerData.identification_expiry
									).toLocaleDateString("sv-SE")}
									onChange={(dateObject: DateObject) => {
										setPassengerData({
											...passengerData,
											identification_expiry: new Date(
												dateObject.toString()
											).toLocaleDateString("sv-SE"),
										});
									}}
									inputClass="custom-rmdp-input"
									numberOfMonths={1}
									mapDays={(object: any) => {
										return {
											disabled: object.today > object.date ? true : false,
										};
									}}
									required={true}
									animations={[
										opacity(),
										transition({ from: 35, duration: 400 }),
									]}
								/>
							</div>
							<div className="form-item">
								<p>Nationality</p>
								<CustomDropdown
									onSelect={(val: any) => {
										setPassengerData({
											...passengerData,
											nationality: val,
										});
									}}
									defaultValue={passengerData.nationality}
									optionsList={countryList.map((item: any) => ({
										label: item?.name?.common,
										value: item?.cca2,
									}))}
									ind={2}
								/>
							</div>
						</div>

						<div className="button-wrapper-container">
							{/* <p>Update profile</p> */}
							<Button className="pry" type="submit" loading={saveLoading}>
								Change
							</Button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export const FlightInfoModal = ({
	type,
	singleFlight,
}: {
	type: "departure" | "arrival";
	singleFlight: any;
}) => {
	const bigFlightData = useTripStore((state: any) => state.bigFlightData);
	const globalAirports = useTripStore((state: any) => state.globalAirports);

	const isRoundTripAndIntl =
		bigFlightData?.showTripType === "Roundtrip" &&
		bigFlightData?.route === "international";

	console.log("singleFlight", singleFlight);
	return (
		<div className="FlightInfoModal">
			<div className="Departure_nightDay">
				<p>
					{type === "departure" ? "Departure" : "Arrival"}{" "}
					{DateTime.fromISO(
						singleFlight?.outbound[0].departure_time
					).toLocaleString({
						weekday: "short",
						day: "2-digit",
						month: "short",
					})}
				</p>

				<BsMoonStars />
			</div>
			{(type === "arrival" && isRoundTripAndIntl
				? singleFlight.outbound_return
				: singleFlight.outbound
			).map((trip: any, ind: number) => (
				<div className="singleFlight" key={ind}>
					<div className="singleFlight__imgData">
						<img
							src={`https://image.tiqwa.com/airlines/${trip.airline_details.code}.png`}
							className="logo"
							alt=""
						/>
						<div>
							<div
								className="singleFlight__left-grid"
								style={{ marginBottom: "1.2px" }}
							>
								<span></span>
								<span></span>
								<p>
									{DateTime.fromISO(trip.departure_time).toLocaleString({
										weekday: "short",
										day: "2-digit",
										month: "short",
									})}
								</p>
							</div>
							<div className="onePlane">
								<div className="singleFlight__left-grid">
									<DepartureIcon />
									<p className="big-black">
										{DateTime.fromISO(trip.departure_time).toLocaleString(
											DateTime.TIME_24_SIMPLE
										)}
									</p>
									<p className="big-black">
										{trip.airport_from}{" "}
										{
											globalAirports.filter(
												(item: any) => item.iata_code === trip.airport_from
											)[0]?.name
										}
									</p>
								</div>
								<div className="singleFlight__left-grid">
									<ArrivalIcon />
									<p className="big-black">
										{DateTime.fromISO(trip.arrival_time).toLocaleString(
											DateTime.TIME_24_SIMPLE
										)}
									</p>
									<p className="big-black">
										{trip.airport_to}{" "}
										{
											globalAirports.filter(
												(item: any) => item.iata_code === trip.airport_to
											)[0]?.name
										}
									</p>
								</div>
							</div>
							<div
								className="singleFlight__left-grid"
								style={{ marginTop: "0.5rem" }}
							>
								<span></span>
								{bigFlightData.route === "domestic" ? (
									<p>
										<span>
											{Math.floor(singleFlight.total_outbound_duration / 60) +
												"h"}
										</span>
										<span> </span>
										<span>
											{(singleFlight.total_outbound_duration % 60) + "m"}
										</span>
									</p>
								) : // <p>
								//   <span>
								//     {!isNaN(
								//       new Date(flightInfo.total_outbound_duration).getDate()
								//     )
								//       ? new Date(
								//           flightInfo.total_outbound_duration
								//         ).getDate() + "d"
								//       : null}
								//   </span>
								//   <span> </span>
								//   <span>
								//     {isNaN(
								//       new Date(
								//         flightInfo.total_outbound_duration
								//       ).getHours()
								//     )
								//       ? new Date(
								//           "0." + flightInfo.total_outbound_duration
								//         ).getHours() + "h"
								//       : new Date(
								//           flightInfo.total_outbound_duration
								//         ).getHours() + "h"}
								//   </span>
								//   <span> </span>
								//   <span>
								//     {isNaN(
								//       new Date(
								//         flightInfo.total_outbound_duration
								//       ).getMinutes()
								//     )
								//       ? new Date(
								//           "0." + flightInfo.total_outbound_duration
								//         ).getMinutes() + "m"
								//       : new Date(
								//           flightInfo.total_outbound_duration
								//         ).getMinutes() + "h"}
								//   </span>
								//   <span> </span>
								//   <span>
								//     {isNaN(
								//       new Date(
								//         flightInfo.total_outbound_duration
								//       ).getSeconds()
								//     )
								//       ? new Date(
								//           "0." + flightInfo.total_outbound_duration
								//         ).getSeconds() + "s"
								//       : new Date(
								//           flightInfo.total_outbound_duration
								//         ).getSeconds() + "h"}
								//   </span>
								// </p>
								null}
								<div className="planeInfo">
									<span>{trip.airline_details.name}</span>
									<span>•</span>
									<span>
										{trip.cabin_type.charAt(0).toUpperCase() +
											trip.cabin_type.slice(1)}
									</span>
									<span>•</span>
									<span>{trip.equipment_type}</span>
								</div>
							</div>
							{ind <
								(type === "arrival" && isRoundTripAndIntl
									? singleFlight.outbound_return
									: singleFlight.outbound
								).length -
									1 && (
								<div className="layover-banner">
									<p>Layover at {trip.airport_to}</p>
									<div>
										{(DateTime.fromISO(
											(type === "arrival" && isRoundTripAndIntl
												? singleFlight.outbound_return
												: singleFlight.outbound)[ind + 1].departure_time
										)
											.diff(DateTime.fromISO(trip.arrival_time), [
												"days",
												"hours",
												"minutes",
											])
											.toObject()?.hours as number) >= 4 ? (
											<p>Long Layover</p>
										) : (
											<></>
										)}
										<p>
											{diffObjToDuration(
												DateTime.fromISO(
													(type === "arrival" && isRoundTripAndIntl
														? singleFlight.outbound_return
														: singleFlight.outbound)[ind + 1].departure_time
												)
													.diff(DateTime.fromISO(trip.arrival_time), [
														"days",
														"hours",
														"minutes",
													])
													.toObject()
											)}
										</p>
									</div>
								</div>
							)}
						</div>
					</div>
					<div className="extras">
						<div>
							<div className="two-grid">
								<NonRefundableIcon />
								<span>Non-refundable</span>
							</div>
							<div className="two-grid">
								<BaggageIcon />
								<span>{trip.baggage}</span>
							</div>
						</div>
						{ind === 0 && (
							<div className="two-grid">
								<div></div>
								{/* <div>
										<Tooltip title="Share flight Details">
											<IoMdShareAlt
												size={18}
												onClick={() => {
													setOpenShare(true);
												}}
											/>
										</Tooltip>
										<Tooltip title="Save flight Details">
											<IoBookmarkSharp size={14} />
										</Tooltip>
									</div> */}
							</div>
						)}
					</div>
				</div>
			))}
		</div>
	);
};

export const FullItenaryModal = () => {
	const bigFlightData = useTripStore((state: any) => state.bigFlightData);
	const globalAirports = useTripStore((state: any) => state.globalAirports);

	const isRoundTripAndIntl =
		bigFlightData?.showTripType === "Roundtrip" &&
		bigFlightData?.route === "international";

	return (
		<div className="FullItenaryModal">
			{bigFlightData?.showTripType === "Multicity" ? (
				<>
					{bigFlightData?.selectedMultiCityFlights?.map(
						(singleFlight: any, ind: number) => (
							<FlightInfoModal singleFlight={singleFlight} type="departure" />
						)
					)}
				</>
			) : (
				<>
					<FlightInfoModal
						singleFlight={bigFlightData?.selectedDeparture}
						type="departure"
					/>
					{bigFlightData?.showTripType === "Roundtrip" && (
						<FlightInfoModal
							singleFlight={bigFlightData?.selectedArrival}
							type="arrival"
						/>
					)}
				</>
			)}
		</div>
	);
};

interface FullItenaryMobileDrawerProps {
	mobileIsOpen: boolean;
	setMobileIsOpen: Dispatch<SetStateAction<boolean>>;
	selected?: boolean;
	onSelect?: () => void;
	setOpenShare: Dispatch<SetStateAction<boolean>>;
	setOpenBreakdown: Dispatch<SetStateAction<boolean>>;
	route: string;
}

export const FullItenaryMobileDrawer = ({
	mobileIsOpen,
	setMobileIsOpen,
	selected,
	onSelect,
	setOpenShare,
	setOpenBreakdown,
	route,
}: FullItenaryMobileDrawerProps) => {
	const globalAirports = useTripStore((state: any) => state.globalAirports);
	const bigFlightData = useTripStore((state: any) => state.bigFlightData);

	const isRoundTripAndIntl =
		bigFlightData?.showTripType === "Roundtrip" &&
		bigFlightData?.route === "international";

	const isMulticityAndIntl =
		bigFlightData.showTripType === "Multicity" &&
		bigFlightData.route === "international";

	let flightInfoArr = [];
	if (bigFlightData?.route === "domestic") {
		if (bigFlightData?.showTripType === "Oneway")
			flightInfoArr = [bigFlightData?.selectedDeparture];
		else if (bigFlightData?.showTripType === "Roundtrip")
			flightInfoArr = [
				bigFlightData?.selectedDeparture,
				bigFlightData?.selectedArrival,
			];
		else flightInfoArr = bigFlightData?.selectedMultiCityFlights;
	}

	return (
		<div
			className={`FlightInfoDrawer__MobileInfo ${
				!mobileIsOpen && "FlightInfoDrawer__MobileInfo--shut"
			}`}
		>
			<div className="FlightInfoNav">
				<p>Flight details</p>
				<IoClose onClick={() => setMobileIsOpen(false)} />
			</div>
			<div className="padding-wrapper">
				{flightInfoArr?.map((flightInfo: any, flightInfoInd: number) => (
					<div key={flightInfoInd}>
						{isMulticityAndIntl ? (
							<></>
						) : (
							<div className="Departure_nightDay">
								{isNightFlight(flightInfo.outbound[0].departure_time) ? (
									<BlackTooltip title="Night Flight">
										<BsMoonStars color="#243256" />
									</BlackTooltip>
								) : (
									<BlackTooltip title="Morning Flight">
										<BsFillSunFill color="#243256" />
									</BlackTooltip>
								)}

								<p>
									{bigFlightData?.showTripType === "Multicity"
										? `Flight${flightInfoInd + 1}`
										: bigFlightData?.showTripType === "Roundtrip" &&
										  flightInfoInd === 1
										? "Return"
										: "Departure"}{" "}
									{/* {DateTime.fromISO(departure_date).toLocaleString({
                    weekday: "short",
                    day: "2-digit",
                    month: "short",
                })} */}
								</p>
							</div>
						)}
						<div className="mobileInfo_outbounds">
							{isMulticityAndIntl ? (
								<>
									{flightInfo.outbound.map(
										(outboundItem: any, legInd: number) => (
											<>
												<div
													className="Departure_nightDay"
													style={{ marginBottom: 0 }}
												>
													{isNightFlight(outboundItem[0].departure_time) ? (
														<BlackTooltip title="Night Flight">
															<BsMoonStars color="#243256" />
														</BlackTooltip>
													) : (
														<BlackTooltip title="Morning Flight">
															<BsFillSunFill color="#243256" />
														</BlackTooltip>
													)}
													<p>
														Departure{" "}
														{/* {DateTime.fromISO(departure_date).toLocaleString({
				weekday: "short",
				day: "2-digit",
				month: "short",
			})} */}
													</p>
												</div>
												{outboundItem.map((trip: any, ind: number) => (
													<div key={ind}>
														<div className="grey-info">
															<span>
																{DateTime.fromISO(
																	trip.departure_time
																).toLocaleString({
																	weekday: "short",
																	day: "2-digit",
																	month: "short",
																})}
															</span>
															<span>•</span>
															<span>
																{trip.cabin_type.charAt(0).toUpperCase() +
																	trip.cabin_type.slice(1)}
															</span>
															<span>•</span>
															{route === "domestic" ? (
																<span>
																	{minutesToDuration(
																		flightInfo.total_outbound_duration[legInd]
																	)}
																</span>
															) : (
																<span>
																	{diffObjToDuration(
																		DateTime.fromISO(trip.arrival_time)
																			.diff(
																				DateTime.fromISO(trip.departure_time),
																				["days", "hours", "minutes"]
																			)
																			.toObject()
																	)}
																</span>
															)}
														</div>
														<div className="plenty-info">
															<div
																className="flex-center"
																style={{ marginBottom: "15px" }}
															>
																<img
																	src={`https://image.tiqwa.com/airlines/${trip.airline_details.code}.png`}
																	alt=""
																/>
																<p style={{ color: "#655F5F" }}>
																	{trip.airline_details.name}
																</p>
															</div>
															<div className="flex-start">
																<DepartureIcon />
																<div>
																	<p className="black">
																		{
																			globalAirports.filter(
																				(item: any) =>
																					item.iata_code === trip.airport_from
																			)[0]?.name
																		}
																	</p>
																	<p>
																		<span>{trip.airport_from}</span>
																		<span className="black">
																			{DateTime.fromISO(
																				trip.departure_time
																			).toLocaleString(DateTime.TIME_24_SIMPLE)}
																		</span>
																	</p>
																</div>
															</div>
															<div className="flex-start">
																<ArrivalIcon />
																<div>
																	<p className="black">
																		{
																			globalAirports.filter(
																				(item: any) =>
																					item.iata_code === trip.airport_to
																			)[0]?.name
																		}
																	</p>
																	<p>
																		<span>{trip.airport_to}</span>
																		<span className="black">
																			{DateTime.fromISO(
																				trip.arrival_time
																			).toLocaleString(DateTime.TIME_24_SIMPLE)}
																		</span>
																	</p>
																</div>
															</div>
															{ind === 0 && (
																<>
																	<div className="flex-center">
																		<BaggageIcon />
																		<p>{trip.baggage}</p>
																	</div>
																	<div className="flex-center">
																		<RefundableIcon />
																		<p>Refundable</p>
																	</div>
																</>
															)}
														</div>
														{ind < flightInfo.outbound[legInd].length - 1 && (
															<div className="blue-banner">
																<span></span>
																<div>
																	<p>Layover at {trip.airport_to}</p>
																	<p>
																		{(DateTime.fromISO(
																			flightInfo.outbound[legInd][ind + 1]
																				.departure_time
																		)
																			.diff(
																				DateTime.fromISO(trip.arrival_time),
																				["days", "hours", "minutes"]
																			)
																			.toObject()?.hours as number) >= 4 ? (
																			<p>Long Layover</p>
																		) : (
																			<></>
																		)}
																	</p>
																	<p>
																		{diffObjToDuration(
																			DateTime.fromISO(
																				flightInfo.outbound[legInd][ind + 1]
																					.departure_time
																			)
																				.diff(
																					DateTime.fromISO(trip.arrival_time),
																					["days", "hours", "minutes"]
																				)
																				.toObject()
																		)}
																	</p>
																</div>
															</div>
														)}
													</div>
												))}
											</>
										)
									)}
								</>
							) : (
								<>
									<div
										className="mobileInfo_outbounds__outbound"
										style={{
											borderBottom: isRoundTripAndIntl
												? "0.8px solid rgba(196, 196, 196, 0.5)"
												: 0,
										}}
									>
										{flightInfo.outbound.map((trip: any, ind: number) => (
											<div key={ind}>
												<div className="grey-info">
													<span>
														{DateTime.fromISO(
															trip.departure_time
														).toLocaleString({
															weekday: "short",
															day: "2-digit",
															month: "short",
														})}
													</span>
													<span>•</span>
													<span>
														{trip.cabin_type.charAt(0).toUpperCase() +
															trip.cabin_type.slice(1)}
													</span>
													<span>•</span>
													{route === "domestic" ? (
														<span>
															{minutesToDuration(
																flightInfo.total_outbound_duration
															)}
														</span>
													) : (
														<span>
															{diffObjToDuration(
																DateTime.fromISO(trip.arrival_time)
																	.diff(DateTime.fromISO(trip.departure_time), [
																		"days",
																		"hours",
																		"minutes",
																	])
																	.toObject()
															)}
														</span>
													)}
												</div>
												<div className="plenty-info">
													<div
														className="flex-center"
														style={{ marginBottom: "15px" }}
													>
														<img
															src={`https://image.tiqwa.com/airlines/${trip.airline_details.code}.png`}
															alt=""
														/>
														<p style={{ color: "#655F5F" }}>
															{trip.airline_details.name}
														</p>
													</div>
													<div className="flex-start">
														<DepartureIcon />
														<div>
															<p className="black">
																{
																	globalAirports.filter(
																		(item: any) =>
																			item.iata_code === trip.airport_from
																	)[0]?.name
																}
															</p>
															<p>
																<span>{trip.airport_from}</span>
																<span className="black">
																	{DateTime.fromISO(
																		trip.departure_time
																	).toLocaleString(DateTime.TIME_24_SIMPLE)}
																</span>
															</p>
														</div>
													</div>
													<div className="flex-start">
														<ArrivalIcon />
														<div>
															<p className="black">
																{
																	globalAirports.filter(
																		(item: any) =>
																			item.iata_code === trip.airport_to
																	)[0]?.name
																}
															</p>
															<p>
																<span>{trip.airport_to}</span>
																<span className="black">
																	{DateTime.fromISO(
																		trip.arrival_time
																	).toLocaleString(DateTime.TIME_24_SIMPLE)}
																</span>
															</p>
														</div>
													</div>
													{ind === 0 && (
														<>
															<div className="flex-center">
																<BaggageIcon />
																<p>{trip.baggage}</p>
															</div>
															<div className="flex-center">
																<RefundableIcon />
																<p>Refundable</p>
															</div>
														</>
													)}
												</div>
												{ind < flightInfo.outbound.length - 1 && (
													<div className="blue-banner">
														<span></span>
														<div>
															<p>Layover at {trip.airport_to}</p>
															<p>
																{(DateTime.fromISO(
																	flightInfo.outbound[ind + 1].departure_time
																)
																	.diff(DateTime.fromISO(trip.arrival_time), [
																		"days",
																		"hours",
																		"minutes",
																	])
																	.toObject()?.hours as number) >= 4 ? (
																	<p>Long Layover</p>
																) : (
																	<></>
																)}
															</p>
															<p>
																{diffObjToDuration(
																	DateTime.fromISO(
																		flightInfo.outbound[ind + 1].departure_time
																	)
																		.diff(DateTime.fromISO(trip.arrival_time), [
																			"days",
																			"hours",
																			"minutes",
																		])
																		.toObject()
																)}
															</p>
														</div>
													</div>
												)}
											</div>
										))}
									</div>
									{isRoundTripAndIntl && (
										<div
											className="Departure_nightDay"
											style={{ marginBottom: 0 }}
										>
											{isNightFlight(
												flightInfo.outbound_return[0].departure_time
											) ? (
												<BlackTooltip title="Night Flight">
													<BsMoonStars color="#243256" />
												</BlackTooltip>
											) : (
												<BlackTooltip title="Morning Flight">
													<BsFillSunFill color="#243256" />
												</BlackTooltip>
											)}
											<p>
												Return{" "}
												{/* {DateTime.fromISO(departure_date).toLocaleString({
                    weekday: "short",
                    day: "2-digit",
                    month: "short",
                })} */}
											</p>
										</div>
									)}
									{isRoundTripAndIntl && (
										<div className="mobileInfo_outbounds__outbound">
											{flightInfo.outbound_return.map(
												(trip: any, ind: number) => (
													<div key={ind}>
														<div className="grey-info">
															<span>
																{DateTime.fromISO(
																	trip.departure_time
																).toLocaleString({
																	weekday: "short",
																	day: "2-digit",
																	month: "short",
																})}
															</span>
															<span>•</span>
															<span>
																{trip.cabin_type.charAt(0).toUpperCase() +
																	trip.cabin_type.slice(1)}
															</span>
															<span>•</span>
															{route === "domestic" ? (
																<span>
																	{minutesToDuration(
																		flightInfo.total_outbound_duration
																	)}
																</span>
															) : (
																<span>
																	{diffObjToDuration(
																		DateTime.fromISO(trip.arrival_time)
																			.diff(
																				DateTime.fromISO(trip.departure_time),
																				["days", "hours", "minutes"]
																			)
																			.toObject()
																	)}
																</span>
															)}
														</div>
														<div className="plenty-info">
															<div
																className="flex-center"
																style={{ marginBottom: "15px" }}
															>
																<img
																	src={`https://image.tiqwa.com/airlines/${trip.airline_details.code}.png`}
																	alt=""
																/>
																<p style={{ color: "#655F5F" }}>
																	{trip.airline_details.name}
																</p>
															</div>
															<div className="flex-start">
																<DepartureIcon />
																<div>
																	<p className="black">
																		{
																			globalAirports.filter(
																				(item: any) =>
																					item.iata_code === trip.airport_from
																			)[0]?.name
																		}
																	</p>
																	<p>
																		<span>{trip.airport_from}</span>
																		<span className="black">
																			{DateTime.fromISO(
																				trip.departure_time
																			).toLocaleString(DateTime.TIME_24_SIMPLE)}
																		</span>
																	</p>
																</div>
															</div>
															<div className="flex-start">
																<ArrivalIcon />
																<div>
																	<p className="black">
																		{
																			globalAirports.filter(
																				(item: any) =>
																					item.iata_code === trip.airport_to
																			)[0]?.name
																		}
																	</p>
																	<p>
																		<span>{trip.airport_to}</span>
																		<span className="black">
																			{DateTime.fromISO(
																				trip.arrival_time
																			).toLocaleString(DateTime.TIME_24_SIMPLE)}
																		</span>
																	</p>
																</div>
															</div>
															{ind === 0 && (
																<>
																	<div className="flex-center">
																		<BaggageIcon />
																		<p>{trip.baggage}</p>
																	</div>
																	<div className="flex-center">
																		<RefundableIcon />
																		<p>Refundable</p>
																	</div>
																</>
															)}
														</div>
														{ind < flightInfo.outbound_return.length - 1 && (
															<div className="blue-banner">
																<span></span>
																<div>
																	<p>Layover at {trip.airport_to}</p>
																	<p>
																		{(DateTime.fromISO(
																			flightInfo.outbound_return[ind + 1]
																				.departure_time
																		)
																			.diff(
																				DateTime.fromISO(trip.arrival_time),
																				["days", "hours", "minutes"]
																			)
																			.toObject()?.hours as number) >= 4 ? (
																			<p>Long Layover</p>
																		) : (
																			<></>
																		)}
																	</p>
																	<p>
																		{diffObjToDuration(
																			DateTime.fromISO(
																				flightInfo.outbound_return[ind + 1]
																					.departure_time
																			)
																				.diff(
																					DateTime.fromISO(trip.arrival_time),
																					["days", "hours", "minutes"]
																				)
																				.toObject()
																		)}
																	</p>
																</div>
															</div>
														)}
													</div>
												)
											)}
										</div>
									)}
								</>
							)}
						</div>
						<div className="fare-breakdown">
							<span></span>
							<div>
								{/* <p onClick={() => setOpenBreakdown(true)}>Fare breakdown</p> */}
								<p></p>
								<div className="iconss">
									{/* <Tooltip title="Share flight Details">
										<IoMdShareAlt
											size={18}
											onClick={() => {
												setOpenShare(true);
											}}
										/>
									</Tooltip> */}
									{/* <Tooltip title="Save flight Details">
								<IoBookmarkSharp size={14} />
							</Tooltip> */}
								</div>
							</div>
						</div>
						<div className="button-bottom">
							<p>
								₦
								{(
									flightInfo.amount +
									(bigFlightData?.route === "international"
										? flightInfo?.tax
										: 0)
								)
									.toFixed(2)
									.replace(/\d(?=(\d{3})+\.)/g, "$&,")}
							</p>
							{/* <Button
								className="pry"
								onClick={
									onSelect
										? () => {
												onSelect();
												setMobileIsOpen(false);
										  }
										: () => {}
								}
							>
								{selected ? "Selected" : "Select"}
							</Button> */}
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export const SuccessfulModal = ({
	message,
	afterAction = () => {},
}: {
	message: string;
	afterAction?: () => void;
}) => {
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setTimeout(() => {
			setLoading(false);
		}, 500);

		setTimeout(() => {
			afterAction();
		}, 3000);
	});

	return (
		<div className="SuccessfulModal">
			{loading ? (
				<RiLoader5Fill size={45} className="spinner" />
			) : (
				<RiCheckboxCircleLine size={45} />
			)}
			<p>{message}</p>
		</div>
	);
};

export const HeadsUpModal = ({
	handleClose,
	proceedAction,
}: {
	handleClose: () => void;
	proceedAction: () => void;
}) => (
	<div className="HeadsUpModal">
		<p className="title">Heads up!</p>
		<p className="desc">
			You are about to process this ticket. Do you wish to continue?
		</p>
		<div className="buttons">
			<button className="button button--white" onClick={() => handleClose()}>
				Return
			</button>
			<button className="button" onClick={() => proceedAction()}>
				Proceed
			</button>
		</div>
	</div>
);

export const ConvertPointsModal = ({
	handleClose,
	refetch,
	setRefetch,
}: {
	handleClose: () => void;
	refetch: boolean;
	setRefetch: Dispatch<SetStateAction<boolean>>;
}) => {
	const [loading, setLoading] = useState(false);
	const [converted, setConverted] = useState(false);

	useEffect(() => {
		if (converted) {
			setTimeout(() => {
				setRefetch(!refetch);
				handleClose();
			}, 1000);
		}
	}, [converted]);

	if (converted)
		return (
			<div
				className="ConvertPointsModal"
				style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					gap: "17px",
					justifyContent: "center",
				}}
			>
				<BsFillCheckCircleFill size={50} color="#70A9A1" />
				<p className="title">Your points has been converted successfully</p>
			</div>
		);

	return (
		<div className="ConvertPointsModal">
			<p className="title">Convert your bonus points!</p>
			<p className="desc">
				Earn points on every trip. You get convertible points by booking your
				trip with us frequently. Earned points will be coverted to wallet
				balance.
			</p>
			<Button
				className="pry"
				onClick={async () => {
					console.log("sdjskjk");
					setLoading(true);

					try {
						const res = await convertPoints();
						if (res.status === 201) {
							setConverted(true);
						}
					} catch (error) {
						console.log(error);
					} finally {
						setLoading(false);
					}

					// setTimeout(() => {
					// 	setConverted(true);
					// }, 1500);
				}}
				loading={loading}
				disabled={converted}
			>
				Convert points
			</Button>
		</div>
	);
};

export const CreditFacilityModal = ({ data }: { data: any }) => {
	let {
		creditAmount,
		setCreditAmount,
		creditPeriod,
		setCreditPeriod,
		travelVolume,
		setTravelVolume,
		travelBudget,
		setTravelBudget,
		country,
		setCountry,
		role,
		setRole,
		companySize,
		setCompanySize,
		credit,
		setCredit,
		companyName,
		setCompanyName,
	} = data;

	let stringInputError = (a: any, setA: (g: any) => void) => {
		console.log(a, isNaN(a));
		if (!isNaN(a)) {
			setA(a);
		}
	};

	return (
		<div className="CreditFacilityModal">
			<div className="CreditFacilityModal__head">
				<p>Credit Facility</p>
			</div>
			<div className="CreditFacilityModal__body">
				<div className="form-section">
					<form>
						<div className="form-item">
							<h5>Credit Amount</h5>
							<div className="select-wrapper">
								<select
									id="creditAmount"
									name="creditAmount"
									value={creditAmount}
									onChange={(e) => {
										setCreditAmount(e.target.value);
										console.log(creditAmount);
									}}
								>
									<option value={0}>Set Credit Amount</option>
									<option value={1000000}>1,000,000</option>
									<option value={3000000}>3,000,000</option>
									<option value={5000000}>5,000,000</option>
									<option value={5000001}>{">"}50,000,000</option>
								</select>
							</div>
						</div>
						<div className="form-item">
							<h5>Credit Period</h5>
							<div className="select-wrapper">
								<select
									id="creditPeriod"
									name="creditPeriod"
									value={creditPeriod}
									onChange={(e) => {
										setCreditPeriod(e.target.value);
									}}
								>
									<option value={"7"}>7 days</option>
									<option value={"14"}>14 days</option>
									<option value={"21"}>21 days</option>
								</select>
							</div>
						</div>

						<div className="form-item">
							<h5>Estimated travel monthly spend</h5>
							<input
								type="text"
								id="travelVolume"
								name="travelVolume"
								value={travelVolume}
								onChange={(e) => {
									stringInputError(Number(e.target.value), setTravelVolume);
								}}
							/>
						</div>
						<div className="form-item">
							<h5>Estimated annual travel budget</h5>
							<input
								type="text"
								id="travelBudget"
								name="travelBudget"
								value={travelBudget}
								onChange={(e) => {
									stringInputError(Number(e.target.value), setTravelBudget);
								}}
							/>
						</div>

						<div className="credit-button-wrapper">
							<Button
								className="sec"
								onClick={(e: any) => {
									setCredit(!credit);
									toast.success("Credit information received.");
								}}
							>
								Add
							</Button>
							<Button
								className="sec"
								onClick={() => {
									setCredit(!credit);
									setCreditAmount(0);
									setCreditPeriod(0);
									setTravelVolume(0);
									setTravelBudget(0);
								}}
							>
								Cancel
							</Button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export const FundAccountModal = ({
	email,
	handleClose,
	type,
	refetch,
	setRefetch,
	balanceLoading,
}: {
	email: string;
	handleClose: any;
	type: string;
	refetch: boolean;
	setRefetch: any;
	balanceLoading: boolean;
}) => {
	const [amount, setAmount] = useState(0);
	const [reference, setReference] = useState(new Date().getTime().toString());

	const verfyFunc = async (reference: string) => {
		console.log("reference", reference);
		try {
			const res = await verifyPayment(reference);
			if (res) {
				setRefetch(!refetch);
			}
		} catch (e) {
			console.log(e);
		}
	};

	let config = {
		reference: reference,
		email: email,
		amount: Math.ceil(
			addPaystackCharges(amount === undefined ? amount : Math.round(amount)) *
				100
		),
		publicKey: `${process.env.REACT_APP_PAYSTACK_KEY}`,
	};
	console.log("reference", reference);

	const onSuccess = (ref: any) => {
		console.log("refObj", ref);
		setReference(ref.reference);
		if (type === "fund_wallet") {
			verfyFunc(reference);
		} else if (type === "book_flight") {
			// props.setPaymentSuccessful(true);
			// props.setReference(reference);
		}

		handleClose();
	};

	const onClose = () => {
		console.log("closed");
	};

	const PaystackHookExample = () => {
		const initializePayment: any = usePaystackPayment(config);
		return (
			<div>
				<button
					className="modal-button"
					onClick={(e) => {
						e.preventDefault();
						initializePayment(onSuccess, onClose);
					}}
				>
					Pay Now
				</button>
			</div>
		);
	};

	return (
		<div className="FundAccountModal">
			<>
				<p className="modal_head_text">
					Fund your wallet and get your credit topped
				</p>
				<form>
					<input
						type="number"
						required
						placeholder="Amount"
						className="modal-form"
						onChange={(e) => setAmount(e.target.valueAsNumber)}
					/>
					<PaystackHookExample />
				</form>
				<div>
					<button
						className="payment-cancel modal-button"
						onClick={() => handleClose()}
					>
						Cancel Payment
					</button>
				</div>
			</>
		</div>
	);
};

export const PreferredPageModal = ({
	action,
	maxPage,
}: {
	action: (num: number) => void;
	maxPage: number;
}): JSX.Element => {
	const [page, setPage] = useState(1);
	return (
		<div className="PreferredPageModal">
			<p>Go to your preferred page</p>
			<input
				type="number"
				name=""
				id=""
				value={page}
				onChange={(e: any) => {
					setPage(e?.target?.value);
				}}
			/>
			<Button
				className="pry"
				onClick={() => {
					if (!isNaN(parseInt(page.toString())))
						action(parseInt(page.toString()));
				}}
				disabled={
					0 >= page || page > maxPage || isNaN(parseInt(page.toString()))
				}
			>
				See page
			</Button>
		</div>
	);
};

export const ShareTripDetailsModal = ({
	handleClose,
}: {
	handleClose: () => void;
}): JSX.Element => {
	const [emails, setEmails] = useState("");
	const [loading, setLoading] = useState(false);
	const [checked, setChecked] = useState(false);
	const bigFlightData = useTripStore((state: any) => state.bigFlightData);
	const userData = useTripStore((state: any) => state.userData);
	console.log("userData", userData);

	return (
		<div className="ShareTripDetailsModal">
			<div className="ShareTripDetailsModal__head">
				<p>Share trip details</p>
			</div>
			<div className="ShareTripDetailsModal__body">
				<p>Send email to</p>
				<input
					type="email"
					name=""
					id=""
					value={emails}
					onChange={(e: any) => {
						setEmails(e?.target?.value);
					}}
					placeholder="separate emails by comma"
				/>

				<label>
					<input
						type="checkbox"
						name=""
						id=""
						checked={checked}
						onChange={() => {
							setChecked((prevState) => !prevState);
						}}
					/>
					Send me a copy
				</label>
				<div className="btn_wrapper">
					<Button
						className="pry"
						loading={loading}
						onClick={async () => {
							try {
								setLoading(true);
								const res = await shareTripDetails({
									first_name: userData?.first_name,
									emails: [
										...emails
											.split(",")
											.map((item) => item.trim())
											.filter((item) => item !== ""),
										...(checked ? [userData?.email] : []),
									],
									trip_type:
										bigFlightData?.showTripType === "Roundtrip"
											? "roundtrip"
											: "oneway",
									isRoundTrip: bigFlightData?.showTripType === "Roundtrip",
									departure: {
										airline:
											bigFlightData?.selectedDeparture?.outbound[0]
												?.airline_details?.name,
										flight_duration: minutesToDuration(
											bigFlightData?.selectedDeparture?.outbound[0]?.duration
										),
										stops:
											bigFlightData?.selectedDeparture?.outbound_stops?.toString(),
										class:
											bigFlightData?.selectedDeparture?.outbound[0]?.cabin_type,
										flight_details: {
											travel_date: DateTime.fromISO(
												bigFlightData?.selectedDeparture?.outbound[0]
													?.departure_time
											).toLocaleString({
												day: "2-digit",
												month: "long",
											}),
											return_date: DateTime.fromISO(
												bigFlightData?.selectedDeparture?.outbound[0]
													?.arrival_time
											).toLocaleString({
												day: "2-digit",
												month: "long",
											}),
											takeoff_time: DateTime.fromISO(
												bigFlightData?.selectedDeparture?.outbound[0]
													?.departure_time
											).toLocaleString(DateTime.TIME_SIMPLE),
											arrival_time: DateTime.fromISO(
												bigFlightData?.selectedDeparture?.outbound[0]
													?.arrival_time
											).toLocaleString(DateTime.TIME_SIMPLE),
											itenary: `${bigFlightData?.departureCity[0]?.city} to ${bigFlightData?.arrivalCity[0]?.city}`,
											layover:
												bigFlightData?.selectedDeparture?.outbound[0]?.layover,
										},
									},
									return: {
										airline:
											bigFlightData?.selectedArrival?.outbound[0]
												?.airline_details?.name,
										flight_duration: minutesToDuration(
											bigFlightData?.selectedArrival?.outbound[0]?.duration
										),
										stops:
											bigFlightData?.selectedArrival?.outbound_stops?.toString(),
										class:
											bigFlightData?.selectedArrival?.outbound[0]?.cabin_type,
										flight_details: {
											travel_date: DateTime.fromISO(
												bigFlightData?.selectedArrival?.outbound[0]
													?.departure_time
											).toLocaleString({
												day: "2-digit",
												month: "long",
											}),
											return_date: DateTime.fromISO(
												bigFlightData?.selectedArrival?.outbound[0]
													?.arrival_time
											).toLocaleString({
												day: "2-digit",
												month: "long",
											}),
											takeoff_time: DateTime.fromISO(
												bigFlightData?.selectedArrival?.outbound[0]
													?.departure_time
											).toLocaleString(DateTime.TIME_SIMPLE),
											arrival_time: DateTime.fromISO(
												bigFlightData?.selectedArrival?.outbound[0]
													?.arrival_time
											).toLocaleString(DateTime.TIME_SIMPLE),
											itenary: `${bigFlightData?.arrivalCity[0]?.city} to ${bigFlightData?.departureCity[0]?.city}`,
											layover:
												bigFlightData?.selectedArrival?.outbound[0]?.layover,
										},
									},
								});
								if (res?.status === 200) {
									toast.success("Trip details successfully shared");
									handleClose();
								} else {
									toast.error("Something went wrong");
								}
							} catch (e) {
								console.log(e);
								toast.error("Something went wrong");
							} finally {
								setLoading(false);
							}
						}}
						disabled={!emailListRegex.test(emails)}
					>
						Send
					</Button>
				</div>
			</div>
		</div>
	);
};

export const AddNewTravellerModal = ({
	setSelectedTraveller,
	handleClose,
	edit = false,
	showAdd = true,
	currentTraveller,
	refetchFunc,
}: {
	setSelectedTraveller?: any;
	handleClose: any;
	edit?: boolean;
	showAdd?: boolean;
	currentTraveller?: any;
	refetchFunc?: () => void;
}): JSX.Element => {
	const [newPassenger, setNewPassenger] = useState(
		edit
			? currentTraveller
			: {
					id: "",
					title: "Mr",
					first_name: "",
					middle_name: "",
					last_name: "",
					email: "",
					gender: "male",
					phone_number: "",
					date_of_birth: "",
					nationality: "NG",
					identification_expiry: "",
					// identification_issue: "",
					identification_country: "NG",
					means_of_identification: "Passport",
					identification_number: "",
					passenger_type: "adult",
			  }
	);
	const [buttonClicked, setButtonClicked] = useState("Add to trip");
	const [saveLoading, setSaveLoading] = useState(false);
	const [addLoading, setAddLoading] = useState(false);

	const bigFlightData = useTripStore((state: any) => state.bigFlightData);
	const countryList = useTripStore((state: any) => state.countryList);
	const myTravellers = useTripStore((state: any) => state.myTravellers);
	const setMyTravellers = useTripStore((state: any) => state.setMyTravellers);
	console.log("countryList", countryList);

	const changeFunc = (e: any) => {
		setNewPassenger({ ...newPassenger, [e.target.name]: e.target.value });
	};

	const idOptions = [
		{ label: "Passport", value: "Passport" },
		{ label: "National ID", value: "National_ID" },
		{ label: "Work ID", value: "Work_ID" },
		{ label: "Student ID", value: "Student_ID" },
		{ label: "Drivers License", value: "Drivers_License" },
		{ label: "Others", value: "Others" },
	];

	return (
		<div className="AddNewTravellerModal-mt">
			<div className="AddNewTravellerModal">
				<div className="top">
					<h3>{edit ? "Edit" : "Add a new"} passenger</h3>
				</div>
				<div className="bottom">
					<div className="id-info">
						<p className="id-text">
							Traveller names must match government-issued photo ID.
						</p>
					</div>
					<div className="form-section">
						<form
							onSubmit={async (e: any) => {
								e.preventDefault();
								console.log(newPassenger);

								if (buttonClicked === "Add to trip") setAddLoading(true);
								else setSaveLoading(true);
								try {
									const { id, ...newPassengerRest } = newPassenger;
									const res = edit
										? await updateTraveller(newPassenger?.id, newPassengerRest)
										: await createTraveller(newPassengerRest);
									console.log(res);
									if (res?.status === 201 || res?.status === 200) {
										edit
											? toast.success(
													"Traveller information successfully updated."
											  )
											: toast.success(
													"Traveller successfully added to your profile."
											  );
										setMyTravellers([...myTravellers, res?.data]);
										refetchFunc && refetchFunc();

										if (buttonClicked === "Add to trip") {
											setSelectedTraveller([
												...bigFlightData.selectedTraveller,
												res?.data,
											]);
											handleClose();
											toast.success(
												"Traveller successfully added to your trip."
											);
										} else {
											setNewPassenger({
												title: "mr",
												first_name: "",
												middle_name: "",
												last_name: "",
												email: "",
												gender: "male",
												phone_number: "",
												date_of_birth: "",
												nationality: "NG",
												identification_expiry: "",
												// identification_issue: "",
												identification_country: "NG",
												means_of_identification: "Passport",
												identification_number: "",
												passenger_type: "adult",
											});
											handleClose();
										}
									}
								} catch (e) {
									console.log(e);
									toast.error("Something went wrong.");
								} finally {
									if (buttonClicked === "Add to trip") setAddLoading(false);
									else setSaveLoading(false);
								}
							}}
						>
							<div className="form-row">
								<div className="conjoined">
									<div className="form-item">
										<p>Title</p>
										<CustomDropdown
											onSelect={(val: any) => {
												setNewPassenger({
													...newPassenger,
													title: val,
												});
											}}
											defaultValue={newPassenger.title}
											optionsList={[
												{ label: "Mr", value: "Mr" },
												{ label: "Mrs", value: "Mrs" },
												{ label: "Ms", value: "Ms" },
											]}
											ind={3}
										/>
									</div>
									<div className="form-item">
										<p>First Name</p>
										<input
											type="text"
											name="first_name"
											value={newPassenger.first_name}
											onChange={(e) => changeFunc(e)}
											style={{
												borderTopLeftRadius: 0,
												borderBottomLeftRadius: 0,
											}}
											required={true}
										/>
									</div>
								</div>
								<div className="form-item">
									<p>Last Name</p>
									<input
										type="text"
										name="last_name"
										value={newPassenger.last_name}
										onChange={(e) => changeFunc(e)}
										required={true}
									/>
								</div>
							</div>
							<div className="form-row">
								<div className="form-item">
									<p>Middle Name</p>
									<input
										type="text"
										name="middle_name"
										value={newPassenger.middle_name}
										onChange={(e) => changeFunc(e)}
										required={false}
									/>
								</div>
								<div className="form-item">
									<p>Gender</p>
									<CustomDropdown
										onSelect={(val: any) => {
											setNewPassenger({
												...newPassenger,
												gender: val,
											});
										}}
										defaultValue={newPassenger.gender}
										optionsList={[
											{ label: "Male", value: "male" },
											{ label: "Female", value: "female" },
										]}
										ind={0}
									/>
								</div>
							</div>
							<div className="form-row">
								<div className="form-item">
									<p>Email address</p>
									<input
										type="email"
										name="email"
										value={newPassenger.email}
										onChange={(e) => changeFunc(e)}
										required={true}
									/>
								</div>
								<div className="form-item">
									<p>Phone number</p>
									<input
										type="text"
										name="phone_number"
										value={newPassenger.phone_number}
										onChange={(e) => changeFunc(e)}
										required={true}
									/>
								</div>
							</div>
							<div className="form-row">
								<div className="form-item">
									<p>Date of Birth</p>
									<DatePicker
										className="teal"
										placeholder="select..."
										// value={newPassenger.date_of_birth}
										value={new Date(
											newPassenger.date_of_birth
										).toLocaleDateString("sv-SE")}
										onChange={(dateObject: DateObject) => {
											setNewPassenger({
												...newPassenger,
												date_of_birth: new Date(
													dateObject.toString()
												).toLocaleDateString("sv-SE"),
											});
										}}
										inputClass="custom-rmdp-input"
										numberOfMonths={1}
										mapDays={(object: any) => {
											return {
												disabled: object.today < object.date ? true : false,
											};
										}}
										required={true}
										animations={[
											opacity(),
											transition({ from: 35, duration: 400 }),
										]}
									/>
								</div>
								<div className="form-item">
									<p>Nationality</p>
									<CustomDropdown
										onSelect={(val: any) => {
											setNewPassenger({
												...newPassenger,
												nationality: val,
											});
										}}
										defaultValue={newPassenger.nationality}
										optionsList={countryList.map((item: any) => ({
											label: item?.name?.common,
											value: item?.cca2,
										}))}
										ind={1}
									/>
								</div>
							</div>
							<div className="form-row">
								<div className="form-item">
									<p>Identification Type</p>
									<CustomDropdown
										onSelect={(val: any) => {
											setNewPassenger({
												...newPassenger,
												means_of_identification: val,
											});
										}}
										defaultValue={newPassenger.means_of_identification}
										optionsList={idOptions}
										ind={2}
									/>
								</div>
								<div className="form-item">
									<p>Passport number</p>
									<input
										type="text"
										name="identification_number"
										value={newPassenger.identification_number}
										onChange={(e) => changeFunc(e)}
										required={true}
									/>
								</div>
							</div>

							<div className="form-row">
								<div className="form-item">
									<p>Issuing Country</p>
									<CustomDropdown
										onSelect={(val: any) => {
											setNewPassenger({
												...newPassenger,
												identification_country: val,
											});
										}}
										defaultValue={newPassenger.identification_country}
										optionsList={countryList.map((item: any) => ({
											label: item?.name?.common,
											value: item?.cca2,
										}))}
										ind={3}
									/>
								</div>
								{/* <div className="form-item">
									<p>Issue date</p>
									<DatePicker
										className="teal"
										placeholder="select..."
										value={new Date(
											newPassenger.identification_issue
										).toLocaleDateString("sv-SE")}
										onChange={(dateObject: DateObject) => {
											setNewPassenger({
												...newPassenger,
												identification_issue: new Date(
													dateObject.toString()
												).toLocaleDateString("sv-SE"),
											});
										}}
										inputClass="custom-rmdp-input"
										numberOfMonths={1}
										mapDays={(object: any) => {
											return {
												disabled: object.today < object.date ? true : false,
											};
										}}
										required={true}
										animations={[
											opacity(),
											transition({ from: 35, duration: 400 }),
										]}
									/>
								</div> */}
							</div>

							<div className="form-row">
								<div className="form-item">
									<p>Expiry date</p>
									<DatePicker
										className="teal"
										placeholder="select..."
										value={new Date(
											newPassenger.identification_expiry
										).toLocaleDateString("sv-SE")}
										onChange={(dateObject: DateObject) => {
											setNewPassenger({
												...newPassenger,
												identification_expiry: new Date(
													dateObject.toString()
												).toLocaleDateString("sv-SE"),
											});
										}}
										inputClass="custom-rmdp-input"
										numberOfMonths={1}
										mapDays={(object: any) => {
											return {
												disabled: object.today > object.date ? true : false,
											};
										}}
										required={true}
										animations={[
											opacity(),
											transition({ from: 35, duration: 400 }),
										]}
									/>
								</div>
								<div className="form-item">
									<p>Passenger Type</p>
									<CustomDropdown
										onSelect={(val: any) => {
											setNewPassenger({
												...newPassenger,
												passenger_type: val,
											});
										}}
										defaultValue={newPassenger.passenger_type}
										optionsList={[
											{ label: "Adult", value: "adult" },
											{ label: "Children", value: "child" },
											{ label: "Infant", value: "infant" },
										]}
										ind={0}
									/>
								</div>
							</div>
							<div className="button-wrapper-container">
								<Button
									className={`pry ${showAdd && "save-profile"}`}
									type="submit"
									onClick={() => setButtonClicked("Save profile")}
									loading={saveLoading}
									disabled={addLoading}
								>
									{edit ? "Save changes" : "Save profile"}
								</Button>
								{showAdd && (
									<Button
										className="pry"
										type="submit"
										onClick={() => setButtonClicked("Add to trip")}
										loading={addLoading}
										disabled={saveLoading}
									>
										{" "}
										Add to trip
									</Button>
								)}
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export const AddNewTravellerModalRewrite = ({
	setSelectedTraveller,
	handleClose,
	edit = false,
	swap = false,
	showAdd = true,
	currentTraveller,
	refetchFunc,
}: {
	setSelectedTraveller?: any;
	handleClose: any;
	edit?: boolean;
	swap?: boolean;
	showAdd?: boolean;
	currentTraveller?: any;
	refetchFunc?: () => void;
}): JSX.Element => {
	const [newPassenger, setNewPassenger] = useState(
		edit
			? currentTraveller
			: {
					id: "",
					title: "Mr",
					first_name: "",
					middle_name: "",
					last_name: "",
					email: "",
					gender: "male",
					phone_number: "",
					passenger_type: "adult",
			  }
	);
	const [buttonClicked, setButtonClicked] = useState("Add to trip");
	const [saveLoading, setSaveLoading] = useState(false);
	const [addLoading, setAddLoading] = useState(false);

	const bigFlightData = useTripStore((state: any) => state.bigFlightData);
	const countryList = useTripStore((state: any) => state.countryList);
	const myTravellers = useTripStore((state: any) => state.myTravellers);
	const setMyTravellers = useTripStore((state: any) => state.setMyTravellers);
	console.log("countryList", countryList);

	const [tempTravellerList, setTempTravellerList] = useState(myTravellers);
	const [tempSelected, setTempSelected] = useState([]);
	const [addTravelerSearch, setAddTravelerSearch] = useState("");

	const changeFunc = (e: any) => {
		setNewPassenger({ ...newPassenger, [e.target.name]: e.target.value });
	};

	const idOptions = [
		{ label: "Passport", value: "Passport" },
		{ label: "National ID", value: "National_ID" },
		{ label: "Work ID", value: "Work_ID" },
		{ label: "Student ID", value: "Student_ID" },
		{ label: "Drivers License", value: "Drivers_License" },
		{ label: "Others", value: "Others" },
	];

	useEffect(() => {
		if (addTravelerSearch !== "")
			setTempTravellerList(
				myTravellers?.filter(
					(item: any) =>
						item?.first_name
							?.toLowerCase()
							?.startsWith(addTravelerSearch?.toLowerCase()) ||
						item?.last_name
							?.toLowerCase()
							?.startsWith(addTravelerSearch?.toLowerCase()) ||
						item?.middle_name
							?.toLowerCase()
							?.startsWith(addTravelerSearch?.toLowerCase()) ||
						item?.email
							?.toLowerCase()
							?.startsWith(addTravelerSearch?.toLowerCase())
				)
			);
		else setTempTravellerList(myTravellers);
	}, [addTravelerSearch]);

	return (
		<div className="AddNewTravellerModalRewrite">
			<div className="top">
				<AddUserIcon />
				<h3>{edit ? "Edit passenger info" : "Add a new passenger"}</h3>
				<CgClose
					className="closeIcon"
					color="#C4C4C4"
					onClick={() => handleClose()}
				/>
			</div>
			<div className="bottom">
				{swap && (
					<SwitchPassengerComponent
						addTravelerSearch={addTravelerSearch}
						setAddTravelerSearch={setAddTravelerSearch}
						currTravellerId={currentTraveller?.id}
						handleClose={handleClose}
					/>
				)}
				<div className="id-info">
					<PhWarningIcon />
					<p className="id-text">
						{edit
							? "To avoid complications, make sure all given names and surnames are exactly as they appear on your passport/ID."
							: "Ensure the travellers' name matches their government photo identification"}
					</p>
				</div>
				<div className="form-section">
					<form
						onSubmit={async (e: any) => {
							e.preventDefault();
							console.log(newPassenger);

							if (newPassenger.phone_number?.length < 12)
								toast.error("Please enter a valid phone number");
							else {
								if (buttonClicked === "Add to trip") setAddLoading(true);
								else setSaveLoading(true);
								try {
									const {
										id,
										is_complete,
										identification_issue,
										...newPassengerRest
									} = newPassenger;
									const res = edit
										? await updateTraveller(newPassenger?.id, {
												...newPassengerRest,
												identification_country: newPassenger.nationality,
												means_of_identification: "Passport",
										  })
										: await createTraveller(newPassengerRest);
									console.log(res);
									if (res?.status === 201 || res?.status === 200) {
										edit
											? toast.success(
													"Traveller information successfully updated."
											  )
											: toast.success(
													"Traveller successfully added to your profile."
											  );
										setMyTravellers([...myTravellers, res?.data]);
										refetchFunc && refetchFunc();

										if (buttonClicked === "Add to trip") {
											setSelectedTraveller([
												...bigFlightData.selectedTraveller,
												res?.data,
											]);
											handleClose();
											toast.success(
												"Traveller successfully added to your trip."
											);
										} else {
											setNewPassenger({
												title: "mr",
												first_name: "",
												middle_name: "",
												last_name: "",
												email: "",
												gender: "male",
												phone_number: "",
												passenger_type: "adult",
												nationality: "NG",
												date_of_birth: "",
												identification_expiry: "",
												// identification_issue: "",
												identification_country: "NG",
												means_of_identification: "Passport",
												identification_number: "",
											});
											handleClose();
										}
									}
								} catch (e) {
									console.log(e);
									toast.error("Something went wrong.");
								} finally {
									if (buttonClicked === "Add to trip") setAddLoading(false);
									else setSaveLoading(false);
								}
							}
						}}
					>
						<div className="form-row">
							<div className="conjoined">
								<div className="form-item">
									<p>
										First Name <span className="required">*</span>
									</p>
									<CustomDropdown
										onSelect={(val: any) => {
											setNewPassenger({
												...newPassenger,
												title: val,
											});
										}}
										defaultValue={newPassenger.title}
										optionsList={[
											{ label: "Mr", value: "Mr" },
											{ label: "Mrs", value: "Mrs" },
											{ label: "Ms", value: "Ms" },
										]}
										ind={3}
									/>
								</div>
								<div className="form-item">
									<p style={{ height: "28px" }}></p>
									<input
										type="text"
										name="first_name"
										value={newPassenger.first_name}
										onChange={(e) => changeFunc(e)}
										style={{
											borderTopLeftRadius: 0,
											borderBottomLeftRadius: 0,
										}}
										required={true}
									/>
								</div>
							</div>
							<div className="form-item">
								<p>
									Last Name <span className="required">*</span>
								</p>
								<input
									type="text"
									name="last_name"
									value={newPassenger.last_name}
									onChange={(e) => changeFunc(e)}
									required={true}
								/>
							</div>
						</div>
						<div className="form-row">
							<div className="form-item">
								<p>
									Middle Name <span className="optional">Optional</span>
								</p>
								<input
									type="text"
									name="middle_name"
									value={newPassenger.middle_name}
									onChange={(e) => changeFunc(e)}
									// required={true}
								/>
							</div>
							<div className="form-item">
								<p>
									Email address <span className="required">*</span>
								</p>
								<input
									type="email"
									name="email"
									value={newPassenger.email}
									onChange={(e) => changeFunc(e)}
									required={true}
								/>
							</div>
						</div>
						<div className="form-row">
							<div className="form-item">
								<p>
									Gender <span className="required">*</span>
								</p>
								<CustomDropdown
									onSelect={(val: any) => {
										setNewPassenger({
											...newPassenger,
											gender: val,
										});
									}}
									defaultValue={newPassenger.gender}
									optionsList={[
										{ label: "Male", value: "male" },
										{ label: "Female", value: "female" },
									]}
									ind={0}
								/>
							</div>
							<div className="form-item">
								<p>
									Phone number <span className="required">*</span>
								</p>
								{/* <input
									type="text"
									name="phone_number"
									value={newPassenger.phone_number}
									onChange={(e) => changeFunc(e)}
									required={true}
								/> */}
								<PhoneDropdown
									value={newPassenger.phone_number}
									updateValue={(val: string) => {
										setNewPassenger({
											...newPassenger,
											phone_number: val,
										});
									}}
								/>
							</div>
						</div>
						<div className="form-row">
							{edit && (
								<div className="form-item">
									<p>Date of Birth</p>
									{/* <DatePicker
										className="teal"
										placeholder="select..."
										// value={newPassenger.date_of_birth}
										value={new Date(
											newPassenger.date_of_birth
										).toLocaleDateString("sv-SE")}
										onChange={(dateObject: DateObject) => {
											setNewPassenger({
												...newPassenger,
												date_of_birth: new Date(
													dateObject.toString()
												).toLocaleDateString("sv-SE"),
											});
										}}
										inputClass="custom-rmdp-input"
										numberOfMonths={1}
										mapDays={(object: any) => {
											return {
												disabled: object.today < object.date ? true : false,
											};
										}}
										required={true}
										animations={[
											opacity(),
											transition({ from: 35, duration: 400 }),
										]}
									/> */}
									<DateDropDown
										dateValue={newPassenger.date_of_birth}
										updateDateValue={(val: string) => {
											setNewPassenger({
												...newPassenger,
												date_of_birth: new Date(val).toLocaleDateString(
													"sv-SE"
												),
											});
										}}
										variant="past"
									/>
								</div>
							)}
							<div className="form-item">
								<p>
									Passenger Type <span className="required">*</span>
								</p>
								<CustomDropdown
									onSelect={(val: any) => {
										setNewPassenger({
											...newPassenger,
											passenger_type: val,
										});
									}}
									defaultValue={newPassenger.passenger_type}
									optionsList={[
										{ label: "Adult", value: "adult" },
										{ label: "Children", value: "child" },
										{ label: "Infant", value: "infant" },
									]}
									ind={0}
								/>
							</div>
						</div>
						{edit && (
							<>
								<div className="form-row">
									<div className="form-item">
										<p>Nationality</p>
										<CustomDropdown
											onSelect={(val: any) => {
												setNewPassenger({
													...newPassenger,
													nationality: val,
												});
											}}
											defaultValue={newPassenger.nationality}
											optionsList={countryList.map((item: any) => ({
												label: item?.name?.common,
												value: item?.cca2,
											}))}
											ind={1}
										/>
									</div>
									<div className="form-item">
										<p>Passport number</p>
										<input
											type="text"
											name="identification_number"
											value={newPassenger.identification_number}
											onChange={(e) => changeFunc(e)}
											required={true}
										/>
									</div>
								</div>
								<div className="form-row">
									<div className="form-item">
										<p>Expiry date</p>
										{/* <DatePicker
											className="teal"
											placeholder="select..."
											value={new Date(
												newPassenger.identification_expiry
											).toLocaleDateString("sv-SE")}
											onChange={(dateObject: DateObject) => {
												setNewPassenger({
													...newPassenger,
													identification_expiry: new Date(
														dateObject.toString()
													).toLocaleDateString("sv-SE"),
												});
											}}
											inputClass="custom-rmdp-input"
											numberOfMonths={1}
											mapDays={(object: any) => {
												return {
													disabled: object.today > object.date ? true : false,
												};
											}}
											required={true}
											animations={[
												opacity(),
												transition({ from: 35, duration: 400 }),
											]}
										/> */}
										<DateDropDown
											dateValue={newPassenger.identification_expiry}
											updateDateValue={(val: string) => {
												setNewPassenger({
													...newPassenger,
													identification_expiry: new Date(
														val
													).toLocaleDateString("sv-SE"),
												});
											}}
											variant="future"
										/>
									</div>
								</div>
							</>
						)}

						<div
							className={`button-wrapper-container  button-wrapper-container--${
								edit && "edit"
							}`}
						>
							<Button
								className={`pry ${showAdd && "save-profile"}`}
								type="submit"
								onClick={() => setButtonClicked("Save profile")}
								loading={saveLoading}
								disabled={addLoading}
								// variant="outline"
							>
								{edit ? "Update passeneger’s information" : "Save profile"}
							</Button>
							{showAdd && (
								<Button
									className="pry"
									type="submit"
									onClick={() => setButtonClicked("Add to trip")}
									loading={addLoading}
									disabled={saveLoading}
								>
									{" "}
									Add to trip
								</Button>
							)}
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export const SelectTravellerModal = ({
	setActive,
	handleClose,
	setAddNewTraveller,
	setShowTraveller,
	handleSelectedTravellers,
	tempSelected,
	setTempSelected,
	showCreate = true,
}: {
	setActive?: any;
	handleClose: any;
	setAddNewTraveller: any;
	setShowTraveller: any;
	handleSelectedTravellers: any;
	tempSelected: any[];
	setTempSelected: any;
	showCreate?: boolean;
}) => {
	const myTravellers = useTripStore((state: any) => state.myTravellers);
	let [addTravelerSearch, setAddNewTravellerSearch] = useState("");

	const [tempTravellerList, setTempTravellerList] = useState(myTravellers);
	// const [typedText, setTypedText] = useState("");

	console.log("myTravellers", myTravellers);

	useEffect(() => {
		if (addTravelerSearch !== "")
			setTempTravellerList(
				myTravellers?.filter(
					(item: any) =>
						item?.first_name
							?.toLowerCase()
							?.startsWith(addTravelerSearch?.toLowerCase()) ||
						item?.last_name
							?.toLowerCase()
							?.startsWith(addTravelerSearch?.toLowerCase()) ||
						item?.middle_name
							?.toLowerCase()
							?.startsWith(addTravelerSearch?.toLowerCase()) ||
						item?.email
							?.toLowerCase()
							?.startsWith(addTravelerSearch?.toLowerCase())
				)
			);
		else setTempTravellerList(myTravellers);
	}, [addTravelerSearch]);

	console.log("tempTravellerList", tempTravellerList);

	return (
		<div className="SelectTravellerModal">
			<div className="SelectTravellerModal__top">
				<div className="titleDesc">
					<h3>Choose the travelling passenger or team</h3>
					<p>You can select up to 5 passengers per trip</p>
				</div>
				<div
					className="goBack"
					onClick={(e) => {
						handleClose();
					}}
				>
					<HiArrowLeft color="#40798C" />
					<p>Go back</p>
				</div>
			</div>
			<div className="SelectTravellerModal__search">
				<GrSearch size={14} color="#c4c4c4" />
				<input
					type="text"
					placeholder="Search Passengers"
					value={addTravelerSearch}
					onChange={(e) => {
						setAddNewTravellerSearch(e.target.value);
					}}
				/>
			</div>
			<div
				className="SelectTravellerModal__btnWrap"
				style={{
					flexDirection:
						myTravellers?.length === 0 ? "column-reverse" : "column",
				}}
			>
				<Button
					className={myTravellers?.length === 0 ? "addNew" : "pry"}
					onClick={(e: any) => {
						handleSelectedTravellers();
						setShowTraveller(false);
						toast.success("Traveller(s) added");
						setActive && setActive(1);
					}}
					disabled={
						tempTravellerList?.length === 0 || tempSelected?.length === 0
					}
				>
					Add to Trip
				</Button>
				{showCreate && (
					<Button
						className={myTravellers?.length === 0 ? "pry" : "addNew"}
						onClick={() => {
							setAddNewTraveller(true);
						}}
					>
						Add new passenger
					</Button>
				)}
			</div>
			<div className="SelectTravellerModal__body">
				{myTravellers?.length === 0 ? (
					<p className="EmptyMessage">
						No travellers attached to this account. Click "Add new passenger" to
						create some
					</p>
				) : tempTravellerList?.length === 0 ? (
					<p className="EmptyMessage">No results found for your search</p>
				) : (
					<div className="passenger-item-wrapper">
						{tempTravellerList?.map((item: any, index: number) => {
							return (
								<PassengerItem
									item={item}
									key={index}
									tempSelected={tempSelected}
									setTempSelected={setTempSelected}
								/>
							);
						})}
					</div>
				)}
			</div>
		</div>
	);
};

const PassengerItem = ({
	item,
	tempSelected,
	setTempSelected,
	max = 5,
}: {
	item: any;
	tempSelected: any;
	setTempSelected: any;
	max?: number;
}) => {
	let { id, first_name, middle_name, last_name, email } = item;
	let nameArray = first_name.split(" ");
	let selected =
		tempSelected?.filter((filtItem: any) => filtItem?.id === id)?.length > 0;

	// console.log("tempSelected", tempSelected);

	console.log("selected", selected);

	console.log("tempSelected", tempSelected);
	let controlSelect = () => {
		if (selected) {
			console.log("second", id);
			setTempSelected(
				tempSelected.filter((filtItem: any) => filtItem.id !== id)
			);
		}
		if (!selected) {
			if (tempSelected?.length < max) {
				setTempSelected([...tempSelected, item]);
			}

			console.log("first", id);
		}
	};
	return (
		<div
			className={`passengerItem passengerItem--${selected ? "selected" : ""} ${
				tempSelected?.length === max && !selected
					? "passengerItem--disabled"
					: ""
			}`}
			onClick={() => {
				controlSelect();
			}}
		>
			<div className="checkbox hover">
				<img src={passengercheck} alt="" />
			</div>
			<div className="passenger-info">
				<p className="passenger-icon">
					{first_name?.charAt(0).toUpperCase()}
					{last_name?.charAt(0).toUpperCase()}
				</p>
				<div className="passenger-details">
					<h5> {`${first_name} ${middle_name} ${last_name}`}</h5>
					<p>{email}</p>
				</div>
			</div>
			<div
				className="passenger-info mob"
				// onClick={() => {
				// 	controlSelect();
				// }}
			>
				<p className="passenger-icon">
					{nameArray[0]?.charAt(0).toUpperCase()}
					{nameArray[nameArray.length - 1].charAt(0).toUpperCase()}
				</p>
				<div className="passenger-details">
					<h5>{`${first_name} ${middle_name} ${last_name}`}</h5>
					<p>{email}</p>
				</div>
				<FiCheck />
			</div>
		</div>
	);
};

export const PaymentModal = ({
	handleClose,
	type,
	amount,
	makePayment,
	setPaymentSuccessful,
	reference,
	setReference,
}: {
	handleClose: any;
	type: string;
	amount: number;
	makePayment: any;
	setPaymentSuccessful: any;
	reference: any;
	setReference: any;
}) => {
	const [fundAmount, setFundAmount] = useState(0);
	const [localreference, setLocalReference] = useState(
		new Date().getTime().toString()
	);
	const userData = useTripStore((state: any) => state.userData);

	const verfyFunc = async (reference: string) => {
		console.log("reference", reference);
		try {
			const res = await verifyPayment(reference);
			if (res) {
				toast.success("Account funded successfully");
				// setRefetch(!refetch);
			}
		} catch (e) {
			console.log(e);
		}
	};

	//   PAYSTACK CONFIGS
	let config = {
		reference: localreference,
		email: userData?.email,
		amount: Math.ceil(
			addPaystackCharges(
				type === "pay_with_wallet" ? fundAmount : Math.round(amount)
			) * 100
		),
		publicKey: `${process.env.REACT_APP_PAYSTACK_KEY}`,
	};

	const onSuccess = (ref: any) => {
		console.log("refObj", ref);
		setLocalReference(ref.reference);
		setReference(ref.reference);

		if (type === "pay_with_wallet") {
			verfyFunc(localreference);
		} else {
			// setPaymentSuccessful(true);
			makePayment(ref.reference);
		}
		handleClose();
	};
	const onClose = () => {
		console.log("closed");
	};

	const PaystackHookExample = ({ label }: { label: string }) => {
		const initializePayment: any = usePaystackPayment(config);
		return (
			<div>
				<button
					className="modal-button"
					onClick={(e) => {
						e.preventDefault();
						initializePayment(onSuccess, onClose);
					}}
				>
					{label}
				</button>
			</div>
		);
	};

	const fundWallet = () => {
		return (
			<div className="modal-box">
				<p className="modal_head_text">
					Fund your wallet and get your credit topped
				</p>
				<form>
					<input
						type="number"
						required
						placeholder="Amount"
						className="modal-form"
						onChange={(e) => setFundAmount(e.target.valueAsNumber)}
					/>
					<PaystackHookExample label="Fund wallet" />
				</form>
				<div>
					<button
						className="payment-cancel modal-button"
						onClick={() => handleClose()}
					>
						Cancel Payment
					</button>
				</div>
			</div>
		);
	};

	const book_flight = () => {
		return (
			<div className="modal-box">
				<p className="modal_head_text">
					We will charge your card for your flight booking
				</p>
				<PaystackHookExample label="Pay Now" />
				<div>
					<button
						className="payment-cancel modal-button"
						onClick={() => handleClose()}
					>
						Cancel Payment
					</button>
				</div>
			</div>
		);
	};

	return type === "pay_with_wallet" ? fundWallet() : book_flight();
};

export const DeleteModal = ({
	handleClose,
	deleteFunc,
	name,
	deleteLoading,
}: {
	handleClose: () => void;
	deleteFunc: () => void;
	name?: string;
	deleteLoading?: boolean;
}) => (
	<div className="HeadsUpModal HeadsUpModal--delete">
		<p className="title">Are you sure?</p>
		<p className="desc">
			You are about to delete {name}. Are you sure you wish to proceed?
		</p>
		<div className="buttons">
			<button className="button button--white" onClick={() => handleClose()}>
				Cancel
			</button>
			<Button
				className="pry button"
				onClick={() => {
					deleteFunc();
				}}
				loading={deleteLoading}
			>
				Delete
			</Button>
		</div>
	</div>
);

export const SaveCardModal = ({ handleClose }: { handleClose: () => void }) => {
	const [modalPhase, setModalPhase] = useState(0);

	const [cardDetails, setCardDetails] = useState({
		card_name: "",
		card_number: "",
	});

	const changeFunc = (
		e:
			| React.ChangeEvent<HTMLInputElement>
			| React.ChangeEvent<HTMLTextAreaElement>
	) => {
		setCardDetails({ ...cardDetails, [e.target.name]: e.target.value });
		console.log(cardDetails);
	};

	switch (modalPhase) {
		case 0:
			return (
				<div className="SaveCardModal">
					<p className="title">Save a new credit card</p>
					{/* <p className="modal_head_text">
						Fund your wallet and get your credit topped
					</p> */}
					<form
						onSubmit={(e) => {
							e.preventDefault();
							setModalPhase(1);
						}}
					>
						<label>
							Name on card
							<input
								name="card_name"
								type="text"
								required
								placeholder="Name on card"
								className="modal-form"
								onChange={changeFunc}
							/>
						</label>
						<label>
							Credit or debit card
							<input
								name="card_number"
								type="number"
								required
								placeholder="Card number"
								className="modal-form"
								onChange={changeFunc}
							/>
						</label>
						<Button type="submit" className="pry">
							Save
						</Button>
					</form>
				</div>
			);
			break;

		case 1:
			return (
				<div className="SaveCardModal">
					<p className="title">Authentication required</p>
					<p className="modal_head_text">
						Your card requires 3d secure authentication. You will be required to
						enter a one-time password (OTP). You won’t be charged at this time.
					</p>
					<Button
						type="submit"
						className="pry"
						onClick={() => {
							setModalPhase(2);
						}}
					>
						Continue
					</Button>
				</div>
			);
			break;

		case 2:
			return (
				<div className="SaveCardModal">
					<p className="title">Card added successfully</p>
					<p className="modal_head_text">
						We have saved your card on file. you can use it at your future
						travel booking.
					</p>
					<Button
						type="submit"
						className="pry"
						onClick={() => {
							setModalPhase(2);
						}}
					>
						Continue
					</Button>
				</div>
			);
			break;

		default:
			return (
				<div className="SaveCardModal">
					<p className="title">Save a new credit card</p>
					{/* <p className="modal_head_text">
						Fund your wallet and get your credit topped
					</p> */}
					<form
						onSubmit={(e) => {
							e.preventDefault();
							setModalPhase(1);
						}}
					>
						<label>
							Name on card
							<input
								name="card_name"
								type="number"
								required
								placeholder="Amount"
								className="modal-form"
								onChange={changeFunc}
							/>
						</label>
						<label>
							Credit or debit card
							<input
								name="card_number"
								type="number"
								required
								placeholder="Card number"
								className="modal-form"
								onChange={changeFunc}
							/>
						</label>
						<Button type="submit" className="pry">
							Save
						</Button>
					</form>
				</div>
			);
			break;
	}
};

export const LoadingModal = ({
	loadingMessage,
	setShownLoad,
}: {
	loadingMessage: string;
	setShownLoad: () => void;
}) => {
	useEffect(() => {
		setShownLoad();
	}, []);
	return (
		<div className="LoadingModal">
			<KachiSpinner />
			<p>{loadingMessage}</p>
		</div>
	);
};

export const LoadingSuccessModal = ({
	successMessage,
	successFunc,
	handleClose,
}: {
	successMessage: string;
	successFunc: () => void;
	handleClose: () => void;
}) => {
	const [done, setDone] = useState(false);

	useEffect(() => {
		setTimeout(() => {
			setDone(true);
			successFunc();
		}, 800);
	}, []);
	return (
		<div className="LoadingSuccessModal">
			{done ? <FaRegCheckCircle /> : <ImSpinner8 className="spinner" />}
			<p>{successMessage}</p>
		</div>
	);
};

export const SearchExpiredModal = ({
	searchAgain,
}: {
	searchAgain: () => void;
}) => {
	const bigFlightData = useTripStore((state: any) => state.bigFlightData);
	const updateBigFlightData = useTripStore(
		(state: any) => state.updateBigFlightData
	);

	const navigate = useNavigate();

	const clearSelected = () => {
		updateBigFlightData({
			selectedDeparture: undefined,
			selectedArrival: undefined,
			flightSelected: false,
			selectedMultiCityFlights: [],
		});
	};

	return (
		<div className="SearchExpiredModal">
			<p className="title">Your Search has expired</p>
			<div>
				<p className="desc">
					Price and availability for your trip may have changed in the last 15
					minutes
				</p>
				<div className="buttons">
					<Button
						className="button button--white"
						onClick={() => {
							clearSelected();
							navigate("/trips");
						}}
					>
						Start again
					</Button>
					<Button
						className="button"
						onClick={() => {
							clearSelected();
							searchAgain();
						}}
					>
						Refresh
					</Button>
				</div>
			</div>
		</div>
	);
};

export const AdditionalNoteModal = ({
	handleClose,
	textVal,
	setTextVal,
}: {
	handleClose: () => void;
	textVal: string;
	setTextVal: Dispatch<SetStateAction<string>>;
}) => {
	const [localVal, setLocalVal] = useState(textVal);

	return (
		<div className="AdditionalNoteModal">
			<textarea
				name=""
				id=""
				cols={30}
				rows={10}
				placeholder="Type here...."
				value={localVal}
				onChange={(e) => setLocalVal(e.target.value)}
			></textarea>
			<div className="AdditionalNoteModal__btnWrap">
				<button
					onClick={() => {
						setTextVal(localVal);
						handleClose();
					}}
				>
					{textVal === "" ? "Add" : "Update"} Note
				</button>
			</div>
		</div>
	);
};

export const VerifyingPrice = () => (
	<div className="LoadingModal">
		<KachiSpinner />
		<p>Hold on, while we verify the pricing.</p>
	</div>
);

export const NewGroupModal = ({
	handleClose,
	addFunc,
}: {
	handleClose: () => void;
	addFunc: (val: string) => void;
}) => {
	const [groupName, setGroupName] = useState("");

	return (
		<div className="NewGroupModal">
			<p className="NewGroupModal__title">New Group</p>
			<label htmlFor="">
				Group name
				<input
					type="text"
					name=""
					id=""
					placeholder="Enter group name"
					value={groupName}
					onChange={(e) => setGroupName(e.target.value)}
				/>
			</label>
			<div className="NewGroupModal__btnWrap">
				<button
					onClick={() => {
						addFunc(groupName);
						handleClose();
					}}
				>
					Save
				</button>
			</div>
		</div>
	);
};

export const ReferralModal = () => {
	const userData = useTripStore((state: any) => state.userData);
	const [copied, setCopied] = useState(false);

	return (
		<div className="ReferralModal">
			<h3 className="ReferralModal__title">
				Refer a business. Gain points. Get cashback
			</h3>
			<p className="ReferralModal__desc">
				Have you enjoyed using our platform? Spread the Kardinal good news.
				Refer someone today.
			</p>
			<div className="formish">
				<p>{`${FRONTEND_URL}/onboarding?ref=${userData?.referral_code}`}</p>
				<button
					className={`copyBtn copyBtn--${copied ? "copied" : ""}`}
					onClick={() => {
						setCopied(true);
						navigator.clipboard.writeText(
							`${FRONTEND_URL}/onboarding?ref=${userData?.referral_code}`
						);
						setTimeout(() => setCopied(false), 2000);
					}}
				>
					{copied ? (
						<>
							Copied
							<IoIosCheckmarkCircleOutline />
						</>
					) : (
						<>
							Copy
							<TbCopy />
						</>
					)}
				</button>
			</div>
		</div>
	);
};

export const CreditModal = ({ handleClose }: { handleClose: () => void }) => {
	const userData = useTripStore((state: any) => state.userData);
	const [formData, setFormData] = useState({
		credit_amount: 0,
		credit_period: 0,
		travel_volume: 0,
	});
	const [loading, setLoading] = useState(false);

	return (
		<div className="CreditFacilityModal">
			<div className="CreditFacilityModal__head">
				<p>Credit Facility</p>
			</div>
			<div className="CreditFacilityModal__body">
				<div className="form-section">
					<form
						onSubmit={async (e: any) => {
							e.preventDefault();
							console.log("formData", formData);

							setLoading(true);
							try {
								const res = await addCredit(formData);
								console.log(res);
								if (res?.status === 201 || res?.status === 200) {
									toast.success("Credit request submitted");

									handleClose();
								}
							} catch (e) {
								console.log(e);
								toast.error("Something went wrong.");
							} finally {
								setLoading(false);
							}
						}}
					>
						<div className="form-item">
							<h5>Credit Amount</h5>
							<div className="select-wrapper">
								<select
									id="credit_amount"
									name="credit_amount"
									value={formData.credit_amount}
									onChange={(e) => {
										setFormData({
											...formData,
											credit_amount: Number(e.target.value),
										});
									}}
								>
									<option value={0}>Select Credit Amount</option>
									<option value={1000000}>1,000,000</option>
									<option value={3000000}>3,000,000</option>
									<option value={5000000}>5,000,000</option>
									<option value={5000001}>{">"}50,000,000</option>
								</select>
							</div>
						</div>
						<div className="form-item">
							<h5>Credit Period</h5>
							<div className="select-wrapper">
								<select
									id="credit_period"
									name="credit_period"
									value={formData.credit_period}
									onChange={(e) => {
										setFormData({
											...formData,
											credit_period: Number(e.target.value),
										});
									}}
								>
									<option value={0}>Select Credit Period</option>
									<option value={7}>7 days</option>
									<option value={14}>14 days</option>
									<option value={21}>21 days</option>
								</select>
							</div>
						</div>

						<div className="form-item">
							<h5>Estimated travel monthly spend</h5>
							<input
								type="text"
								id="travel_volume"
								name="travel_volume"
								value={formData.travel_volume}
								onChange={(e) => {
									setFormData({
										...formData,
										travel_volume: Number(e.target.value),
									});
								}}
							/>
						</div>

						<div className="form-item">
							<Button
								onClick={() => {}}
								className="pry"
								loading={loading}
								type="submit"
								disabled={
									formData.credit_amount === 0 ||
									formData.credit_period === 0 ||
									formData.travel_volume === 0
								}
							>
								Save
							</Button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};
