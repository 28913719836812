import { request } from "../utils/axios";
import { toast } from "react-toastify";

export const fetchTripHistorySummary = async () => {
	try {
		const res = await request.get("api/history/trip_history/");
		console.log(res);
		return res;
	} catch (err) {
		console.log(err?.response?.data);
		console.log(err?.response?.data?.message);
		throw err;
	}
};

export const fetchTripHistoryDetail = async (kardinal_ref, service) => {
	try {
		const res = await request.get(
			`api/history/details/${kardinal_ref}/?service=${service}`
		);
		console.log(res);
		return res;
	} catch (err) {
		console.log(err?.response?.data);
		console.log(err?.response?.data?.message);
		throw err;
	}
};

export const cancelBooking = async (values) => {
	try {
		const res = await request.post(`api/history/cancel-booking/`, values);
		console.log(res);
		return res;
	} catch (err) {
		console.log(err?.response?.data);
		console.log(err?.response?.data?.message);
		throw err;
	}
};

export const openTicket = async (values) => {
	try {
		const res = await request.post(`api/history/open-ticket/`, values);
		console.log(res);
		return res;
	} catch (err) {
		console.log(err?.response?.data);
		console.log(err?.response?.data?.message);
		throw err;
	}
};

export const sendTicket = async (values, kardinal_ref) => {
	try {
		const res = await request.post(
			`api/history/send/ticket/${kardinal_ref}/`,
			values
		);
		console.log(res);
		return res;
	} catch (err) {
		console.log(err?.response?.data);
		console.log(err?.response?.data?.message);
		throw err;
	}
};

export const changeTicket = async (values) => {
	try {
		const res = await request.post(`api/history/change-ticket/`, values);
		console.log(res);
		return res;
	} catch (err) {
		console.log(err?.response?.data);
		console.log(err?.response?.data?.message);
		throw err;
	}
};
