import { useState, useEffect, useRef, useMemo } from "react";
import { IoChevronDown } from "react-icons/io5";
import { GoCheck } from "react-icons/go";
import useOutsideAlerter from "../../hooks/useOutsideAlerter";
import "./style.scss";
import PortLoader from "../PortLoader";

interface CustomDropdownProps {
	placeholder?: string;
	onOpen?: () => void;
	onSelect: (val: any) => void;
	defaultValue?: string;
	optionsList: { label: string; value: string; displayField?: string }[];
	active?: boolean;
	icon?: JSX.Element | undefined;
	plain?: boolean;
	loading?: boolean;
	ind?: number;
}

const CustomDropdown = ({
	icon = undefined,
	placeholder,
	onOpen = () => {},
	onSelect,
	defaultValue,
	optionsList,
	active,
	plain = false,
	loading = false,
	ind = 0,
}: CustomDropdownProps) => {
	const [open, setOpen] = useState(false);
	const [tempList, setTempList] = useState(optionsList);
	const [typedText, setTypedText] = useState("");
	const [typeActive, setTypeActive] = useState(false);
	const [selected, setSelected] = useState(
		optionsList.filter((item) => item.value === defaultValue)[0]
			?.displayField ??
			optionsList.filter((item) => item.value === defaultValue)[0]?.label ??
			""
	);

	//input refs. Might need to create more if there's more than 6 on the page
	const inputRef0 = useRef<HTMLInputElement>(null);
	const inputRef1 = useRef<HTMLInputElement>(null);
	const inputRef2 = useRef<HTMLInputElement>(null);
	const inputRef3 = useRef<HTMLInputElement>(null);
	const inputRef4 = useRef<HTMLInputElement>(null);
	const inputRef5 = useRef<HTMLInputElement>(null);
	const inputRef6 = useRef<HTMLInputElement>(null);

	const wrapperRef0 = useRef(null);
	const wrapperRef1 = useRef(null);
	const wrapperRef2 = useRef(null);
	const wrapperRef3 = useRef(null);
	const wrapperRef4 = useRef(null);
	const wrapperRef5 = useRef(null);
	const wrapperRef6 = useRef(null);

	useOutsideAlerter(eval(`wrapperRef${ind}`), () => {
		setOpen(false);
		setTypeActive(false);
	});

	useEffect(() => {
		if (active) {
			console.log(open);
			setOpen(!open);

			setTypeActive(true);
			setTimeout(() => {
				if (eval(`inputRef${ind}`).current) {
					eval(`inputRef${ind}`).current?.focus();
				}
			}, 500);
		}
	}, [active]);

	useMemo(() => {
		if (typedText !== "")
			setTempList(
				optionsList?.filter((item: any) =>
					item?.label?.toLowerCase()?.startsWith(typedText?.toLowerCase())
				)
			);
		else setTempList(optionsList);
	}, [typedText]);

	return (
		<div className="CustomDropdown" ref={eval(`wrapperRef${ind}`)}>
			<div
				className={`CustomDropdown__box CustomDropdown__box--${
					plain ? "plain" : "notPlain"
				}`}
				onClick={(e) => {
					onOpen();
					console.log(open);
					setOpen(!open);
					setTypeActive(true);
				}}
			>
				{icon && icon}
				<div>
					{/* <p>{selected ? selected : placeholder}</p> */}
					<input
						type="text"
						value={typeActive ? typedText : selected || placeholder}
						onChange={(ev) => setTypedText(ev.target.value)}
						ref={eval(`inputRef${ind}`)}
						id="real-input"
						className={`dropInput dropInput--${icon ? "hasIcon" : ""}`}
						style={{
							color: typeActive ? "#000000" : selected ? "#000000" : "#9c9393",
						}}
						// onBlur={() => {
						//     setTypeActive(false);
						// }}
						// onClick={(e) => (e.target as HTMLInputElement).focus()}
					/>
					<IoChevronDown size={16} />
				</div>
			</div>
			<div
				className={`CustomDropdown__drop ${
					open && "CustomDropdown__drop--open"
				}`}
			>
				{loading ? (
					<>
						<PortLoader />
					</>
				) : tempList.length === 0 ? (
					<div className="noOptions">
						<p>No options available</p>
					</div>
				) : (
					tempList.map((option, ind) => (
						<div
							key={ind}
							className={`drop-item ${
								selected === option.label && "drop-item--selected"
							}`}
							onClick={() => {
								onSelect(option.value);
								setSelected(option.displayField ?? option.label);
								setOpen(false);
								setTypeActive(false);
								setTypedText("");
							}}
						>
							<span>{option.label}</span>
							<GoCheck size={14} />
						</div>
					))
				)}
			</div>
		</div>
	);
};

export default CustomDropdown;
