import React, { useState, Dispatch, SetStateAction } from "react";
import "./style.scss";

type BigToggleProps = {
	list: { label: string; extra: string }[];
	selectedVal: number;
	setSelectedVal: Dispatch<SetStateAction<number>>;
	onClick: any;
};

const BigToggle = ({
	list,
	selectedVal,
	setSelectedVal,
	onClick,
}: BigToggleProps) => {
	return (
		<div className="BigToggle">
			{list.map((item, ind) => (
				<div
					key={ind}
					onClick={() => {
						setSelectedVal(ind);
						onClick();
					}}
					className={`BigToggle__item BigToggle__item${
						selectedVal === ind && "--selected"
					}`}
				>
					<p>{item.label}</p>
					<p className="extra">{item.extra}</p>
				</div>
			))}
		</div>
	);
};
export default BigToggle;
