import Button from "../Button";
import "./style.scss";

interface EmptyStateProps {
    message?: string;
    button?: { label: string; action?: () => void };
    height?: number;
}

const EmptyState = ({
    message = "Nothing to find here.",
    button,
    height = 250,
}: EmptyStateProps) => (
    <div className="EmptyState" style={{ height: `${height}px` }}>
        <p>{message}</p>
        {button && (
            <Button onClick={button?.action} className="pry">
                {button.label}
            </Button>
        )}
    </div>
);

export default EmptyState;
