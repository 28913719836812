import "./style.scss";
import {
	MdChevronLeft,
	MdChevronRight,
	MdFirstPage,
	MdLastPage,
} from "react-icons/md";
import MUIModal from "../MUIModal";
import { PreferredPageModal } from "../../modals";
import { useState } from "react";

const PaginationComponent = ({
	next,
	prev,
	jump,
	currentData,
	currentPage,
	maxPage,
	total,
	limit,
}: {
	next: () => void;
	prev: () => void;
	jump: (page: number) => void;
	currentData: any;
	currentPage: number;
	maxPage: number;
	total: number;
	limit: number;
}) => {
	const [openModal, setOpenModal] = useState(false);
	console.log("maxPage", maxPage);

	if (maxPage === 1) return <></>;

	return (
		<div className="PaginationComponent">
			<p>
				{1 + (currentPage - 1) * limit} -{" "}
				{(currentPage - 1) * limit + currentData()?.length} of {total} results
			</p>
			<div className="PaginationComponent__nav">
				<MdFirstPage size={18} onClick={() => jump(1)} />
				<MdChevronLeft size={18} onClick={() => prev()} />
				{maxPage < 6 ? (
					Array(maxPage)
						.fill(0)
						.map((_, ind) => (
							<p
								key={ind}
								onClick={() => jump(ind + 1)}
								className={`page-btn ${
									currentPage === ind + 1 ? "page-btn--active" : ""
								}`}
							>
								{ind + 1}
							</p>
						))
				) : (
					<>
						{Array(maxPage)
							.fill(0)
							.slice(currentPage - 1, currentPage - 1 + 3)
							.map((_, ind) => (
								<p
									key={ind}
									onClick={() => jump(currentPage + ind)}
									className={`page-btn ${
										currentPage === currentPage + ind ? "page-btn--active" : ""
									}`}
								>
									{currentPage + ind}
								</p>
							))}
						<p style={{ cursor: "pointer" }} onClick={() => setOpenModal(true)}>
							...
						</p>
						{Array(maxPage)
							.fill(0)
							.slice(maxPage - 3)
							.map((_, ind) => (
								<p
									key={ind}
									onClick={() => jump(maxPage - 2 + ind)}
									className={`page-btn ${
										currentPage === maxPage - 2 + ind ? "page-btn--active" : ""
									}`}
								>
									{maxPage - 2 + ind}
								</p>
							))}
					</>
				)}
				<MdChevronRight size={18} onClick={() => next()} />
				<MdLastPage size={18} onClick={() => jump(maxPage)} />
			</div>
			<MUIModal open={openModal} handleClose={() => setOpenModal(false)}>
				<PreferredPageModal
					action={(page) => {
						if (0 < page && page <= maxPage) jump(page);
						setOpenModal(false);
					}}
					maxPage={maxPage}
				/>
			</MUIModal>
		</div>
	);
};

export default PaginationComponent;
