import React from "react";
import { Navigate, RouteProps } from "react-router-dom";


const ProtectedRoute = ({
  children,
}: {
  children: JSX.Element;

}) => {
  const getLoggedIn = () => {
    const token = localStorage.getItem("lkzio") || null;
    if (token == null) {
      return false;
    }
    return true;
  };
  return getLoggedIn() ? children : <Navigate to="/login" replace />;
};
export default ProtectedRoute;
