import { request } from "../utils/axios";
import { toast } from "react-toastify";

export const fetchInvoicingActivities = async () => {
	try {
		const res = await request.get("api/finance/invoice/activities/");
		console.log(res);
		return res;
	} catch (err) {
		console.log(err?.response?.data);
		console.log(err?.response?.data?.message);
		throw err;
	}
};

export const fetchInvoices = async () => {
	try {
		const res = await request.get("api/finance/invoice/");
		console.log(res);
		return res;
	} catch (err) {
		console.log(err?.response?.data);
		console.log(err?.response?.data?.message);
		throw err;
	}
};

export const fetchInvoiceSummary = async () => {
	try {
		const res = await request.get("api/finance/invoice/summary/");
		console.log(res);
		return res;
	} catch (err) {
		console.log(err?.response?.data);
		console.log(err?.response?.data?.message);
		throw err;
	}
};

export const updateInvoiceGenerationPreference = async (values) => {
	try {
		const res = await request.post(
			"api/finance/invoice/change/preference/",
			values
		);
		console.log(res);
		return res;
	} catch (err) {
		console.log(err?.response?.data);
		console.log(err?.response?.data?.message);
		throw err;
	}
};

export const generateInvoice = async (values) => {
	try {
		const res = await request.post(
			"api/finance/invoice/generate/manual/",
			values
		);
		console.log(res);
		return res;
	} catch (err) {
		console.log(err?.response?.data);
		toast.error(err?.response?.data?.message);
		console.log(err?.response?.data?.message);
		throw err;
	}
};

export const fetchInvoiceDetails = async (invoice_id) => {
	try {
		const res = await request.get(`api/finance/invoice/${invoice_id}/`);
		console.log(res);
		return res;
	} catch (err) {
		console.log(err?.response?.data);
		console.log(err?.response?.data?.message);
		throw err;
	}
};

export const settleInvoice = async (invoice_id, values) => {
	try {
		const res = await request.patch(
			`api/finance/invoice/settle/${invoice_id}/`,
			values
		);
		console.log(res);
		return res;
	} catch (err) {
		console.log(err?.response?.data);
		toast.error(err?.response?.data?.message);
		console.log(err?.response?.data?.message);
		throw err;
	}
};

export const separateInvoice = async (invoice_id) => {
	try {
		const res = await request.patch(`api/finance/invoice/split/${invoice_id}/`);
		console.log(res);
		return res;
	} catch (err) {
		console.log(err?.response?.data);
		toast.error(err?.response?.data?.message);
		console.log(err?.response?.data?.message);
		throw err;
	}
};

export const mergeInvoice = async (invoice_id, values) => {
	try {
		const res = await request.patch(
			`api/finance/invoice/merge/${invoice_id}/`,
			values
		);
		console.log(res);
		return res;
	} catch (err) {
		console.log(err?.response?.data);
		toast.error(err?.response?.data?.message);
		console.log(err?.response?.data?.message);
		throw err;
	}
};

export const bulkSettleOverdue = async (values) => {
	try {
		const res = await request.patch(
			`api/finance/invoice/settle/bulk/overdue/`,
			values
		);
		console.log(res);
		return res;
	} catch (err) {
		console.log(err?.response?.data);
		toast.error(err?.response?.data?.message);
		console.log(err?.response?.data?.message);
		throw err;
	}
};

export const bulkSettleUnpaid = async (values) => {
	try {
		const res = await request.patch(
			`api/finance/invoice/settle/bulk/unpaid/`,
			values
		);
		console.log(res);
		return res;
	} catch (err) {
		console.log(err?.response?.data);
		toast.error(err?.response?.data?.message);
		console.log(err?.response?.data?.message);
		throw err;
	}
};
