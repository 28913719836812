import { DateTime, DurationObject, DurationOptions } from "ts-luxon";
import { Dispatch, SetStateAction } from "react";
import {
	fetchFlightData,
	fetchMulticityFlightData,
	combinableSearch,
	addSearchHistory,
} from "../requests";
import { NavigateFunction } from "react-router-dom";
import { toast } from "react-toastify";

//search flight flow

const departureFlight = async (
	emailRequest = false,
	searchLoading: boolean,
	setSearchLoading: any,
	bigFlightData: any,
	updateBigFlightData: any
) => {
	setSearchLoading(true);
	try {
		var res = await fetchFlightData({
			route: bigFlightData.route,
			adults: bigFlightData.selectedTraveller.filter(
				(item: any) => item.passenger_type === "adult"
			)?.length,
			children: bigFlightData.selectedTraveller.filter(
				(item: any) => item.passenger_type === "child"
			)?.length,
			infants: bigFlightData.selectedTraveller.filter(
				(item: any) => item.passenger_type === "infant"
			)?.length,
			cabin: bigFlightData.cabin,
			departure_date: bigFlightData.departure_date,
			destination: bigFlightData.destination,
			departure: bigFlightData.departure,
			emailRequest: emailRequest,
		});

		console.log("miniObj", {
			adults: bigFlightData.selectedTraveller.filter(
				(item: any) => item.passenger_type === "adult"
			)?.length,
			children: bigFlightData.selectedTraveller.filter(
				(item: any) => item.passenger_type === "child"
			)?.length,
			infants: bigFlightData.selectedTraveller.filter(
				(item: any) => item.passenger_type === "infant"
			)?.length,
		});
		console.log("NdepartureFlight", res);

		console.log("searchDataRes", { searchData: res });
		if (res)
			updateBigFlightData({
				session_id: res?.data?.SessionId,
				searchData: res,
			});
	} catch (e) {
		console.log(e);
	} finally {
		console.log("editFlightData", bigFlightData);
		setTimeout(() => {
			setSearchLoading(false);
		}, 200);
		return res;
	}
};
const arrivalFlight = async (
	emailRequest = false,
	arrivalLoading: boolean,
	setArrivalLoading: any,
	bigFlightData: any,
	updateBigFlightData: any
) => {
	setArrivalLoading(true);
	try {
		var res = await fetchFlightData({
			route: bigFlightData.route,
			adults: bigFlightData.selectedTraveller.filter(
				(item: any) => item.passenger_type === "adult"
			)?.length,
			children: bigFlightData.selectedTraveller.filter(
				(item: any) => item.passenger_type === "child"
			)?.length,
			infants: bigFlightData.selectedTraveller.filter(
				(item: any) => item.passenger_type === "infant"
			)?.length,
			cabin: bigFlightData.cabin,
			departure_date: bigFlightData.return_date,
			destination: bigFlightData.departure,
			departure: bigFlightData.destination,
			emailRequest: emailRequest,
		});
		console.log("NdepartureFlight", res);
		updateBigFlightData({
			session_id2: res?.data?.SessionId,
			arrivalData: res,
		});
	} catch (e) {
		console.log(e);
	} finally {
		setTimeout(() => {
			setArrivalLoading(false);
		}, 200);

		return res;
	}
};
const multiCityFlights = async (
	emailRequest = false,
	searchLoading: boolean,
	setSearchLoading: any,
	bigFlightData: any,
	updateBigFlightData: any
) => {
	setSearchLoading(true);
	const resultsArr = await Promise.all(
		bigFlightData?.multiCity.map(async (item: any) => {
			try {
				var res = await fetchFlightData({
					route: bigFlightData.route,
					adults: bigFlightData.selectedTraveller.filter(
						(item: any) => item.passenger_type === "adult"
					)?.length,
					children: bigFlightData.selectedTraveller.filter(
						(item: any) => item.passenger_type === "child"
					)?.length,
					infants: bigFlightData.selectedTraveller.filter(
						(item: any) => item.passenger_type === "infant"
					)?.length,
					cabin: bigFlightData.cabin,
					departure_date: item.departure_date,
					destination: item.destination,
					departure: item.departure,
					emailRequest: emailRequest,
				});
				console.log("NMultiFlight", res);
				updateBigFlightData({
					session_id: res?.data?.SessionId,
				});
				return res;
			} catch (e) {
				console.log(e);
			} finally {
				// setSearchLoading(false);
				return res;
			}
		})
	).finally(() => {
		setTimeout(() => {
			setSearchLoading(false);
		}, 200);
	});

	console.log("resultsArr", resultsArr);
	updateBigFlightData({
		multiSearchData: resultsArr,
	});
	return resultsArr;
};
const multiCityIntlFlights = async (
	emailRequest = false,
	searchLoading: boolean,
	setSearchLoading: any,
	bigFlightData: any,
	updateBigFlightData: any
) => {
	setSearchLoading(true);
	try {
		var res = await fetchMulticityFlightData({
			route: bigFlightData.route,
			adults: bigFlightData.selectedTraveller.filter(
				(item: any) => item.passenger_type === "adult"
			)?.length,
			children: bigFlightData.selectedTraveller.filter(
				(item: any) => item.passenger_type === "child"
			)?.length,
			infants: bigFlightData.selectedTraveller.filter(
				(item: any) => item.passenger_type === "infant"
			)?.length,
			cabin: bigFlightData.cabin,
			tripArray: bigFlightData.multiCity,
			emailRequest: emailRequest,
		});
		console.log("NMultiFlight", res);
		updateBigFlightData({
			session_id: res?.data?.SessionId,
			searchData: res,
			arrivalData: res,
		});
	} catch (e) {
		console.log(e);
	} finally {
		setTimeout(() => {
			setSearchLoading(false);
		}, 1000);
		return res;
	}
};
const combinedFlights = async (
	emailRequest = false,
	searchLoading: boolean,
	setSearchLoading: any,
	bigFlightData: any,
	updateBigFlightData: any
) => {
	setSearchLoading(true);
	try {
		var res = await combinableSearch({
			adults: bigFlightData.selectedTraveller.filter(
				(item: any) => item.passenger_type === "adult"
			)?.length,
			children: bigFlightData.selectedTraveller.filter(
				(item: any) => item.passenger_type === "child"
			)?.length,
			infants: bigFlightData.selectedTraveller.filter(
				(item: any) => item.passenger_type === "infant"
			)?.length,
			cabin: bigFlightData.cabin?.split(" ")[0],
			departure_date: bigFlightData.departure_date,
			return_date: bigFlightData.return_date,
			destination: bigFlightData.destination,
			departure: bigFlightData.departure,
			emailRequest: emailRequest,
		});
		console.log("NdepartureFlight", res);
		updateBigFlightData({
			session_id: res?.data?.SessionId,
			searchData: res,
			arrivalData: res,
		});
	} catch (e) {
		console.log(e);
	} finally {
		setTimeout(() => {
			setSearchLoading(false);
		}, 1000);

		if (res.message === "success") return "success";
	}
};
export const searchFlight = async (
	emailRequest = false,
	searchLoading: boolean,
	setSearchLoading: any,
	arrivalLoading: boolean,
	setArrivalLoading: any,
	bigFlightData: any,
	updateBigFlightData: any,
	navigate: NavigateFunction,
	directFlight = false
) => {
	console.log("searchFlight", searchFlight);

	if (bigFlightData.showTripType === "One way") {
		const departureRequest = await departureFlight(
			emailRequest,
			searchLoading,
			setSearchLoading,
			bigFlightData,
			updateBigFlightData
		);
		if (departureRequest?.data?.message === "Endpoint request timed out") {
			toast.error("Tiqwa timeout error");
			navigate("/trips");
			setSearchLoading(false);
			setArrivalLoading(false);
			return;
		}

		if (departureRequest?.message === "success") {
			const saveRequest = addSearchHistory(
				{
					from_location: bigFlightData.departure,
					to_location: bigFlightData.destination,
					departure_date: new Date(bigFlightData.departure_date).toISOString(),
					return_date: null,
					travelers: bigFlightData.selectedTraveller?.length,
					flight_type: bigFlightData.route,
				},
				emailRequest
			);
			console.log("saveRequest", saveRequest);
			navigate(`/trips/flight-results?direct=${directFlight}`);
			return;
		}
	} else if (bigFlightData.showTripType === "Roundtrip") {
		if (bigFlightData.route === "domestic") {
			const departureRequest = await departureFlight(
				emailRequest,
				searchLoading,
				setSearchLoading,
				bigFlightData,
				updateBigFlightData
			);
			if (departureRequest?.data?.message === "Endpoint request timed out") {
				toast.error("Tiqwa timeout error");
				setSearchLoading(false);
				setArrivalLoading(false);
				console.log("looo", searchLoading, arrivalLoading);
				return;
			}

			const arrivalRequest = await arrivalFlight(
				emailRequest,
				arrivalLoading,
				setArrivalLoading,
				bigFlightData,
				updateBigFlightData
			);

			if (
				departureRequest?.message === "success" &&
				arrivalRequest?.message === "success"
			) {
				// if ()
				const saveRequest = addSearchHistory(
					{
						from_location: bigFlightData.departure,
						to_location: bigFlightData.destination,
						departure_date: new Date(
							bigFlightData.departure_date
						).toISOString(),
						return_date: new Date(bigFlightData.return_date).toISOString(),
						travelers: bigFlightData.selectedTraveller?.length,
						flight_type: bigFlightData.route,
					},
					emailRequest
				);
				navigate(`/trips/flight-results?direct=${directFlight}`);
				return;
			}
		} else {
			const combinedRequest = await combinedFlights(
				emailRequest,
				searchLoading,
				setSearchLoading,
				bigFlightData,
				updateBigFlightData
			);
			console.log("combinedRequest", combinedRequest);
			if (combinedRequest === "success") {
				const saveRequest = addSearchHistory(
					{
						from_location: bigFlightData.departure,
						to_location: bigFlightData.destination,
						departure_date: new Date(
							bigFlightData.departure_date
						).toISOString(),
						return_date: new Date(bigFlightData.return_date).toISOString(),
						travelers: bigFlightData.selectedTraveller?.length,
						flight_type: bigFlightData.route,
					},
					emailRequest
				);
				navigate(`/trips/flight-results?direct=${directFlight}`);
				return combinedRequest;
			}
		}
	} else if (bigFlightData.showTripType === "Multicity") {
		if (bigFlightData.route === "domestic") {
			const multiCityRequest = await multiCityFlights(
				emailRequest,
				searchLoading,
				setSearchLoading,
				bigFlightData,
				updateBigFlightData
			);
			if (multiCityRequest[0]?.data?.message === "Endpoint request timed out") {
				toast.error("Tiqwa timeout error");
				navigate("/trips");
				setSearchLoading(false);
				setArrivalLoading(false);
				return;
			}

			if (multiCityRequest[0]?.message === "success") {
				const saveRequest = addSearchHistory(
					{
						from_location: bigFlightData.multiCity[0].departure,
						to_location: bigFlightData.multiCity[0].destination,
						departure_date: new Date(
							bigFlightData.multiCity[0].departure_date
						).toISOString(),
						return_date: null,
						travelers: bigFlightData.selectedTraveller?.length,
						flight_type: bigFlightData.route,
					},
					emailRequest
				);
				console.log("saveRequest", saveRequest);
				navigate(`/trips/flight-results?direct=${directFlight}`);
			}
		} else {
			const multiCityRequest = await multiCityIntlFlights(
				emailRequest,
				searchLoading,
				setSearchLoading,
				bigFlightData,
				updateBigFlightData
			);
			console.log("multiCityRequest", multiCityRequest);
			if (multiCityRequest?.message) {
				// const saveRequest = addSearchHistory(
				// 	{
				// 		from_location: bigFlightData.departure,
				// 		to_location: bigFlightData.destination,
				// 		departure_date: new Date(
				// 			bigFlightData.departure_date
				// 		).toISOString(),
				// 		return_date: new Date(bigFlightData.return_date).toISOString(),
				// 		travelers: bigFlightData.selectedTraveller?.length,
				// 		flight_type: bigFlightData.route,
				// 	},
				// 	emailRequest
				// );
				console.log("tried nav");
				navigate(`/trips/flight-results?direct=${directFlight}`);
			}
		}
	}
};

// to be used when adding passengers on the confirmation page. We have to re-run the search,
// filter the results to the right flight id, and auto-select

export const redoFlightSearch = async (
	emailRequest = false,
	searchLoading: boolean,
	setSearchLoading: any,
	arrivalLoading: boolean,
	setArrivalLoading: any,
	bigFlightData: any,
	updateBigFlightData: any,
	errorFunc: () => void
) => {
	console.log("searchFlight", searchFlight);

	if (bigFlightData.showTripType === "One way") {
		const departureRequest = await departureFlight(
			emailRequest,
			searchLoading,
			setSearchLoading,
			bigFlightData,
			updateBigFlightData
		);
		if (departureRequest?.data?.message === "Endpoint request timed out") {
			toast.error("Tiqwa timeout error");
			errorFunc();
			return;
		}

		if (departureRequest?.message === "success") {
			const saveRequest = addSearchHistory(
				{
					from_location: bigFlightData.departure,
					to_location: bigFlightData.destination,
					departure_date: new Date(bigFlightData.departure_date).toISOString(),
					return_date: null,
					travelers: bigFlightData.selectedTraveller?.length,
					flight_type: bigFlightData.route,
				},
				emailRequest
			);
			console.log("currId", bigFlightData?.selectedDeparture?.id);
			console.log("currId", bigFlightData?.searchData?.data);
			console.log(
				"currId",
				bigFlightData?.searchData?.data?.filter(
					(item: any) => item?.id === bigFlightData?.selectedDeparture?.id
				)
			);
			return;
		}
	} else if (bigFlightData.showTripType === "Roundtrip") {
		if (bigFlightData.route === "domestic") {
			const departureRequest = await departureFlight(
				emailRequest,
				searchLoading,
				setSearchLoading,
				bigFlightData,
				updateBigFlightData
			);
			if (departureRequest?.data?.message === "Endpoint request timed out") {
				toast.error("Tiqwa timeout error");
				errorFunc();
				return;
			}

			const arrivalRequest = await arrivalFlight(
				emailRequest,
				arrivalLoading,
				setArrivalLoading,
				bigFlightData,
				updateBigFlightData
			);

			if (
				departureRequest?.message === "success" &&
				arrivalRequest?.message === "success"
			) {
				const saveRequest = addSearchHistory(
					{
						from_location: bigFlightData.departure,
						to_location: bigFlightData.destination,
						departure_date: new Date(
							bigFlightData.departure_date
						).toISOString(),
						return_date: new Date(bigFlightData.return_date).toISOString(),
						travelers: bigFlightData.selectedTraveller?.length,
						flight_type: bigFlightData.route,
					},
					emailRequest
				);
				return;
			}
		} else {
			const combinedRequest = await combinedFlights(
				emailRequest,
				searchLoading,
				setSearchLoading,
				bigFlightData,
				updateBigFlightData
			);
			console.log("combinedRequest", combinedRequest);
			if (combinedRequest === "success") {
				const saveRequest = addSearchHistory(
					{
						from_location: bigFlightData.departure,
						to_location: bigFlightData.destination,
						departure_date: new Date(
							bigFlightData.departure_date
						).toISOString(),
						return_date: new Date(bigFlightData.return_date).toISOString(),
						travelers: bigFlightData.selectedTraveller?.length,
						flight_type: bigFlightData.route,
					},
					emailRequest
				);
				return combinedRequest;
			}
		}
	} else if (bigFlightData.showTripType === "Multicity") {
		if (bigFlightData.route === "domestic") {
			const multiCityRequest = await multiCityFlights(
				emailRequest,
				searchLoading,
				setSearchLoading,
				bigFlightData,
				updateBigFlightData
			);
			if (multiCityRequest[0]?.data?.message === "Endpoint request timed out") {
				toast.error("Tiqwa timeout error");
				errorFunc();
				return;
			}

			if (multiCityRequest[0]?.message === "success") {
				const saveRequest = addSearchHistory(
					{
						from_location: bigFlightData.multiCity[0].departure,
						to_location: bigFlightData.multiCity[0].destination,
						departure_date: new Date(
							bigFlightData.multiCity[0].departure_date
						).toISOString(),
						return_date: null,
						travelers: bigFlightData.selectedTraveller?.length,
						flight_type: bigFlightData.route,
					},
					emailRequest
				);
				console.log("saveRequest", saveRequest);
			}
		} else {
			const multiCityRequest = await multiCityIntlFlights(
				emailRequest,
				searchLoading,
				setSearchLoading,
				bigFlightData,
				updateBigFlightData
			);
			console.log("multiCityRequest", multiCityRequest);
			if (multiCityRequest?.message) {
				console.log("tried nav");
			}
		}
	}
};
