import { useState } from "react";
import bigCar from "../../../resource/img/big-car-img.svg";
import "./style.scss";
import CustomDropdown from "../../CustomDropdown";
import { FaRegSnowflake } from "react-icons/fa";
import { MdGroups, MdInfoOutline } from "react-icons/md";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { TextField } from "@mui/material";
import Button from "../../Button";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import RentalInfoDrawer from "./RentalInfoDrawer";
import { useTripStore } from "../../../zustand/store";
import { useNavigate } from "react-router-dom";
import { toggleSideDrawer } from "../../../helpers/helperFuncs";
import MUIModal from "../../MUIModal";
import { AdditionalNoteModal, PaymentMethodModal } from "../../../modals";
import { bookCar } from "../../../requests/cars";
import { toast } from "react-toastify";
import LoadingModalsDebacle from "../../LoadingModalsDebacle";
import { DateTime } from "ts-luxon";

interface CarPhase2Props {
	setCarPhase: (val: number) => void;
}

export const CarPhase2 = ({ setCarPhase }: CarPhase2Props) => {
	const [reference, setReference] = useState("");
	const [openSide, setOpenSide] = useState(false);
	const [bookingLoading, setBookingLoading] = useState(false);
	const [openPayment, setOpenPayment] = useState(false);
	const [openAddNote, setOpenAddNote] = useState(false);
	const [textVal, setTextVal] = useState(""); // additional note
	const [successLoading, setSuccessLoading] = useState(false);
	let [successFunc, setSuccessFunc] = useState<() => void>(
		() => () => console.log("init")
	);

	const navigate = useNavigate();

	const bigCarData = useTripStore((state: any) => state.bigCarData);
	const updateBigCarData = useTripStore((state: any) => state.updateBigCarData);

	let amount = 0;
	amount =
		bigCarData?.searchResults[0]?.base_fare +
		bigCarData?.searchResults[0]?.parking_fee +
		bigCarData?.searchResults[0]?.booking_fee;

	const confirmBooking = async (
		payment_method: string,
		latestReference: string = ""
	) => {
		setBookingLoading(true);
		let res1: any;
		try {
			res1 = await bookCar({
				payment_method: payment_method,
				traveler: bigCarData?.selectedTraveller.map(
					({ id }: { id: number }) => id
				),
				carbooked: bigCarData?.carType?.id,
				amount:
					bigCarData?.searchResults[0]?.base_fare +
					bigCarData?.searchResults[0]?.parking_fee +
					bigCarData?.searchResults[0]?.booking_fee,
				pickup_time: bigCarData?.ISODate,
				dropoff_time: bigCarData?.ISODate,
				additional_note: textVal === "" ? "No Note" : textVal,
				...(payment_method === "pay_with_card" && {
					paystack_refID: latestReference,
				}),
			});
		} catch (e) {
		} finally {
			setBookingLoading(false);

			console.log("res1", res1);

			if (res1?.status === 201) {
				setSuccessFunc(() => () => {
					setTimeout(() => {
						updateBigCarData({
							bookingConfirmed: true,
							kardinal_reference: res1?.data?.kardinal_reference,
						});
						navigate("/trips/car-successful");
					}, 1000);
				});

				setSuccessLoading(true);
			} else if (res1?.status >= 500) {
				toast.error("Server error");
			} else {
				// toast.error("Something went wrong");
			}
		}
	};

	const timeOptions = [
		...Array(12)
			.fill(0)
			.map((item, ind) => ({
				label: `${ind + 1}:00am`,
				value: `${ind + 1}:00am`,
			})),
		...Array(12)
			.fill(0)
			.map((item, ind) => ({
				label: `${ind + 1}:00pm`,
				value: `${ind + 1}:00pm`,
			})),
	];
	console.log("timeOptions", timeOptions);

	return (
		<div className="CarPhase2">
			<div className="CarPhase2__left">
				<h3 className="car-title">Ride Itinerary</h3>
				<img
					src={
						bigCar
						// bigCarData?.carType?.picture
					}
					alt=""
				/>
			</div>
			<div className="CarPhase2__right">
				<div className="CarPhase2__right__edit">
					<p onClick={() => setCarPhase(1)}>Edit Search</p>
				</div>
				<div className="CarPhase2__right__card">
					<div className="carInfo">
						<div className="carInfo__row">
							<CarInfoBunch
								label="Pick-up:"
								value={bigCarData?.pickUp?.location_name}
							/>
							<CarInfoBunch
								label="Drop-off:"
								value={bigCarData?.dropOff?.location_name}
								variant="right"
							/>
						</div>
						<div className="carInfo__row">
							{/* format: "Thursday, Nov 25th" */}
							<CarInfoBunch
								label="Pick-up date:"
								value={DateTime.fromISO(bigCarData?.date).toFormat(
									"EEEE, MMM d"
								)}
							/>
							<CarInfoBunch
								label="Time:"
								value={
									<LocalizationProvider dateAdapter={AdapterDayjs}>
										<TimePicker
											// label="Time"
											value={bigCarData?.time}
											onChange={(newValue: any) => {
												updateBigCarData({ time: newValue });

												const dateObj = new Date(bigCarData?.date);
												dateObj.setHours(newValue?.$H);
												dateObj.setMinutes(newValue?.$m);

												console.log("dateObj", dateObj);
												updateBigCarData({ ISODate: dateObj.toISOString() });
											}}
											renderInput={(params) => (
												<TextField
													{...params}
													inputProps={{
														...params.inputProps,
														placeholder: "Select",
													}}
													sx={{
														paddingRight: 0,
														marginRight: "-10px",
														"& .MuiOutlinedInput-notchedOutline": {
															paddingRight: 0,
														},
														"& .MuiInputAdornment-root": {
															// display: "none",
														},
													}}
												/>
											)}
											components={{ OpenPickerIcon: KeyboardArrowDownIcon }}
										/>
									</LocalizationProvider>
								}
								variant="right"
							/>
						</div>
						<div className="carInfo__row">
							<CarInfoBunch
								label="Car information:"
								value={
									<div className="carInformation">
										{bigCarData?.carType?.air_condition && (
											<div className="carInformation__row">
												<FaRegSnowflake />
												<span>Air condition</span>
											</div>
										)}
										<div className="carInformation__row">
											<MdGroups />
											<span>{bigCarData?.carType?.no_of_seats} seaters</span>
										</div>
									</div>
								}
							/>
							<CarInfoBunch
								label="Car type:"
								value={bigCarData?.carType?.car_type}
								variant="right"
							/>
						</div>
						<div
							className="carInfo__row"
							style={{
								gridTemplateColumns: "unset",
								borderBottom: 0,
								paddingBottom: 0,
								marginBottom: "12px",
							}}
						>
							<div className="priceBreakdown">
								<p className="priceBreakdown__title">Price breakdown</p>
								<div className="priceBreakdown__double">
									<p>Base Fare</p>
									<p>
										₦
										{bigCarData?.searchResults[0]?.base_fare?.toLocaleString(
											"en-US"
										)}
									</p>
								</div>
								<div className="priceBreakdown__double">
									<p>Parking fee</p>
									<p>
										₦
										{bigCarData?.searchResults[0]?.parking_fee?.toLocaleString(
											"en-US"
										)}
									</p>
								</div>
								<div className="priceBreakdown__double">
									<p>Booking fee</p>
									<p>{bigCarData?.searchResults[0]?.booking_fee || "Free"}</p>
								</div>
								<div className="priceBreakdown__double total">
									<p>Total</p>
									<p className="price">
										₦
										{(
											bigCarData?.searchResults[0]?.base_fare +
											bigCarData?.searchResults[0]?.parking_fee +
											bigCarData?.searchResults[0]?.booking_fee
										).toLocaleString("en-US")}
									</p>
								</div>
							</div>
						</div>
						<div className="rentalTag" onClick={() => setOpenSide(true)}>
							<MdInfoOutline />
							<span>Rental conditions</span>
						</div>
					</div>
					<div className="btnWrap">
						<Button variant="outline" onClick={() => setOpenAddNote(true)}>
							Add additional note
						</Button>
						<Button
							className="pry"
							disabled={bigCarData?.ISODate === ""}
							onClick={() => setOpenPayment(true)}
						>
							Proceed
						</Button>
					</div>
				</div>
			</div>
			<SwipeableDrawer
				anchor="right"
				open={openSide}
				onClose={toggleSideDrawer(false, setOpenSide)}
				onOpen={toggleSideDrawer(true, setOpenSide)}
			>
				<RentalInfoDrawer />
			</SwipeableDrawer>
			<MUIModal open={openPayment} handleClose={() => setOpenPayment(false)}>
				<PaymentMethodModal
					confirmBooking={confirmBooking}
					loading={bookingLoading}
					handleClose={() => setOpenPayment(false)}
					setReference={setReference}
					reference={reference}
					amount={amount}
					hideOnHold={true}
				/>
			</MUIModal>
			<MUIModal open={openAddNote} handleClose={() => setOpenAddNote(false)}>
				<AdditionalNoteModal
					textVal={textVal}
					setTextVal={setTextVal}
					handleClose={() => setOpenAddNote(false)}
				/>
			</MUIModal>
			<LoadingModalsDebacle
				successLoading={successLoading}
				setSuccessLoading={setSuccessLoading}
				loading={bookingLoading}
				handleClose={() => {}}
				loadingMessage={"Hold on, we are confirming your payment"}
				successMessage={"Booking successful"}
				showSuccess={true}
				successFunc={successFunc}
			/>
		</div>
	);
};

export const CarInfoBunch = ({
	label,
	value,
	selectOptions,
	variant,
}: {
	label: string;
	value: string | JSX.Element;
	selectOptions?: { label: string; value: string }[];
	variant?: "right";
}) => (
	<div className={`CarInfoBunch CarInfoBunch--${variant}`}>
		<div className={`CarInfoBunch__left`}>
			<p className="label">{label}</p>
			{selectOptions ? (
				<CustomDropdown
					onSelect={(val: any) => {
						// setNewPassenger({
						// 	...newPassenger,
						// 	means_of_identification: val,
						// });
					}}
					// defaultValue={newPassenger.means_of_identification}
					placeholder="Select"
					optionsList={selectOptions}
					plain={true}
					ind={0}
				/>
			) : (
				<p className="value">{value}</p>
			)}
		</div>
	</div>
);
