import "../style.scss";
import UploadPhoto from "../../../components/UploadPhoto";
import ToggleSwitch from "../../../components/ToggleSwitch";
import { Switch } from "@mui/material";
import { useState } from "react";
import Button from "../../../components/Button";
import { useTripStore } from "../../../zustand/store";
import CustomDropdown from "../../../components/CustomDropdown";
import { jobRoleList } from "../../../helpers/constants";
import { updatePersonalProfile } from "../../../requests";
import { toast } from "react-toastify";

const PersonalProfile = () => {
	const userData = useTripStore((state: any) => state.userData);
	const updateUserData = useTripStore((state: any) => state.updateUserData);

	const [notificationsOn, setNotificationsOn] = useState(false);
	const [loading, setLoading] = useState(false);
	const [formValues, setFormValues] = useState({
		first_name: userData?.first_name,
		last_name: userData?.last_name,
		username: userData?.username,
		email: userData?.email,
		job_role: userData?.job_role,
	});
	console.log("userData", userData);
	console.log("formValues", formValues);

	const changeFunc = (e: React.ChangeEvent<HTMLInputElement>) => {
		setFormValues({ ...formValues, [e.target.name]: e.target.value });
		console.log(formValues);
	};

	return (
		<div className="SettingsCrux">
			<form
				className="SettingsCrux__main"
				onSubmit={async (e) => {
					e.preventDefault();
					setLoading(true);
					try {
						const { email, ...formValuesRest } = formValues;
						const res = await updatePersonalProfile(formValuesRest);
						console.log("updatePersonalProfile", res);
						if (res?.status === 200) toast.success("Details updated");
					} catch (e) {
						console.log(e);
						toast.success("Something went wrong");
					} finally {
						setLoading(false);
					}
				}}
			>
				<div className="SettingsCrux__form">
					<label htmlFor="">
						First name
						<input
							type="text"
							name="first_name"
							id=""
							value={formValues.first_name}
							onChange={changeFunc}
							required={true}
						/>
					</label>
					<label htmlFor="">
						Last name
						<input
							type="text"
							name="last_name"
							id=""
							value={formValues.last_name}
							onChange={changeFunc}
							required={true}
						/>
					</label>
					<label htmlFor="">
						Username
						<input
							type="text"
							name="username"
							id=""
							value={formValues.username}
							onChange={changeFunc}
							required={true}
						/>
					</label>
					<label htmlFor="">
						Email address
						<input
							type="email"
							name="email"
							id=""
							value={formValues.email}
							// onChange={changeFunc}
							required={true}
							disabled={true}
						/>
					</label>
					<label htmlFor="">
						Job Title
						<div style={{ marginTop: "0.75rem" }}>
							<CustomDropdown
								onSelect={(val: any) => {
									setFormValues({
										...formValues,
										job_role: val,
									});
								}}
								defaultValue={formValues.job_role}
								optionsList={jobRoleList}
								ind={0}
							/>
						</div>
					</label>
				</div>
				{/* <div className="notifications">
					<div>
						<p className="title">Notifications</p>
						<p>
							From time to time, we may send you emails about our product,
							research surveys, or sales and promotions.
						</p>
					</div>
					<Switch
						checked={notificationsOn}
						onChange={(e) => {
							setNotificationsOn(e.target.checked);
						}}
						inputProps={{ "aria-label": "controlled" }}
						sx={{
							padding: "5px 0",

							"& .MuiSwitch-root": {
								padding: "5px 0",
							},

							"& .MuiSwitch-track": {
								borderRadius: 22 / 2,
								// backgroundColor: "green",
								// "&:before, &:after": {
								//     content: '""',
								//     position: "absolute",
								//     top: "50%",
								//     transform: "translateY(-50%)",
								//     width: 16,
								//     height: 16,
								// },
								// "&:before": {
								//     left: 12,
								// },
								// "&:after": {
								//     right: 12,
								// },
								// "& .MuiSwitch-thumb": {
								//     boxShadow: "none",
								//     width: 16,
								//     height: 16,
								//     margin: 2,
								//    ,
								// },
							},
							"& .Mui-checked + .MuiSwitch-track": {
								backgroundColor: "#21D047 !important",
								opacity: "1 !important",
							},
						}}
					/>
				</div> */}
				<div className="submit-div">
					<Button
						className="pry"
						type="submit"
						onClick={() => {}}
						loading={loading}
					>
						Save
					</Button>
				</div>
			</form>
			<div className="SettingsCrux__aside">
				<UploadPhoto title="Personal photo" />
			</div>
		</div>
	);
};

export default PersonalProfile;
