import { useState, ChangeEvent } from "react";
import Switch from "@mui/material/Switch";

interface ToggleSwitchProps {
    checked: boolean;
    handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const ToggleSwitch = ({ checked, handleChange }: ToggleSwitchProps) => {
    return (
        <Switch
            checked={checked}
            onChange={handleChange}
            inputProps={{ "aria-label": "controlled" }}
        />
    );
};

export default ToggleSwitch;
