import React, { useState, Dispatch, SetStateAction } from "react";
import { Outlet, Link, useLocation, useNavigate } from "react-router-dom";
import { MenuCancel } from "../../components/pieces/svgs";
import { useTripStore } from "../../zustand/store";

interface MobileNavDrawerProps {
	mobileNavList: { label: string; route: string }[];
	sideOpen: boolean;
	setSideOpen: Dispatch<SetStateAction<boolean>>;
}

const MobileNavDrawer = ({
	mobileNavList,
	sideOpen,
	setSideOpen,
}: MobileNavDrawerProps) => {
	let userPicture = "https://xsgames.co/randomusers/avatar.php?g=male";
	const { pathname } = useLocation();

	const navigate = useNavigate();

	const userData = useTripStore((state: any) => state.userData);
	const updateBigFlightData = useTripStore(
		(state: any) => state.updateBigFlightData
	);
	const updateBigCarData = useTripStore((state: any) => state.updateBigCarData);

	const resetZustand = () => {
		//reset zustand
		updateBigFlightData({
			search: "",
			selectedTraveller: [],
			departure: "",
			destination: "",
			departure_date: "",
			return_date: null,
			cabin: "economy",
			showTripType: "Roundtrip",
			selectedDeparture: undefined,
			selectedArrival: undefined,
			flightSelected: false,
			bookingConfirmed: false,
			paymentConfirmed: false,
			departureCity: [],
			arrivalCity: [],
			flightReference: "",
			route: "domestic",
			session_id: "",
			session_id2: "",
			searchData: {},
			arrivalData: {},
			exchange_rate: 0,
			multiCity: [
				{
					departure: "",
					destination: "",
					departure_date: "",
				},
				{
					departure: "",
					destination: "",
					departure_date: "",
				},
			],
			selectedMultiCityFlights: [],
			multiSearchData: [],
		});
		updateBigCarData({
			selectedTraveller: [],
			service_type: "FULLDAY",
			carType: "",
			date: "",
			ISODate: "",
			time: "",
			pickUp: "",
			dropOff: "",
			bookingConfirmed: false,
			searchResults: [],
			kardinal_reference: "",
		});
	};

	return (
		<div className="menu-options">
			<div className="menu-top">
				<div className="userInfo">
					<img className="user-pic" src={userPicture} alt="" />
					<p>
						{userData?.first_name} {userData?.last_name}
					</p>
				</div>
				<div className="menu-cancel" onClick={() => setSideOpen(!sideOpen)}>
					<MenuCancel />
				</div>
			</div>
			<ul>
				{mobileNavList.map((navItem, ind) => (
					<Link
						to={navItem.route}
						onClick={() => {
							setSideOpen(false);
							resetZustand();
						}}
						key={ind}
						style={{
							color: pathname.startsWith(navItem.route) ? "#40798C" : "#252627",
						}}
					>
						<li>{navItem.label}</li>
					</Link>
				))}
			</ul>
			<p
				className="signoutLink"
				onClick={() => {
					localStorage.removeItem("lkzio");
					setSideOpen(false);
					navigate("/login");
				}}
			>
				Log out
			</p>
		</div>
	);
};

export default MobileNavDrawer;
