import { useState, useEffect, useRef, SetStateAction, Dispatch } from "react";
import { IoChevronDown } from "react-icons/io5";
import { GoCheck } from "react-icons/go";
import "./style.scss";
import useOutsideAlerter from "../../hooks/useOutsideAlerter";
import MUIRangeSlider from "../MUIRangeSlider";
import { CheckBox } from "../TripComponents/tripData";
import CustomCheckBox from "../CustomCheckBox";

interface CustomFilterProps {
	placeholder: string;
	title: string;
	optionsList: any[];
	type: "radio" | "checkbox" | "time";
	timeFilters?: {
		departure: Array<"early" | "morning" | "afternoon" | "night">;
		arrival: Array<"early" | "morning" | "afternoon" | "night">;
	};
	selectedRadio?: string | any;
	selectedList?: string[];
	onSelect?: (val: any) => void;
	setTimeFilters?: Dispatch<
		SetStateAction<{
			departure: Array<"early" | "morning" | "afternoon" | "night">;
			arrival: Array<"early" | "morning" | "afternoon" | "night">;
		}>
	>;
}

interface CustomRadioCheckboxProps {
	optionsList: any[];
	type: "radio" | "checkbox";
	closeFunc: () => void;
	selectedRadio?: string | any;
	selectedList?: string[];
	onSelect?: (val: any) => void;
}

const CustomFilter = ({
	placeholder,
	title,
	optionsList,
	type,
	timeFilters,
	selectedRadio,
	selectedList,
	onSelect,
	setTimeFilters,
}: CustomFilterProps) => {
	const [open, setOpen] = useState(false);
	const wrapperRef = useRef(null);
	useOutsideAlerter(wrapperRef, () => setOpen(false));

	useEffect(() => {}, []);

	return (
		<div
			className={`CustomFilter ${type === "time" && "CustomFilter--time"}`}
			ref={wrapperRef}
		>
			<div
				className="CustomFilter__box"
				onClick={(e) => {
					setOpen(!open);
				}}
			>
				<p>{placeholder}</p>
				<IoChevronDown size={16} />
			</div>
			<div
				className={`CustomFilter__drop ${open && "CustomFilter__drop--open"}`}
			>
				<div>
					<p
						className="title"
						style={{
							marginLeft: title === "Flight Time" ? "0.75rem" : 0,
						}}
					>
						{title}
					</p>
					{type === "time" ? (
						<CustomTimeFilter
							selectedTimes={timeFilters ?? { departure: [], arrival: [] }}
							setSelectedTimes={setTimeFilters}
						/>
					) : (
						<CustomRadioCheckbox
							optionsList={optionsList}
							type={type}
							closeFunc={() => setOpen(false)}
							onSelect={onSelect}
							selectedRadio={selectedRadio}
							selectedList={selectedList}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

const CustomRadioCheckbox = ({
	optionsList,
	type,
	closeFunc,
	selectedRadio = "",
	selectedList = [],
	onSelect,
}: CustomRadioCheckboxProps) => {
	const [localSelectedRadio, setLocalSelectedRadio] = useState(selectedRadio);
	const [localSelectedList, setLocalSelectedList] =
		useState<string[]>(selectedList);

	if (type === "radio")
		return (
			<div>
				{optionsList.map((item, ind) => (
					<div
						className="radioItem"
						onClick={() => {
							setLocalSelectedRadio(item?.value);
							onSelect && onSelect(item?.value);
							closeFunc();
						}}
						key={ind}
					>
						<div
							className={`radioItem__dot ${
								localSelectedRadio === item?.value && "radioItem__dot--selected"
							}`}
						></div>
						<p>{item?.label}</p>
					</div>
				))}
			</div>
		);
	else
		return (
			<div>
				{optionsList.map((item) => (
					<div
						className="radioItem"
						onClick={() => {
							const selectIndex = localSelectedList.findIndex(
								(option) => option === item?.value
							);
							if (selectIndex === -1) {
								const newList: string[] = [...localSelectedList];
								newList.push(item?.value);
								setLocalSelectedList(newList);
							} else {
								const newList: string[] = [
									...localSelectedList.slice(0, selectIndex),
									...localSelectedList.slice(selectIndex + 1),
								];
								setLocalSelectedList(newList);
							}
							onSelect && onSelect(item?.value);
						}}
					>
						<div
							className={`radioItem__dot radioItem__dot--checkbox ${
								localSelectedList.findIndex(
									(option) => option === item?.value
								) !== -1 && "radioItem__dot--selected"
							}`}
						>
							<GoCheck color="#ffffff" size={11} />
						</div>
						<p>{item?.label}</p>
					</div>
				))}
			</div>
		);
};

const CustomTimeFilter = ({
	selectedTimes,
	setSelectedTimes,
}: {
	selectedTimes: {
		departure: Array<"early" | "morning" | "afternoon" | "night">;
		arrival: Array<"early" | "morning" | "afternoon" | "night">;
	};
	setSelectedTimes?: Dispatch<
		SetStateAction<{
			departure: Array<"early" | "morning" | "afternoon" | "night">;
			arrival: Array<"early" | "morning" | "afternoon" | "night">;
		}>
	>;
}) => {
	const flights: ["departure", "arrival"] = ["departure", "arrival"];
	const times: Array<{
		label: string;
		val: "early" | "morning" | "afternoon" | "night";
		startTime: string;
		endTime: string;
	}> = [
		{ label: "Early", val: "early", startTime: "00:00", endTime: "06:00" },
		{ label: "Morning", val: "morning", startTime: "06:00", endTime: "12:00" },
		{
			label: "Afternoon",
			val: "afternoon",
			startTime: "12:00",
			endTime: "18:00",
		},
		{ label: "Night", val: "night", startTime: "18:00", endTime: "00:00" },
	];

	return (
		<div className="CustomTimeFilter">
			{flights.map((item, ind) => (
				<div key={ind}>
					<h4>{item} TIME</h4>
					<div className="CustomTimeFilter__checkboxes">
						{times.map((timeObj, ind_ii) => (
							<div key={ind_ii}>
								<CustomCheckBox
									label={timeObj.label}
									checked={selectedTimes[item].includes(timeObj.val)}
									onChange={() => {
										if (selectedTimes[item].includes(timeObj.val)) {
											let newArr = [...selectedTimes[item]];
											const index = newArr.indexOf(timeObj.val);
											const x = newArr.splice(index, 1);

											setSelectedTimes &&
												setSelectedTimes({ ...selectedTimes, [item]: newArr });
										} else {
											setSelectedTimes &&
												setSelectedTimes({
													...selectedTimes,
													[item]: [...selectedTimes[item], timeObj.val],
												});
										}
									}}
								/>
								<span className="range">{`${timeObj.startTime} - ${timeObj.endTime}`}</span>
							</div>
						))}
					</div>
				</div>
			))}
		</div>
	);
};

export default CustomFilter;
