import { Flights, Hotel, Cars } from "../TripComponents";
import {
	TripTabIcon1,
	TripTabIcon2,
	TripTabIcon3,
	TripTabIcon4,
} from "../pieces/svgs";
import React, { useState, cloneElement } from "react";
import tab1 from "../../resource/img/tab-1.svg";
import tab2 from "../../resource/img/tab-2.svg";
import tab3 from "../../resource/img/tab-3.svg";
import tab4 from "../../resource/img/tab-4.svg";
import "./style.scss";
import EmptyTab from "../TripComponents/EmptyTab";

const TripSelector = (props: any) => {
	const [activeTab, setActiveTab] = useState(0);
	const tabList = [
		{
			name: "Flights",
			icon: <TripTabIcon1 />,
			content: <Flights {...props} />,
		},
		{ name: "Car", icon: <TripTabIcon3 />, content: <Cars /> },
		{
			name: "Hotel",
			icon: <TripTabIcon2 />,
			content: <EmptyTab />,
		},
		{ name: "Experiences", icon: <TripTabIcon4 />, content: <EmptyTab /> },
	];

	return (
		<div className="trip-content">
			<div className="tabs">
				{tabList.map((tabItem, ind) => (
					<p
						className={activeTab === ind ? "active" : ""}
						key={ind}
						onClick={() => setActiveTab(ind)}
					>
						{tabItem.name}
					</p>
				))}
			</div>
			<div className="mob-tabs">
				{tabList.map((tabItem, ind) => (
					<div
						className={`img-wrapper ${activeTab === ind ? "active" : ""}`}
						key={ind}
						onClick={() => setActiveTab(ind)}
					>
						{cloneElement(tabItem.icon, {
							color: activeTab === ind ? "white" : "#9c9393",
						})}
					</div>
				))}
			</div>
			<div className="content">{tabList[activeTab].content}</div>
		</div>
	);
};

export default TripSelector;
