import { useState } from "react";
import { Link } from "react-router-dom";
import "./style.scss";
import {
	BaggageIcon,
	RefundableIcon,
	NonRefundableIcon,
	DepartureIcon,
	ArrivalIcon,
	ShareIcon,
	SaveIcon,
} from "../pieces/svgs";
import { BsMoonStars, BsFillSunFill } from "react-icons/bs";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";
import Button from "../Button";
import MUIModal from "../MUIModal";
import { FareBreakdownModal, ShareModal, VerifyingPrice } from "../../modals";
import { styled, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";
import { useTripStore } from "../../zustand/store";
import { DateTime, Duration } from "ts-luxon";
import {
	minutesToDuration,
	renderDuration,
	diffObjToDuration,
	addDiffs,
	isNightFlight,
} from "../../helpers/helperFuncs";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import MobileFlightDrawer from "./MobileFlightDrawer";
import { toggleSideDrawer } from "../../helpers/helperFuncs";
import { confirmDomesticFlight } from "../../requests";
import { toast } from "react-toastify";
import CustomTooltip from "../CustomTooltip";
import PolicyTag from "../PolicyTag";

interface FlightInfoDrawerOutboundProps {
	airline_details: {
		logo: string;
		name: string;
		code: string;
	};
	departure_time: string;
	arrival_time: string;
	baggage: string;
	layover: number;
	airport_to: string;
	airport_from: string;
	cabin_type: string;
	duration: number;
	equipment_type: string;
}

interface FlightInfoDrawerProps {
	onSelect?: () => void;
	selected?: boolean;
	flightInfo: {
		amount: number;
		tax: number;
		id: string;
		outbound:
			| FlightInfoDrawerOutboundProps[]
			| FlightInfoDrawerOutboundProps[][];
		outbound_return: FlightInfoDrawerOutboundProps[];
		arrivalTime: string;
		arrivalCity: string;
		departureTime: string;
		departureCity: string;
		total_outbound_duration: any;
		total_outbound_duration_return: any;
		outbound_stops: any;
		outbound_stops_return: number;
	};
	return_date: string;
	departure_date: string;
	departure: string;
	arrival: string;
	route: string;
	departureCity: any;
	arrivalCity: any;
	openedIndex: null | number | "selected";
	onOpen: () => void;
	currIndex: number | "selected";
	resetOpen: () => void;
	changeAmount: (new_val: number) => void;
}

export const BlackTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: "#1a1a1a",
		// color: "rgba(0, 0, 0, 0.87)",
		boxShadow: theme.shadows[1],
		fontSize: 13,
		padding: "0.5rem 1rem",
		typography: {
			fontFamily: "Circular Std",
		},
	},
}));

const FlightInfoDrawer = ({
	flightInfo,
	onSelect,
	selected,
	return_date,
	departure_date,
	departure,
	arrival,
	route,
	departureCity,
	arrivalCity,
	openedIndex,
	onOpen,
	currIndex,
	resetOpen,
	changeAmount,
}: FlightInfoDrawerProps): JSX.Element => {
	const [isOpen, setIsOpen] = useState(false);
	const [mobileIsOpen, setMobileIsOpen] = useState(false);
	const [openShare, setOpenShare] = useState(false);
	const [openBreakdown, setOpenBreakdown] = useState(false);
	const [verifyingPrice, setVerifyingPrice] = useState(false);

	// zustand global states
	const bigFlightData = useTripStore((state: any) => state.bigFlightData);
	const updateBigFlightData = useTripStore(
		(state: any) => state.updateBigFlightData
	);
	const localAirports = useTripStore((state: any) => state.localAirports);
	const globalAirports = useTripStore((state: any) => state.globalAirports);

	if (isOpen) console.log("flightInfo", flightInfo);

	const isRoundTripAndIntl =
		bigFlightData?.showTripType === "Roundtrip" &&
		bigFlightData?.route === "international";

	const isMulticityAndIntl =
		bigFlightData.showTripType === "Multicity" &&
		bigFlightData.route === "international";

	const confirmFlightPricing = async (): Promise<{
		is_valid: boolean;
		new_price: number;
		error: boolean;
	}> => {
		if (bigFlightData?.route === "domestic") {
			setVerifyingPrice(true);
			try {
				const res = await confirmDomesticFlight(flightInfo?.id);
				console.log("confirmDomesticFlight", res);

				if (res.data?.data?.message === "Provided flight ID not found.") {
					return {
						is_valid: false,
						new_price: 0,
						error: true,
					};
				} else if (res.data?.data?.code === 500) {
					return {
						is_valid: false,
						new_price: 0,
						error: true,
					};
				} else
					return {
						is_valid: res.data?.data?.amount === flightInfo?.amount,
						new_price: res.data?.data?.amount,
						error: false,
						// is_valid: 100000 === flightInfo?.amount,
						// new_price: 100000,
					};
			} catch (e) {
				console.log(e);
				toast.error("something went wrong");
				return {
					is_valid: false,
					new_price: 0,
					error: true,
				};
			} finally {
				setVerifyingPrice(false);
			}
		} else
			return {
				is_valid: true,
				new_price: flightInfo?.amount,
				error: false,
			};
	};

	const validatedSelect = onSelect
		? async () => {
				const priceValidation = await confirmFlightPricing();

				if (priceValidation.error) toast.error("Price validation error");
				else {
					if (priceValidation.is_valid) {
						onSelect();
						setIsOpen(false);
						resetOpen();
					} else {
						toast.info(
							`Flight price has changed. The new price is ₦${priceValidation.new_price}`
						);
						changeAmount(priceValidation.new_price);
						onSelect();
						resetOpen();
						// flightInfo.amount = priceValidation.new_price;
					}
				}
		  }
		: () => {};

	if (flightInfo?.outbound)
		return (
			<div className="FlightInfoDrawer">
				<div
					className={`FlightInfoDrawer__Overview FlightInfoDrawer__Overview--${
						selected ? "selected" : ""
					}`}
					onClick={() => {
						if (document.documentElement.clientWidth > 930) {
							//   setIndex(index);
							if (!isOpen) onOpen();
							else resetOpen();

							setIsOpen(!isOpen);
						} else {
							setMobileIsOpen(true);
						}
					}}
				>
					<div
						className="overview_outbounds"
						style={{
							gridTemplateRows:
								bigFlightData?.showTripType === "Roundtrip" &&
								bigFlightData?.route === "international"
									? "repeat(2, 1fr)"
									: "1fr",
						}}
					>
						{isMulticityAndIntl ? (
							(flightInfo?.outbound as FlightInfoDrawerOutboundProps[][])?.map(
								(outboundItem, ind) => (
									<>
										<div className="overview_outbounds__outbound">
											<div className="airline">
												<img
													src={`https://image.tiqwa.com/airlines/${outboundItem[0].airline_details.code}.png`}
													alt=""
												/>
												<p>{outboundItem[0].airline_details.name}</p>
											</div>
											<div className="MobTimesWrap">
												<div className="times">
													<div className="double-block">
														<p>
															{DateTime.fromISO(
																outboundItem[0].departure_time
															).toLocaleString(DateTime.TIME_24_SIMPLE)}
														</p>
														<p>{outboundItem[0]?.airport_from}</p>
													</div>
													<div className="stopover-indicator">
														<div>
															{Array(
																(
																	flightInfo.outbound[
																		ind
																	] as FlightInfoDrawerOutboundProps[]
																).length - 1
															)
																.fill("")
																.map((item, secInd) => (
																	<div className="stop-circle" key={secInd}>
																		<CustomTooltip>
																			{`${
																				globalAirports.filter(
																					(item: any) =>
																						item.iata_code ===
																						(
																							flightInfo.outbound[
																								ind
																							] as FlightInfoDrawerOutboundProps[]
																						)[secInd]?.airport_to
																				)[0]?.name
																			} (${
																				(
																					flightInfo.outbound[
																						ind
																					] as FlightInfoDrawerOutboundProps[]
																				)[secInd]?.airport_to
																			})`}
																		</CustomTooltip>
																	</div>
																))}
														</div>
													</div>
													<div className="double-block">
														<p>
															{DateTime.fromISO(
																outboundItem[outboundItem.length - 1]
																	.arrival_time
															).toLocaleString(DateTime.TIME_24_SIMPLE)}
														</p>
														{/* <p>
                  					{new Date(
                					    flightInfo?.outbound[flightInfo.outbound.length - 1].departure_time
                					  ).getTime()}
                					</p> */}
														<p>
															{
																outboundItem[outboundItem.length - 1]
																	?.airport_to
															}
														</p>
													</div>
												</div>
												<span></span>
											</div>
											<div className="double-block time-direct">
												{route === "domestic" ? (
													<p>
														<span>
															{Math.floor(
																flightInfo.total_outbound_duration / 60
															) + "h"}
														</span>
														<span> </span>
														<span>
															{(flightInfo.total_outbound_duration % 60) + "m"}
														</span>
													</p>
												) : (
													<p>
														{renderDuration(
															isMulticityAndIntl
																? flightInfo.total_outbound_duration[ind]
																: flightInfo.total_outbound_duration
														)}
														{/* {diffObjToDuration(
															DateTime.fromISO(
																(
																	flightInfo?.outbound[
																		ind
																	] as FlightInfoDrawerOutboundProps[]
																)[
																	(
																		flightInfo?.outbound[
																			ind
																		] as FlightInfoDrawerOutboundProps[]
																	).length - 1
																].arrival_time
															)
																.diff(
																	DateTime.fromISO(
																		(
																			flightInfo?.outbound[
																				ind
																			] as FlightInfoDrawerOutboundProps[]
																		)[0].departure_time
																	),
																	["days", "hours", "minutes"]
																)
																.toObject()
														)} */}
														{/* {addDiffs(
															(
																flightInfo.outbound[ind] as FlightInfoDrawerOutboundProps[]
															).map((trip) =>
																DateTime.fromISO(trip.arrival_time)
																	.diff(DateTime.fromISO(trip.departure_time), [
																		"days",
																		"hours",
																		"minutes",
																	])
																	.toObject()
															)
														)} */}
													</p>
												)}
												<p>
													{flightInfo.outbound_stops === 0
														? "direct"
														: `${flightInfo.outbound_stops[ind]} stop${
																flightInfo.outbound_stops[ind] === 2 ? "" : "s"
														  }`}
												</p>
											</div>
											<div className="icons">
												<BaggageIcon />
												<NonRefundableIcon />
											</div>
										</div>
									</>
								)
							)
						) : (
							<>
								<div className="overview_outbounds__outbound">
									<div className="airline">
										<img
											src={`https://image.tiqwa.com/airlines/${
												(
													flightInfo
														.outbound[0] as FlightInfoDrawerOutboundProps
												).airline_details.code
											}.png`}
											alt=""
										/>
										<p>
											{
												(
													flightInfo
														.outbound[0] as FlightInfoDrawerOutboundProps
												).airline_details.name
											}
										</p>
									</div>
									<div className="MobTimesWrap">
										<div className="times">
											<div className="double-block">
												<p>
													{DateTime.fromISO(
														(
															flightInfo
																.outbound[0] as FlightInfoDrawerOutboundProps
														).departure_time
													).toLocaleString(DateTime.TIME_24_SIMPLE)}
												</p>
												<p>
													{
														(
															flightInfo
																.outbound[0] as FlightInfoDrawerOutboundProps
														)?.airport_from
													}
												</p>
											</div>
											<div className="stopover-indicator">
												<div>
													{Array(flightInfo.outbound.length - 1)
														.fill("")
														.map((item, ind) => (
															<div className="stop-circle" key={ind}>
																<CustomTooltip>
																	{`${
																		globalAirports.filter(
																			(item: any) =>
																				item.iata_code ===
																				(
																					flightInfo.outbound[
																						ind
																					] as FlightInfoDrawerOutboundProps
																				)?.airport_to
																		)[0]?.name
																	} (${
																		(
																			flightInfo.outbound[
																				ind
																			] as FlightInfoDrawerOutboundProps
																		)?.airport_to
																	})`}
																</CustomTooltip>
															</div>
														))}
												</div>
											</div>
											<div className="double-block">
												<p>
													{DateTime.fromISO(
														(
															flightInfo?.outbound[
																flightInfo.outbound.length - 1
															] as FlightInfoDrawerOutboundProps
														).arrival_time
													).toLocaleString(DateTime.TIME_24_SIMPLE)}
												</p>
												{/* <p>
                  					{new Date(
                					    flightInfo?.outbound[flightInfo.outbound.length - 1].departure_time
                					  ).getTime()}
                					</p> */}
												<p>
													{
														(
															flightInfo?.outbound[
																flightInfo?.outbound.length - 1
															] as FlightInfoDrawerOutboundProps
														)?.airport_to
													}
												</p>
											</div>
										</div>
										<span></span>
									</div>
									<div className="double-block time-direct">
										{route === "domestic" ? (
											<p>
												<span>
													{Math.floor(flightInfo.total_outbound_duration / 60) +
														"h"}
												</span>
												<span> </span>
												<span>
													{(flightInfo.total_outbound_duration % 60) + "m"}
												</span>
											</p>
										) : (
											<p>
												{renderDuration(flightInfo.total_outbound_duration)}
												{/* {diffObjToDuration(
													DateTime.fromISO(
														(
															flightInfo?.outbound[
																flightInfo?.outbound.length - 1
															] as FlightInfoDrawerOutboundProps
														).arrival_time
													)
														.diff(
															DateTime.fromISO(
																(
																	flightInfo
																		?.outbound[0] as FlightInfoDrawerOutboundProps
																).departure_time
															),
															["days", "hours", "minutes"]
														)
														.toObject()
												)} */}
												{/* {addDiffs(
													(
														flightInfo.outbound as FlightInfoDrawerOutboundProps[]
													).map((trip) =>
														DateTime.fromISO(trip.arrival_time)
															.diff(DateTime.fromISO(trip.departure_time), [
																"days",
																"hours",
																"minutes",
															])
															.toObject()
													)
												)} */}
											</p>
										)}
										<p>
											{flightInfo.outbound_stops === 0
												? "direct"
												: `${flightInfo.outbound_stops} stop${
														flightInfo.outbound_stops === 2 ? "" : "s"
												  }`}
										</p>
									</div>
									<div className="icons">
										<BaggageIcon />
										<NonRefundableIcon />
									</div>
								</div>
								<>
									{isRoundTripAndIntl && (
										<div className="overview_outbounds__outbound">
											<div className="airline">
												<img
													src={`https://image.tiqwa.com/airlines/${flightInfo.outbound_return[0].airline_details.code}.png`}
													alt=""
												/>
												<p>
													{flightInfo.outbound_return[0].airline_details.name}
												</p>
											</div>
											<div className="MobTimesWrap">
												<div className="times">
													<div className="double-block">
														<p>
															{DateTime.fromISO(
																flightInfo?.outbound_return[0].departure_time
															).toLocaleString(DateTime.TIME_24_SIMPLE)}
														</p>
														<p>{arrival}</p>
													</div>
													<div className="stopover-indicator">
														<div>
															{Array(flightInfo.outbound_return.length - 1)
																.fill("")
																.map((item, ind) => (
																	<div className="stop-circle" key={ind}>
																		<CustomTooltip>
																			{`${
																				globalAirports.filter(
																					(item: any) =>
																						item.iata_code ===
																						(
																							flightInfo.outbound_return[
																								ind
																							] as FlightInfoDrawerOutboundProps
																						)?.airport_to
																				)[0]?.name
																			} (${
																				(
																					flightInfo.outbound_return[
																						ind
																					] as FlightInfoDrawerOutboundProps
																				)?.airport_to
																			})`}
																		</CustomTooltip>
																	</div>
																))}
														</div>
													</div>
													<div className="double-block">
														<p>
															{DateTime.fromISO(
																flightInfo?.outbound_return[
																	flightInfo.outbound_return.length - 1
																].arrival_time
															).toLocaleString(DateTime.TIME_24_SIMPLE)}
														</p>
														{/* <p>
                  					{new Date(
                					    flightInfo?.outbound_return[flightInfo.outbound_return.length - 1].departure_time
                					  ).getTime()}
                					</p> */}
														<p>{departure}</p>
													</div>
												</div>
												<span></span>
											</div>
											<div className="double-block time-direct">
												{route === "domestic" ? (
													<p>
														<span>
															{Math.floor(
																flightInfo.total_outbound_duration_return / 60
															) + "h"}
														</span>
														<span> </span>
														<span>
															{(flightInfo.total_outbound_duration_return %
																60) +
																"m"}
														</span>
													</p>
												) : (
													<p>
														{renderDuration(
															flightInfo.total_outbound_duration_return
														)}
														{/* {diffObjToDuration(
															DateTime.fromISO(
																(
																	flightInfo?.outbound_return[
																		flightInfo?.outbound_return.length - 1
																	] as FlightInfoDrawerOutboundProps
																).arrival_time
															)
																.diff(
																	DateTime.fromISO(
																		(
																			flightInfo
																				?.outbound_return[0] as FlightInfoDrawerOutboundProps
																		).departure_time
																	),
																	["days", "hours", "minutes"]
																)
																.toObject()
														)} */}
														{/* {addDiffs(
															(
																flightInfo.outbound_return as FlightInfoDrawerOutboundProps[]
															).map((trip) =>
																DateTime.fromISO(trip.arrival_time)
																	.diff(DateTime.fromISO(trip.departure_time), [
																		"days",
																		"hours",
																		"minutes",
																	])
																	.toObject()
															)
														)} */}
													</p>
												)}
												<p>
													{flightInfo.outbound_stops_return === 0
														? "direct"
														: `${flightInfo.outbound_stops_return} stop${
																flightInfo.outbound_stops_return === 2
																	? ""
																	: "s"
														  }`}
												</p>
											</div>
											<div className="icons">
												<BaggageIcon />
												<NonRefundableIcon />
											</div>
										</div>
									)}
								</>
							</>
						)}
					</div>
					<div className="right">
						<div className="double-block">
							<p>
								₦
								{(
									flightInfo.amount +
									(bigFlightData?.route === "international"
										? flightInfo?.tax
										: 0)
								)
									.toFixed(2)
									.replace(/\d(?=(\d{3})+\.)/g, "$&,")}
							</p>
							<PolicyTag status={true} variant="short" />
						</div>
						{mobileIsOpen ? (
							<IoChevronUp size={18} color="#C4C4C4" />
						) : (
							<IoChevronDown size={18} color="#C4C4C4" />
						)}
					</div>
				</div>
				{/* {selectedIndex === index ? ( */}
				<div
					className={`FlightInfoDrawer__MoreInfo ${
						openedIndex !== currIndex && "FlightInfoDrawer__MoreInfo--shut"
						// !isOpen && "FlightInfoDrawer__MoreInfo--shut"
					}`}
					// style={{ display: isOpen ? "none" : "" }}
					style={{
						maxHeight: isRoundTripAndIntl
							? "1500px"
							: isMulticityAndIntl
							? "2000px"
							: "800px",
					}}
				>
					{isMulticityAndIntl ? (
						<>
							<div
								style={{
									borderBottom: isRoundTripAndIntl
										? "0.8px solid rgba(196, 196, 196, 0.5)"
										: 0,
								}}
							>
								{flightInfo.outbound.map((outboundItem, legInd: number) => (
									<>
										<div className="Departure_nightDay">
											<p>
												{DateTime.fromISO(
													(outboundItem as FlightInfoDrawerOutboundProps[])[0]
														.departure_time
												).toLocaleString({
													weekday: "short",
													day: "2-digit",
													month: "short",
												})}
											</p>
											{isNightFlight(
												(outboundItem as FlightInfoDrawerOutboundProps[])[0]
													.departure_time
											) ? (
												<BlackTooltip title="Night Flight">
													<BsMoonStars color="#243256" />
												</BlackTooltip>
											) : (
												<BlackTooltip title="Morning Flight">
													<BsFillSunFill color="#243256" />
												</BlackTooltip>
											)}
										</div>
										{(outboundItem as FlightInfoDrawerOutboundProps[]).map(
											(trip, ind) => (
												<div className="singleFlight" key={ind}>
													<div className="singleFlight__imgData">
														<img
															src={`https://image.tiqwa.com/airlines/${trip.airline_details.code}.png`}
															className="logo"
															alt=""
														/>
														<div>
															<div
																className="singleFlight__left-grid singleFlight__left-grid-longer"
																style={{ marginBottom: "1.2px" }}
															>
																<span></span>
																<p>
																	{DateTime.fromISO(
																		trip.departure_time
																	).toLocaleString({
																		weekday: "short",
																		day: "2-digit",
																		month: "short",
																	})}
																</p>
																<span></span>
															</div>
															<div className="onePlane">
																<div className="singleFlight__left-grid">
																	<DepartureIcon />
																	<p className="big-black">
																		{DateTime.fromISO(
																			trip.departure_time
																		).toLocaleString(DateTime.TIME_24_SIMPLE)}
																	</p>
																	<p className="big-black">
																		{trip.airport_from}{" "}
																		{
																			globalAirports.filter(
																				(item: any) =>
																					item.iata_code === trip.airport_from
																			)[0]?.name
																		}
																	</p>
																</div>
																<div className="singleFlight__left-grid">
																	<ArrivalIcon />
																	<p className="big-black">
																		{DateTime.fromISO(
																			trip.arrival_time
																		).toLocaleString(DateTime.TIME_24_SIMPLE)}
																	</p>
																	<p className="big-black">
																		{trip.airport_to}{" "}
																		{
																			globalAirports.filter(
																				(item: any) =>
																					item.iata_code === trip.airport_to
																			)[0]?.name
																		}
																	</p>
																</div>
															</div>
															<div
																className="singleFlight__left-grid"
																style={{ marginTop: "0.5rem" }}
															>
																<span></span>
																{route === "domestic" ? (
																	<p>
																		{minutesToDuration(
																			flightInfo.total_outbound_duration[legInd]
																		)}
																	</p>
																) : (
																	<p>
																		{/* {renderDuration(
																			trip.duration as unknown as string
																		)} */}
																		{diffObjToDuration(
																			DateTime.fromISO(trip.arrival_time)
																				.diff(
																					DateTime.fromISO(trip.departure_time),
																					["days", "hours", "minutes"]
																				)
																				.toObject()
																		)}
																	</p>
																)}
																<div className="planeInfo">
																	{/* <span></span> */}
																	<span>{trip.airline_details.name}</span>
																	<span>•</span>
																	<span>
																		{trip.cabin_type.charAt(0).toUpperCase() +
																			trip.cabin_type.slice(1)}
																	</span>
																	<span>•</span>
																	<span>{trip.equipment_type}</span>
																</div>
															</div>
															{ind <
																(
																	flightInfo.outbound as FlightInfoDrawerOutboundProps[][]
																)[legInd].length -
																	1 && (
																<div className="layover-banner">
																	<p>Layover at {trip.airport_to}</p>
																	<div>
																		{(DateTime.fromISO(
																			(
																				flightInfo.outbound as FlightInfoDrawerOutboundProps[][]
																			)[legInd][ind + 1].departure_time
																		)
																			.diff(
																				DateTime.fromISO(trip.arrival_time),
																				["days", "hours", "minutes"]
																			)
																			.toObject()?.hours as number) >= 4 ? (
																			<p>Long Layover</p>
																		) : (
																			<></>
																		)}
																		<p>
																			{diffObjToDuration(
																				DateTime.fromISO(
																					(
																						flightInfo.outbound as FlightInfoDrawerOutboundProps[][]
																					)[legInd][ind + 1].departure_time
																				)
																					.diff(
																						DateTime.fromISO(trip.arrival_time),
																						["days", "hours", "minutes"]
																					)
																					.toObject()
																			)}
																		</p>
																	</div>
																</div>
															)}
														</div>
													</div>
													{legInd === 0 && ind === 0 && (
														<div className="extras">
															<div>
																<div className="two-grid">
																	<NonRefundableIcon />
																	<span>Non-refundable</span>
																</div>
																<div className="two-grid">
																	<BaggageIcon />
																	<span>{trip.baggage}</span>
																</div>
															</div>
														</div>
													)}
												</div>
											)
										)}
									</>
								))}
								<div className="bottom">
									<div className="bottom__left-grid">
										<span></span>
										{/* <p className="fare" onClick={() => setOpenBreakdown(true)}>
										Fare breakdown
									</p> */}
									</div>
									<Button
										className="pry"
										style={{
											fontWeight: 450,
											fontSize: "13.6895px",
											lineHeight: "17px",
											padding: "10px 17px",
										}}
										onClick={validatedSelect}
									>
										{selected ? "Selected" : "Select"}
									</Button>
								</div>
								<div className="bottomActions">
									<button>Fare breakdown</button>
									<PolicyTag status={true} variant="long" />
									<div className="bottomActions__buttons">
										<button
											onClick={() => {
												setOpenShare(true);
											}}
										>
											<ShareIcon />
											<span>Share</span>
										</button>
										<button>
											<SaveIcon />
											<span>Save</span>
										</button>
									</div>
								</div>
							</div>
						</>
					) : (
						<>
							<div
								style={{
									borderBottom: isRoundTripAndIntl
										? "0.8px solid rgba(196, 196, 196, 0.5)"
										: 0,
								}}
							>
								<div className="Departure_nightDay">
									<p>
										Departure{" "}
										{DateTime.fromISO(
											(flightInfo?.outbound[0] as FlightInfoDrawerOutboundProps)
												.departure_time
										).toLocaleString({
											weekday: "short",
											day: "2-digit",
											month: "short",
										})}
									</p>
									{isNightFlight(
										(flightInfo.outbound as FlightInfoDrawerOutboundProps[])[0]
											.departure_time
									) ? (
										<BlackTooltip title="Night Flight">
											<BsMoonStars color="#243256" />
										</BlackTooltip>
									) : (
										<BlackTooltip title="Morning Flight">
											<BsFillSunFill color="#243256" />
										</BlackTooltip>
									)}
								</div>
								{(flightInfo.outbound as FlightInfoDrawerOutboundProps[]).map(
									(trip, ind) => (
										<div className="singleFlight" key={ind}>
											<div className="singleFlight__imgData">
												<img
													src={`https://image.tiqwa.com/airlines/${trip.airline_details.code}.png`}
													className="logo"
													alt=""
												/>
												<div>
													<div
														className="singleFlight__left-grid singleFlight__left-grid-longer"
														style={{ marginBottom: "1.2px" }}
													>
														<span></span>
														<p>
															{DateTime.fromISO(
																trip.departure_time
															).toLocaleString({
																weekday: "short",
																day: "2-digit",
																month: "short",
															})}
														</p>
														<span></span>
													</div>
													<div className="onePlane">
														<div className="singleFlight__left-grid">
															<DepartureIcon />
															<p className="big-black">
																{DateTime.fromISO(
																	trip.departure_time
																).toLocaleString(DateTime.TIME_24_SIMPLE)}
															</p>
															<p className="big-black">
																{trip.airport_from}{" "}
																{
																	globalAirports.filter(
																		(item: any) =>
																			item.iata_code === trip.airport_from
																	)[0]?.name
																}
															</p>
														</div>
														<div className="singleFlight__left-grid">
															<ArrivalIcon />
															<p className="big-black">
																{DateTime.fromISO(
																	trip.arrival_time
																).toLocaleString(DateTime.TIME_24_SIMPLE)}
															</p>
															<p className="big-black">
																{trip.airport_to}{" "}
																{
																	globalAirports.filter(
																		(item: any) =>
																			item.iata_code === trip.airport_to
																	)[0]?.name
																}
															</p>
														</div>
													</div>
													<div
														className="singleFlight__left-grid"
														style={{ marginTop: "0.5rem" }}
													>
														<span></span>
														{route === "domestic" ? (
															<p>
																{minutesToDuration(
																	flightInfo.total_outbound_duration
																)}
															</p>
														) : (
															<p>
																{/* {renderDuration(
																	flightInfo.total_outbound_duration
																)} */}
																{diffObjToDuration(
																	DateTime.fromISO(trip.arrival_time)
																		.diff(
																			DateTime.fromISO(trip.departure_time),
																			["days", "hours", "minutes"]
																		)
																		.toObject()
																)}
															</p>
														)}
														<div className="planeInfo">
															{/* <span></span> */}
															<span>{trip.airline_details.name}</span>
															<span>•</span>
															<span>
																{trip.cabin_type.charAt(0).toUpperCase() +
																	trip.cabin_type.slice(1)}
															</span>
															<span>•</span>
															<span>{trip.equipment_type}</span>
														</div>
													</div>
													{ind < flightInfo.outbound.length - 1 && (
														<div className="layover-banner">
															<p>Layover at {trip.airport_to}</p>
															<div>
																{(DateTime.fromISO(
																	(
																		flightInfo.outbound[
																			ind + 1
																		] as FlightInfoDrawerOutboundProps
																	).departure_time
																)
																	.diff(DateTime.fromISO(trip.arrival_time), [
																		"days",
																		"hours",
																		"minutes",
																	])
																	.toObject()?.hours as number) >= 4 ? (
																	<p>Long Layover</p>
																) : (
																	<></>
																)}
																<p>
																	{diffObjToDuration(
																		DateTime.fromISO(
																			(
																				flightInfo.outbound[
																					ind + 1
																				] as FlightInfoDrawerOutboundProps
																			).departure_time
																		)
																			.diff(
																				DateTime.fromISO(trip.arrival_time),
																				["days", "hours", "minutes"]
																			)
																			.toObject()
																	)}
																</p>
															</div>
														</div>
													)}
												</div>
											</div>
											{ind === 0 && (
												<div className="extras">
													<div>
														<div className="two-grid">
															<NonRefundableIcon />
															<span>Non-refundable</span>
														</div>
														<div className="two-grid">
															<BaggageIcon />
															<span>{trip.baggage}</span>
														</div>
													</div>
												</div>
											)}
										</div>
									)
								)}
								{!isRoundTripAndIntl && (
									<>
										<div className="bottom">
											<div className="bottom__left-grid">
												<span></span>
												{/* <p className="fare" onClick={() => setOpenBreakdown(true)}>
									Fare breakdown
								</p> */}
											</div>
											<Button
												className="pry"
												style={{
													fontWeight: 450,
													fontSize: "13.6895px",
													lineHeight: "17px",
													padding: "10px 17px",
												}}
												onClick={validatedSelect}
											>
												{selected ? "Selected" : "Select"}
											</Button>
										</div>
										<div className="bottomActions">
											<button>Fare breakdown</button>
											<PolicyTag status={true} variant="long" />
											<div className="bottomActions__buttons">
												<button
													onClick={() => {
														setOpenShare(true);
													}}
												>
													<ShareIcon />
													<span>Share</span>
												</button>
												<button>
													<SaveIcon />
													<span>Save</span>
												</button>
											</div>
										</div>
									</>
								)}
							</div>

							{isRoundTripAndIntl && (
								<div>
									<div className="Departure_nightDay">
										<p>
											Return{" "}
											{DateTime.fromISO(
												(
													flightInfo
														?.outbound_return[0] as FlightInfoDrawerOutboundProps
												).departure_time
											).toLocaleString({
												weekday: "short",
												day: "2-digit",
												month: "short",
											})}
										</p>

										{isNightFlight(
											(
												flightInfo.outbound_return as FlightInfoDrawerOutboundProps[]
											)[0].departure_time
										) ? (
											<BlackTooltip title="Night Flight">
												<BsMoonStars color="#243256" />
											</BlackTooltip>
										) : (
											<BlackTooltip title="Morning Flight">
												<BsFillSunFill color="#243256" />
											</BlackTooltip>
										)}
									</div>
									{flightInfo.outbound_return.map((trip, ind) => {
										console.log("arrival_time", trip.arrival_time);
										console.log(
											"departure_time",
											ind < flightInfo.outbound_return.length - 1
												? flightInfo.outbound_return[ind + 1].departure_time
												: 0
										);

										if (ind < flightInfo.outbound_return.length - 1) {
											const diff = DateTime.fromISO(
												flightInfo.outbound_return[ind + 1].departure_time
											).diff(DateTime.fromISO(trip.arrival_time), [
												"days",
												"hours",
												"minutes",
											]);
											console.log("diff", diffObjToDuration(diff.toObject()));
										}

										return (
											<div className="singleFlight" key={ind}>
												<div className="singleFlight__imgData">
													<img
														src={`https://image.tiqwa.com/airlines/${trip.airline_details.code}.png`}
														className="logo"
														alt=""
													/>
													<div>
														<div
															className="singleFlight__left-grid singleFlight__left-grid-longer"
															style={{ marginBottom: "1.2px" }}
														>
															<span></span>
															<p>
																{DateTime.fromISO(
																	trip.departure_time
																).toLocaleString({
																	weekday: "short",
																	day: "2-digit",
																	month: "short",
																})}
															</p>
															<span></span>
														</div>
														<div className="onePlane">
															<div className="singleFlight__left-grid">
																<DepartureIcon />
																<p className="big-black">
																	{DateTime.fromISO(
																		trip.departure_time
																	).toLocaleString(DateTime.TIME_24_SIMPLE)}
																</p>
																<p className="big-black">
																	{trip.airport_from}{" "}
																	{
																		globalAirports.filter(
																			(item: any) =>
																				item.iata_code === trip.airport_from
																		)[0]?.name
																	}
																</p>
															</div>
															<div className="singleFlight__left-grid">
																<ArrivalIcon />
																<p className="big-black">
																	{DateTime.fromISO(
																		trip.arrival_time
																	).toLocaleString(DateTime.TIME_24_SIMPLE)}
																</p>
																<p className="big-black">
																	{trip.airport_to}{" "}
																	{
																		globalAirports.filter(
																			(item: any) =>
																				item.iata_code === trip.airport_to
																		)[0]?.name
																	}
																</p>
															</div>
														</div>
														<div
															className="singleFlight__left-grid"
															style={{ marginTop: "0.5rem" }}
														>
															<span></span>
															{route === "domestic" ? (
																<p>
																	{minutesToDuration(
																		flightInfo.total_outbound_duration_return
																	)}
																</p>
															) : (
																<p>
																	{/* {renderDuration(
																		flightInfo.total_outbound_duration_return
																	)} */}
																	{diffObjToDuration(
																		DateTime.fromISO(trip.arrival_time)
																			.diff(
																				DateTime.fromISO(trip.departure_time),
																				["days", "hours", "minutes"]
																			)
																			.toObject()
																	)}
																</p>
															)}
															<div className="planeInfo">
																<span>{trip.airline_details.name}</span>
																<span>•</span>
																<span>
																	{trip.cabin_type.charAt(0).toUpperCase() +
																		trip.cabin_type.slice(1)}
																</span>
																<span>•</span>
																<span>{trip.equipment_type}</span>
															</div>
														</div>
														{ind < flightInfo.outbound_return.length - 1 && (
															<div className="layover-banner">
																<p>Layover at {trip.airport_to}</p>
																<div>
																	{(DateTime.fromISO(
																		flightInfo.outbound_return[ind + 1]
																			.departure_time
																	)
																		.diff(DateTime.fromISO(trip.arrival_time), [
																			"days",
																			"hours",
																			"minutes",
																		])
																		.toObject()?.hours as number) >= 4 ? (
																		<p>Long Layover</p>
																	) : (
																		<></>
																	)}
																	<p>
																		{diffObjToDuration(
																			DateTime.fromISO(
																				flightInfo.outbound_return[ind + 1]
																					.departure_time
																			)
																				.diff(
																					DateTime.fromISO(trip.arrival_time),
																					["days", "hours", "minutes"]
																				)
																				.toObject()
																		)}
																	</p>
																</div>
															</div>
														)}
													</div>
												</div>
											</div>
										);
									})}
									<div className="bottom">
										<div className="bottom__left-grid">
											<span></span>
											{/* <p className="fare" onClick={() => setOpenBreakdown(true)}>
									Fare breakdown
								</p> */}
										</div>
										<Button
											className="pry"
											style={{
												fontWeight: 450,
												fontSize: "13.6895px",
												lineHeight: "17px",
												padding: "10px 17px",
											}}
											onClick={validatedSelect}
										>
											{selected ? "Selected" : "Select"}
										</Button>
									</div>
									<div className="bottomActions">
										<button>Fare breakdown</button>
										<PolicyTag status={true} variant="long" />
										<div className="bottomActions__buttons">
											<button
												onClick={() => {
													setOpenShare(true);
												}}
											>
												<ShareIcon />
												<span>Share</span>
											</button>
											<button>
												<SaveIcon />
												<span>Save</span>
											</button>
										</div>
									</div>
								</div>
							)}
						</>
					)}
				</div>

				<SwipeableDrawer
					anchor="top"
					open={mobileIsOpen}
					onClose={toggleSideDrawer(false, setMobileIsOpen)}
					onOpen={toggleSideDrawer(true, setMobileIsOpen)}
				>
					<MobileFlightDrawer
						mobileIsOpen={mobileIsOpen}
						setMobileIsOpen={setMobileIsOpen}
						flightInfo={flightInfo}
						selected={selected}
						onSelect={onSelect}
						setOpenShare={setOpenShare}
						setOpenBreakdown={setOpenBreakdown}
						route={route}
						changeAmount={changeAmount}
						setVerifyingPrice={setVerifyingPrice}
					/>
				</SwipeableDrawer>
				<MUIModal open={openShare} handleClose={() => setOpenShare(false)}>
					<ShareModal flightInfo={flightInfo} />
				</MUIModal>
				<MUIModal
					open={openBreakdown}
					handleClose={() => setOpenBreakdown(false)}
				>
					<FareBreakdownModal />
				</MUIModal>
				<MUIModal
					open={verifyingPrice}
					handleClose={() => setVerifyingPrice(false)}
					cantClose={true}
				>
					<VerifyingPrice />
				</MUIModal>
			</div>
		);
	else return <></>;
};

export default FlightInfoDrawer;
