import { Skeleton } from "@mui/material";
import "./style.scss";

const SummarySkeleton = () => {
	return (
		<main className="SummarySkeleton">
			<div className="SummarySkeleton__left">
				<Skeleton variant="rectangular" />
				<Skeleton variant="rectangular" />
				<Skeleton variant="rectangular" />
				<Skeleton variant="rectangular" />
				<Skeleton variant="rectangular" />
			</div>
			<Skeleton variant="rectangular" height="100%" />
		</main>
	);
};

export default SummarySkeleton;
