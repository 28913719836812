import { useState } from "react";
import { BiLoader } from "react-icons/bi";
import "./style.scss";

interface LoadMoreProps {
	loadFunc: () => void;
	disabled: boolean;
}

const LoadMore = ({ loadFunc, disabled }: LoadMoreProps) => {
	const [loading, setLoading] = useState(false);

	return (
		<div
			className={`LoadMore LoadMore--${loading && "loading"} LoadMore--${
				disabled && "disabled"
			}`}
			onClick={() => {
				if (!disabled) {
					setLoading(true);
					setTimeout(() => {
						loadFunc();
						setLoading(false);
					}, 500);
				}
			}}
		>
			<BiLoader />
			<span>Load More</span>
		</div>
	);
};

export default LoadMore;
