import "./style.scss";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";

const ComingSoon = () => {
	const navigate = useNavigate();

	return (
		<main className="ComingSoon">
			<div>
				<h1 className="ComingSoon__title">Coming soon</h1>
				<p className="ComingSoon__desc">
					{/* This feature is not available yet. You can continue exploring. */}
					We're cooking up something special. Check back soon!
				</p>
				<div className="ComingSoon__buttons">
					<Button className="pry" onClick={() => navigate("/")}>
						Back Home
					</Button>
					<Button className="pry" onClick={() => navigate("/trips")}>
						Search Flights
					</Button>
				</div>
			</div>
		</main>
	);
};

export default ComingSoon;
