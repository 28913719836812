import UserAvatar from "../../../components/UserAvatar";
import { useTripStore } from "../../../zustand/store";
import "./PassengerBlock.scss";

const PassengerBlock = ({
	disabled,
	titleRight = <></>,
	variant = "flight",
}: {
	disabled: boolean;
	titleRight?: JSX.Element;
	variant?: "flight" | "car" | "hotel";
}) => {
	const bigFlightData = useTripStore((state: any) => state.bigFlightData);
	const bigCarData = useTripStore((state: any) => state.bigCarData);

	console.log("bigCarData", bigCarData);

	return (
		<div className="PassengerBlock">
			<p className="PassengerBlock__title">Passengers {titleRight}</p>
			<div className="users">
				{(variant === "flight"
					? bigFlightData
					: variant === "car"
					? bigCarData
					: bigCarData
				).selectedTraveller?.map((user: any) => (
					<UserAvatar userInfo={user} disabled={disabled} />
				))}
			</div>
		</div>
	);
};

export default PassengerBlock;
