import { useNavigate } from "react-router-dom";
import {
	PersonalProfile,
	CompanyProfile,
	Travellers,
	PaymentMethod,
	Security,
} from "./components";
import "./style.scss";
import CustomDropdown from "../../components/CustomDropdown";
import UploadPhoto from "../../components/UploadPhoto";

const SettingsPage = () => {
	const navigate = useNavigate();

	const settingsPages = [
		{
			name: "Personal profile",
			component: <PersonalProfile />,
			hash: "personal-profile",
		},
		{
			name: "Company profile",
			component: <CompanyProfile />,
			hash: "company-profile",
		},
		{ name: "Travellers", component: <Travellers />, hash: "travellers" },
		// {
		//     name: "Payment method",
		//     component: <PaymentMethod />,
		//     hash: "payment-method",
		// },
		{ name: "Security", component: <Security />, hash: "security" },
	];

	console.log(window.location.hash);

	return (
		<div className="SettingsPage">
			<div className="paddedTop">
				<div className="paddedTop__titleImg">
					<h3>Account settings</h3>
					<UploadPhoto />
				</div>
				<div>
					<CustomDropdown
						onSelect={(val: any) => {
							navigate(`#${val}`);
						}}
						defaultValue={window.location.hash.slice(1)}
						optionsList={[
							{
								label: "Personal profile",
								value: "personal-profile",
							},
							{
								label: "Company profile",
								value: "company-profile",
							},
							{ label: "Travellers", value: "travellers" },
							// {
							//     label: "Payment method",
							//     value: "payment-method",
							// },
							{ label: "Security", value: "security" },
						]}
						ind={0}
					/>
				</div>
			</div>

			<div className="settings-grid">
				<aside>
					<ul>
						{settingsPages.map((pageItem, ind) => (
							<li
								className={`sideItem ${
									settingsPages.filter(
										(item) => `#${item.hash}` === window.location.hash
									).length === 0 && ind === 0
										? "sideItem--active"
										: `#${pageItem.hash}` === window.location.hash &&
										  "sideItem--active"
								}`}
								key={ind}
								onClick={() => {
									navigate(`#${pageItem.hash}`);
								}}
							>
								{pageItem.name}
							</li>
						))}
					</ul>
				</aside>
				{settingsPages.filter(
					(item) => `#${item.hash}` === window.location.hash
				)[0]?.component ?? <PersonalProfile />}
			</div>
		</div>
	);
};

export default SettingsPage;
