import { useState, useEffect, useRef } from "react";
import "./style.scss";
import PlainTable from "../../../components/PlainTable";
import { RiErrorWarningFill } from "react-icons/ri";
import { HiPrinter } from "react-icons/hi";
import { MdOutlineContactSupport } from "react-icons/md";
import UserAvatar from "../../../components/UserAvatar";
import { useTripStore } from "../../../zustand/store";
import { toast } from "react-toastify";
import { DateTime } from "ts-luxon";
import { minutesToDuration } from "../../../helpers/helperFuncs";
import {
	FlightDetailsBit,
	ShowDetailsDrawer,
	PassengerBlock,
	FootNoteCard,
	PriceBreakdownBlock,
} from "../confirmationComponents";
import ConfirmationLayout from "../../../layouts/ConfirmationLayout";
import { ConfirmedStamp } from "../../../components/pieces/svgs";
import confirmed from "../../../assets/images/confirmed.svg";
import { SingleFlight } from "../OnHoldSummaryPage";
import SummarySkeleton from "../../../components/SummarySkeleton";
import { Navigate, useNavigate } from "react-router-dom";

const BookedSummaryPage = (props: any) => {
	const navigate = useNavigate();
	// zustand states
	const bigFlightData = useTripStore((state: any) => state.bigFlightData);
	const updateBigFlightData = useTripStore(
		(state: any) => state.updateBigFlightData
	);
	const transitionLoading = useTripStore(
		(state: any) => state.transitionLoading
	);
	const setTransitionLoading = useTripStore(
		(state: any) => state.setTransitionLoading
	);

	const [initialRender, setInitialRender] = useState(false);
	const [imgUrl, setImgUrl] = useState("/images/fun-img.png");
	const [FormattedAddr, setFormattedAddr] = useState("/images/fun-img.png");
	const tripRef = useRef<HTMLDivElement>(null);

	console.log(props);

	const isRoundTripAndIntl =
		bigFlightData?.showTripType === "Roundtrip" &&
		bigFlightData?.route === "international";

	const isMultiCityAndLocal =
		bigFlightData?.showTripType === "Multicity" &&
		bigFlightData?.route === "domestic";

	const isMulticityAndIntl =
		bigFlightData.showTripType === "Multicity" &&
		bigFlightData.route === "international";

	let fareCost =
		bigFlightData?.selectedDeparture?.amount +
		(bigFlightData?.selectedArrival === undefined
			? 0
			: bigFlightData?.selectedArrival?.amount);
	let tax =
		bigFlightData?.selectedDeparture?.tax === undefined
			? 0
			: bigFlightData?.selectedDeparture?.tax +
			  (bigFlightData?.selectedArrival === undefined
					? 0
					: bigFlightData?.selectedArrival?.tax);
	let bookingFee = 0;

	if (isRoundTripAndIntl || isMulticityAndIntl) {
		fareCost = bigFlightData.selectedDeparture?.amount;
		tax = bigFlightData.selectedDeparture.tax;
	}
	if (isMultiCityAndLocal) {
		fareCost = bigFlightData.selectedMultiCityFlights.reduce(
			(total: number, selectedObj: any) => total + selectedObj?.amount,
			0
		);
		tax = 0;
	}

	const amount = fareCost + tax;

	// useEffect(() => {
	// 	toast.success("Your flight has been booked");
	// 	const PS = new google.maps.places.PlacesService(tripRef.current!);
	// 	const fetchFunc = async () => {
	// 		try {
	// 			PS.textSearch(
	// 				{ query: bigFlightData.arrivalCity[0].city },
	// 				(res: any) => {
	// 					console.log("photores", res);
	// 					setFormattedAddr(res[0]?.formatted_address);
	// 					const url = res[0]?.photos[0].getUrl();
	// 					console.log(url);
	// 					setImgUrl(url);
	// 				}
	// 			);

	// 			// console.log("photores", res);
	// 		} catch (e) {
	// 			console.log("queryres", e);
	// 		}
	// 	};
	// 	fetchFunc();
	// 	// PS.findPlaceFromQuery()
	// }, []);

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setInitialRender(true);
		}, 2000);

		return () => clearTimeout(timeoutId);
	}, []);

	useEffect(() => {
		if (initialRender) {
			if (bigFlightData.paymentConfirmed && bigFlightData.bookingConfirmed)
				return;
			else {
				navigate("/trips");
			}
		}
	}, [initialRender]);

	if (bigFlightData.paymentConfirmed && bigFlightData.bookingConfirmed)
		return (
			<>
				<div className="BookedSummaryPage">
					<ConfirmationLayout>
						<div>
							<img
								src={confirmed}
								alt=""
								style={{
									width: "84px",
									height: "84px",
								}}
							/>
							<h3>
								Your trip to <span>{bigFlightData?.arrivalCity[0]?.city}</span>{" "}
								is being processed!
							</h3>
							<p className="msg">
								Thanks for choosing Kardinal. Your e-ticket is being processed,
								and will be sent to your email and to the emails of all other
								passengers on this trip.
							</p>
							<PassengerBlock disabled={true} />
							<div className="left-tables">
								<PlainTable
									tableHead="Flight Information"
									tableBody={<PriceBreakdownBody props={props} />}
								/>
								<div className="dets">
									<PlainTable
										tableHead="Flight details"
										tableBody={<FlightDetailsBody props={props} />}
									/>
								</div>
								<FootNoteCard
									title="2474U"
									textCol="#FCA17D"
									bgCol="#FFEAE2"
									icon={<MdOutlineContactSupport size={16} />}
								>
									<div>
										<p style={{ marginBottom: "0.8rem" }}>
											Thank you for your booking.
										</p>
										<p>
											We are available to help you 24/7. Please contact us on
											any of the number below:{" "}
											<a href="tel:+2348026993460">+234 802 6993 460</a>,{" "}
											<a href="tel:+2348121463765">+234 802 5198 619</a> or
											email us on{" "}
											<a href="mailto:bookings@gokardinal.com">
												bookings@gokardinal.com
											</a>
										</p>
									</div>
								</FootNoteCard>
							</div>
						</div>
						<div>
							<div style={{ marginTop: "6.2rem" }}>
								<PriceBreakdownBlock tax={tax} fareCost={fareCost} />
							</div>
						</div>
					</ConfirmationLayout>
				</div>
				<div className="BookedSummaryPage-mobile">
					<div>
						<img src={confirmed} alt="" />

						<h3>
							Your trip to <span>{bigFlightData?.arrivalCity[0]?.city}</span> is
							booked!
						</h3>
						<p className="msg">
							Thanks for choosing Kardinal. Your e-ticket is being processed,
							and will be sent to your email and to the emails of all other
							passengers on this trip.
						</p>
					</div>

					<div className="FlightInformation">
						<p className="FlightInformation__title">Flight information</p>
						<div className="double-col">
							<p className="gray">Booking Reference</p>
							<span>:</span>
							<p>{bigFlightData.flightReference}</p>
						</div>
						<div className="double-col">
							<p className="gray">Booking date</p>
							<span>:</span>
							<p>
								{DateTime.fromISO(new Date().toISOString()).toLocaleString({
									weekday: "short",
									day: "2-digit",
									month: "short",
								})}
							</p>
						</div>
						<div className="double-col">
							<p className="gray">Payment Status</p>
							<span>:</span>
							<p style={{ color: "#21D047" }}>Confirmed!</p>
						</div>
					</div>

					<div className="passengers">
						<PassengerBlock disabled={true} />
					</div>

					<FlightDetailsBit />

					<div className="price-breakdown">
						<PriceBreakdownBlock tax={tax} fareCost={fareCost} />
					</div>

					<FootNoteCard
						title="2474U"
						textCol="#FCA17D"
						bgCol="#FFEAE2"
						icon={<MdOutlineContactSupport size={16} />}
					>
						<div>
							<p style={{ marginBottom: "0.8rem" }}>
								Thank you for your booking.
							</p>
							<p>
								We are available to help you 24/7. Please contact us on any of
								the number below:{" "}
								<a href="tel:+2348026993460">+234 802 6993 460</a>,{" "}
								<a href="tel:+2348121463765">+234 802 5198 619</a> or email us
								on{" "}
								<a href="mailto:bookings@gokardinal.com">
									bookings@gokardinal.com
								</a>
							</p>
						</div>
					</FootNoteCard>
				</div>
				<div ref={tripRef}></div>
			</>
		);
	else return <SummarySkeleton />;
};

export default BookedSummaryPage;

// tableBody Components
const PriceBreakdownBody = (props: any) => {
	const bigFlightData = useTripStore((state: any) => state.bigFlightData);
	const updateBigFlightData = useTripStore(
		(state: any) => state.updateBigFlightData
	);

	return (
		<div className="tableBody priceBreakdown">
			<div className="double-col">
				<p>Booking Date</p>
				<p style={{ color: "#252627" }}>
					{DateTime.fromISO(new Date().toISOString()).toLocaleString({
						weekday: "short",
						day: "2-digit",
						month: "short",
					})}
				</p>
			</div>
			<div className="double-col">
				<p>Ticket Reference</p>
				<p style={{ color: "#252627" }}>{bigFlightData.flightReference}</p>
			</div>
			<div className="double-col">
				<p>Payment Status</p>
				<p style={{ color: "#21D047" }}>Confirmed!</p>
			</div>
			{/* <div className="double-col">
				<p></p>
				<p className="printer">
					<HiPrinter />
					<span>Print ticket</span>
				</p>
			</div> */}
		</div>
	);
};

const FlightDetailsBody = (props: any) => {
	const bigFlightData = useTripStore((state: any) => state.bigFlightData);
	const updateBigFlightData = useTripStore(
		(state: any) => state.updateBigFlightData
	);

	const isMultiCityAndLocal =
		bigFlightData?.showTripType === "Multicity" &&
		bigFlightData?.route === "domestic";

	const isMulticityAndIntl =
		bigFlightData.showTripType === "Multicity" &&
		bigFlightData.route === "international";

	return (
		<div className="flightDetails">
			{isMultiCityAndLocal ? (
				bigFlightData?.selectedMultiCityFlights?.map(
					(multiItem: any, ind: number) => (
						<SingleFlight
							outboundList={multiItem.outbound}
							selectedFlight={multiItem}
							badge={`Flight ${ind + 1}`}
						/>
					)
				)
			) : isMulticityAndIntl ? (
				bigFlightData.selectedDeparture?.outbound?.map(
					(multiItem: any, ind: number) => (
						<SingleFlight
							outboundList={multiItem}
							selectedFlight={bigFlightData.selectedDeparture}
							badge={`Flight ${ind + 1}`}
							ind={ind}
						/>
					)
				)
			) : (
				<>
					<SingleFlight
						outboundList={bigFlightData.selectedDeparture.outbound}
						selectedFlight={bigFlightData.selectedDeparture}
						badge="Departure"
					/>
					{bigFlightData.showTripType === "Roundtrip" ? (
						bigFlightData?.route === "domestic" ? (
							<SingleFlight
								outboundList={bigFlightData.selectedArrival.outbound}
								selectedFlight={bigFlightData.selectedArrival}
								badge="Return"
							/>
						) : (
							<SingleFlight
								outboundList={bigFlightData.selectedDeparture.outbound_return}
								selectedFlight={bigFlightData.selectedDeparture}
								badge="Return"
							/>
						)
					) : null}
				</>
			)}
		</div>
	);
};
