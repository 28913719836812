import { DateTime, DurationObject, DurationOptions } from "ts-luxon";
import { Dispatch, SetStateAction } from "react";
import {
	fetchFlightData,
	fetchMulticityFlightData,
	combinableSearch,
	addSearchHistory,
} from "../requests";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { countryCodeList } from "./countryCodeList";

export const morphSearchHistory = (
	searchArr: {
		id: number;
		from_location: string;
		to_location: string;
		departure_date: string;
		return_date: string;
		user: number;
	}[]
): { name: string; date: string }[] =>
	searchArr.map((item) => ({
		name: `${item.from_location} - ${item.to_location}`,
		date: `${DateTime.fromISO(item.departure_date).toLocaleString({
			day: "2-digit",
			month: "short",
		})}
            ${
							item.return_date
								? ` -  
            ${DateTime.fromISO(item.return_date).toLocaleString({
							day: "2-digit",
							month: "short",
						})}`
								: ""
						}`,
	}));

export const hideCardNumber = (cardNo: Number) => {
	const stringified = cardNo.toString();

	return `${stringified.slice(0, 4)} **** **** ${stringified.slice(-4)}`;
};

export const morphOnHoldData = (onHoldArr: any) => {
	console.log("onHoldArr", onHoldArr);
	if (onHoldArr) {
		console.log(
			Object.keys(onHoldArr)
				.reverse()
				.map((item) => ({
					kardinal_referenceerence: item,
					destination: `${onHoldArr[item][0]?.itenary}`,
					passenger: `${onHoldArr[item][0]?.travelers[0]?.first_name} ${onHoldArr[item][0]?.travelers[0]?.last_name}`,
					deadline: "1 hour",
					status: "Process",
				}))
		);
		return Object.keys(onHoldArr)
			.reverse()
			.map((item) => ({
				kardinal_referenceerence: item,
				destination: `${onHoldArr[item][0]?.itenary}`,
				passenger: `${onHoldArr[item][0]?.travelers[0]?.first_name} ${onHoldArr[item][0]?.travelers[0]?.last_name}`,
				deadline: "1 hour",
				status: "Process",
			}));
	} else return [];
};

export const OnHoldDataToArr = (onHoldObj: any) => {
	return Object.keys(onHoldObj)
		.reverse()
		.map((item) => ({
			array: onHoldObj[item],
			kardinal_reference: item,
		}));
};

export const capitalizeFirstLetter = (text: string) =>
	text.charAt(0).toUpperCase() + text.slice(1);

export const renderDuration = (dateString: string = "12:30") => {
	console.log("dateString", dateString);
	// renders duration D.HH:MM:SS in format 5d, 12h or 12h, 7m
	let days, hours, minutes, seconds;

	if (dateString?.includes(".")) {
		days = dateString.split(".")[0];
		[hours, minutes, seconds] = dateString.split(".")[1].split(":");
		return `${Number(days)}d${
			Number(hours) !== 0 ? `, ${Number(hours)}h` : ""
		}`;
	} else {
		[hours, minutes, seconds] = dateString.split(":");
		return `${Number(hours)}h${
			Number(minutes) !== 0 ? `, ${Number(minutes)}m` : ""
		}`;
	}
};

export const minutesToDuration = (minutesNum: number): string => {
	let minutes, hours;

	hours = Math.floor(minutesNum / 60);
	minutes = minutesNum % 60;

	if (hours === 0) return `${minutes}m`;
	else return `${hours}h${minutes > 0 ? `, ${minutes}m` : ""}`;
};

export const diffObjToDuration = (
	diffObj: DurationObject & Partial<DurationOptions>
): string => {
	const { days, hours, minutes } = diffObj;
	console.log("diffObj", diffObj);

	return `${days ? `${days}d${hours && minutes ? ", " : ""}` : ""}${
		hours ? `${hours}h${minutes ? ", " : ""}` : ""
	}${minutes ? `${minutes}m` : ""}`;
	// return "";
};

export const addDiffs = (
	diffArr: (DurationObject & Partial<DurationOptions>)[]
): string => {
	console.log("diffArr", diffArr);

	let day: number = 0;
	let hour: number = 0;
	let minute: number = 0;

	diffArr.forEach((diffObj) => {
		day += diffObj.days ?? 0;
		hour += diffObj.hours ?? 0;
		minute += diffObj.minutes ?? 0;
	});

	if (minute >= 60) {
		hour = hour + Math.floor(minute / 60);
		minute = minute % 60;
	}
	if (hour >= 24) {
		day = day + Math.floor(hour / 24);
		hour = hour % 24;
	}

	return `${day ? `${day}d${hour && minute ? ", " : ""}` : ""}${
		hour ? `${hour}h${minute ? ", " : ""}` : ""
	}${minute ? `${minute}m` : ""}`;
	// return "";
};

export const convertDuration = (dateString: string) => {
	let days,
		hours,
		minutes,
		seconds = "0";
	let totalSeconds = 0;

	if (dateString?.includes(".")) {
		days = dateString?.split(".")[0];
		[hours, minutes, seconds] = dateString?.split(".")[1].split(":");
		totalSeconds += Number(seconds);
		totalSeconds += Number(minutes) * 60;
		totalSeconds += Number(hours) * 60 * 60;
		totalSeconds += Number(days) * 24 * 60 * 60;
	} else {
		[hours, minutes, seconds] = dateString.split(":");
		totalSeconds += Number(seconds);
		totalSeconds += Number(minutes) * 60;
		totalSeconds += Number(hours) * 60 * 60;
	}

	return totalSeconds;
};

export const compareDurationV2 = (dateNumA: any, dateNumB: any) =>
	dateNumA?.convertedDuration - dateNumB?.convertedDuration;

export const prettifyMoney = (value: number) => {
	if (value < 1000000)
		return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
	else
		return Intl.NumberFormat("en-US", {
			notation: "compact",
			maximumFractionDigits: 1,
		}).format(value);
};

export const longNumToMoney = (value: number) => {
	/* converts long numbers to money format (with commas, and in 2 decimal places)

	e.g:
	103549 to 103,549.00
	*/

	return value?.toFixed(2)?.replace(/\d(?=(\d{3})+\.)/g, "$&,");
};

export const toggleSideDrawer =
	(open: boolean, setOpen: Dispatch<SetStateAction<boolean>>) =>
	(event: any) => {
		if (
			event &&
			event.type === "keydown" &&
			(event.key === "Tab" || event.key === "Shift")
		) {
			return;
		}

		setOpen(open);
	};

export function focusAndOpenKeyboard(
	el: HTMLElement,
	parent: HTMLElement,
	timeout: number
) {
	// console.log("el", el);
	// console.log("parent", parent);

	if (!timeout) {
		timeout = 100;
	}
	if (el) {
		// Align temp input element approximately where the input element is
		// so the cursor doesn't jump around
		var __tempEl__: HTMLInputElement = document.createElement("input");
		__tempEl__.style.position = "absolute";
		__tempEl__.style.top = el.offsetTop + 7 + "px";
		__tempEl__.style.left = el.offsetLeft + "px";
		__tempEl__.style.height = "0";
		__tempEl__.style.opacity = "0";
		__tempEl__.classList.add("temp-input");
		// Put this temp element as a child of the page <body> and focus on it
		parent.appendChild(__tempEl__);
		__tempEl__.focus();

		// The keyboard is open. Now do a delayed focus on the target element
		setTimeout(function () {
			el.focus();
			el.click();
			// Remove the temp element
			parent.removeChild(__tempEl__);
		}, timeout);
	}
}

export const addPaystackCharges = (amount: number): number => {
	let final_amount: number;
	let fee_cap = 2000;
	const decimal_fee = 1.5 / 100;
	const flat_fee = 100;
	const applicable_fees = decimal_fee * amount + flat_fee;

	if (applicable_fees > fee_cap) final_amount = amount + fee_cap;
	else final_amount = (amount + flat_fee) / (1 - decimal_fee) + 0.01;
	console.log("final_amount", final_amount);

	return final_amount;
};

export const isNightFlight = (dateString: string) => {
	if (
		(DateTime.fromISO(dateString).startOf("minute").toISOTime() ?? "") >
			"17:00" &&
		(DateTime.fromISO(dateString).startOf("minute").toISOTime() ?? "") < "23:59"
	)
		return true;

	return false;
};

export const generateItenary = (bigFlightData: any): string => {
	// this is for all flights, local or international

	/*
	RESULT FORMAT:
	one way: LOS-LHR
	roundtrip: LOS-LHR-LOS
	multicity: LOS-LHR-JFK-ABV
	*/

	let result = "";

	if (bigFlightData?.showTripType === "Multicity") {
		result += `${bigFlightData?.multiCity[0]?.departure}-`;
		bigFlightData?.multiCity?.forEach((obj: any, ind: number) => {
			result += `${obj?.destination}${
				ind < bigFlightData?.multiCity?.length - 1 ? "-" : ""
			}`;
		});
	} else if (bigFlightData?.showTripType === "Roundtrip") {
		result += `${bigFlightData?.departure}-${bigFlightData?.destination}-${bigFlightData?.departure}`;
	} else {
		result += `${bigFlightData?.departure}-${bigFlightData?.destination}`;
	}

	return result;
};

export const generateLayoverArray = (bigFlightData: any): any[] => {
	//	this is for only international flights, cos they have layovers. It represents the layovers a flight has.
	// Note that the last one on each row is always an empty string, as that's the final destination on that leg.

	/*

	RESULT FORMAT:
		[
			{ 0: ["1h", "1h", ""] },
			{ 1: ["1h", "1h", ""] },
			{ 2: ["1h", "1h", ""] },
			{ 3: ["1h", "1h", ""] },
		]
	*/

	let result: any[] = [];
	let layoverObj: any = {};

	if (bigFlightData?.showTripType === "Multicity") {
		bigFlightData?.selectedDeparture?.outbound?.forEach(
			(outboundItem: any, legInd: number) => {
				let legArr: string[] = [];
				outboundItem.forEach((trip: any, ind: number) => {
					let val = "";
					if (
						ind <
						(bigFlightData?.selectedDeparture.outbound)[legInd].length - 1
					)
						val = diffObjToDuration(
							DateTime.fromISO(
								(bigFlightData?.selectedDeparture.outbound)[legInd][ind + 1]
									.departure_time
							)
								.diff(DateTime.fromISO(trip.arrival_time), [
									"days",
									"hours",
									"minutes",
								])
								.toObject()
						);

					legArr.push(val);
				});
				layoverObj = { ...layoverObj, [legInd]: legArr };
			}
		);
	} else if (bigFlightData?.showTripType === "Roundtrip") {
		//departure
		let legArr: string[] = [];
		bigFlightData?.selectedDeparture?.outbound?.forEach(
			(trip: any, ind: number) => {
				let val = "";
				if (ind < bigFlightData?.selectedDeparture.outbound.length - 1)
					val = diffObjToDuration(
						DateTime.fromISO(
							(bigFlightData?.selectedDeparture.outbound)[ind + 1]
								.departure_time
						)
							.diff(DateTime.fromISO(trip.arrival_time), [
								"days",
								"hours",
								"minutes",
							])
							.toObject()
					);

				legArr.push(val);
			}
		);
		layoverObj = { ...layoverObj, [0]: legArr };

		//return
		legArr = [];
		bigFlightData?.selectedDeparture?.outbound_return?.forEach(
			(trip: any, ind: number) => {
				let val = "";
				if (ind < bigFlightData?.selectedDeparture.outbound_return.length - 1)
					val = diffObjToDuration(
						DateTime.fromISO(
							(bigFlightData?.selectedDeparture.outbound_return)[ind + 1]
								.departure_time
						)
							.diff(DateTime.fromISO(trip.arrival_time), [
								"days",
								"hours",
								"minutes",
							])
							.toObject()
					);

				legArr.push(val);
			}
		);
		layoverObj = { ...layoverObj, [1]: legArr };
	} else {
		//departure
		let legArr: string[] = [];
		bigFlightData?.selectedDeparture?.outbound?.forEach(
			(trip: any, ind: number) => {
				let val = "";
				if (ind < bigFlightData?.selectedDeparture.outbound.length - 1)
					val = diffObjToDuration(
						DateTime.fromISO(
							(bigFlightData?.selectedDeparture.outbound)[ind + 1]
								.departure_time
						)
							.diff(DateTime.fromISO(trip.arrival_time), [
								"days",
								"hours",
								"minutes",
							])
							.toObject()
					);

				legArr.push(val);
			}
		);
		layoverObj = { ...layoverObj, [0]: legArr };
	}

	result.push(layoverObj);

	return result;
};

const nn = {
	first_name: "Tomiwa",
	full_name: "Tomiwa Daniel Adekola",
	title: "mr",
	amount: 979243.0,
	booking_reference: "KNFBEJCR",
	issue_date: "Tue, 21 February 2023",
	current_year: "2023",
	airline_reference: "2VU67B",
	itenary: [
		[
			{
				departure_time: "13:40:00, Thu, 23 February 2023",
				arrival_time: "21:00:00, Thu, 23 February 2023",
				flight_duration: "05:20:00",
				origin: "LOS",
				origin_city: "temp",
				origin_airport: "Lagos-Murtala Muhammed Intl, Nigeria",
				destination: "ADD",
				destination_city: "temp",
				destination_airport: "Addis Ababa-Bole Intl, Ethiopia",
				airport_code: "LOS",
				cabin_type: "Economy",
				baggage: "2PCS",
				airline_name: "Ethiopian Airlines",
				airline_number: "900",
				airline_logo: "http://rest.resvoyage.com/content/airline-logos/ET.png",
				layover: "2h 50m",
			},
			{
				departure_time: "01:20:00, Fri, 24 February 2023",
				arrival_time: "06:35:00, Fri, 24 February 2023",
				flight_duration: "08:15:00",
				origin: "ADD",
				origin_city: "temp",
				origin_airport: "Addis Ababa-Bole Intl, Ethiopia",
				destination: "LHR",
				destination_city: "temp",
				destination_airport: "London-Heathrow, United Kingdom",
				airport_code: "ADD",
				cabin_type: "Economy",
				baggage: "2PCS",
				airline_name: "Ethiopian Airlines",
				airline_number: "700",
				airline_logo: "http://rest.resvoyage.com/content/airline-logos/ET.png",
				layover: "",
			},
		],
		[
			{
				departure_time: "20:15:00, Sat, 25 February 2023",
				arrival_time: "07:00:00, Sun, 26 February 2023",
				flight_duration: "07:45:00",
				origin: "LHR",
				origin_city: "temp",
				origin_airport: "London-Heathrow, United Kingdom",
				destination: "ADD",
				destination_city: "temp",
				destination_airport: "Addis Ababa-Bole Intl, Ethiopia",
				airport_code: "LHR",
				cabin_type: "Economy",
				baggage: "2PCS",
				airline_name: "Ethiopian Airlines",
				airline_number: "701",
				airline_logo: "http://rest.resvoyage.com/content/airline-logos/ET.png",
				layover: "2h 50m",
			},
			{
				departure_time: "11:30:00, Sun, 26 February 2023",
				arrival_time: "13:00:00, Sun, 26 February 2023",
				flight_duration: "02:30:00",
				origin: "ADD",
				origin_city: "temp",
				origin_airport: "Addis Ababa-Bole Intl, Ethiopia",
				destination: "KGL",
				destination_city: "temp",
				destination_airport: "Kigali-International, Rwanda",
				airport_code: "ADD",
				cabin_type: "Economy",
				baggage: "2PCS",
				airline_name: "Ethiopian Airlines",
				airline_number: "807",
				airline_logo: "http://rest.resvoyage.com/content/airline-logos/ET.png",
				layover: "",
			},
		],
	],
};
export const ellipsisTruncation = (str: string, maxLength: number) => {
	if (str.length > maxLength) return str.slice(0, maxLength) + "...";
	else return str;
};

export const parseCommaSeperate = (emailsString: string): string[] => {
	const emailsArray: string[] = emailsString.split(",");
	return emailsArray.map((email) => email.trim());
};

export const validateEmails = (emails: string[]): boolean => {
	const regex = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
	return emails.every((email) => regex.test(email));
};

type FlightType = "oneway" | "Roundtrip" | "multicity";

interface Flight {
	departure: string;
	destination: string;
}

interface FlightBooking {
	type: FlightType;
	departure?: string;
	destination?: string;
	trips?: Flight[];
}

export const parseItinerary = (itinerary: string): FlightBooking => {
	const cities = itinerary.split("-");

	if (cities.length === 2) {
		// One-way flight
		return {
			departure: cities[0],
			destination: cities[1],
			type: "oneway",
		};
	} else if (cities.length === 3 && cities[0] === cities[2]) {
		// Roundtrip flight
		return {
			departure: cities[0],
			destination: cities[1],
			type: "Roundtrip",
		};
	} else {
		// Multi-city flight
		const trips = [];
		for (let i = 0; i < cities.length - 1; i++) {
			trips.push({
				departure: cities[i],
				destination: cities[i + 1],
			});
		}
		return {
			trips: trips,
			type: "multicity",
		};
	}
};

export const extractAirlineCode = (logoURL: string): string => {
	const regex = /\/([A-Z0-9]+)\.png$/;
	const match = logoURL.match(regex);
	if (match && match[1]) {
		return match[1];
	}
	return "";
};

export const generateMonthList = (year: number): string[] => {
	const months: string[] = [];
	for (let month = 0; month < 12; month++) {
		const date = new Date(year, month, 1);
		const monthAbbreviation = new Intl.DateTimeFormat("en-US", {
			month: "short",
		}).format(date);
		months.push(monthAbbreviation);
	}
	return months;
};

export const itineraryExpandCities = (
	itinerary: string,
	airportList: any[]
): string => {
	const cities = itinerary.split("-");
	let fullCities: string[] = [];

	for (const city of cities) {
		fullCities.push(
			airportList.filter((item) => item?.iata_code === city)[0]?.city
		);
	}

	return fullCities.join(" - ");
};

interface Range {
	from: string;
	to: string;
	preference?: string;
}

export const formatDateRange = (range: Range): string => {
	const fromDate = DateTime.fromISO(range.from);
	const toDate = DateTime.fromISO(range.to);

	if (fromDate.hasSame(toDate, "day")) {
		return fromDate.toFormat("d' 'MMMM',' yyyy");
	}

	return `${fromDate.toFormat("dd' '")} - ${toDate.toFormat(
		"dd' 'MMMM',' yyyy"
	)}`;
};

export function getUniqueUserObjects(arr1: any[], arr2: number[]): any[] {
	const uniqueObjects: any[] = [];

	for (const obj of arr1) {
		if (!arr2.includes(obj.id)) {
			uniqueObjects.push(obj);
		}
	}

	return uniqueObjects;
}

export function splitNumber(value: string) {
	const country_codes = countryCodeList.map((item) => item.dial_code);

	for (let i = 0; i < country_codes.length; i++) {
		if (value.includes(country_codes[i])) {
			let code_size = country_codes[i].length;
			return {
				code: value.substring(0, code_size),
				number: value.substring(code_size),
			};
		}
	}
}

export const isTimeBetween = (
	isoString: string,
	startHour: number,
	endHour: number
): boolean => {
	const dateTime = DateTime.fromISO(isoString);
	const hour = dateTime.toFormat("H");

	console.log(
		"isTimeBetween",
		isoString,
		startHour,
		endHour,
		Number(hour) >= startHour && Number(hour) < endHour
	);
	return Number(hour) >= startHour && Number(hour) < endHour;
};
