import "./style.scss";
import { useState } from "react";
import MUIModal from "../MUIModal";
import {
	AddNewTravellerModalRewrite,
	PassengerDetailsModal,
} from "../../modals";
import { fetchTravellers } from "../../requests";
import { useTripStore } from "../../zustand/store";
import { toggleSideDrawer } from "../../helpers/helperFuncs";
import { Drawer } from "@mui/material";
import { EditIcon, PhWarningIcon } from "../pieces/svgs";
import { RiErrorWarningFill, RiErrorWarningLine } from "react-icons/ri";

interface UserAvatarProps {
	userInfo: {
		id: number;
		first_name: string;
		middle_name: string;
		last_name: string;
		imageURL?: string;
		email?: string;
		phone_number?: string;
		is_complete: boolean;
	};
	size?: number;
	disabled?: boolean;
}

const UserAvatar = ({
	userInfo,
	size = 34,
	disabled = false,
}: UserAvatarProps) => {
	const colors = ["#FF7477", "#E69597", "#CEB5B7", "#B5D6D6", "#B9EBBF"];
	console.log(userInfo);

	const setMyTravellers = useTripStore((state: any) => state.setMyTravellers);
	const bigFlightData = useTripStore((state: any) => state.bigFlightData);
	const updateBigFlightData = useTripStore(
		(state: any) => state.updateBigFlightData
	);

	const currentCol = colors[Math.floor(Math.random() * colors.length)];
	const [showDetails, setShowDetails] = useState(false);

	const refetchFunc = async () => {
		try {
			const travellerRes = await fetchTravellers();
			console.log("travellerRes", travellerRes);
			setMyTravellers(travellerRes?.data?.data);

			setTimeout(() => {
				const currInd = bigFlightData?.selectedTraveller?.findIndex(
					(item: any) => item?.id === userInfo?.id
				);
				const newArr = [
					...bigFlightData?.selectedTraveller?.slice(0, currInd),
					travellerRes?.data?.data?.filter(
						(item: any) => item?.id === userInfo?.id
					)[0],
					...bigFlightData?.selectedTraveller?.slice(currInd + 1),
				];
				updateBigFlightData({ selectedTraveller: newArr });
			}, 0);
		} catch (e) {
			console.log(e);
		} finally {
		}
	};

	return (
		<>
			<div
				className={`UserAvatar UserAvatar--${
					bigFlightData?.route === "international" &&
					!userInfo.is_complete &&
					"incomplete"
				}`}
				onClick={() => {
					if (!disabled) setShowDetails(true);
				}}
			>
				{userInfo.imageURL ? (
					<img
						src={userInfo.imageURL}
						alt="profile"
						className="UserAvatar__circle"
					/>
				) : (
					<p
						className="UserAvatar__circle"
						style={{
							width: `${size}px`,
							height: `${size}px`,
							minWidth: `${size}px`,
							background: currentCol,
						}}
					>
						{userInfo.first_name.slice(0, 1)}
						{userInfo.last_name.slice(0, 1)}
					</p>
				)}
				<span>{`${userInfo.first_name} ${userInfo.middle_name} ${userInfo.last_name}`}</span>

				<span className="UserAvatar__farRight">
					{/* {disabled ? userInfo.email : "Edit"} */}
					{bigFlightData?.route === "international" &&
						!userInfo.is_complete && <PhWarningIcon />}
					{disabled ? (
						document.documentElement.clientWidth < 780 ? (
							""
						) : (
							userInfo.email
						)
					) : (
						<div className="editFlex">
							<EditIcon color="#9c9393" />
							<span>edit</span>
						</div>
					)}
				</span>
				<div className="UserInfo">
					<div
						className="circle"
						style={{
							background: currentCol,
						}}
					>
						{userInfo.imageURL ? (
							<img src={userInfo.imageURL} alt="profile" />
						) : (
							<p>
								{userInfo.first_name.slice(0, 1)}
								{userInfo.last_name.slice(0, 1)}
							</p>
						)}
					</div>
					<div>
						<p className="name">
							{userInfo?.first_name} {userInfo?.last_name}
						</p>
						<p className="email">{userInfo?.email}</p>
						<p>{userInfo?.phone_number}</p>
					</div>
				</div>
			</div>

			{document.documentElement.clientWidth > 780 && (
				<MUIModal open={showDetails} handleClose={() => setShowDetails(false)}>
					<AddNewTravellerModalRewrite
						currentTraveller={userInfo}
						handleClose={() => setShowDetails(false)}
						edit={true}
						showAdd={false}
						refetchFunc={refetchFunc}
						swap={true}
					/>
				</MUIModal>
			)}

			{document.documentElement.clientWidth <= 780 && (
				<Drawer
					anchor="top"
					open={showDetails}
					onClose={toggleSideDrawer(false, setShowDetails)}
					// onOpen={toggleSideDrawer(true, setShowTraveller)}
				>
					<AddNewTravellerModalRewrite
						currentTraveller={userInfo}
						handleClose={() => setShowDetails(false)}
						edit={true}
						showAdd={false}
						refetchFunc={refetchFunc}
						swap={true}
					/>
				</Drawer>
			)}
		</>
	);
};

export default UserAvatar;
