import { PersonOutline } from "../pieces/svgs";
import "./style.scss";

const UploadPhoto = ({ title }: { title?: string }) => (
	<div className="UploadPhoto">
		<div className="UploadPhoto__box">
			<PersonOutline />
			<button>Upload</button>
		</div>
		{title && <p>{title}</p>}
	</div>
);

export default UploadPhoto;
