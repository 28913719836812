import ShowDetailsDrawer from "./ShowDetailsDrawer";
import { useTripStore } from "../../../zustand/store";
import { useState } from "react";
import { DateTime } from "ts-luxon";
import {
	diffObjToDuration,
	minutesToDuration,
} from "../../../helpers/helperFuncs";
import "./style.scss";
import Badge from "./Badge";

const FlightDetailsBit = () => {
	const bigFlightData = useTripStore((state: any) => state.bigFlightData);
	const globalAirports = useTripStore((state: any) => state.globalAirports);

	const isMultiCityAndLocal =
		bigFlightData?.showTripType === "Multicity" &&
		bigFlightData?.route === "domestic";

	const isMulticityAndIntl =
		bigFlightData.showTripType === "Multicity" &&
		bigFlightData.route === "international";

	return (
		<div className="flight-details">
			<h4>Flight details</h4>

			{isMultiCityAndLocal ? (
				bigFlightData?.selectedMultiCityFlights?.map((multiItem: any) => (
					<div className="section">
						<div className="two-col">
							<div>
								<Badge variant="Departure" />
							</div>
							<div className="locations">
								<div>
									<p className="black">
										{
											globalAirports.filter(
												(item: any) =>
													item.iata_code ===
													multiItem?.outbound[0]?.airport_from
											)[0]?.city
										}{" "}
										({multiItem.outbound[0].airport_from})
									</p>
									<p>
										{DateTime.fromISO(
											multiItem?.outbound[0].departure_time
										).toLocaleString(DateTime.TIME_24_SIMPLE)}{" "}
										hrs
									</p>
								</div>
								<div>
									<p className="black">
										{
											globalAirports.filter(
												(item: any) =>
													item.iata_code ===
													multiItem?.outbound[0]?.airport_from
											)[multiItem?.outbound?.length - 1]?.city
										}{" "}
										(
										{
											multiItem.outbound[multiItem?.outbound?.length - 1]
												.airport_to
										}
										)
									</p>
									<p>
										{DateTime.fromISO(
											multiItem?.outbound[multiItem?.outbound?.length - 1]
												.arrival_time
										).toLocaleString(DateTime.TIME_24_SIMPLE)}{" "}
										hrs
									</p>
								</div>
							</div>
						</div>
						<div className="two-col">
							<div>
								<img
									src={`https://image.tiqwa.com/airlines/${multiItem?.outbound[0].airline_details.code}.png`}
									alt=""
								/>
							</div>
							<div>
								<p className="black">
									{DateTime.fromISO(
										multiItem?.outbound[0].departure_time
									).toLocaleString({
										weekday: "long",
										day: "2-digit",
										month: "short",
									})}
								</p>
								<p>{minutesToDuration(multiItem.total_outbound_duration)}</p>
							</div>
						</div>

						<ShowDetailsDrawer
							type="departure"
							total_outbound_duration={multiItem?.total_outbound_duration}
							outboundList={[]}
						/>
					</div>
				))
			) : isMulticityAndIntl ? (
				bigFlightData?.selectedDeparture?.outbound?.map((multiItem: any) => {
					console.log("multiItem", multiItem);
					multiItem.map((selectedItem: any) => {
						console.log("selectedItem", selectedItem);
						return (
							<div className="section">
								<div className="two-col">
									<div>
										<Badge variant="Departure" />
									</div>
									<div className="locations">
										<div>
											<p className="black">
												{
													globalAirports.filter(
														(item: any) =>
															item.iata_code === selectedItem?.airport_from
													)[0]?.city
												}{" "}
												({selectedItem.airport_from})
											</p>
											<p>
												{DateTime.fromISO(
													selectedItem.departure_time
												).toLocaleString(DateTime.TIME_24_SIMPLE)}{" "}
												hrs
											</p>
										</div>
										<div>
											<p className="black">
												{
													globalAirports.filter(
														(item: any) =>
															item.iata_code === selectedItem?.airport_from
													)[selectedItem?.length - 1]?.city
												}{" "}
												({selectedItem.airport_to})
											</p>
											<p>
												{DateTime.fromISO(
													selectedItem.arrival_time
												).toLocaleString(DateTime.TIME_24_SIMPLE)}{" "}
												hrs
											</p>
										</div>
									</div>
								</div>
								<div className="two-col">
									<div>
										<img
											src={`https://image.tiqwa.com/airlines/${selectedItem.airline_details.code}.png`}
											alt=""
										/>
									</div>
									<div>
										<p className="black">
											{DateTime.fromISO(
												selectedItem.departure_time
											).toLocaleString({
												weekday: "long",
												day: "2-digit",
												month: "short",
											})}
										</p>
										<p>
											{minutesToDuration(selectedItem?.total_outbound_duration)}
										</p>
									</div>
								</div>

								<ShowDetailsDrawer
									type="departure"
									total_outbound_duration={
										selectedItem?.total_outbound_duration
									}
									outboundList={[]}
								/>
							</div>
						);
					});
				})
			) : (
				<>
					<div className="section">
						<div className="two-col">
							<div>
								<Badge variant="Departure" />
							</div>
							<div className="locations">
								<div>
									<p className="black">
										{bigFlightData?.departureCity[0]?.city} (
										{bigFlightData.selectedDeparture.outbound[0].airport_from})
									</p>
									<p>
										{DateTime.fromISO(
											bigFlightData.selectedDeparture?.outbound[0]
												.departure_time
										).toLocaleString(DateTime.TIME_24_SIMPLE)}{" "}
										hrs
									</p>
								</div>
								<div>
									<p className="black">
										{bigFlightData?.arrivalCity[0]?.city} (
										{
											bigFlightData.selectedDeparture.outbound[
												bigFlightData.selectedDeparture.outbound.length - 1
											].airport_to
										}
										)
									</p>
									<p>
										{DateTime.fromISO(
											bigFlightData.selectedDeparture?.outbound[
												bigFlightData.selectedDeparture.outbound.length - 1
											].arrival_time
										).toLocaleString(DateTime.TIME_24_SIMPLE)}{" "}
										hrs
									</p>
								</div>
							</div>
						</div>
						<div className="two-col">
							<div>
								<img
									src={`https://image.tiqwa.com/airlines/${bigFlightData.selectedDeparture?.outbound[0].airline_details.code}.png`}
									alt=""
								/>
							</div>
							<div>
								<p className="black">
									{DateTime.fromISO(
										bigFlightData.selectedDeparture?.outbound[0].arrival_time
									).toLocaleString({
										weekday: "long",
										day: "2-digit",
										month: "short",
									})}
								</p>
								<p>
									{diffObjToDuration(
										DateTime.fromISO(
											bigFlightData.selectedDeparture?.outbound[
												bigFlightData.selectedDeparture?.outbound.length - 1
											].arrival_time
										)
											.diff(
												DateTime.fromISO(
													bigFlightData.selectedDeparture?.outbound[0]
														.departure_time
												),
												["days", "hours", "minutes"]
											)
											.toObject()
									)}
								</p>
							</div>
						</div>

						<ShowDetailsDrawer
							type="departure"
							total_outbound_duration={
								bigFlightData?.selectedDeparture?.total_outbound_duration
							}
							outboundList={bigFlightData.selectedDeparture.outbound}
						/>
					</div>
					{bigFlightData?.showTripType === "Roundtrip" &&
						(bigFlightData?.route === "domestic" ? (
							<div className="section" style={{ marginBottom: 0 }}>
								<div className="two-col">
									<div>
										<Badge variant="Return" />
									</div>
									<div className="locations">
										<div>
											<p className="black">
												{bigFlightData?.arrivalCity[0]?.city} (
												{bigFlightData.selectedArrival.outbound[0].airport_from}
												)
											</p>
											<p>
												{DateTime.fromISO(
													bigFlightData.selectedArrival?.outbound[0]
														.departure_time
												).toLocaleString(DateTime.TIME_24_SIMPLE)}{" "}
												hrs
											</p>
										</div>
										<div>
											<p className="black">
												{bigFlightData?.departureCity[0]?.city} (
												{
													bigFlightData.selectedArrival.outbound[
														bigFlightData.selectedArrival?.outbound?.length - 1
													].airport_to
												}
												)
											</p>
											<p>
												{DateTime.fromISO(
													bigFlightData.selectedArrival?.outbound[0]
														.arrival_time
												).toLocaleString(DateTime.TIME_24_SIMPLE)}{" "}
												hrs
											</p>
										</div>
									</div>
								</div>
								<div className="two-col">
									<div>
										<img
											src={`https://image.tiqwa.com/airlines/${bigFlightData.selectedArrival?.outbound[0].airline_details.code}.png`}
											alt=""
										/>
									</div>
									<div>
										<p className="black">
											{DateTime.fromISO(
												bigFlightData.selectedArrival?.outbound[0].arrival_time
											).toLocaleString({
												weekday: "long",
												day: "2-digit",
												month: "short",
											})}
										</p>
										<p>
											{minutesToDuration(
												bigFlightData.selectedArrival.total_outbound_duration
											)}
										</p>
									</div>
								</div>

								<ShowDetailsDrawer
									type="return"
									total_outbound_duration={
										bigFlightData?.selectedArrival?.total_outbound_duration
									}
									outboundList={[]}
								/>
							</div>
						) : (
							<div className="section" style={{ marginBottom: 0 }}>
								<div className="two-col">
									<div>
										<Badge variant="Return" />
									</div>
									<div className="locations">
										<div>
											<p className="black">
												{bigFlightData?.arrivalCity[0]?.city} (
												{
													bigFlightData.selectedDeparture.outbound_return[0]
														.airport_from
												}
												)
											</p>
											<p>
												{DateTime.fromISO(
													bigFlightData.selectedDeparture.outbound_return[0]
														.departure_time
												).toLocaleString(DateTime.TIME_24_SIMPLE)}{" "}
												hrs
											</p>
										</div>
										<div>
											<p className="black">
												{bigFlightData?.departureCity[0]?.city} (
												{
													bigFlightData.selectedDeparture.outbound_return[
														bigFlightData.selectedDeparture.outbound_return
															?.length - 1
													].airport_to
												}
												)
											</p>
											<p>
												{DateTime.fromISO(
													bigFlightData.selectedDeparture.outbound_return[
														bigFlightData.selectedDeparture.outbound_return
															?.length - 1
													].arrival_time
												).toLocaleString(DateTime.TIME_24_SIMPLE)}{" "}
												hrs
											</p>
										</div>
									</div>
								</div>
								<div className="two-col">
									<div>
										<img
											src={`https://image.tiqwa.com/airlines/${bigFlightData.selectedArrival?.outbound_return[0].airline_details.code}.png`}
											alt=""
										/>
									</div>
									<div>
										<p className="black">
											{DateTime.fromISO(
												bigFlightData.selectedArrival?.outbound_return[
													bigFlightData.selectedArrival?.outbound_return
														?.length - 1
												].arrival_time
											).toLocaleString({
												weekday: "long",
												day: "2-digit",
												month: "short",
											})}
										</p>
										<p>
											{diffObjToDuration(
												DateTime.fromISO(
													bigFlightData.selectedArrival?.outbound_return[
														bigFlightData.selectedArrival?.outbound_return
															.length - 1
													].arrival_time
												)
													.diff(
														DateTime.fromISO(
															bigFlightData.selectedArrival?.outbound_return[0]
																.departure_time
														),
														["days", "hours", "minutes"]
													)
													.toObject()
											)}
										</p>
									</div>
								</div>

								<ShowDetailsDrawer
									type="return"
									total_outbound_duration={
										bigFlightData?.selectedArrival?.total_outbound_duration
									}
									outboundList={
										bigFlightData?.route === "domestic"
											? bigFlightData.selectedArrival.outbound
											: bigFlightData.selectedDeparture.outbound_return
									}
								/>
							</div>
						))}
				</>
			)}
		</div>
	);
};

export default FlightDetailsBit;
